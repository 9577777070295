import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import Button from "../../../Components/Button/Button";
import { formatDateTime, formatTime } from "../../../Services/Utils/TimeUtils";
import { SponsorRequestStatusEnum } from "../../../Shared/Enums/EventsEnum";
import "./UsersSponsorRequestPreview.scss";
import { APIsAuthorizeUsers } from "../../../Services/APIs/APIsController";
import { ApiId } from "../../../Shared/Enums/APIsEnum";
import { message } from "antd";
import { useState } from "react";
import LoadingDots from "../../../Components/LoadingDots/LoadingDots";
import { useLanguage } from "../../../Services/Hooks/useLanguage";

type Props = {
    currViewRequest: any;
    onInitSponsorRequests?: () => void;
    setCurrViewRequest: (val: any) => void;
}

const UsersSponsorRequestPreview = ({ currViewRequest, onInitSponsorRequests, setCurrViewRequest }: Props) => {
    const { onTranslate } = useLanguage();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const onHandleRequestStatus = () => {
        if (!currViewRequest) return;

        switch (currViewRequest.status) {
            case SponsorRequestStatusEnum.PROCESSING:
                return {
                    key: SponsorRequestStatusEnum.PROCESSING,
                    label: onTranslate('LABEL.PROCESSING')
                }
            case SponsorRequestStatusEnum.CONFIRMED:
                return {
                    key: SponsorRequestStatusEnum.CONFIRMED,
                    label: onTranslate('LABEL.CONFIRMED')
                }
            case SponsorRequestStatusEnum.REJECTED:
                return {
                    key: SponsorRequestStatusEnum.REJECTED,
                    label: onTranslate('LABEL.REJECTED')
                }
            default: return;
        }
    }

    const handleUpdateRequestStatus = async (status: SponsorRequestStatusEnum) => {
        if (!onInitSponsorRequests) return;
        setIsLoading(true);

        const result = await APIsAuthorizeUsers(ApiId.UPDATE_SPONSOR_REQUEST_STATUS, { eventId: currViewRequest.eventId, status, userId: currViewRequest.userId });
        setIsLoading(false);

        if (!result) return;

        message.success(onTranslate('MESSAGE.UPDATED_STATUS_SUCCESS'));
        const newReq = { ...currViewRequest, status: status };
        setCurrViewRequest(newReq);

        onInitSponsorRequests();
    }

    return (
        <div className='sponsorReqPreview'>
            <div className='title'>{onTranslate('LABEL.SPONSOR_REQUEST')} <div className={`tag ${onHandleRequestStatus()}`}>{onHandleRequestStatus()?.label}</div></div>

            <div className="label">{onTranslate('LABEL.NAME')}</div>
            <div className="content">{currViewRequest.fullName}</div>

            <div className="label">{onTranslate('LABEL.EMAIL')}</div>
            <div className="content">{currViewRequest.email} <a href={`mailto:${currViewRequest.email}`}><FaArrowUpRightFromSquare /></a></div>

            <div className="label">{onTranslate('LABEL.REQUESTED_TIME')}</div>
            <div className="content">{formatTime(currViewRequest.createdAt)} {formatDateTime(currViewRequest.createdAt)}</div>

            <div className="label">{onTranslate('LABEL.SPONSOR_TYPE')}</div>
            <div className="content">{currViewRequest.sponsorType}</div>

            <div className="label">{onTranslate('LABEL.AMOUNT')}</div>
            <div className="content">{Number(currViewRequest.amount).toLocaleString()}</div>

            <div className="label">{onTranslate('LABEL.NOTE')}</div>
            <div className="content">{currViewRequest.message}</div>

            {
                isLoading ?
                    <div style={{ margin: '25px 0 25px 0' }}>
                        <LoadingDots />
                    </div>
                    :
                    onHandleRequestStatus()?.key === SponsorRequestStatusEnum.PROCESSING
                        ?
                        <div className="btnCtn">
                            <Button type="secondary" className="rejectBtn" onClick={() => handleUpdateRequestStatus(SponsorRequestStatusEnum.REJECTED)}>{onTranslate('BUTTON.REJECT')}</Button>
                            <Button type="primary" className="acceptBtn" onClick={() => handleUpdateRequestStatus(SponsorRequestStatusEnum.CONFIRMED)}>{onTranslate('BUTTON.ACCEPT')}</Button>
                        </div>
                        :
                        <div className="caution">{onTranslate('MESSAGE.ORGANIZER_UPDATE_REQUEST', onHandleRequestStatus()?.label, `${formatTime(currViewRequest.updatedAt)} ${formatDateTime(currViewRequest.updatedAt)}`)}</div>
            }
        </div>
    )
}

export default UsersSponsorRequestPreview