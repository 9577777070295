import React, { useEffect, useState } from 'react'
import Button from '../../../Components/Button/Button'
import { FaAngleLeft, FaGoogle } from 'react-icons/fa6'
import TextInput from '../../../Components/Input/TextInput'
import { emailValidation } from '../../../Services/Utils/StringUtils'
import { message } from 'antd'
import { ParseIntFromString } from '../../../Services/Utils/NumberUtils'
import OTP from '../../../Components/OTP/OTP'
import { ApiId } from '../../../Shared/Enums/APIsEnum'
import { APIsAuthorizeUsers } from '../../../Services/APIs/APIsController'
import { useLoading } from '../../../Services/Hooks/useLoading'
import { useUser } from '../../../Services/Hooks/useUser'
import { AccountStatus } from '../../../Shared/Enums/UsersEnum'
import { useLanguage } from '../../../Services/Hooks/useLanguage'

type Props = {
    handleFormChange: React.MouseEventHandler;
    setIsLogin: (val: boolean) => void;
}


export const LoginForm = ({ handleFormChange, setIsLogin }: Props) => {
    const { onTranslate } = useLanguage();
    const { updateLoading } = useLoading();
    const { setUser } = useUser();

    const [emailInput, setEmailInput] = useState<string>('');
    const [loginStep, setLoginStep] = useState<number>(1);
    const [otp, setOtp] = useState<string[]>(Array(6).fill(''));

    const onClickSignIn = async () => {
        if (!emailValidation(emailInput)) {
            message.error(onTranslate("MESSAGE.INVALID_EMAIL"));
            return;
        }

        if (loginStep === 1) {
            updateLoading(true, onTranslate("MESSAGE.LOGGING_IN"));

            const res = await APIsAuthorizeUsers(ApiId.SIGNIN_OTP, emailInput);

            updateLoading(false, '');

            if (!res) return;

            setLoginStep(2);
            return;
        }

        // Call API to login
        const OTP = otp.join('')
        updateLoading(true, onTranslate("MESSAGE.VERIFYING_OTP"));

        const res = await APIsAuthorizeUsers(ApiId.VALIDATE_OTP, { email: emailInput, otp: OTP });

        if (res && res !== AccountStatus.BLOCKED) {
            setUser(res);
            message.success(onTranslate("MESSAGE.WELCOME_BACK", res.fullName));
            setIsLogin(false);
        } else {
            message.error(
                res === AccountStatus.BLOCKED
                    ?
                    onTranslate("MESSAGE.SUSPENDED_ACCOUNT")
                    :
                    onTranslate("MESSAGE.LOGIN_FAILED")
            );
        }

        updateLoading(false);
    }

    const onResendOtp = async () => {
        updateLoading(true, onTranslate("MESSAGE.RESEND_OTP"));

        const res = await APIsAuthorizeUsers(ApiId.SIGNIN_OTP, emailInput);

        updateLoading(false, onTranslate("MESSAGE.LOGGING_IN"));
    }

    const onSignInWithGoogle = async () => {
        updateLoading(true, onTranslate("MESSAGE.LOGGING_IN"));

        const res = await APIsAuthorizeUsers(ApiId.SIGNIN_WITH_GOOGLE);

        if (res && res !== AccountStatus.BLOCKED) {
            setUser(res);
            message.success(onTranslate("MESSAGE.WELCOME_BACK", res.fullName));
            setIsLogin(false);
        } else {
            message.error(
                res === AccountStatus.BLOCKED
                    ?
                    onTranslate("MESSAGE.SUSPENDED_ACCOUNT")
                    :
                    onTranslate("MESSAGE.LOGIN_FAILED")
            );
        }

        updateLoading(false);
    }

    return (
        <div className='form-container'>

            {
                loginStep === 1
                    ?
                    <>
                        <div className='inputLabel'>{onTranslate("LABEL.EMAIL")}</div>
                        <TextInput isTextArea={false} className='emailInput' placeholder='your@email.com' onChange={setEmailInput} value={emailInput} />
                        <span onClick={handleFormChange} className='register'>{onTranslate("BUTTON.DO_NOT_HAVE_ACCOUNT")}</span>
                    </>
                    :
                    <>
                        <Button className='backBtn' type="secondary" onClick={() => setLoginStep(1)}><FaAngleLeft /></Button>
                        <OTP otpReceiver={emailInput} onChange={setOtp} resendOtp={onResendOtp} />
                    </>
            }

            <Button className='button-login' type='primary' onClick={onClickSignIn}>{onTranslate("BUTTON.SIGNIN")}</Button>

            {loginStep === 1 &&
                <>
                    <div className='divide'>
                        <span className='divider'></span>
                    </div>

                    <Button className='button-login' type='secondary' onClick={onSignInWithGoogle}>
                        <FaGoogle className='icon-button' />
                        <span>{onTranslate("BUTTON.SIGNIN_GOOGLE")}</span>
                    </Button>

                    {/* <Button className='button-login' type='secondary' style={{ margin: '10px 0 0 0' }} onClick={() => APIsAuthorizeUsers(ApiId.SIGNIN_WITH_GOOGLE, true)}>
                        <FaGoogle className='icon-button' />
                        <span>TEST GG</span>
                    </Button> */}
                </>
            }

        </div>
    )
}