import { Colors } from "../../Shared/Constants/Theme/Colors";
import { DarkTheme } from "../../Shared/Constants/Theme/DarkTheme";
import { SizeConfigurations } from "../../Shared/Constants/Theme/SizeConfigurations";
import { EventThemeColors } from "../../Shared/Data/EventThemeData";
// @ts-ignore
import less from 'less';

export const InitTheme = () => {
    SetCSSVariable(SizeConfigurations);
    SetCSSVariable(Colors);
    SetCSSVariable(DarkTheme);
    randomEventTheme();
}

export const SetCSSVariable = (theme: Object) => {
    // Set Theme
    if (!theme) return;
    Object.keys(theme).forEach((key) => {
        const value = (theme as any)[key];
        document.documentElement.style.setProperty(key, value);
    })
}

export const setEventTheme = (color: string) => {
    document.documentElement.style.setProperty('--event-theme-color', color);
}

export const randomEventTheme = () => {
    return EventThemeColors[Math.round(Math.random() * EventThemeColors.length - 1)];
}

export const getEventTheme = () => {
    const rootedElement = document.querySelector(':root');

    if (!rootedElement) return null;

    const rootedComputedStyle = getComputedStyle(rootedElement);
    return rootedComputedStyle.getPropertyValue('--event-theme-color');
}

export const getLuminance = (r: number, g: number, b: number) => {
    const a = [r, g, b].map((v) => {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

export const getRgbFromHex = (hex: string) => {
    if (!hex) return;
    // Parse the r, g, b values
    hex = hex.replace(/^#/, '');

    // Check if the hex string is valid (3 or 6 characters long)
    if (hex.length === 3) {
        hex = hex.split('').map(char => char + char).join('');
    }

    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    const rgb = [r, g, b];

    return rgb;
}

export const addOpacityToHex = (hex: string, opacity: number) => {
    const rgb = getRgbFromHex(hex);

    if (!rgb) return;

    return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`;

}

export const addFactorToHex = (hex: string, factor: number) => {
    const rgb = getRgbFromHex(hex);

    if (!rgb) return;

    return `rgba(${rgb[0] * factor}, ${rgb[1] * factor}, ${rgb[2] * factor})`;

}

// Function to determine text color based on background luminance
export const getTextColorBaseOnBackground = (backgroundColor: string) => {
    // let rgb = backgroundColor.match(/\d+/g)?.map(Number);

    const rgb = getRgbFromHex(backgroundColor);

    if (!rgb) return;

    const luminance = getLuminance(rgb[0], rgb[1], rgb[2]);

    return luminance > 0.5 ? 'black' : 'white';
}

export const avgColor = (hex1: string, hex2: string) => {
    const rgb1 = getRgbFromHex(hex1);
    const rgb2 = getRgbFromHex(hex2);

    if (!rgb1 || !rgb2) return;

    return `rgb(${(rgb1[0] + rgb2[0]) / 2}, ${(rgb1[1] + rgb2[1]) / 2}, ${(rgb1[2] + rgb2[2]) / 2})`;
}

export const onGenerateRandomHexColor = () => {
    const hex = Math.floor(Math.random() * 0xFFFFFF).toString(16).padStart(6, '0');
    return `#${hex}`;
}