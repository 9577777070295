import { useEffect, useState } from "react";
import "./GridSwitch.scss";
import { IGridSwitch } from "../../Shared/Model/Others";

type Props = {
    options: IGridSwitch[];
    onChange: (val: IGridSwitch) => void;
}

const GridSwitch = ({ options, onChange }: Props) => {
    const [currViewTypeIndex, setCurrViewTypeIndex] = useState<number>(0);
    const [viewTypeOptsWidth, setViewTypeOptsWidth] = useState<number>(0);

    // Get view options width
    useEffect(() => {
        const viewTypeOption = document.getElementById('gridOption');

        if (!viewTypeOption) return;

        setViewTypeOptsWidth(viewTypeOption?.clientWidth)
    })

    const onChangeView = (newOpt: IGridSwitch) => {
        setCurrViewTypeIndex(options.indexOf(newOpt));
        onChange(newOpt);
    }

    return (
        <div className="gridSwitch">
            <ul className="gridOptsCtn">
                {
                    options.map((opt, i) => (
                        <li key={i} id="gridOption" className={`${currViewTypeIndex === i && 'selected'}`} onClick={() => onChangeView(opt)}>{opt.label}</li>
                    ))
                }
            </ul>
            <div className="selectedBg" style={{ right: `calc(${(options.length - 1 - currViewTypeIndex) * viewTypeOptsWidth}px + 4px)` }}></div>
        </div>

    )
}

export default GridSwitch