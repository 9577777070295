import { Col, Row } from "antd"
import "./Dashboard.scss"
import { APIsAuthorizeUsers } from "../../Services/APIs/APIsController"
import { ApiId } from "../../Shared/Enums/APIsEnum"
import { useEffect, useState } from "react"
import LoadingDots from "../../Components/LoadingDots/LoadingDots";
import MonthlyUsersChart from "./MonthlyUsersChart";
import MonthlyEventsChart from "./MonthlyEventsChart"
import EventStatusTotal from "./EventStatusTotal"
import Button from "../../Components/Button/Button"
import { FaArrowUpRightFromSquare } from "react-icons/fa6"
import Users from "./Users"
import Event from "./Event"
import Transactions from "./Transactions"

type Props = {}

const navItems = ['Dashboard', 'Users', 'Events', 'Transactions'];

const Dashboard = (props: Props) => {
    const [currNav, setCurrNav] = useState<string>(navItems[0]);
    const [totalUsers, setTotalUsers] = useState<any>(0);
    const [monthlyUsers, setMonthlyUsers] = useState<any>([]);
    const [eventsStatusTotal, setEventStatusTotal] = useState<any>([]);
    const [monthlyEvents, setMonthlyEvents] = useState<any>([]);
    const [loadingCount, setLoadingCount] = useState<number>(0);

    useEffect(() => {
        onInitTotalUsers();
        onInitMonthlyUsers();
        onInitTotalEvents();
        onInitMonthlyEvets();
    }, []);

    const onInitTotalUsers = async () => {
        setLoadingCount((prev: any) => prev + 1);
        const totalUsers = await APIsAuthorizeUsers(ApiId.GET_TOTAL_USERS);
        setLoadingCount((prev: any) => prev - 1);

        if (!totalUsers) return;
        setTotalUsers(totalUsers.total);
    }

    const onInitMonthlyUsers = async () => {
        setLoadingCount((prev: any) => prev + 1);
        const monthlyUsers = await APIsAuthorizeUsers(ApiId.GET_MONTHLY_USERS);
        setLoadingCount((prev: any) => prev - 1);

        if (!monthlyUsers) return;
        setMonthlyUsers(monthlyUsers);
    }

    const onInitTotalEvents = async () => {
        setLoadingCount((prev: any) => prev + 1);
        const totalEvents = await APIsAuthorizeUsers(ApiId.GET_TOTAL_EVENTS);
        setLoadingCount((prev: any) => prev - 1);

        if (!totalEvents) return;
        setEventStatusTotal(totalEvents);
    }

    const onInitMonthlyEvets = async () => {
        setLoadingCount((prev: any) => prev + 1);
        const monthlyEvents = await APIsAuthorizeUsers(ApiId.GET_MONTHLY_EVENTS);
        setLoadingCount((prev: any) => prev - 1);

        if (!monthlyEvents) return;
        setMonthlyEvents(monthlyEvents)
    }

    const onHandleSignout = async () => {
        await APIsAuthorizeUsers(ApiId.SIGNOUT);
    }

    return (
        <Row className="mainDashboardCtn">
            <Col className="leftNav" span={5}>
                {
                    navItems.map((item, i) => (
                        <div className={`NavBtn ${currNav === item ? 'active' : ''}`} key={i} onClick={() => setCurrNav(item)}>{item}</div>
                    ))
                }
                <div onClick={onHandleSignout} className="NavBtn" style={{color: 'red'}}>Logout</div>

            </Col>
            <Col className="rightContent" span={18}>
                <div className="MainText">{currNav}</div>
                {
                    loadingCount > 0
                        ?
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <LoadingDots />
                        </div>
                        :

                        currNav === 'Dashboard'
                            ?
                            <>
                                <div className="head">
                                    <div className="infoCtn">
                                        <div className="title">Users Statistic</div>
                                        <div className="subtitle">View User's summary number</div>
                                    </div>
                                    <Button type="link" onClick={() => setCurrNav('Users')}>Details <FaArrowUpRightFromSquare className="icon" /></Button>
                                </div>
                                <div className="statCtn" style={{ height: '300px' }}>
                                    <div className="graphCtn">
                                        <div className="totalUsers">
                                            <div className="title">Total Users</div>
                                            <div className="value">{totalUsers}</div>
                                        </div>
                                    </div>
                                    <div className="graphCtn">
                                        <MonthlyUsersChart monthlyUsers={monthlyUsers} />
                                    </div>
                                </div>

                                <div className="head">
                                    <div className="infoCtn">
                                        <div className="title">Events Statistic</div>
                                        <div className="subtitle">View Event's summary number</div>
                                    </div>
                                    <Button onClick={() => setCurrNav('Events')} type="link">Details <FaArrowUpRightFromSquare className="icon" /></Button>
                                </div>
                                <div className="statCtn">
                                    <div className="graphCtn">
                                        <EventStatusTotal eventsStatusTotal={eventsStatusTotal} />

                                    </div>
                                    <div className="graphCtn">
                                        <MonthlyEventsChart monthlyEvents={monthlyEvents} />
                                    </div>
                                </div>
                            </>
                            :
                            currNav === 'Users'
                                ?
                                <>
                                    <div className="statCtn" style={{ height: '300px' }}>
                                        <div className="graphCtn">
                                            <div className="totalUsers">
                                                <div className="title">Total Users</div>
                                                <div className="value">{totalUsers}</div>
                                            </div>
                                        </div>
                                        <div className="graphCtn">
                                            <MonthlyUsersChart monthlyUsers={monthlyUsers} />
                                        </div>
                                    </div>
                                    <Users />
                                </>
                                :
                                currNav === 'Events'
                                    ?
                                    <>
                                        <div className="statCtn">
                                            <div className="graphCtn">
                                                <EventStatusTotal eventsStatusTotal={eventsStatusTotal} />

                                            </div>
                                            <div className="graphCtn">
                                                <MonthlyEventsChart monthlyEvents={monthlyEvents} />
                                            </div>
                                        </div>
                                        <Event />
                                    </>
                                    :
                                    currNav === 'Transactions'
                                        ?
                                        <>
                                            <Transactions />
                                        </>
                                        :
                                        <></>


                }
            </Col>
            <Col span={1}></Col>
        </Row>
    )
}

export default Dashboard