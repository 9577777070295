import "./AddCom.scss";
import { PlusOutlined } from "@ant-design/icons";
import { useDebouncedCallback } from "use-debounce";
import { APIsAuthorizeUsers } from "../../../Services/APIs/APIsController";
import { ApiId } from "../../../Shared/Enums/APIsEnum";
import { useState } from "react";
import { IResponseGetAll } from "../../../Shared/Model/EventsInterface";
import LoadingDots from "../../../Components/LoadingDots/LoadingDots";
import { EventRole } from "../../../Shared/Enums/EventsEnum";
import { useLoading } from "../../../Services/Hooks/useLoading";
import { message } from "antd";
import { useLanguage } from "../../../Services/Hooks/useLanguage";

type Props = {
    committeeList: any,
    currEvent: IResponseGetAll | undefined,
    onInitCheckinList: () => void
}

const AddCommittee = ({ committeeList, currEvent, onInitCheckinList }: Props) => {
    const { onTranslate } = useLanguage();
    const [keyword, setKeyword] = useState<string>('');
    const [suggestions, setSuggestions] = useState<any>([]);
    const [selectedOpt, setSelectedOpt] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isAdding, setIsAdding] = useState<boolean>(false);

    const handleGetUsersByKeyword = async () => {
        if (keyword.trim().length === 0) return;
        setIsLoading(true);
        const result = await APIsAuthorizeUsers(ApiId.GET_USER_BY_KEYWORD, keyword);
        setIsLoading(false)

        if (!result) return;
        setSuggestions(result);
    }

    const handleChangeInput = (e: any) => {
        setKeyword(e.target.value.trim());
        clearHeartDebounce();
    }

    const onHandleSelect = (sug: any) => {
        if (onCheckUserRole(sug) !== '') return;
        setSelectedOpt(sug);
    }

    const onCheckUserRole = (sug: any) => {
        const staff = committeeList.filter((com: any) => com.userId === sug.userId);
        return currEvent?.host.id === sug?.userId ? `${onTranslate('LABEL.HOST')}` : staff.length > 0 ? `${onTranslate('LABEL.STAFF')}` : '';
    }

    const onHandleAddUser = async () => {
        if (!selectedOpt) return;

        setIsAdding(true);
        const result = await APIsAuthorizeUsers(ApiId.ADD_USER_TO_EVENT, { userId: selectedOpt.userId, eventId: currEvent?.eventId, roleEventId: EventRole.CHECKING_STAFF });
        setIsAdding(false);

        if (result) {
            setKeyword('');
            setSelectedOpt(null);
            message.success(onTranslate('MESSAGE.ADDED_SUCCESS'));
            onInitCheckinList();
        }
    }

    const clearHeartDebounce = useDebouncedCallback(handleGetUsersByKeyword, 1000);

    return (
        <div className='comCtn'>
            <div className="title">{onTranslate('LABEL.ADD_COMMITTEE')}</div>
            <div className="textLabel">{onTranslate('LABEL.EMAIL')}</div>
            <input className='comEmailInput' placeholder="committee@email.com" value={keyword} onChange={handleChangeInput} />
            {
                isAdding
                    ?
                    <LoadingDots color={currEvent?.theme} />
                    :
                    selectedOpt && <div className="selecting" onClick={onHandleAddUser}><PlusOutlined style={{ marginRight: '5px' }} /> {onTranslate('LABEL.CLICK_TO_ADD')} <b>{selectedOpt.email}</b> {onTranslate('LABEL.CLICK_TO_ADD')}</div>
            }

            <div className="textLabel" style={{ margin: '20px 0 10px 0' }}>{onTranslate('LABEL.SUGGESTION')}</div>
            {
                isLoading
                    ?
                    <div style={{ margin: '0 0 15px 0' }}>
                        <LoadingDots color={currEvent?.theme} />
                    </div>
                    :
                    <ul className="suggestionsList">
                        {
                            suggestions.map((sug: any, i: number) => (
                                <li className={selectedOpt?.userId === sug?.userId ? 'selected' : ''} key={sug.userId} onClick={() => onHandleSelect(sug)}>
                                    <div className="name">{sug.fullName} <i>{onCheckUserRole(sug)}</i></div>
                                    <div className="email">{sug.email}</div>
                                </li>
                            ))
                        }
                    </ul>
            }

        </div>
    )
}

export default AddCommittee