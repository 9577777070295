import React from 'react'
import { getLetterFromIndex } from '../../Services/Utils/StringUtils'
import { FaCircle, FaRegCircle, FaRegSquare } from 'react-icons/fa6';

type Props = {
    userAnswered: any;
    onHandleUpdateAnswer: (questionId: string, answer: any) => void;
    notFinishQuestion: number[];
}

const QuestionsAttempt = ({ notFinishQuestion, userAnswered, onHandleUpdateAnswer }: Props) => {

    const onHandleAnswer = (content: string) => {
        onHandleUpdateAnswer(userAnswered.questionId, content);
    }

    return (
        <div className='questionAttempt'>
            <div className={`questionName ${notFinishQuestion.includes(userAnswered.qNo) && !userAnswered.answered ? 'notAnswered' : ''}`}>{userAnswered.qNo}. {userAnswered.questionName}</div>
            {
                !userAnswered.isMultipleAnswers
                    ?
                    <>
                        <textarea placeholder='answer...' rows={3} className='answerInput' onChange={(e) => onHandleAnswer(e.target.value)} />
                    </>
                    :
                    <ul className='multileAnswersCtn'>
                        {
                            userAnswered.answers.map((a: any, i: number) => {
                                return <li
                                    key={a.answerId}
                                    onClick={() => onHandleAnswer(a.answerId)}>
                                    {userAnswered.answered === a.answerId ? <FaCircle className='icon' /> : <FaRegCircle className='icon'/>}
                                    {getLetterFromIndex(i)}. {a.answerContent}
                                </li>
                            })
                        }
                    </ul>
            }
        </div>
    )
}

export default QuestionsAttempt