import React, { useEffect, useState } from 'react'
import Button from '../../Components/Button/Button'
import { Popconfirm, message } from 'antd';
import { ParseIntFromString } from '../../Services/Utils/NumberUtils';
import { APIsAuthorizeUsers } from '../../Services/APIs/APIsController';
import { ApiId } from '../../Shared/Enums/APIsEnum';
import { IResponseGetAll } from '../../Shared/Model/EventsInterface';
import LoadingDots from '../../Components/LoadingDots/LoadingDots';
import { SponsorRequestStatusEnum } from '../../Shared/Enums/EventsEnum';
import { formatDateTime, formatTime } from '../../Services/Utils/TimeUtils';
import { useUser } from '../../Services/Hooks/useUser';
import { useLanguage } from '../../Services/Hooks/useLanguage';

type Props = {
    event: IResponseGetAll | undefined
    isShowSponsorModal: boolean
}

const SponsorRequest = ({ event, isShowSponsorModal }: Props) => {
    const { onTranslate } = useLanguage();
    const { user } = useUser();
    const [sponsorType, setSponsorType] = useState<string>('');
    const [amount, setAmount] = useState<number>(0);
    const [note, setNote] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [userRequest, setUserRequest] = useState<any>(null);

    useEffect(() => {
        onInitMyRequest();
    }, [user])

    const onInitMyRequest = async () => {
        if (!event) return;

        setLoading(true);
        const result = await APIsAuthorizeUsers(ApiId.GET_MY_SPONSOR_REQUEST, event.eventId);
        setLoading(false);

        if (!result) return;
        setUserRequest(result);
    }

    const onSubmitSponsorRequest = async () => {
        if (!event) return;

        if (amount === 0 || sponsorType.trim().length === 0) return message.error('Sponsor Type and Amount are required');

        const data = {
            eventId: event.eventId,
            sponsorType,
            amount,
            message: note
        }

        setLoading(true);
        const result = await APIsAuthorizeUsers(ApiId.CREATE_SPONSOR_REQUEST, data);
        setLoading(false);

        if (!result) return message.error(onTranslate('MESSAGE.SUBMIT_SPONSOR_REQ_FAIL'));
        onInitMyRequest();
        message.success(onTranslate('MESSAGE.SUBMIT_SPONSOR_REQ_SUCCESS'));
        setSponsorType('');
        setAmount(0);
        setNote('');
    }

    const handleNumberChange = (e: any) => {
        let parsedValue = e.target.value.trim() === '' ? '' : ParseIntFromString(e.target.value);

        if (parsedValue === null) return;
        setAmount(parsedValue === '' ? 0 : parsedValue as number);
    }

    const onHandleRequestStatus = () => {
        if (!userRequest) return;

        switch (userRequest.status) {
            case SponsorRequestStatusEnum.PROCESSING:
                return {
                    key: SponsorRequestStatusEnum.PROCESSING,
                    label: onTranslate('LABEL.PROCESSING')
                }
            case SponsorRequestStatusEnum.CONFIRMED:
                return {
                    key: SponsorRequestStatusEnum.CONFIRMED,
                    label: onTranslate('LABEL.CONFIRMED')
                }
            case SponsorRequestStatusEnum.REJECTED:
                return {
                    key: SponsorRequestStatusEnum.REJECTED,
                    label: onTranslate('LABEL.REJECTED')
                }
            default: return;
        }
    }

    const onCancelRequest = async () => {
        if (!event) return;

        setLoading(true);
        const result = await APIsAuthorizeUsers(ApiId.CANCEL_MY_SPONSOR_REQUEST, event.eventId);
        setLoading(false);

        if (!result) return message.error(onTranslate('MESSAGE.FAILED_TO_CANCEL'));

        message.success(onTranslate('MESSAGE.CANCEL_SPOSOR_REQUEST_SUCCESS'));
        setUserRequest(null);
    }

    return (
        <div className='sponsorRequestCtn'>
            <div className="title">{onTranslate('LABEL.YOUR_SPONSOR_REQUEST')} <div className={`tag ${onHandleRequestStatus()}`}>{onHandleRequestStatus()?.label}</div></div>
            <a className="contactHost" href={`mailto:${event?.host.email}`}>{onTranslate('LABEL.CONTACT_ORGANIZER')}</a>

            {
                loading
                    ?
                    <div style={{ margin: '0 0 20px 0' }}>
                        <LoadingDots color={`${event?.theme}`} />
                    </div>
                    :
                    <>
                        {
                            userRequest &&
                            <>
                                <div className='label'>{onTranslate('LABEL.SUBMITTED_TIME')}</div>
                                <div className='sponsorContent'>{formatTime(userRequest.createdAt)} {formatDateTime(userRequest.createdAt)}</div>
                            </>
                        }

                        <div className='label'>{onTranslate('LABEL.SPONSOR_TYPE')} <label>*</label></div>
                        {
                            userRequest
                                ?
                                <div className='sponsorContent'>{userRequest.sponsorType}</div>
                                :
                                <input value={sponsorType} className='inputSponsor' placeholder='Voucher, Money, Cookies...' onChange={(e) => setSponsorType(e.target.value)} maxLength={30} />
                        }

                        <div className='label'>{onTranslate('LABEL.AMOUNT')} <label>*</label></div>
                        {
                            userRequest
                                ?
                                <div className='sponsorContent'>{Number(userRequest.amount).toLocaleString()}</div>
                                :
                                <input value={amount} className='inputSponsor' type="number" min="0" onChange={handleNumberChange} maxLength={12} />
                        }

                        <div className='label'>{onTranslate('LABEL.NOTE')}</div>
                        {
                            userRequest
                                ?
                                <div className='sponsorContent'>{userRequest.message}</div>
                                :
                                <input value={note} className='inputSponsor' onChange={(e) => setNote(e.target.value)} />
                        }

                        {
                            userRequest
                                ?
                                <>
                                    {
                                        onHandleRequestStatus()?.key === SponsorRequestStatusEnum.PROCESSING ?
                                            <>
                                                <Popconfirm title={onTranslate('MESSAGE.SPONSOR_CANCEL_CONFIRM')} onConfirm={onCancelRequest}>
                                                    <Button type="primary" className='createSponsorReqBtn deleteBtn'>{onTranslate('BUTTON.CANCEL_REQUEST')}</Button>
                                                </Popconfirm>
                                                <div className='warning'>{onTranslate('LABEL.SPONSOR_REQUEST_WARNING')}</div>
                                            </>
                                            :
                                            <div className='warning' style={{ margin: '30px 0 0 0' }}>{onTranslate('LABEL.ORGANIZER_ANSWERED', onHandleRequestStatus()?.label, `${formatTime(userRequest.updatedAt)} ${formatDateTime(userRequest.updatedAt)}`)}</div>
                                    }
                                </>
                                :
                                <Button onClick={onSubmitSponsorRequest} type="primary" className='createSponsorReqBtn'>{onTranslate('BUTTON.CREATE')}</Button>
                        }
                    </>
            }

        </div>
    )
}

export default SponsorRequest