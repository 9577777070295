export const checkGoogleMeetLink = (url: string) => {
    const extractUrl = url.replace('https://', '')
    const googleMeetRegex = /^meet.google.com\/[a-z]{3}-[a-z]{4}-[a-z]{3}/;

    // Check if the pathname matches the expected pattern for Google Meet URLs
    // const meetPattern = /^\/[a-z]{3}-[a-z]{4}-[a-z]{3}$/;

    const meetLink = extractUrl.match(googleMeetRegex);
    return meetLink ? meetLink[0] : false;
}

export const validatePassword = (password: string): number => {
    const regexList = [
        /[A-Z]/, // At least one uppercase letter
        /[a-z]/, // At least one lowercase letter
        /[0-9]/, // At least one digit
        /[!@#$%^&*]/, // At least one special character
        /.{8,}/ // Minimum 8 characters
    ];

    let progress = 0;

    for (const regex of regexList) {
        if (regex.test(password)) {
            progress++;
        }
    }

    return progress;
};

export const emailValidation = (email: string) => {
    // Regular expression for validating an email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regular expression
    return emailRegex.test(email);
}

export const onGenerateRandomID = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz+_)(*&^%$#@!~`=-?/>.<,:;"';

    const now = (new Date()).getTime();
    let randomString = '';

    for (let i = 0; i <= 15; i++) {
        randomString += characters[Math.round(Math.random() * characters.length)];
    }

    return `${randomString}${now}`
}

export const removeAllHTMLTags = (htmlContent: string) => {
    var tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;
    return tempDiv.textContent || tempDiv.innerText || "";
}

const onEncrypt = (value: string) => {
    return btoa(value);
}

const onDecrypt = (value: string) => {
    return atob(value);
}

export const onParseCheckinQR = (data: string) => {
    const splittedData = data.split('|');

    return {
        userId: splittedData[0],
        fullName: splittedData[1],
        email: splittedData[2],
        eventId: splittedData[3]
    }
}

export function generateGUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
        const random = (Math.random() * 16) | 0;
        const value = char === 'x' ? random : (random & 0x3) | 0x8;
        return value.toString(16);
    });
}

export const isGuid = (str: string) => {
    const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return guidRegex.test(str);
}

export function getLetterFromIndex(index: number): string {
    return String.fromCharCode(index + 'A'.charCodeAt(0));
}