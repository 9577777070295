import { useNavigate } from "react-router-dom";
import { eventPreviewDateFormat } from "../../Services/Utils/TimeUtils";
import { IResponseGetAll } from "../../Shared/Model/EventsInterface";
import "./EventsPreviewList.scss";
import { useEffect, useState } from "react";

type Props = {
    event: IResponseGetAll;
    index: number;
}

const Events = ({ event, index }: Props) => {
    const navigate = useNavigate();

    return (
        <div className="eventRepresent" onClick={() => navigate(`/events/${event.eventId}`)} style={{animationDelay: `${index * 0.25}s`}}>
            <div className="imgWrapper">
                <img src={event?.image} draggable={false} />
            </div>
            <div className="eventInfo">
                <div className="name">{event.eventName}</div>
                <div className="createdBy">
                    {event.host?.avatar && <img src={event.host?.avatar} onError={(e) => console.log(e)} draggable={false} />}
                    <label>{event.host?.name}</label>
                </div>
                <div className="time">{eventPreviewDateFormat(event.startDate)}</div>
                <div className="location">{event.location?.name}</div>
            </div>
        </div>
    )
}

export default Events