import { useContext } from "react";
import { DataContext } from "../../App";
import { IContext } from "../../Shared/Model/Others";

export const useLoading = () => {
    const { loading, setLoading } = useContext(DataContext) as IContext;

    const updateLoading = (status: boolean, tooltip?: string) => {
        setLoading({ status, tooltip });
    }

    return { updateLoading };
}