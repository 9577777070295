import React from 'react'

type Props = {
    className?: string;
    style?: Object;
    width?: number;
    height?: number;
}

const WebStarIcon = ({className, style = {}, width = 60, height = 61}: Props) => {
    return (
        <div className={className} style={style}>
            <svg width={width} height={height} viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_f_411_508)">
                    <path d="M29.0078 4.8955C29.1545 3.72841 30.8455 3.72842 30.9922 4.89551L33.6371 25.9422C33.6939 26.3943 34.049 26.7508 34.5008 26.8093L55.3421 29.5083C56.5042 29.6588 56.5042 31.3412 55.3421 31.4917L34.5008 34.1907C34.049 34.2492 33.6939 34.6057 33.6371 35.0578L30.9922 56.1045C30.8455 57.2716 29.1545 57.2716 29.0078 56.1045L26.3629 35.0578C26.3061 34.6057 25.951 34.2492 25.4992 34.1907L4.65789 31.4917C3.4958 31.3412 3.4958 29.6588 4.65789 29.5083L25.4992 26.8093C25.951 26.7508 26.3061 26.3943 26.3629 25.9422L29.0078 4.8955Z" fill="#837AFF" />
                </g>
                <defs>
                    <filter id="filter0_f_411_508" x="0.486377" y="0.72002" width="59.0272" height="59.56" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="1.65" result="effect1_foregroundBlur_411_508" />
                    </filter>
                </defs>
            </svg>
        </div>
    )
}

export default WebStarIcon