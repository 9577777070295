import React, { useState } from 'react'
import TextInput from '../../../../Components/Input/TextInput';
import Button from '../../../../Components/Button/Button';
import { IAnswer, IQuestion } from '../../../../Shared/Model/QuizInterface';
import { generateGUID, getLetterFromIndex } from '../../../../Services/Utils/StringUtils';
import { FaCheck, FaTrash } from 'react-icons/fa6';
import { Popconfirm } from 'antd';
import { useLanguage } from '../../../../Services/Hooks/useLanguage';

type Props = {
    question: IQuestion;
    handleUpdateQuestion: (question: IQuestion) => void;
    handleDeleteQuestion: (question: IQuestion) => void;
}

const QuestionComponent = ({ question, handleUpdateQuestion, handleDeleteQuestion }: Props) => {
    const { onTranslate } = useLanguage();

    const onAddAnswer = () => {
        const newAnswer: IAnswer[] = [
            ...question.answers,
            {
                answerId: generateGUID(),
                answerContent: '',
                isCorrectAnswer: false
            }
        ]

        handleUpdateQuestion({ ...question, answers: newAnswer });
    }

    const onChangeQuestion = (content: string) => {
        handleUpdateQuestion({ ...question, questionName: content });
    }

    const onChangeAnswer = (id: string, answerContent: string) => {
        const newAnswerContent = question.answers.map(a => a.answerId === id ? { ...a, answerContent } : a);
        handleUpdateQuestion({ ...question, answers: newAnswerContent });
    }

    const onDeleteAnswer = (id: string) => {
        const newAnswerArr = question.answers.filter(a => a.answerId !== id);
        handleUpdateQuestion({ ...question, answers: newAnswerArr });
    }

    const onChangeQuestionMode = (newMode: string) => {
        const isMultipleChoices = newMode === 'multiple'
        handleUpdateQuestion({ ...question, isMultipleAnswers: isMultipleChoices, answers: isMultipleChoices ? question.answers : [] });
    }

    const onSetAnswerCorrect = (ansId: string) => {
        const newAnswerArr = question.answers.map(a => {
            return {...a, isCorrectAnswer: a.answerId === ansId}
        });

        handleUpdateQuestion({...question, answers: newAnswerArr});
    }

    return (
        <div className='question'>
            <textarea defaultValue={question.questionName} className='nameInput' placeholder={`${onTranslate('LABEL.QUESTION')}...`} rows={2} onChange={(e) => onChangeQuestion(e.target.value)}></textarea>
            <div className='answersCtn'>
                {question.isMultipleAnswers &&
                    question.answers.map((a: IAnswer, i: number) =>
                        <div className='answer' key={a.answerId}>
                            <FaCheck onClick={() => onSetAnswerCorrect(a.answerId)} className={`checkIcon ${a.isCorrectAnswer ? 'isCorrect' : ''}`} />
                            <label>{getLetterFromIndex(i)}.</label>
                            <input defaultValue={a.answerContent} onChange={(e) => onChangeAnswer(a.answerId, e.target.value)} className='nameInput' placeholder={`${onTranslate('LABEL.ANSWER')}...`}></input>
                            <FaTrash onClick={() => onDeleteAnswer(a.answerId)} className='deleteAns' />
                        </div>
                    )
                }
            </div>
            <div className='footer'>
                {question.isMultipleAnswers ? <Button type="link" onClick={onAddAnswer}>+ {onTranslate('BUTTON.ADD_ANSWER')}</Button> : <div></div>}
                <div className='optionsBtn'>
                    <select onChange={(e) => onChangeQuestionMode(e.target.value)} defaultValue={question.isMultipleAnswers ? 'multiple' : 'paragraph'}>
                        <option value="multiple">{onTranslate('LABEL.MULTIPLE_CHOICES')}</option>
                        <option value="paragraph">{onTranslate('LABEL.PARAGRAPH')}</option>
                    </select>
                    <Popconfirm title={onTranslate('MESSAGE.ASK_DELETE')} onConfirm={() => handleDeleteQuestion(question)}>
                        <FaTrash className='delete' />
                    </Popconfirm>
                </div>
            </div>
        </div>
    )
}

export default QuestionComponent