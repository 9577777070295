import axios from "axios";
import { getPlaceDetailsApi, getPlacesApi } from "./APIsConfig";

const API_KEY = process.env.REACT_APP_GOONGJS_API_KEY;
const MAPTILES_KEY = process.env.REACT_APP_GOONGJS_MAPTILES_KEY;


export const getLocationBySuggestion = (input: string) => {
    return axios.get(getPlacesApi(input))
        .then((res) => {
            return res.data.predictions;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getLocationDetails = (locationId: string) => {
    return axios.get(getPlaceDetailsApi(locationId))
        .then((res) => {
            return res.data.result;
        })
        .catch((err) => {
            console.log(err);
        })
}