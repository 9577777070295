import "./Progressbar.scss"

type Props = {
    current: number,
    max: number,
    progressColor?: string;
    backgroundColor?: string;
}

const Progressbar = ({ current, max, progressColor = "white", backgroundColor = "var(--tertiary-bg-color)" }: Props) => {
    return (
        <div className="progressbar" style={{backgroundColor: backgroundColor}}>
            <div className="progress" style={{ backgroundColor: progressColor, width: `${((current / max) * 100)}%` }}></div>
        </div>
    )
}

export default Progressbar