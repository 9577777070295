import axios from "axios"
import { getAllTagsApi, getTagById, getTrendingTag, tagsApi } from "./APIsConfig"
import { APIErrorController } from "./APIsController"
import { ITag } from "../../Shared/Model/TagsInterface"

export const getAllTags = () => {
    return axios.get(`${getAllTagsApi}`)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const getTagByKeyword = (keyword: string) => {
    return axios.get(getTagById(keyword))
        .then((res) => {
            let returnedData = res.data.data;

            const result = returnedData?.filter((tag: ITag) => {
                return tag.tagName.toUpperCase().includes(keyword.toUpperCase().trim().replace(/ /g, '_'));
            })

            return result;
        })
        .catch((err) => APIErrorController(err))
}

export const addNewTag = (tagName: string) => {
    return axios.post(tagsApi, { tagName })
        .then((res) => res)
        .catch((err) => APIErrorController(err))
}

export const onGetTagById = (tagId: string) => {
    return axios.get(`${tagsApi}?tagId=${tagId}`)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onGetTrendingTags = () => {
    return axios.get(getTrendingTag)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}