import "./Certificate.scss"
import Certificate from '../../../Components/DefaultCertification/Certificate'
import { useUser } from '../../../Services/Hooks/useUser'
import { IResponseGetAll } from '../../../Shared/Model/EventsInterface'
import CertiTable from "./CertiTable"
import { useLanguage } from "../../../Services/Hooks/useLanguage"

type Props = {
    currEvent: IResponseGetAll | undefined
}

const CertificateEManage = ({ currEvent }: Props) => {
    const { onTranslate } = useLanguage();
    const { user } = useUser();

    return (
        <div className='certificatePageCtn'>
            <div className='title'>{onTranslate('LABEL.CERTIFICATE_PREVIEW')}</div>
            <div className="subtitle">{onTranslate('LABEL.CERTIFICATE_PREVIEW_DESCRIPTION')}</div>
            <div className="svgCertiCtn">
                {
                    currEvent &&
                    <Certificate userName={user?.fullName.toLocaleUpperCase() as string} eventName={currEvent.eventName.toUpperCase()} issuedDate={new Date()} certificateId='none' />
                }
            </div>

            <div className='title' style={{ margin: '40px 0 0 0' }}>{onTranslate('LABEL.CERTIFICATE_MANAGE')}</div>
            <div className="subtitle">{onTranslate('LABEL.CERTIFICATE_MANAGE_DESCRIPTION')}</div>
            <CertiTable currEvent={currEvent} />
        </div>
    )
}

export default CertificateEManage