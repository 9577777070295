import { AppConfigurations } from "../AppConfigurations";

export const DarkTheme = {
    // Background
    '--primary-bg-color': '#221A2D',
    '--secondary-bg-color': '#2A2038',
    '--tertiary-bg-color': '#362947',
    '--quaternary-bg-color': '#3E2F52',
    '--quaternary-opacity-bg-color': 'rgba(62, 47, 82, 60)',
    '--quaternary-opacity-bg-hover-color': 'rgba(54, 41, 71, 70)',

    '--brand-color': "#4C3FF4",
    '--brand-color-light': '#BBC0FF',
    '--brand-color-hover': '#FDFCDC',

    '--event-theme-color': '#837AFF',

    // Text
    '--primary-text-color': '#ffff',
    '--secondary-text-color': '#d2d4d7',
    '--tertiary-text-color': '#939597',
    '--quaternary-text-color': '#535557',

    // Buttons & Links
    '--primary-btn-bg-color': '#FFFFFF',
    '--primary-btn-bg-hover-color': 'rgba(0, 0, 0, 0)',
    '--primary-link-color': '#ffff',
    '--primary-link-hover-color': '#BBC0FF',

    '--secondary-btn-bg-color': '#7375775d',
    '--secondary-btn-bg-hover-color': '#ebeced89',
    '--secondary-opacity-btn-bg-color': '#c4c6c65d',
    '--secondary-opacity-btn-bg-hover-color': '#f6f6f65d',

    '--success-btn-bg-color': "#07a460",
    '--success-btn-bg-hover-color': '#078c53',
    '--success-link-color': "#07a460",
    '--success-link-hover-color': '#47c97e',

    '--error-btn-bg-color': "#e83b47",
    '--error-btn-bg-hover-color': '#cb343e',
    '--error-link-color': "#e83b47",
    '--error-link-hover-color': '#ff5965',

    // Nav items
    '--nav-item': 'hsla(0,0%,100%,.75)',
    '--nav-item-hover': '#837AFF',
    '--nav-item-selected': '#4C3FF4',
}