import { Dropdown, MenuProps } from 'antd';
import React from 'react'
import { useLanguage } from '../../Services/Hooks/useLanguage';

type Props = {}

const SwitchLanguage = (props: Props) => {
    const { onGetCurrLang, onChangeLanguage } = useLanguage();

    const items: MenuProps['items'] = [
        {
            label: <div onClick={() => onChangeLanguage('vi')} style={onGetCurrLang() === 'vi' ? { color: 'var(--brand-color)' } : {}}>vi</div>,
            key: 'vi',
        },
        {
            label: <div onClick={() => onChangeLanguage('en')} style={onGetCurrLang() === 'en' ? { color: 'var(--brand-color)' } : {}}>en</div>,
            key: 'en',
        },
    ];
    return (
        <Dropdown menu={{ items }} trigger={['click']}>
            <div className='switchLanguage'>
                {onGetCurrLang()}
            </div>
        </Dropdown>
    )
}

export default SwitchLanguage