import { type Editor } from '@tiptap/react';
import "../UpdateEvent.scss";
import { Fa1, Fa2, FaBold, FaHeading, FaItalic, FaList, FaListOl } from 'react-icons/fa6';

type Props = {
    editor: Editor | null;
}

const ToggleTool = ({ editor }: Props) => {

    return (
        <div className='ToggleTool'>
            {/* <button onClick={() => editor?.chain().focus().toggleItalic().run()}>{editor?.isActive('italic') ? 'a' : 'b'}</button> */}
            <button
                className={editor?.isActive('heading', { level: 1 }) ? 'active' : ''}
                onClick={() => editor?.chain().focus().toggleHeading({ level: 1 }).run()}
            >
                <FaHeading /><Fa1 />
            </button>
            <button
                className={editor?.isActive('heading', { level: 2 }) ? 'active' : ''}
                onClick={() => editor?.chain().focus().toggleHeading({ level: 2 }).run()}>
                <FaHeading /><Fa2 />
            </button>
            <button
                className={editor?.isActive('bold') ? 'active' : ''}
                onClick={() => editor?.chain().focus().toggleBold().run()}>
                <FaBold />
            </button>
            <button
                className={editor?.isActive('italic') ? 'active' : ''}
                onClick={() => editor?.chain().focus().toggleItalic().run()}
            >
                <FaItalic />
            </button>
            <button
                className={editor?.isActive('bulletList') ? 'active' : ''}
                onClick={() => editor?.chain().focus().toggleBulletList().run()}
            >
                <FaList />
            </button>
            <button
                className={editor?.isActive('orderedList') ? 'active' : ''}
                onClick={() => editor?.chain().focus().toggleOrderedList().run()}
            >
                <FaListOl />
            </button>
        </div>
    )
}

export default ToggleTool