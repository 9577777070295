import { IResponseGetAll } from "../../Shared/Model/EventsInterface";
import Events from "./Events";
import "./EventsPreviewList.scss"

type Props = {
  eventsList: IResponseGetAll[] | null;
}

const EventsPreviewList = ({ eventsList }: Props) => {
  return (
    <div className="eventsPreviewList">
      {
        eventsList && eventsList.map((event: IResponseGetAll, i) => (
          <Events key={event.eventId} event={event} index={i} />
        ))
      }
    </div>
  )
}

export default EventsPreviewList;