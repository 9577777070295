import { useEffect, useState } from "react";
import "./AttemptQuiz.scss"
import { addZeroBefore } from "../../Services/Utils/NumberUtils";
import { DarkTheme } from "../../Shared/Constants/Theme/DarkTheme";
import { convertSecondToMinuteAndSecond } from "../../Services/Utils/TimeUtils";

type Props = {
  totalTime: string;
  setCurrCountDown: (countDown: any) => void;
  currCountDown: number;
}

const CountdownClock = ({ currCountDown, totalTime, setCurrCountDown }: Props) => {

  useEffect(() => {
    const removeInterval = onInitTime();

    return removeInterval;
  }, []);

  const onInitTime = () => {
    const countdown = convertSecondToMinuteAndSecond(totalTime);
    setCurrCountDown(countdown)

    const removeInterval = coundDownInterval();
    return removeInterval;
  }

  const coundDownInterval = (() => {
    const interval = setInterval(() => {
      setCurrCountDown((prev: number) => {
        if (prev === 0) {
          clearInterval(interval);
          return 0;
        }
        return prev - 1;
      })
    }, 1000)

    return () => clearInterval(interval);
  });

  const onFormatTimer = () => {
    return `radial-gradient(closest-side, ${DarkTheme['--quaternary-bg-color']} 89%, transparent 90% 100%), conic-gradient(${currCountDown < (convertSecondToMinuteAndSecond(totalTime) * 0.3) ? '#EA4335' : DarkTheme['--brand-color-light']} ${(currCountDown / convertSecondToMinuteAndSecond(totalTime)) * 100}%, ${DarkTheme['--quaternary-bg-color']} 0)`
  }

  return (
    <div
      style={{ background: onFormatTimer() }}
      className={`countdownClock ${currCountDown <= (convertSecondToMinuteAndSecond(totalTime) * 0.3) ? 'red' : ''}`}
    >
      {addZeroBefore(Math.floor(currCountDown / 60))}:{addZeroBefore(currCountDown - (Math.floor(currCountDown / 60) * 60))}
    </div>
  )
}

export default CountdownClock