import { useEffect, useState } from 'react';
import "./randomAvatarByName.scss";
import { avgColor, onGenerateRandomHexColor, randomEventTheme } from '../../Services/Utils/ThemeUtils';

type Props = {
    name: string;
    className?: string;
}

const RandomAvatarByName = ({ name = 'FPT University', className = '' }: Props) => {
    const [shortName, setShortName] = useState<string>();
    const [avatarBgColor, setAvatarBgColor] = useState<string>();

    useEffect(() => {
        getShortName();
        randomAvatarBg();
    }, [])

    const getShortName = () => {
        const splittedName = name.split(' ');
        if (splittedName.length === 1) {
            setShortName(splittedName[0].charAt(0).toLocaleUpperCase());
        } else {
            setShortName(`${splittedName[0][0]}${splittedName[1][0]}`.toUpperCase());
        }
    }

    const randomAvatarBg = () => {
        setAvatarBgColor(avgColor(onGenerateRandomHexColor(), '#939597'));
    }

    return (
        <div className={`randomAvatarByName ${className}`} style={{backgroundColor: avatarBgColor}}>{shortName}</div>
    )
}

export default RandomAvatarByName