import React, { useEffect, useState } from 'react'
import { useLanguage } from '../../Services/Hooks/useLanguage';
import { useUser } from '../../Services/Hooks/useUser';
import { FaLock } from 'react-icons/fa6';

type Props = {
    setCurrNav: (value: string) => void;
    currNav: string
}

const EventManageSettingsNav = ({ setCurrNav, currNav }: Props) => {
    const { onGetCurrLang } = useLanguage();
    const { validatePremium } = useUser();

    const [navItems, setNavItems] = useState([
        {
            id: 'Overview',
            label: ""
        },
        {
            id: 'Users',
            label: ""
        },
        {
            id: 'Transactions',
            label: ""
        },
        {
            id: 'Feedbacks',
            label: ""
        },
        {
            id: 'Advertise',
            label: ''
        },
        {
            id: 'Quiz',
            label: ""
        },
        {
            id: 'Certificates',
            label: ""
        },
    ]);

    const lockByPremium = ['QUIZ', 'CERTIFICATES'];

    useEffect(() => {
        let navLabel: any = [];
        if (onGetCurrLang() === "vi") {
            navLabel = ['Tổng quan', 'Người dùng', 'Giao dịch', 'Đánh giá','Quảng cáo', 'Quiz', 'Chứng nhận'];
        } else {
            navLabel = ['Overview', 'Users', 'Transactions', 'Feedbacks', 'Advertise', 'Quiz', 'Certificates'];
        }
        const newNavItem: any = []
        navItems.forEach((item, index) => {
            newNavItem.push({
                ...item,
                label: navLabel[index]
            })
        });
        setNavItems(newNavItem);
    }, [onGetCurrLang()]);

    const onChangeNav = (item: string) => {
        if(!validatePremium() && lockByPremium.includes(item.toUpperCase())) return;
        setCurrNav(item)
    }

    return (
        <ul className="settingNav">
            {
                navItems.map((item, index) =>
                    <li
                        className={`${item.id === currNav ? 'active' : ''}`}
                        key={index}
                        onClick={() => onChangeNav(item.id)}
                    >
                        {item.label} 
                        {
                            (lockByPremium.includes(item.id.toUpperCase()) && !validatePremium()) && <FaLock className='lock' />
                        }
                    </li>
                )
            }
        </ul>
    )
}

export default EventManageSettingsNav