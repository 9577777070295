import { useState } from 'react'
import "./Authentication.scss";
import { LoginForm } from './Form/LoginForm';
import { RegisterForm } from './Form/RegisterForm';
import { FaArrowRightToBracket } from 'react-icons/fa6';
import Button from '../../Components/Button/Button';
import { useLanguage } from '../../Services/Hooks/useLanguage';

type Props = {
    setIsLogin: (val: boolean) => void;
}

const Authentication = ({ setIsLogin }: Props) => {
    const { onTranslate } = useLanguage();
    const [isLoginForm, setLoginForm] = useState(true);

    const handleFormChange = () => {
        setLoginForm(!isLoginForm);
    };

    return (
        <div className='authCover'>
            <div className='authenPage'>
                <div className='authen-content'>
                    <FaArrowRightToBracket className='mainIcon' />
                    <div className='title'>
                        <h2>{onTranslate("LABEL.LOGIN_HEADER")}</h2>
                        <p>{onTranslate("LABEL.LOGIN_SUB")}</p>
                    </div>
                    {
                        isLoginForm
                            ?
                            <LoginForm handleFormChange={handleFormChange} setIsLogin={setIsLogin}></LoginForm>
                            :
                            <RegisterForm handleFormChange={handleFormChange} setIsLogin={setIsLogin}></RegisterForm>
                    }
                </div>
            </div>
        </div>
    )
}

export default Authentication;