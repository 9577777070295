import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { ParticipantEventStatusEnum } from "../../../Shared/Enums/EventsEnum";
import { APIsAuthorizeUsers } from "../../../Services/APIs/APIsController";
import { ApiId } from "../../../Shared/Enums/APIsEnum";
import { useLoading } from "../../../Services/Hooks/useLoading";
import { Popconfirm, message } from "antd";
import { IResponseGetAll } from "../../../Shared/Model/EventsInterface";
import { FaChevronRight, FaPlus, FaTrash } from "react-icons/fa6";
import Button from "../../../Components/Button/Button";
import { handleImageValidation, readDataAsUrl } from "../../../Services/Utils/ImageUtils";
import { useLanguage } from "../../../Services/Hooks/useLanguage";
import { onTranslateOutsideHook } from "../../../Services/Utils/LanguageUtils";

const onHandleUserRequest = async (isAccept: boolean, data: any, eventId: string, updateLoading: any, onInitCheckinList: () => void, onTranslate: any) => {
    const status = isAccept ? ParticipantEventStatusEnum.CONFIRMED : ParticipantEventStatusEnum.BLOCKED;

    updateLoading(true, onTranslate('MESSAGE.UPDATING_PARTIC_STATUS'));
    const result = await APIsAuthorizeUsers(ApiId.PROCESS_TICKET, { eventId, userId: data.userId, status });
    updateLoading(false);

    if (!result) {
        message.error(onTranslate('MESSAGE.FAILED_UPDATE_PARTIC_STATUS'));
        return;
    }

    onInitCheckinList();
}

const onRemoveStaff = async (eventId: string, userId: string, updateLoading: (val: boolean) => void, onInitCheckinList: () => void) => {
    updateLoading(true);
    const result = await APIsAuthorizeUsers(ApiId.REMOVE_PARTICIPANT, { eventId, userId });
    updateLoading(false);

    if (result.statusResponse === 200) {
        message.success(onTranslateOutsideHook(null, 'MESSAGE.DELETE_SUCCESS'));
        onInitCheckinList()
    }
}

export const TableColumn = (eventId: string, onInitCheckinList: () => void): any[] => {
    const { updateLoading } = useLoading();
    const { onTranslate } = useLanguage();
    return (
        [
            {
                title: onTranslate('LABEL.NAME'),
                key: 'name',
                dataIndex: 'fullName'
            },
            {
                title: onTranslate('LABEL.EMAIL'),
                key: 'email',
                dataIndex: 'email'
            },
            {
                title: onTranslate('LABEL.ACTION'),
                key: 'action',
                render: (row: any) => {
                    return (
                        <>
                            <CloseCircleFilled onClick={() => onHandleUserRequest(false, row, eventId, updateLoading, onInitCheckinList, onTranslate)} style={{ fontSize: '18px', color: 'red', margin: '0 10px 0 0', cursor: 'pointer' }} />
                            <CheckCircleFilled onClick={() => onHandleUserRequest(true, row, eventId, updateLoading, onInitCheckinList, onTranslate)} style={{ fontSize: '18px', color: 'lime', cursor: 'pointer' }} />
                        </>
                    )
                }
            },
        ]
    )
}

export const TableSponsorRequestColumn = (eventId: string, setCurrViewRequest: any): any[] => {
    const { onTranslate } = useLanguage();
    return (
        [
            {
                title: onTranslate('LABEL.NAME'),
                key: 'name',
                dataIndex: 'fullName'
            },
            {
                title: onTranslate('LABEL.EMAIL'),
                key: 'email',
                dataIndex: 'email'
            },
            {
                title: onTranslate('LABEL.STATUS'),
                key: 'status',
                render: (row: any) => {
                    return (
                        <>
                            {row.status}
                        </>
                    )
                }
            },
            {
                title: onTranslate('LABEL.REQUEST'),
                key: 'request',
                render: (row: any) => (
                    <Button onClick={() => setCurrViewRequest(row)} type="link" style={{ fontSize: '12px' }}>View request <FaChevronRight style={{ fontSize: '10px', padding: 0, margin: 0, transform: 'translateY(17%)' }} /></Button>
                )
            },
        ]
    )
}

export const participantsColumn = [
    {
        title: 'Name',
        key: 'name',
        dataIndex: 'fullName'
    },
    {
        title: 'Email',
        key: 'email',
        dataIndex: 'email'
    },
    {
        title: 'Status',
        key: 'status',
        render: (row: any) => {
            return (
                <>
                    {row.checkedIn ? 'Checked In' : 'Not check in'}
                </>
            )
        }
    },
]

export const comitteeColumn = (event: IResponseGetAll, setLoading: (val: boolean) => void, onInitCheckinList: () => void) => {
    // const { onTranslate } = useLanguage();
    return (
        [
            {
                title: onTranslateOutsideHook(null, 'LABEL.NAME'),
                key: 'name',
                dataIndex: 'fullName'
            },
            {
                title: onTranslateOutsideHook(null, 'LABEL.EMAIL'),
                key: 'email',
                dataIndex: 'email'
            },
            {
                title: onTranslateOutsideHook(null, 'LABEL.ROLE'),
                key: 'role',
                render: (row: any) => {
                    return (
                        row.userId === event.host.id
                            ? onTranslateOutsideHook(null, 'LABEL.HOST')
                            : onTranslateOutsideHook(null, 'LABEL.STAFF')
                    )
                }
            },
            {
                title: onTranslateOutsideHook(null, 'LABEL.ACTION'),
                key: 'action',
                render: (row: any) => {
                    return (
                        row.userId === event.host.id
                            ?
                            <></>
                            :
                            <Popconfirm title={onTranslateOutsideHook(null, 'MESSAGE.ASK_REMOVE_STAFF')} onConfirm={() => onRemoveStaff(event.eventId, row.userId, setLoading, onInitCheckinList)}>
                                <FaTrash style={{ color: 'red', cursor: 'pointer' }} />
                            </Popconfirm>
                    )
                }
            }
        ]
    )
}

export const onlyInfoColumn = (onViewRequest: any, onClickAddLogo: any,) => {
    return [
        {
            title: onTranslateOutsideHook(null, 'LABEL.NAME'),
            key: 'name',
            dataIndex: 'fullName'
        },
        {
            title: onTranslateOutsideHook(null, 'LABEL.EMAIL'),
            key: 'email',
            dataIndex: 'email'
        },
        // {
        //     title: 'Logo',
        //     key: 'logo',
        //     render: (row: any) => (
        //         <Button onClick={() => onClickAddLogo(row.userId)} type="link" style={{ fontSize: '12px' }}>Add Logo <FaPlus style={{ fontSize: '10px', padding: 0, margin: 0, transform: 'translateY(17%)' }} /></Button>
        //     )
        // },
        {
            title: onTranslateOutsideHook(null, 'LABEL.REQUEST'),
            key: 'request',
            render: (row: any) => (
                <Button onClick={() => onViewRequest(row)} type="link" style={{ fontSize: '12px' }}>{onTranslateOutsideHook(null, 'BUTTON.VIEW_REQUEST')} <FaChevronRight style={{ fontSize: '10px', padding: 0, margin: 0, transform: 'translateY(17%)' }} /></Button>
            )
        },
    ]
}