import axios from "axios"
import { addSponsorLogoApi, addUserToEventApi, authApiConfig, checkinApi, contentTypeHeader, createSponsorRequestApi, currentUserInfoInEvent, eventsApi, eventsDetailsApi, getAllEventsApi, getCheckinListApi, getEventHostedByUser, getFeedbackApi, getMyEventsApi, getMySponsorRequestApi, getParticipantsApi, getPopularLocationsApi, getPopularOrganizersApi, getTotalParticipantsChartApi, getUserFeedback, onGetSponsorRequestsFilter, processTicketApi, registerEventApi, submitFeedbackApi, updateSponsorRequestStatusApi } from "./APIsConfig"
import { ICreateEventFormat, IGetAllEventsFormat, ISubmitFeedback } from "../../Shared/Model/EventsInterface"
import { APIErrorController } from "./APIsController"
import { EventRole } from "../../Shared/Enums/EventsEnum"
import { IChartParticipants } from "../../Shared/Model/Others"

export const getAllEvents = ({ pageNo, elementEachPage, status = null }: IGetAllEventsFormat) => {
    return axios.get(getAllEventsApi(pageNo, elementEachPage, status))
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => APIErrorController(err))
}

export const getEventsDetails = (eventId: string) => {
    return axios.get(eventsDetailsApi(eventId))
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onCreateEvent = (event: ICreateEventFormat) => {
    const config = authApiConfig();

    return axios.post(eventsApi, event, config as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onUpdateEvent = (event: any) => {
    const config = authApiConfig();

    return axios.put(`${eventsApi}?eventId=${event.eventId}`, event, config as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onDeleteEvent = (eventId: string) => {
    const config = authApiConfig();

    return axios.delete(`${eventsApi}?eventId=${eventId}`, config as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))

}

export const onRegisterEvent = (eventId: string) => {
    const config = authApiConfig();

    // console.log(eventId)
    return axios.post(`${registerEventApi}?eventId=${eventId}`, {}, config as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onGetUserInfoInEvent = (eventId: string) => {
    const config = authApiConfig();
    return axios.get(`${currentUserInfoInEvent}?eventId=${eventId}`, config as any)
        .then((res) => {
            // console.log(res)
            return res
        })
        .catch((err) => {
            return APIErrorController(err)

        })
}

export const onGetPopularOrganizers = () => {
    return axios.get(getPopularOrganizersApi)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onGetPopularLocations = () => {
    return axios.get(getPopularLocationsApi, contentTypeHeader as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const getCheckinList = ({ eventId, page, pageSize }: any) => {
    const config = authApiConfig();
    return axios.get(getCheckinListApi(eventId, page, pageSize), config as any)
        .then((res) => res.data)
        .catch((err) => APIErrorController(err))
}

export const onCheckinUser = (eventId: string, userId: string) => {
    const config = authApiConfig();
    return axios.get(checkinApi(eventId, userId), config as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onProcessTicket = ({ eventId, userId, status }: any) => {
    const config = authApiConfig();
    return axios.post(processTicketApi, { eventId, userId, status }, config as any)
        .then((res) => res.data)
        .catch((err) => APIErrorController(err))
}

export const onGetMyEvents = () => {
    const config = authApiConfig();
    return axios.get(getMyEventsApi, config as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onGetFeedbacks = (eventId: string) => {
    const config = authApiConfig();
    return axios.get(getFeedbackApi(eventId), config as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onFeedback = (data: ISubmitFeedback) => {
    const config = authApiConfig();
    axios.post(submitFeedbackApi, data, config as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onUpdateFeedback = (data: ISubmitFeedback) => {
    const config = authApiConfig();
    return axios.put(submitFeedbackApi, data, config as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}


export const onGetMyFeedbacks = (eventId: string) => {
    const config = authApiConfig();
    return axios.get(getUserFeedback(eventId), config as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onAddUserToEvent = ({ userId, eventId, roleEventId }: any) => {
    const config = authApiConfig();
    return axios.post(addUserToEventApi, { userId, eventId, roleEventId }, config as any)
        .then((res) => res.data)
        .catch((err) => APIErrorController(err))
}

export const onDeleteParticipant = ({ eventId, userId }: any) => {
    const config = authApiConfig();
    return axios.delete(`${getParticipantsApi}?userId=${userId}&eventId=${eventId}`, config as any)
        .then((res) => res.data)
        .catch((err) => APIErrorController(err))
}

export const onCreateSponsorRequest = ({ eventId, sponsorType, amount, message }: any) => {
    const config = authApiConfig();
    return axios.post(createSponsorRequestApi, { eventId, sponsorType, amount, message }, config as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onGetMySponsorRequest = (eventId: string) => {
    const config = authApiConfig();
    return axios.get(getMySponsorRequestApi(eventId), config as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onCancelMySponsorRequest = (eventId: string) => {
    const config = authApiConfig();
    return axios.delete(`${createSponsorRequestApi}?eventId=${eventId}`, config as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onGetAllEventSponsorRequests = ({ eventId, status }: any) => {
    const config = authApiConfig();
    return axios.get(onGetSponsorRequestsFilter(eventId, 1, 30, status), config as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onUpdateSponsorRequestStatus = ({ eventId, status, userId }: any) => {
    const config = authApiConfig();

    // console.log({ eventId, status })
    return axios.put(updateSponsorRequestStatusApi, { eventId, status, userId }, config as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onGetEventUserHosted = (userId: string) => {
    return axios.get(getEventHostedByUser(userId))
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onSearchEvent = (queryStr: string) => {
    return axios.get(`${eventsApi}?${queryStr}`)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onUploadSponsorLogo = (base64: string, eventId: string, sponsorName: string) => {
    const config = authApiConfig();
    axios.post(addSponsorLogoApi, { base64, eventId, sponsorName }, config as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onGetTotalParticipantsChart = (data: IChartParticipants) => {
    const config = authApiConfig();

    return axios.get(getTotalParticipantsChartApi(data), config)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}