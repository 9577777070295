import { useEffect, useState } from "react";
import "./UpdateEvent.scss";
import { Col, Modal, Row, Select, message } from 'antd';
import Button from "../../Components/Button/Button";
import { FaAngleLeft, FaCircleXmark, FaEarthAsia, FaFile, FaHashtag, FaLocationDot, FaLock, FaPen, FaTicket, FaUserCheck } from "react-icons/fa6";
import TextInput from "../../Components/Input/TextInput";
import TimePicker from "../../Components/Input/TimePicker";
import dayjs, { Dayjs } from "dayjs";
import Switch from "../../Components/Input/Switch";
import { getEventTheme, getTextColorBaseOnBackground, randomEventTheme, setEventTheme } from "../../Services/Utils/ThemeUtils";
import BottomModal from "../../Components/Modal/BottomModal";
import EventThemePicker from "../../Components/EventThemePicker/EventThemePicker";
import { checkFutureTimeDayjs, compareDayjs } from "../../Services/Utils/TimeUtils";
import { AppConfigurations } from "../../Shared/Constants/AppConfigurations";
import { ITimeDifference } from "../../Shared/Model/Others";
import EventSizeOption from "./ModalOptions/EventSizeOption";
import { useDebouncedCallback } from 'use-debounce';
import { ICreateEventFormat, IEventLocation, ILocationCompound, IResponseGetAll } from "../../Shared/Model/EventsInterface";
import { onGenerateRandomID, removeAllHTMLTags } from "../../Services/Utils/StringUtils";
import UploadImage from "./Image/UploadImage";
import { checkGoogleMeetLink } from "../../Services/Utils/StringUtils";
import { GoogleMeetLogo } from "../../Components/Logo/GoogleMeetLogo";
import { APIsAuthorizeUsers } from "../../Services/APIs/APIsController";
import { ApiId } from "../../Shared/Enums/APIsEnum";
import DescriptionEditor from "./Description/DescriptionEditor";
import TagsModal from "./ModalOptions/TagsModal";
import { ITag } from "../../Shared/Model/TagsInterface";
import { useLoading } from "../../Services/Hooks/useLoading";
import { useUser } from "../../Services/Hooks/useUser";
import { useNavigate, useParams } from "react-router-dom";
import Ticket from "./ModalOptions/Ticket";
import { DarkTheme } from "../../Shared/Constants/Theme/DarkTheme";
import { EventStatus } from "../../Shared/Enums/EventsEnum";
import { useLanguage } from "../../Services/Hooks/useLanguage";


type Props = {}

const imgSrc = 'https://cdn.lu.ma/cdn-cgi/image/format=auto,fit=cover,dpr=2,quality=75,width=400,height=400/event-defaults/1-1/standard2.png';

const UpdateEvent = (props: Props) => {
    const { onTranslate } = useLanguage();
    const { eventId } = useParams();
    const { user, validatePremium } = useUser();
    const { updateLoading } = useLoading();
    const navigate = useNavigate();

    const [currEvent, setCurrEvent] = useState<IResponseGetAll | null>(null);

    // Control State
    const [isShowingThemeColorModal, setIsShowingThemeColorModal] = useState<boolean>(false);
    const [sizeModalState, setSizeModalState] = useState<boolean>(false);
    const [isShowingPickingLocation, setIsShowingPickingLocation] = useState<boolean>(false);
    const [showLocationLoading, setShowLocationLoading] = useState<boolean>(false);
    const [isUpdatingDescription, setIsUpdatingDescription] = useState<boolean>(false);
    const [isShowTagsModal, setIsShowTagsModal] = useState<boolean>(false);
    const [priceModal, setPriceModal] = useState<boolean>(false);

    // Event UI
    const [customImgSrc, setCustomImgSrc] = useState<string>(imgSrc);
    const [originImgSrc, setOriginImgSrc] = useState<string>(imgSrc);
    const [eventThemeColor, setEventThemeColor] = useState<string>('#fff');

    // Event name
    const [eventName, setEventName] = useState<string>('');

    // Event time
    const [startTime, setStartTime] = useState<Dayjs>(dayjs().add(13, 'hour'));
    const [endTime, setEndTime] = useState<Dayjs>(startTime.add(1, 'hour'));
    const [originTime, setOriginTime] = useState<any>();

    // Event options
    const [isApproval, setIsApproval] = useState<boolean>(false);
    const [limitPersons, setLimitPersons] = useState<number>(-1);

    // Locations
    const [inputAddress, setInputAddress] = useState<string>('');
    const [locationsSuggestions, setLocationsSuggestions] = useState<any[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<IEventLocation | null>(null);
    const [isVirtualLink, setIsVirtualLink] = useState<boolean>(false);

    // Tags
    const [finalSelectedTags, setFinalSelectedTags] = useState<ITag[]>([]);

    // Descriptions
    const [descriptions, setDescriptions] = useState<string>('');

    // Price
    const [price, setPrice] = useState<number>(0);

    // Generate random theme everytime page loaded up
    useEffect(() => {
        initEvent();
    }, []);

    useEffect(() => {
        if (!currEvent) return;

        if (currEvent.status !== EventStatus.NOT_YET) {
            message.error(onTranslate('MESSAGE.CAN_NOT_EDIT_EVENT'));
            navigate(`/events/${currEvent.eventId}/manage`);
            return;
        }

        // Theme
        setEventThemeColor(currEvent.theme);
        setEventTheme(currEvent.theme);

        // Other info
        setCustomImgSrc(currEvent.image);
        setOriginImgSrc(currEvent.image);
        setEventName(currEvent.eventName);

        setStartTime(dayjs(currEvent.startDate));
        setEndTime(dayjs(currEvent.endDate));
        setOriginTime({
            start: currEvent.startDate,
            end: currEvent.endDate
        });

        setDescriptions(currEvent.description);
        setFinalSelectedTags(currEvent.eventTags as any);
        setPrice(currEvent.fare as number);
        setIsApproval(currEvent.approval);
        setLimitPersons(currEvent.capacity);

        setSelectedLocation({
            locationId: currEvent.location.id,
            location: currEvent.location.name,
            locationAddress: currEvent.location.address,
            locationUrl: currEvent.location.url,
            locationCoord: currEvent.location.coord,
            compound: currEvent.location.compound
        })
    }, [currEvent]);

    const initEvent = async () => {
        updateLoading(true, "Preparing event...")

        let eventData = await APIsAuthorizeUsers(ApiId.GET_EVENT_DETAILS, eventId);

        updateLoading(false);

        if (!eventData || eventData.host.id !== user?.userId) {
            navigate(`/events/${eventId}`);
            return;
        }

        setCurrEvent(eventData);
    }

    const onStartTimeChange = (e: Dayjs) => {
        const createdDate = dayjs(currEvent?.createdAt);
        const futureDay = compareDayjs(e, createdDate, { number: 12, unit: 'hour' })


        // Start time must in the future
        if (!futureDay) {
            message.error(onTranslate('MESSAGE.START_TIME_FROM_CREATED_TIME', 12));
            return;
        }

        // End time =  new start time + current difference
        const diffBeforeInSeconds = endTime.diff(startTime, 'second');
        setEndTime(e.add(diffBeforeInSeconds, 'second'));
        setStartTime(e);
    }

    const onEndTimeChange = (e: Dayjs) => {
        const futureDay = checkFutureTimeDayjs(e);
        const diff = compareDayjs(e, startTime, AppConfigurations.timeDifferenceBetweenStartAndEnd as ITimeDifference);

        // End time must in the future
        // End time must > start Time atleast 30 minutes
        if (futureDay && diff) {
            setEndTime(e);
            return;
        }

        message.error(onTranslate("MESSAGE.END_TIME_VALIDATION", 30));
    }

    // Control show/off picking location modal
    const onShowPickingLocation = (value: boolean) => {
        setIsShowingPickingLocation(value);

        const checkingFunc = (e: any) => {
            const elementId = e.target.id;

            if (!elementId.includes('doNotClose')) {
                setIsShowingPickingLocation(false);
            }
        }

        if (!value) {
            document.removeEventListener('click', checkingFunc);
        } else {
            document.addEventListener('click', checkingFunc);
        }
    }

    const onSearchingLocation = async (e: any) => {
        const inputValue = e;
        setShowLocationLoading(false);

        if (inputValue.trim().length === 0 && inputAddress.trim() === '') {
            setLocationsSuggestions([]);
            setIsVirtualLink(false);
            return;
        }

        const localIsVirtualLink = checkGoogleMeetLink(inputValue);

        if (localIsVirtualLink) {
            setLocationsSuggestions([]);
            setIsVirtualLink(localIsVirtualLink ? true : false);
            setInputAddress(localIsVirtualLink);
            return;
        }

        const suggestions = await APIsAuthorizeUsers(ApiId.GET_PLACE, inputValue);
        if (suggestions === undefined) return;

        setLocationsSuggestions(suggestions ? suggestions : []);
        setIsVirtualLink(false);
    }

    const locationInputDebounced = useDebouncedCallback(onSearchingLocation, 600);
    const locationModalDebounced = useDebouncedCallback(onShowPickingLocation, 100);

    const onTypingInLocationInput = (e: any) => {
        locationInputDebounced(e);
        setShowLocationLoading(true);
        setInputAddress(e);
    }

    const onSelectLocation = async (selectedLocation: any) => {

        const locationDetails = await APIsAuthorizeUsers(ApiId.GET_PLACE_DETAILS, selectedLocation.place_id);
        if (locationDetails === undefined) return;

        const locationSaved: IEventLocation = {
            locationId: selectedLocation.place_id,
            location: selectedLocation.structured_formatting.main_text,
            locationAddress: selectedLocation.structured_formatting.secondary_text,
            locationUrl: locationDetails.url,
            locationCoord: `${locationDetails.geometry.location.lat},${locationDetails.geometry.location.lng}`,
            compound: locationDetails.compound
        }

        setSelectedLocation(locationSaved);
        setLocationsSuggestions([]);
        setInputAddress('');
    }

    const onCustomLocation = () => {
        const customLocation: IEventLocation = {
            locationId: onGenerateRandomID(),
            location: isVirtualLink ? onTranslate('LABEL.VIRTUAL_LOCATION') : inputAddress,
            locationAddress: inputAddress,
            locationUrl: null,
            locationCoord: null,
            compound: null
        }

        setSelectedLocation(customLocation);
        setLocationsSuggestions([]);
        setInputAddress('');
        setIsVirtualLink(false);
    }

    const onRemoveSelectedLocation = () => {
        setSelectedLocation(null);
        setIsShowingPickingLocation(false);
    }

    const onCreateEvent = async () => {
        if (price > 0 && isApproval) return message.error(onTranslate('MESSAGE.PREVENT_TURN_ON_APPROVAL'));

        const eventTagsId = finalSelectedTags.map((tag) => tag.tagId);
        const eventData: any = {
            eventId: currEvent?.eventId,
            eventName,
            description: descriptions,
            startDate: `${startTime.valueOf()}` === `${originTime.start}` ? -1 : `${startTime.valueOf()}` as any,
            endDate: `${endTime.valueOf()}` === `${originTime.end}` ? -1 : `${endTime.valueOf()}` as any,
            image: originImgSrc === customImgSrc ? null : customImgSrc.replace('data:image/png;base64,', ''),
            location: {
                address: selectedLocation?.locationAddress as string,
                coord: selectedLocation?.locationCoord as string | null,
                id: selectedLocation?.locationId as string,
                name: selectedLocation?.location as string,
                url: selectedLocation?.locationUrl as string | null,
                compound: selectedLocation?.compound as null | ILocationCompound,
            },
            capacity: limitPersons,
            approval: isApproval,
            ticket: price,
            theme: eventThemeColor,
            tagId: eventTagsId,
        }

        const errorArr: string[] = [];

        if (eventData.eventName.trim().length <= 3 || eventData.eventName.length >= 250) {
            errorArr.push(onTranslate('MESSAGE.NEW_EVENT_NAME_ERR', 3, 250));
        }
        if (eventData.description.trim().length <= 3 || eventData.description.length >= 5000) {
            errorArr.push(onTranslate('MESSAGE.NEW_EVENT_DESCRIPTION_ERR', 3, 5000))
        }
        if (!eventData.location.address) {
            errorArr.push(onTranslate('MESSAGE.NEW_EVENT_LOCATION_ERR'));
        }
        if (eventData.image === imgSrc) {
            errorArr.push(onTranslate('MESSAGE.NEW_EVENT_IMAGE_ERR'));
        }

        if (errorArr.length > 0) {
            errorArr.forEach((errMsg) => message.error(errMsg))
            return;
        }

        updateLoading(true, onTranslate('MESSAGE.UPDATING_EVENT'));
        const newEventData = await APIsAuthorizeUsers(ApiId.UPDATE_EVENT, eventData);
        updateLoading(false);

        if (!newEventData || !newEventData.eventId) {
            message.error(onTranslate('MESSAGE.UPDATE_EVENT_FAIL'));
            return;
        }

        navigate(`/events/${newEventData.eventId}/manage`);
        message.success(onTranslate('MESSAGE.UPDATE_EVENT_SUCCESS'));
    }

    const handleShowColorDialog = () => {
        if (!validatePremium()) return;
        setIsShowingThemeColorModal(true);
    }

    if (!currEvent) return <div style={{ height: '100vh' }}></div>
    return (
        <Row className='newEvent'>

            {/* Display color in the background base on theme color */}
            <div className="eventThemeMask"></div>

            {/* Picking theme color Modal */}
            <BottomModal isShowing={isShowingThemeColorModal} onClose={() => setIsShowingThemeColorModal(false)}>
                <EventThemePicker setDisplayTheme={setEventThemeColor} />
            </BottomModal>

            <Modal open={sizeModalState} title={onTranslate("LABEL.EVENT_SIZE").toUpperCase()} footer={null} closable={false}>
                <EventSizeOption value={limitPersons} setValue={setLimitPersons} setModalState={setSizeModalState} />
            </Modal>

            <Modal open={isShowTagsModal} title={onTranslate("LABEL.TAG").toUpperCase()} footer={null} closable={false}>
                <TagsModal finalSelectedTags={finalSelectedTags} setFinalSelectedTags={setFinalSelectedTags} setIsShowTagsModal={setIsShowTagsModal} />
            </Modal>

            <Modal open={priceModal} title={onTranslate("LABEL.TICKET").toUpperCase()} footer={null} closable={false}>
                <Ticket setPrice={setPrice} price={price} setModal={setPriceModal} />
            </Modal>

            {isUpdatingDescription && <div className="editorModal">
                <DescriptionEditor setIsUpdatingDescription={setIsUpdatingDescription} descriptions={descriptions} setDescriptions={setDescriptions} />
            </div>}

            <Col span={0} sm={2}></Col>

            <Col span={24} sm={20}>
                <Row className="contentCtn">
                    <Col span={24} sm={9} className="leftSide">
                        <div className="Back" onClick={() => navigate(`/events/${currEvent?.eventId}/manage`)}><FaAngleLeft /> Back to manage page</div>

                        {window.screen.availWidth <= 768 &&
                            <TextInput size="large" isTextArea placeholder="Event's name" value={eventName} onChange={setEventName} />}
                            
                        <UploadImage imgSrc={customImgSrc} setImgSrc={setCustomImgSrc} />

                        {/* Theme selection */}
                        <div className="interactEle" onClick={handleShowColorDialog}>
                            <div className="themeDescrip">
                                <div className="themeColor"></div>
                                <ul>
                                    <li>{onTranslate('LABEL.THEME_COLOR')}</li>
                                    <li>{eventThemeColor.toUpperCase()}</li>
                                </ul>
                            </div>
                            <Button type="link">
                                {
                                    validatePremium()
                                        ? <FaPen />
                                        : <FaLock style={{ color: 'red' }} />
                                }

                            </Button>
                        </div>
                    </Col>

                    <Col span={22} sm={15} className="rightSide">

                        {/* Event name */}

                        {window.screen.availWidth > 768 &&
                            <TextInput size="large" isTextArea placeholder="Event's name" value={eventName} onChange={setEventName} />}

                        {/* Select time */}
                        <div className="interactEle">
                            <div className="metaCtn">
                                <div className="timeline"></div>
                                <div className="timeStart">
                                    <label>{onTranslate('LABEL.START')}</label>
                                    <TimePicker value={startTime} onChange={onStartTimeChange} />
                                </div>
                                <div className="timeEnd">
                                    <label>{onTranslate('LABEL.END')}</label>
                                    <TimePicker value={endTime} onChange={onEndTimeChange} />
                                </div>
                            </div>
                        </div>

                        {/* Location */}
                        <div className="locationWrapper">
                            <div className="interactEle" onClick={() => locationModalDebounced(!isShowingPickingLocation)}>
                                <div className="locationInfo">
                                    <FaLocationDot className="icon" />
                                    <ul>
                                        <li>{onTranslate('LABEL.LOCATION')} </li>
                                        <li>{selectedLocation ? `${selectedLocation.location} ${selectedLocation.locationAddress === selectedLocation.location ? '' : `(${selectedLocation.locationAddress})`}` : onTranslate('LABEL.LOCATION_INSTRUCTION')}</li>
                                    </ul>
                                </div>
                                {selectedLocation && <Button type="link" style={{ transform: 'translateX(-10px)' }}><FaCircleXmark onClick={onRemoveSelectedLocation} /></Button>}
                            </div>

                            {
                                isShowingPickingLocation &&
                                <div className="locationTypingCtn" id="doNotClose">
                                    <TextInput value={inputAddress} id="doNotClose" className="locationInput" placeholder={onTranslate('LABEL.LOCATION_INSTRUCTION_2')} onChange={onTypingInLocationInput} />

                                    {/* Show searching loading when typing in location input */}
                                    {showLocationLoading &&
                                        <ul className="loading">
                                            <li></li>
                                            <li style={{ animationDelay: '0.5s' }}></li>
                                            <li></li>
                                        </ul>
                                    }

                                    {/* Show suggestion locations */}
                                    <ul className="address" id="doNotClose">
                                        {selectedLocation &&
                                            <>
                                                <div className="currentSelectedLocation">{onTranslate('LABEL.CURRENT_LOCATION')}</div>
                                                <li className="currentLoc">
                                                    <FaLocationDot className="icon" />
                                                    <div className="content">
                                                        <div className="mainText">{selectedLocation.location}</div>
                                                        <div className="secondaryText">{selectedLocation.locationAddress}</div>
                                                    </div>
                                                </li>
                                            </>
                                        }
                                        {
                                            (locationsSuggestions.length === 0 && isVirtualLink) &&
                                            <li onClick={onCustomLocation}>
                                                <GoogleMeetLogo className="icon" />
                                                <div className="contentCustonLocation">{onTranslate('LABEL.USE')} <label style={{ fontWeight: 400 }}>'{inputAddress}'</label></div>
                                            </li>
                                        }
                                        {

                                            locationsSuggestions.length > 0 ?
                                                <>
                                                    <div className="currentSelectedLocation" style={{ marginTop: '0' }}>{onTranslate('LABEL.SUGGESTED_LOCATION')}</div>
                                                    {
                                                        locationsSuggestions.map((location, i) => (
                                                            <li key={i} onClick={() => onSelectLocation(location)}>
                                                                <FaLocationDot className="icon" />
                                                                <div className="content">
                                                                    <div className="mainText">{location.structured_formatting.main_text}</div>
                                                                    <div className="secondaryText">{location.structured_formatting.secondary_text}</div>
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                    <li onClick={onCustomLocation}>
                                                        <FaLocationDot className="icon" />
                                                        <div className="contentCustonLocation">{onTranslate('LABEL.USE')} <label style={{ fontWeight: 400 }}>'{inputAddress}'</label></div>
                                                    </li>
                                                </>
                                                :
                                                !isVirtualLink && <div className="noLocation">{onTranslate('LABEL.NO_SUGGESTION')}</div>
                                        }
                                    </ul>
                                </div>
                            }
                        </div>
                        {
                            (selectedLocation && selectedLocation.locationUrl) &&
                            <div className="mapCtn">
                                <iframe className="eventMap" src={selectedLocation?.locationUrl}></iframe>
                            </div>
                        }
                        {/* Description */}
                        <div className="interactEle" onClick={() => setIsUpdatingDescription(true)}>
                            <div className="locationInfo">
                                <FaFile className="icon" />
                                <ul>
                                    <li>{onTranslate('LABEL.DESCRIPTION')} </li>
                                    <li>{removeAllHTMLTags(descriptions)}</li>
                                </ul>
                            </div>
                        </div>

                        {/* Tags */}
                        <div className="interactEle" onClick={() => setIsShowTagsModal(true)}>
                            <div className="locationInfo">
                                <FaHashtag className="icon" />
                                <ul>
                                    <li>{onTranslate('LABEL.TAGS')}</li>
                                    <li className="ftagsCtn">{finalSelectedTags.map((tag) => <div key={tag.tagId}>#{tag.tagName}</div>)}</li>
                                </ul>
                            </div>
                        </div>

                        {/* Options */}
                        <div className="createEventOptions">{onTranslate('LABEL.EVENT_OPTIONS')}</div>
                        <div className="interactEle multipleInteract" style={{ marginTop: '5px' }}>
                            <ul className="eventsOptions">
                                <li onClick={() => setPriceModal(true)}>
                                    <label><FaTicket className="icon" />{onTranslate('LABEL.TICKET')}</label>
                                    <div className="options">
                                        <label>{price === 0 ? onTranslate('LABEL.FREE') : `${price.toLocaleString()} VND`}</label>
                                        <FaPen className="icon" />
                                    </div>
                                </li>
                                <li>
                                    <label><FaUserCheck className="icon" />{onTranslate('LABEL.APPROVAL')}</label>
                                    <div className="options">
                                        <Switch value={isApproval} onChange={setIsApproval} />
                                    </div>
                                </li>
                                <li onClick={() => setSizeModalState(true)}>
                                    <label><FaEarthAsia className="icon" />{onTranslate('LABEL.EVENT_SIZE')}</label>
                                    <div className="options">
                                        <label>{limitPersons >= 0 ? limitPersons : onTranslate('LABEL.UNLIMITED')}</label>
                                        <FaPen className="icon" />
                                    </div>
                                </li>
                            </ul>
                        </div>

                        {/* Create button */}
                        <Button disabled={!user} onClick={onCreateEvent} type="primary" className="newEventBtn" style={{ background: eventThemeColor, color: getTextColorBaseOnBackground(eventThemeColor) }} size="big">{onTranslate('BUTTON.UPDATE_EVENT')}</Button>
                        {!user && <div className="notice">{onTranslate('MESSAGE.CREATE_EVENT_NEED_TO_SIGNIN')}</div>}
                    </Col>

                </Row>
            </Col>
            <Col span={0} sm={2}></Col>
        </Row>
    )
}

export default UpdateEvent