import React from 'react'
import { useUser } from '../../Services/Hooks/useUser'
import { useLanguage } from '../../Services/Hooks/useLanguage'

type Props = {
    setisShowSponsorModal(val: boolean): void
}

const SupportSponsorText = ({ setisShowSponsorModal }: Props) => {
    const { onTranslate } = useLanguage();
    const { user } = useUser();
    return (
        <>
            {
                !user
                    ?
                    <></>
                    :
                    <>
                        <div className="registerTitle">{onTranslate('LABEL.SPONSOR')}</div>
                        <div className="sponsorText" onClick={() => setisShowSponsorModal(true)}>{onTranslate('BUTTON.SUPPORT_ORGANIZER_SPONSOR')}</div>
                    </>
            }

        </>
    )
}

export default SupportSponsorText