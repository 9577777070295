import { createBrowserRouter } from "react-router-dom";
import MainLayout from "./Layout/MainLayout";
import HomePage from "./Layout/HomePage/HomePage";
import Events from "./Layout/Events/Events";
import NewEvent from "./Layout/NewEvent/NewEvent";
import Explore from "./Layout/Explore/Explore";
import ViewEventDetails from "./Layout/ViewEventDetails/ViewEventDetails";
import EventManage from "./Layout/EventManage/EventManage";
import UpdateEvent from "./Layout/UpdateEvent/UpdateEvent";
import Dashboard from "./Layout/Dashboard/Dashboard";
import MyTransactions from "./Layout/MyTransactions/MyTransactions";
import Profile from "./Layout/Profile/Profile";
import SearchEvent from "./Layout/SearchEvent/SearchEvent";
import About from "./Layout/About/About";
import Policy from "./Layout/Policy/Policy";
import Help from "./Layout/Help/Help";
import Pricing from "./Layout/Pricing/Pricing";
import CertificateViewPage from "./Layout/CertificateViewPage/CertificateViewPage";
import AttemptQuiz from "./Layout/AttempQuiz/AttemptQuiz";

export const Routers = createBrowserRouter([
    {
        path: '/',
        element: <MainLayout />,
        children: [
            {
                path: '/',
                element: <HomePage />,
            },
            {
                path: '/c/:certificateId',
                element: <CertificateViewPage />,
            },
            {
                path: '/quiz/:quizId',
                element: <AttemptQuiz />,
            },
            {
                path: '/about',
                element: <About />,
            },
            {
                path: '/policy',
                element: <Policy />,
            },
            {
                path: '/pricing',
                element: <Pricing />,
            },
            {
                path: '/help',
                element: <Help />,
            },
            {
                path: '/my-events',
                element: <Events />,
            },
            {
                path: '/events/:eventId',
                element: <ViewEventDetails />,
            },
            {
                path: '/events/:eventId/manage',
                element: <EventManage />,
            },
            {
                path: '/events/:eventId/update',
                element: <UpdateEvent />,
            },
            {
                path: '/new-event',
                element: <NewEvent />,
            },
            {
                path: '/events',
                element: <Explore />,
            },
            // {
            //     path: '/explore',
            //     element: <Explore />,
            // },
            {
                path: '/dashboard',
                element: <Dashboard />,
            },
            {
                path: '/my-transaction',
                element: <MyTransactions />,
            },
            {
                path: '/profile/:userId',
                element: <Profile />,
            },
            {
                path: '/profile',
                element: <Profile />,
            },
            {
                path: '/search-event',
                element: <SearchEvent />,
            },
            {
                path: '/*',
                element: <>404 not found</>
            }
        ],
    }
])