import { useEffect, useState } from "react"
import "./EventManage.scss"
import { IParticipantDataInEvent } from "../../Shared/Model/UserInterface";
import { APIsAuthorizeUsers } from "../../Services/APIs/APIsController";
import { ApiId } from "../../Shared/Enums/APIsEnum";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../Services/Hooks/useUser";
import { DarkTheme } from "../../Shared/Constants/Theme/DarkTheme";
import { IFeedbacks, IResponseGetAll } from "../../Shared/Model/EventsInterface";
import { setEventTheme } from "../../Services/Utils/ThemeUtils";
import { Col, Row } from "antd";
import Button from "../../Components/Button/Button";
import LoadingDots from "../../Components/LoadingDots/LoadingDots";
import { FaChevronRight } from "react-icons/fa6";
import EventManageSettingsNav from "./EventManageSettingsNav";
import EventManageContent from "./EventManageContent";
import { EventRole, ParticipantEventStatusEnum } from "../../Shared/Enums/EventsEnum";
import { onGetEventTransactions } from "../../Services/APIs/PaymentAPIs";
import { useLanguage } from "../../Services/Hooks/useLanguage";

type Props = {}

const EventManage = (props: Props) => {
  const { onTranslate } = useLanguage();
  const { eventId } = useParams();
  const { user } = useUser();
  const navigate = useNavigate();

  const [loadingCount, setLoadingCount] = useState<number>(0);
  const [currEvent, setCurrEvent] = useState<IResponseGetAll>();
  const [currNav, setCurrNav] = useState<string>('Overview');
  const [feedbacks, setFeedbacks] = useState<IFeedbacks[] | null>(null);
  const [checkinList, setCheckinList] = useState<any[]>(Array.from({ length: 6 }, () => []));
  const [sponsorRequests, setSponsorRequests] = useState<any>([]);
  const [transactions, setTransactions] = useState<any[]>([]);

  useEffect(() => {
    initEvent();
    onInitCheckinList();
    getFeedbacks();
    onInitSponsorRequests();
    onInitEventTransactions();
  }, [])

  const initEvent = async () => {
    setLoadingCount(prev => prev + 1);

    let eventData = await APIsAuthorizeUsers(ApiId.GET_EVENT_DETAILS, eventId);
    setLoadingCount(prev => prev - 1);

    if (!eventData || eventData.host.id !== user?.userId) {
      navigate(`/events/${eventId}`);
      return;
    }

    // Event have no theme
    if (!eventData.theme || !eventData.theme.includes('#')) eventData.theme = DarkTheme["--brand-color"];

    // Event have no image
    if (!eventData.image) eventData.image = '/assets/image/defaultEvent.png'

    setCurrEvent(eventData);
    setEventTheme(eventData.theme);

    const eventDescriptionContent = document.getElementById('eventDescriptionContent');
    if (!eventDescriptionContent) return;

    eventDescriptionContent.innerHTML = eventData.description;
  }

  const getFeedbacks = async () => {
    setLoadingCount(prev => prev + 1);
    const feedbacks = await APIsAuthorizeUsers(ApiId.GET_FEEDBACKS, eventId);
    setLoadingCount(prev => prev - 1);
    setFeedbacks(feedbacks)
  }

  const onInitCheckinList = async () => {
    setLoadingCount(prev => prev + 1);
    const result = await APIsAuthorizeUsers(ApiId.GET_CHECKIN_LIST, { eventId: eventId, page: 1, pageSize: 1000 });
    setLoadingCount(prev => prev - 1);
    if (!result) return;
    setCheckinList(onFilterCheckinList(result));
  }

  const onFilterCheckinList = (data: any) => {
    if (!data) return;

    const userLists: any = Array.from({ length: 6 }, () => []);
    // Check for 0 Staff ->  1 Sponsor -> Visitor
    // Visitor: 2 Pending, 3 Blocked , 4 Checkedin, 5 Not Checkin
    data.forEach((user: any) => {
      if (user.roleEventId === EventRole.CHECKING_STAFF) {
        userLists[0].push(user);
        return;
      }

      if (user.roleEventId === EventRole.SPONSOR) {
        userLists[1].push(user);
        return;
      }

      if (user.status === ParticipantEventStatusEnum.PENDING) {
        userLists[2].push(user);
        return;
      }

      if (user.status === ParticipantEventStatusEnum.PENDING) {
        userLists[3].push(user);
        return;
      }

      if (user.checkedIn) {
        userLists[4].push(user);
        return;
      }

      if (!user.checkedIn) {
        userLists[5].push(user);
        return;
      }
    });
    return userLists;
  }

  const onInitSponsorRequests = async () => {
    setLoadingCount(prev => prev + 1);
    const result = await APIsAuthorizeUsers(ApiId.GET_ALL_EVENT_SPONSOR_REQUESTS, { eventId });
    setLoadingCount(prev => prev - 1);
    if (!result) return;

    setSponsorRequests(result);
  }

  const onInitEventTransactions = async () => {
    if (!eventId) return;
    setLoadingCount(prev => prev + 1);
    const result = await onGetEventTransactions(eventId);
    setLoadingCount(prev => prev - 1);

    if (!result) return;
    setTransactions(result);
  }

  return (
    <Row className="eventManage">
      <div className="eventThemeMask"></div>

      <Col span={2} sm={4}></Col>
      <Col span={20} sm={16}>
        <div className="infoCtn">
          {
            loadingCount > 0
              ?
              <LoadingDots />
              :
              <div className="info">
                <div className="host" onClick={() => navigate('/profile')}>{currEvent?.host.name} <FaChevronRight className="icon" /></div>
                <div className="eventName">{currEvent?.eventName}</div>
              </div>
          }

          <div className="btnCtn">
            <Button onClick={() => navigate(`/events/${eventId}`)}>{onTranslate('LABEL.EVENT_PAGE')}</Button>
          </div>
        </div>

        <EventManageSettingsNav setCurrNav={setCurrNav} currNav={currNav} />
        {loadingCount === 0 && <EventManageContent transactions={transactions} setCurrNav={setCurrNav} currNav={currNav} currEvent={currEvent} feedbacks={feedbacks} checkinList={checkinList} onInitCheckinList={onInitCheckinList} onInitSponsorRequests={onInitSponsorRequests} sponsorRequests={sponsorRequests} />}
      </Col>
      <Col span={2} sm={4}></Col>
    </Row>
  )
}

export default EventManage