import React from 'react'
import { QuizStatusEnum } from '../../../Shared/Enums/QuizEnum'
import { useLanguage } from '../../../Services/Hooks/useLanguage'

type Props = {
    quizStatus: QuizStatusEnum;
    style?: object;
    onClick?: (event: any) => void;
}

const QuizLabel = ({ quizStatus, style, onClick = () => {} }: Props) => {
    const { onTranslate } = useLanguage();

    const commonStyle = {
        paddingRight: '7px', 
        paddingLeft: '7px', 
        borderRadius: '7px',
        ...style
    }

    switch (quizStatus) {
        case QuizStatusEnum.ACTIVE:
            return <label onClick={onClick} style={{ background: 'green', ...commonStyle }}>{onTranslate('LABEL.ACTIVE_QUIZ')}</label>
        case QuizStatusEnum.CLOSED:
            return <label onClick={onClick} style={{ background: 'orange', ...commonStyle }}>{onTranslate('LABEL.CLOSED')}</label>
        case QuizStatusEnum.DELETED:
            return <label onClick={onClick} style={{ background: 'red', ...commonStyle }}>{onTranslate('LABEL.DELETED')}</label>
        case QuizStatusEnum.DRAFTED:
            return <label onClick={onClick} style={{ background: 'gray', ...commonStyle }}>{onTranslate('LABEL.DRAFT')}</label>
    }
}

export default QuizLabel