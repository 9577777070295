import React, { useEffect, useState } from 'react'
import { APIsAuthorizeUsers } from '../../Services/APIs/APIsController'
import { ApiId } from '../../Shared/Enums/APIsEnum'
import RandomAvatarByName from '../../Components/RandomAvatarByName/RandomAvatarByName'
import LoadingDots from '../../Components/LoadingDots/LoadingDots'
import { useNavigate } from 'react-router-dom'
import { useLanguage } from '../../Services/Hooks/useLanguage'

type Props = {}

const Locations = (props: Props) => {
    const navigate = useNavigate();
    const { onTranslate } = useLanguage();

    const [locations, setLocations] = useState<any[]>([]);
    const [locationsLoading, setLocationsLoading] = useState<boolean>(false);

    useEffect(() => {
        onGetAllPopularLocations();
    }, [])

    const onGetAllPopularLocations = async () => {
        setLocationsLoading(true);
        const fetchedResult = await APIsAuthorizeUsers(ApiId.GET_POPULAR_LOCATIONS);
        setLocationsLoading(false);

        if (!fetchedResult) return;
        setLocations(fetchedResult);
    }
    return (
        <div className='locationsCtn'>

            {
                locationsLoading
                    ?
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <LoadingDots color="var(--brand-color)" />
                    </div>
                    :
                    locations && locations.length > 0
                        ?
                        locations?.map((loc, i) => (
                            <div className='location' key={loc?.locationId} style={{ animationDelay: `${i & 0.3}s` }} onClick={() => navigate(`/search-event?locationId=${loc.locationId}`)}>
                                <RandomAvatarByName name={loc?.location} />
                                <div className='infoCtn'>
                                    <div className='name'>{loc?.location}</div>
                                    <div className='amount'>{loc?.totalevent} {onTranslate("LABEL.EVENTS")}</div>
                                </div>
                            </div>
                        ))
                        :
                        <div className="empty" style={{textAlign: 'center', width: '100%'}}>{onTranslate("LABEL.NO_LOCATION_FOUND")}</div>
            }
        </div>
    )
}

export default Locations