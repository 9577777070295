import { useEffect } from "react";
import "./Input.scss";
import { Switch as AntdSwitch } from "antd";

type Props = {
    value: boolean;

    onChange: (newValue: boolean) => void;
}

const Switch = ({ value, onChange }: Props) => {

    return (
        <AntdSwitch checked={value} onChange={onChange} />
    )
}

export default Switch