import React, { useEffect, useState } from 'react'
import { APIsAuthorizeUsers } from '../../Services/APIs/APIsController'
import { ApiId } from '../../Shared/Enums/APIsEnum'
import Table from '../../Components/Table/Table'
import { UsersTableColumn } from './TableColumns'
import { onDeleteUser } from '../../Services/APIs/UserApis'
import LoadingDots from '../../Components/LoadingDots/LoadingDots'
import { message } from 'antd'

type Props = {}

const Users = (props: Props) => {
    const [users, setUsers] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        handleGetAllUsers();
    }, [])

    const handleGetAllUsers = async () => {
        setIsLoading(true)
        const result = await APIsAuthorizeUsers(ApiId.GET_ALL_USERS);
        setIsLoading(false);

        if (!result) return;
        setUsers(result);
    }

    const handleDeleteUser = async (userId: string) => {
        setIsLoading(true)
        const result = await onDeleteUser(userId);
        setIsLoading(false);

        if(!result) return;
        handleGetAllUsers();
        message.success('Updated successfully');
    }

    const [usersCol, setUsersCol] = useState<any>(() => UsersTableColumn(handleDeleteUser));

    return (
        <div style={{ margin: '30px 0 0 0' }}>
            <div className="title">Users list</div>
            <div className="subtitle" style={{ margin: '0 0 20px 0' }}>View users' details</div>
            {
                isLoading
                    ?
                    <LoadingDots />
                    :
                    <Table data={users} columns={usersCol} />
            }
        </div>
    )
}

export default Users