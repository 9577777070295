import "./EventSlider.scss";
import { IResponseGetAll } from '../../Shared/Model/EventsInterface';
import { useRef, useState } from "react";
import Button from "../Button/Button";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { eventPreviewDateFormat } from "../../Services/Utils/TimeUtils";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../Services/Hooks/useLanguage";

type Props = {
    eventsList: IResponseGetAll[] | null;
}

const EventSlider = ({ eventsList }: Props) => {
    const navigate = useNavigate();
    const { onTranslate } = useLanguage();
    const sliderRef = useRef<HTMLDivElement>(null);

    const [imageIndex, setImageIndex] = useState<number>(1);
    const [isAnimation, setIsAnimation] = useState<boolean>(false);

    const onNext = () => {
        if (!eventsList || isAnimation) return;

        const slider = sliderRef.current;
        if (!slider) return;

        setIsAnimation(true);
        slider.style.animationName = "scrollOutLeft";

        const aniTimeout = setTimeout(() => {
            slider.style.animationName = "scrollInFromRight";
            setIsAnimation(false);
            clearTimeout(aniTimeout);

            if (imageIndex === Math.ceil(eventsList.length / 3)) {
                setImageIndex(1);
                return;
            }
            setImageIndex(prev => prev + 1);
        }, 350);

    }

    const onPrev = () => {
        if (!eventsList) return;

        const prevPage = imageIndex;

        const slider = sliderRef.current;
        if (!slider) return;

        setIsAnimation(true);
        slider.style.animationName = "scrollOutRight";

        const aniTimeout = setTimeout(() => {
            slider.style.animationName = "scrollInFromLeft";
            setIsAnimation(false);
            clearTimeout(aniTimeout);

            if (prevPage === 1) {
                setImageIndex(Math.ceil(eventsList.length / 3));
                return;
            }
            setImageIndex(prev => prev - 1);
        }, 350);
    }



    return (
        <div className="eventSlider">
            <Button className="actionBtn" onClick={() => onPrev()} style={{ left: 0 }}>
                <FaAngleLeft />
            </Button>

            <div className="EventSliderCtn" ref={sliderRef}>
                {
                    eventsList?.map((ev, i) => (
                        (imageIndex * 3) > i && ((imageIndex - 1) * 3) - 1 < i
                            ?
                            <div className="eventCard" onClick={() => navigate(`/events/${ev.eventId}`)} key={ev.eventId}>
                                <img className="imgCard" src={ev.image} />
                                {ev?.isAdvertised && <div className="advertise">{onTranslate('LABEL.TRENDING')}</div>}
                                <div className="eventName">{ev.eventName}</div>
                            </div>
                            :
                            <div key={i}></div>
                    ))
                }
            </div>
            <Button className="actionBtn" onClick={() => onNext()} style={{ right: 0 }}>
                <FaAngleRight />
            </Button>
        </div>
    )
}

export default EventSlider