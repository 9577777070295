import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { avgColor } from '../../Services/Utils/ThemeUtils';
import { DarkTheme } from '../../Shared/Constants/Theme/DarkTheme';

type Props = {
    eventsStatusTotal: any
}

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend
);

const EventStatusTotal = ({ eventsStatusTotal }: Props) => {
    const data = {
        labels: Object.keys(eventsStatusTotal).filter((status, i) => i <= 4 ),
        datasets: [
            {
                label: "Event's Status",
                data: Object.values(eventsStatusTotal).filter((status, i) => i <= 4 ),
                backgroundColor: [
                    avgColor('#f7c51e', DarkTheme['--quaternary-bg-color']),
                    avgColor('#82ff88', DarkTheme['--quaternary-bg-color']),
                    DarkTheme['--tertiary-text-color'],
                    avgColor('#ff8482', DarkTheme['--quaternary-bg-color']),
                    avgColor('#ffa263', DarkTheme['--quaternary-bg-color']),
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    '#cf9f04',
                    '#02bd0b',
                    DarkTheme['--secondary-text-color'],
                    '#b30604',
                    '#bf5004',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
            },
            title: {
                display: true,
                text: "Event's Status Summary",
                color: DarkTheme['--primary-text-color'], // Title text color
            },
        },
    };

    return <Pie data={data} options={options as any} />;
}

export default EventStatusTotal