import { MutableRefObject, useEffect, useRef, useState } from "react";
import "./Modal.scss";

type Props = {
  isShowing: boolean;
  children?: any;

  onClose?: () => void;
}

const BottomModal = ({ isShowing, children, onClose }: Props) => {
  const bottomModal = useRef() as MutableRefObject<HTMLDivElement>;
  const [isModalShowing, setIsModalShowing] = useState<boolean>(isShowing);

  // Control modal local state
  useEffect(() => {
    setIsModalShowing(isShowing);
  }, [isShowing]);

  // Add listen to click outside
  useEffect(() => {
    if (isModalShowing) {
      document.addEventListener("click", handleCloseModal);
    }
  }, [isModalShowing]);


  const handleCloseModal = (e: any) => {
    const modalElement = bottomModal.current;

    if (!modalElement) return;
    const modalHeight = bottomModal.current.clientHeight;
    const windowHeight = window.innerHeight;
    const mousePosition = e.clientY;

    const colorPicker = document.querySelector('.ant-color-picker-palette');

    if ((mousePosition < (windowHeight - modalHeight)) && onClose && e.target !== colorPicker) {
      document.removeEventListener("click", handleCloseModal);

      modalElement.classList.add('closeAnimate');

      const closeTimeout = setTimeout(() => {
        onClose();
        modalElement.classList.remove('closeAnimate');
        clearTimeout(closeTimeout);
      }, 350);
    }
  }

  return (
    <>
      {
        isModalShowing &&
        <div className='bottomModal' ref={bottomModal}>
          {children}
        </div>
      }
    </>
  )
}

export default BottomModal