import NumberInput from "../../../Components/Input/NumberInput";
import { AppConfigurations } from "../../../Shared/Constants/AppConfigurations";
import Button from "../../../Components/Button/Button";
import "./EventOptionsStyle.scss";
import { useState } from "react";
import { useLanguage } from "../../../Services/Hooks/useLanguage";

type Props = {
  setValue?: (value: number) => void;
  value?: number;

  setModalState?: (val: boolean) => void;
}

const EventSizeOption = ({ value = 0, setValue = (val) => { }, setModalState = (val) => { } }: Props) => {
  const { onTranslate } = useLanguage();
  const [tempValue, setTempValue] = useState<number>(value === -1 ? AppConfigurations.eventDefaultSize : value);

  const onSaveLimit = () => {
    setValue(tempValue);
    setModalState(false);
  }

  const onSetUnlimit = () => {
    setValue(-1);
    setModalState(false);
  }

  return (
    <div className='EventSizeOption'>
      <ol className="description">
        <li>{onTranslate('LABEL.SIZE_HINT_1')}</li>
        <li>{onTranslate('LABEL.SIZE_HINT_2')}</li>
      </ol>

      <div className="limitTitle">{onTranslate('LABEL.LIMIT')}</div>
      <NumberInput value={tempValue} setValue={setTempValue} />

      <div className="eventSizeBtnCtn">
        <Button type="primary" onClick={onSaveLimit}>{onTranslate('BUTTON.SET_LIMIT')}</Button>
        <Button type="secondary" onClick={onSetUnlimit}>{onTranslate('BUTTON.REMOVE_LIMIT')}</Button>
      </div>
    </div>
  )
}

export default EventSizeOption