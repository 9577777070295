import axios from "axios";
import { authApiConfig, getAdvertiseStatistic, getAdvertisedByEvent, getDetailsAdvertiseEvent, getEventAdvertisedApi } from "./APIsConfig";
import { APIErrorController } from "./APIsController";

export const onGetAdvertisedOfEvent = (eventId: string) => {
    const config = authApiConfig();
    return axios.get(getAdvertisedByEvent(eventId), config as any)
        .then(res => res.data.data)
        .catch(err => APIErrorController(err))
}

export const onGetAllAdvertisedEvent = () => {
    return axios.get(getEventAdvertisedApi)
        .then(res => res.data.data)
        .catch(err => APIErrorController(err))
}

export const onGetAdvertisedEvent = () => {
    return axios.get(getEventAdvertisedApi)
        .then(res => res.data.data.data)
        .catch(err => APIErrorController(err))
}

export const onGetAdvertisedEventStats = () => {
    const config = authApiConfig();
    return axios.get(getAdvertiseStatistic, config)
        .then(res => res.data.data)
        .catch(err => APIErrorController(err))
}

export const onGetAdvertisedEventDetails = (eventId: string) => {
    const config = authApiConfig();
    return axios.get(getDetailsAdvertiseEvent(eventId), config)
        .then(res => res.data.data)
        .catch(err => APIErrorController(err))
}