import { useLanguage } from "../../Services/Hooks/useLanguage";
import "./NoEvent.scss"
import { FaCalendar } from 'react-icons/fa6'

type Props = {
    label?: string;
}

const NoEvent = ({ label }: Props) => {
    const { onTranslate } = useLanguage();
    return (
        <div className="emptyCalendar">
            <FaCalendar />
            <div className="emptyCalendar_text">{label ? label : onTranslate("LABEL.NO_UPCOMMING_EVENT")}</div>
        </div>
    )
}

export default NoEvent