import { FaStar } from "react-icons/fa6";
import "./EMFeedback.scss";
import { avgColor } from "../../../Services/Utils/ThemeUtils";
import { IResponseGetAll } from "../../../Shared/Model/EventsInterface";
import { DarkTheme } from "../../../Shared/Constants/Theme/DarkTheme";
import FeedbackTemplate from "./FeedbackTemplate";
import BackProgressBar from "./BackProgressBar";
import { useLanguage } from "../../../Services/Hooks/useLanguage";

type Props = {
    feedbacks: any[] | null,
    currEvent: IResponseGetAll | undefined,
}

const EMFeedback = ({ feedbacks, currEvent }: Props) => {
    const { onTranslate } = useLanguage();
    return (
        <div className="fbackCtn">
            <div className="title">{onTranslate('BUTTON.FEEDBACK')}</div>
            <div className="subtitle">{onTranslate('LABEL.VIEW_ALL_FEEDBACKS')}</div>

            <BackProgressBar feedbacks={feedbacks} theme={currEvent?.theme} />

            <div className="fbacksList">
                {
                    feedbacks?.map((fback, i) => (
                        <FeedbackTemplate currEvent={currEvent} fback={fback} />
                    ))
                }
            </div>
        </div>
    )
}

export default EMFeedback