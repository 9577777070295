import { Col, Collapse, Row } from "antd"
import "./Help.scss"
import { AppConfigurations } from "../../Shared/Constants/AppConfigurations"
import { useLanguage } from "../../Services/Hooks/useLanguage"

type Props = {}

const Help = (props: Props) => {
  const { onTranslate } = useLanguage();

  const QA = [
    {
      question: onTranslate('LABEL.QA_QUESTION_1'),
      answer: onTranslate('LABEL.QA_ANSWER_1')
    },
    {
      question: onTranslate('LABEL.QA_QUESTION_2'),
      answer: onTranslate('LABEL.QA_ANSWER_2')
    },
    {
      question: onTranslate('LABEL.QA_QUESTION_3'),
      answer: onTranslate('LABEL.QA_ANSWER_3')
    },
    {
      question: onTranslate('LABEL.QA_QUESTION_4'),
      answer: onTranslate('LABEL.QA_ANSWER_4')
    },
    {
      question: onTranslate('LABEL.QA_QUESTION_5'),
      answer: onTranslate('LABEL.QA_ANSWER_5')
    },
    {
      question: onTranslate('LABEL.QA_QUESTION_6'),
      answer: onTranslate('LABEL.QA_ANSWER_6')
    },
    {
      question: onTranslate('LABEL.QA_QUESTION_7'),
      answer: onTranslate('LABEL.QA_ANSWER_7')
    },
    {
      question: onTranslate('LABEL.QA_QUESTION_8'),
      answer: onTranslate('LABEL.QA_ANSWER_8')
    },
    {
      question: onTranslate('LABEL.QA_QUESTION_9'),
      answer: onTranslate('LABEL.QA_ANSWER_9')
    },
    {
      question: onTranslate('LABEL.QA_QUESTION_10'),
      answer: onTranslate('LABEL.QA_ANSWER_10')
    },
    {
      question: onTranslate('LABEL.QA_QUESTION_11'),
      answer: onTranslate('LABEL.QA_ANSWER_11')
    },
    {
      question: onTranslate('LABEL.QA_QUESTION_12'),
      answer: onTranslate('LABEL.QA_ANSWER_12')
    },
    {
      question: onTranslate('LABEL.QA_QUESTION_13'),
      answer: onTranslate('LABEL.QA_ANSWER_13')
    },
    {
      question: onTranslate('LABEL.QA_QUESTION_14'),
      answer: onTranslate('LABEL.QA_ANSWER_14')
    },
    {
      question: onTranslate('LABEL.QA_QUESTION_15'),
      answer: onTranslate('LABEL.QA_ANSWER_15')
    },
    {
      question: onTranslate('LABEL.QA_QUESTION_16'),
      answer: onTranslate('LABEL.QA_ANSWER_16')
    }
  ];

  const items = QA.map((item, i) => ({
    key: i,
    label: item.question,
    children: <div style={{fontWeight: 400, fontSize: 'var(--pc-font-medium)'}}>{item.answer}</div>,
  }))

  return (
    <Row className="help">
      <Col span={AppConfigurations.blankCol.span} sm={AppConfigurations.blankCol.sm}></Col>

      <Col span={AppConfigurations.contentCol.span} sm={AppConfigurations.contentCol.sm}>
        <div className="title">{onTranslate('LABEL.HELP').toUpperCase()}</div>
        <Collapse  items={items} bordered={false}/>
      </Col>

      <Col span={AppConfigurations.blankCol.span} sm={AppConfigurations.blankCol.sm}></Col>
    </Row>
  )
}

export default Help