import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { DarkTheme } from '../../Shared/Constants/Theme/DarkTheme';
import { avgColor } from '../../Services/Utils/ThemeUtils';

type Props = {
    monthlyEvents: any,
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const MonthlyEventsChart = ({ monthlyEvents }: Props) => {
    const data = {
        labels: monthlyEvents.map((data: any) => data.month),
        datasets: [{
            label: 'Events',
            data: monthlyEvents.map((data: any) => data.value),
            fill: false,
            borderColor: avgColor(DarkTheme['--brand-color'], DarkTheme['--quaternary-text-color']),
            backgroundColor: avgColor(DarkTheme['--brand-color'], DarkTheme['--secondary-text-color']),
            tension: 0.1
        }]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: ''
            }
        },
        scales: {
            x: {
                type: 'category',
                labels: monthlyEvents.map((data: any) => data.month),
                ticks: {
                    color: DarkTheme['--secondary-text-color'] // X axis text color
                },
                grid: {
                    color: DarkTheme['--quaternary-text-color'] // X axis grid line color
                }
            },
            y: {
                beginAtZero: true,
                ticks: {
                    color: DarkTheme['--secondary-text-color'] // X axis text color
                },
                grid: {
                    color: DarkTheme['--quaternary-text-color'] // X axis grid line color
                }
            }
        }
    };
    return (
        <Line data={data} options={options as any} />
    )
}

export default MonthlyEventsChart