import { localStorageId } from "../../Shared/Enums/StorageEnum";
import { IUserData } from "../../Shared/Model/UserInterface";
import { getDataLocalStorage, setDataLocalStorage } from "./StorageUtils";

export const onSaveUserToLocal = (userData: IUserData) => {
    const {fullName, ...otherInfo} = userData;
    const enc = btoa(JSON.stringify(otherInfo));

    const savedObj = {fullName, enc};
    setDataLocalStorage(localStorageId.USER_DATA, savedObj);
}

export const onLoadUserFromLocal = () => {
    const storedData = getDataLocalStorage(localStorageId.USER_DATA);
    if(!storedData) return null;

    const {fullName, enc} = storedData;
    const userData = JSON.parse(atob(enc));

    return {fullName, ...userData};
}