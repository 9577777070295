import React, { useState } from 'react'
import { useLanguage } from '../../Services/Hooks/useLanguage';
import Button from '../../Components/Button/Button';
import WebStarIcon from '../../Components/WebLogo/WebStarIcon';
import { useUser } from '../../Services/Hooks/useUser';
import { IZaloPaymentCreateOrder } from '../../Shared/Model/ZaloPayment';
import { PaymentSubscriptionType } from '../../Shared/Enums/PaymentEnum';
import { onZaloCreateTransaction } from '../../Services/APIs/PaymentAPIs';
import { message } from 'antd';
import LoadingDots from '../../Components/LoadingDots/LoadingDots';

type Props = {
    packageType: 'FREE' | 'PREMIUM',
    price: number,
}

const PackageCtn = ({ packageType, price }: Props) => {
    const { onTranslate } = useLanguage();
    const { user, validatePremium } = useUser();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const isFree = packageType === 'FREE';

    const onImplementOrder = async () => {
        if (validatePremium()) return;
        if (!user) return message.error(onTranslate('MESSAGE.LOGIN_REQUIRED'));

        const zaloPaymentData: IZaloPaymentCreateOrder = {
            userId: user.userId,
            description: `Purchase Business Package: ${user.fullName} ${user.email} - `,
            amount: `400000`,
            subscriptionType: PaymentSubscriptionType.SUBSCRIPTION,
            redirectUrl: window.location.href
        }

        setIsLoading(true);
        const result = await onZaloCreateTransaction(zaloPaymentData);

        if (!result) return message.error(onTranslate('MESSAGE.PAYMENT_FAIL'));

        // Create new payment success
        message.success(onTranslate('MESSAGE.PAYMENT_SUCCESS'));
        window.open(result.order_url);
        setIsLoading(false);
        return;
    }

    return (
        <div className='PackageCtn'>
            <div className='packageTitle'>{onTranslate(`LABEL.${packageType}_PACKAGE`).toUpperCase()}</div>
            <div className='packagePrice'>
                {isFree ? onTranslate(`LABEL.FREE`).toUpperCase() : `${price?.toLocaleString() ?? '_'} VND`}
            </div>
            <div className='packageDuration'>{onTranslate(`LABEL.${isFree ? 'FOREVER' : 'MONTHLY'}`)}</div>
            {
                isFree
                    ? <Button className='buyBtn' type='primary' disabled>{onTranslate('LABEL.ACTIVE').toUpperCase()}</Button>
                    :
                    <>
                        {
                            isLoading
                                ? <LoadingDots />
                                :
                                validatePremium()
                                    ? <Button className='buyBtn' type='primary' disabled>{onTranslate('LABEL.ACTIVE').toUpperCase()}</Button>
                                    : <Button onClick={onImplementOrder} className='buyBtn buyPremiumBtn' type='primary'>{onTranslate('BUTTON.UPGRADE').toUpperCase()}</Button>
                        }
                    </>
            }
            <div className='packageDescription'>
                <div className='describe'>{onTranslate(`LABEL.${isFree ? 'FREE' : 'PREMIUM'}_DESCRIBE`)}</div>
                {onTranslate(`LABEL.${packageType}_PACKAGE_DESCRIPTION`).split('|')
                    .map((line: string, i: number) => (
                        <div className='line' key={i}><WebStarIcon className='star' width={window.screen.availWidth <= 768 ? 15 : 22} height={window.screen.availWidth <= 768 ? 15 : 22} /> {line}</div>
                    ))}
            </div>
        </div>
    )
}

export default PackageCtn