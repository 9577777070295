import { FaRegBell, FaRegCircleUser } from "react-icons/fa6";
import Button from "../Button/Button"
import "./Navbar.scss";
import { Dropdown } from "antd";
import type { MenuProps } from 'antd';
import { onSignOut } from "../../Services/Auth/Authentication";
import LogoutBtn from "./LogoutBtn";
import { useUser } from "../../Services/Hooks/useUser";
import { Link } from "react-router-dom";
import { useLanguage } from "../../Services/Hooks/useLanguage";
import SwitchLanguage from "./SwitchLanguage";
import { BiMenu, BiX } from "react-icons/bi";
import { useState } from "react";

interface IProps {
    isLogin: boolean;
    setIsLogin: (val: boolean) => void;

}
export const UnLogInNavbar = ({ isLogin, setIsLogin }: IProps) => {
    const { onTranslate } = useLanguage();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const items: MenuProps['items'] = [
        {
            label: <Link to='/'>{onTranslate('BUTTON.HOME')}</Link>,
            key: '4',
        },
        {
            label: <Link to='/new-event'>{onTranslate('BUTTON.CREATE_EVENT')}</Link>,
            key: '5',
        },
    ];

    return (
        <>

            <ul className='navItems'>
                {/* <li>{addZeroBefore(now.getHours())}:{addZeroBefore(now.getMinutes())} GMT+7</li> */}
                {
                    window.innerWidth > 768 &&
                    <>
                        <li><Button type="link" onLinkTo="/">{onTranslate('BUTTON.HOME')}</Button></li>
                        <li><Button type="link" onLinkTo="/new-event">{onTranslate('BUTTON.CREATE_EVENT')}</Button></li>
                    </>
                }
            </ul>
            <ul className="rightNav">
                <li><SwitchLanguage /></li>
                <li>
                    <Button onClick={() => setIsLogin(!isLogin)}>{!isLogin ? onTranslate('BUTTON.SIGNIN') : onTranslate('BUTTON.CLOSE_SIGNIN_FORM')}</Button>
                </li>
                {
                    window.innerWidth < 768 &&
                    <li onClick={() => setIsOpen(prev => !prev)}>
                        <Dropdown menu={{ items }} trigger={['click']}>
                            <Button type="link">{isOpen ? < BiX /> : <BiMenu />} </Button>
                        </Dropdown>
                    </li>
                }
            </ul>

        </>
    )
}

export const LoggedInNavbar = () => {
    const { user } = useUser();
    const { onTranslate } = useLanguage();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const mobileItems: MenuProps['items'] =
        window.innerWidth > 768
            ? []
            : [
                {
                    label: <Link to='/'>{onTranslate('BUTTON.HOME')}</Link>,
                    key: '4',
                },
                {
                    label: <Link to='/new-event'>{onTranslate('BUTTON.CREATE_EVENT')}</Link>,
                    key: '5',
                },
            ];

    const items: MenuProps['items'] = [
        ...mobileItems,
        {
            label: <Link to='/profile'>{onTranslate('BUTTON.PROFILE')}</Link>,
            key: '0',
        },
        {
            label: <Link to='/my-transaction'>{onTranslate('BUTTON.TRANSACTIONS')}</Link>,
            key: '1',
        },
        {
            type: 'divider',
        },
        {
            label: <LogoutBtn />,
            key: '3',
        },
    ];

    if (!user?.isPremiumUser) {
        items.push({
            type: 'divider',
        })
        items.push({
            label: <Link to='/pricing'>{onTranslate('BUTTON.UPGRADE_ACCOUNT')}</Link>,
            key: '4',
        })
    }
    return (
        <>
            {
                window.innerWidth > 768 &&
                <ul className='navItems'>
                    <li><Button type="link" onLinkTo="/">{onTranslate('BUTTON.HOME')}</Button></li>
                    <li><Button type="link" onLinkTo='/my-events'>{onTranslate('BUTTON.MY_EVENT')}</Button></li>
                </ul>
            }
            <ul className="rightNav">
                <li><SwitchLanguage /></li>
                {
                    window.innerWidth > 768 ?
                        <>
                            <li><Button type="secondary" onLinkTo="/new-event">{onTranslate('BUTTON.CREATE_EVENT')}</Button></li>
                            <li>
                                <Dropdown menu={{ items }} trigger={['click']}>
                                    <Button type="link"><img className="userAvt" src={user?.avatar} /></Button>
                                </Dropdown>
                            </li>
                        </>
                        :
                        <li onClick={() => setIsOpen(prev => !prev)}>
                            <Dropdown menu={{ items }} trigger={['click']}>
                                <Button type="link">{isOpen ? < BiX /> : <BiMenu />} </Button>
                            </Dropdown>
                        </li>
                }
            </ul>
        </>
    )
}

export const AdminNavbar = () => {
    const { user } = useUser();
    return (
        <>
            <ul className='navItems'>
                {/* <li><Button type="link" onLinkTo='/my-events'>My Events</Button></li>
                <li><Button type="link" onLinkTo="/explore">Explore</Button></li> */}
                {/* <li><Button type="link">Help</Button></li> */}
            </ul>
            <ul className="rightNav">
                {/* <li><Button type="secondary" onLinkTo="/new-event">New Event</Button></li> */}
                {/* <li><Button type="link" badge="5"><FaRegBell /></Button></li> */}
                <li>
                    {/* <Dropdown menu={{ items }} trigger={['click']}>
                        <Button type="link"><img className="userAvt" src={user?.avatar} /></Button>
                    </Dropdown> */}
                </li>
            </ul>
        </>
    )
}
