import { message } from "antd";
import { ApiId } from "../../Shared/Enums/APIsEnum";
import { UserRole } from "../../Shared/Enums/UsersEnum";
import { handleSigninWithGoogle, onDecryptRole, onSignOut } from "../Auth/Authentication";
import { getLocationBySuggestion, getLocationDetails } from "./MapAPIs";
import { APIsAuthorize } from "../../Shared/Constants/AuthorizeCfg/APIsAuthorizeCfg";
import { onGetAllUsers, onGetUserByKeyword, onRefreshToken, onRegisterWithOTP, onSignInWithOTP, onValidateOTP } from "./UserApis";
import { getAllEvents, getCheckinList, getEventsDetails, onAddUserToEvent, onCancelMySponsorRequest, onCreateEvent, onCreateSponsorRequest, onDeleteEvent, onDeleteParticipant, onFeedback, onGetAllEventSponsorRequests, onGetFeedbacks, onGetMyEvents, onGetMyFeedbacks, onGetMySponsorRequest, onGetPopularLocations, onGetPopularOrganizers, onGetUserInfoInEvent, onProcessTicket, onRegisterEvent, onUpdateEvent, onUpdateFeedback, onUpdateSponsorRequestStatus } from "./EventsApis";
import { addNewTag, getAllTags, getTagByKeyword } from "./TagsAPIs";
import { getDataLocalStorage, setCookie, setDataLocalStorage } from "../Utils/StorageUtils";
import { localStorageId, tokenId } from "../../Shared/Enums/StorageEnum";
import { onCreatePaypalPayment, onPayout } from "./PaymentAPIs";
import { onGetMonthlyEvents, onGetMonthlyUsers, onGetTotalEvents, onGetTotalUsers } from "./AdminAPIs";
import { onTranslateOutsideHook } from "../Utils/LanguageUtils";
import { onLoadUserFromLocal } from "../Utils/UserUtils";

let refreshHistoryStack: number[] = [];

// Check user permissions
export const APIsAuthorizeUsers = (requestedApiId: number, data?: any) => {
    const user = onLoadUserFromLocal();

    const userRole: number = !user ? 1 : onDecryptRole(user.roleId);

    const allowedApisIdListGuest = APIsAuthorize[UserRole.GUEST] as number[];
    const allowedApisIdList = APIsAuthorize[userRole] as number[];

    // Guest & UserRole do not have that API ID -> Return undefined
    if (!allowedApisIdList.includes(requestedApiId) && !allowedApisIdListGuest.includes(requestedApiId)) {
        // message.error("You don't have permission to do this");
        return;
    }
    // If Guest or UserRole contain that API ID -> Authorized -> Moving on
    return APIsController(requestedApiId, data);
}

// Fetch the correct function and return data base on the API ID
const APIsController = (requestedApiId: number, data: any): any => {
    // Save last user API call
    const savedAction = {
        requestedApiId,
        data
    }

    setDataLocalStorage(localStorageId.LAST_ACTION, JSON.stringify(savedAction));

    switch (requestedApiId) {
        case ApiId.SIGNIN_WITH_GOOGLE:
            return handleSigninWithGoogle(data);
        case ApiId.SIGNIN_OTP:
            return onSignInWithOTP(data);
        case ApiId.REGISTER_OTP:
            return onRegisterWithOTP(data);
        case ApiId.VALIDATE_OTP:
            return onValidateOTP(data);
        case ApiId.SIGNOUT:
            return onSignOut(false);
        case ApiId.GET_USER_BY_KEYWORD:
            return onGetUserByKeyword(data);
        case ApiId.GET_PLACE:
            return getLocationBySuggestion(data);
        case ApiId.GET_PLACE_DETAILS:
            return getLocationDetails(data);
        case ApiId.GET_ALL_EVENTS:
            return getAllEvents(data);
        case ApiId.MY_EVENT:
            return onGetMyEvents();
        case ApiId.CREATE_EVENT:
            return onCreateEvent(data);
        case ApiId.UPDATE_EVENT:
            return onUpdateEvent(data);
        case ApiId.DELETE_EVENT:
            return onDeleteEvent(data);
        case ApiId.GET_EVENT_DETAILS:
            return getEventsDetails(data);
        case ApiId.GET_ALL_TAGS:
            return getAllTags();
        case ApiId.GET_TAGS_BY_KEYWORD:
            return getTagByKeyword(data);
        case ApiId.ADD_NEW_TAG:
            return addNewTag(data);
        case ApiId.REGISTER_EVENT:
            return onRegisterEvent(data);
        case ApiId.GET_USER_INFO_IN_EVENT:
            return onGetUserInfoInEvent(data);
        case ApiId.ADD_USER_TO_EVENT:
            return onAddUserToEvent(data);
        case ApiId.REMOVE_PARTICIPANT:
            return onDeleteParticipant(data);
        case ApiId.GET_FEEDBACKS:
            return onGetFeedbacks(data);
        case ApiId.SEND_FEEDBACK:
            return onFeedback(data);
        case ApiId.UPDATE_FEEDBACK:
            return onUpdateFeedback(data);
        case ApiId.GET_MY_FEEDBACK:
            return onGetMyFeedbacks(data);
        case ApiId.GET_POPULAR_ORGANIZERS:
            return onGetPopularOrganizers();
        case ApiId.GET_POPULAR_LOCATIONS:
            return onGetPopularLocations();
        case ApiId.REFRESH_TOKEN:
            return onRefreshToken();
        case ApiId.GET_CHECKIN_LIST:
            return getCheckinList(data);
        case ApiId.PROCESS_TICKET:
            return onProcessTicket(data);
        case ApiId.CREATE_SPONSOR_REQUEST:
            return onCreateSponsorRequest(data);
        case ApiId.GET_MY_SPONSOR_REQUEST:
            return onGetMySponsorRequest(data);
        case ApiId.CANCEL_MY_SPONSOR_REQUEST:
            return onCancelMySponsorRequest(data);
        case ApiId.GET_ALL_EVENT_SPONSOR_REQUESTS:
            return onGetAllEventSponsorRequests(data);
        case ApiId.UPDATE_SPONSOR_REQUEST_STATUS:
            return onUpdateSponsorRequestStatus(data);
        case ApiId.CREATE_PAYMENT_PAYPAL:
            return onCreatePaypalPayment(data);
        case ApiId.PAYMENT_PAYOUT:
            return onPayout(data);
        case ApiId.GET_TOTAL_EVENTS:
            return onGetTotalEvents();
        case ApiId.GET_MONTHLY_EVENTS:
            return onGetMonthlyEvents();
        case ApiId.GET_TOTAL_USERS:
            return onGetTotalUsers();
        case ApiId.GET_MONTHLY_USERS:
            return onGetMonthlyUsers();
        case ApiId.GET_ALL_USERS:
            return onGetAllUsers();
        default: return
    }
}

export const APIErrorController = async (err: any) => {
    if (err.code === "ERR_NETWORK") {
        message.error(onTranslateOutsideHook(null, 'MESSAGE.NO_INTERNET'));
        return;
    }

    console.log(err);
    switch (err.response?.status) {
        case 404:
            break;
        case 500:
            message.error(onTranslateOutsideHook(null, 'MESSAGE.INTERNAL_SERVER_ERROR'));
            break;
        case 401:
            const user = getDataLocalStorage(localStorageId.USER_DATA);
            if (!user) {
                // message.error('You need to login to do this', 5);
                return;
            }

            const refreshResult = await onRefreshToken();

            if (refreshResult) {
                const lastAction = JSON.parse(getDataLocalStorage(localStorageId.LAST_ACTION));

                // Prevent refresh spam
                const now = (new Date()).getTime();
                if (refreshHistoryStack.length >= 3 && (now - refreshHistoryStack[refreshHistoryStack.length - 1] > 1000 * 60)) {
                    refreshHistoryStack = [];
                }

                if (refreshHistoryStack.length >= 3 && (now - refreshHistoryStack[refreshHistoryStack.length - 1] <= 1000 * 60)) {
                    refreshHistoryStack = [];

                    // Remove cookies
                    setCookie(tokenId.REFRESH_TOKEN, null);
                    setCookie(tokenId.ACCESS_TOKEN, null);

                    alert(onTranslateOutsideHook(null, 'MESSAGE.SESSION_TIMEOUT'));

                    // Remove user from local storage
                    setDataLocalStorage(localStorageId.USER_DATA, null);

                    window.location.reload();
                    return;
                }

                refreshHistoryStack.push((new Date()).getTime());

                return APIsController(lastAction.requestedApiId, lastAction.data) as any;
            }

            const lastTimeout = getDataLocalStorage(localStorageId.TIMED_OUT);
            const diff = ((new Date()).getTime() - (new Date(lastTimeout)).getTime()) / 1000;
            if (diff <= 5) return;

            setDataLocalStorage(localStorageId.TIMED_OUT, (new Date()).getTime());

            // Remove user from local storage
            setDataLocalStorage(localStorageId.USER_DATA, null);
            setDataLocalStorage(localStorageId.LAST_ACTION, null);

            // message.error('Session Timed out', 5);
            alert(onTranslateOutsideHook(null, 'MESSAGE.SESSION_TIMEOUT'));
            window.location.reload();

            break;

        default:
            console.log(err);
            let data = err?.response?.data?.title ? err?.response?.data?.title : err?.response?.data?.message;
            if(data.includes('One or more validation errors occurred')) return;
            message.error(onTranslateOutsideHook(null, `MESSAGE.${data}`), 5);
    }
}