/** @format */

export const en = {
  BUTTON: {
    VIEW_MORE: "View more",
    EXPLORE: "Explore",
    CREATE_EVENT: "Create Event",
    HOME: "Home",
    SIGNIN: "Sign in",
    LOGOUT: "Logout",
    PROFILE: "Profile",
    TRANSACTIONS: "Transactions",
    CLOSE_SIGNIN_FORM: "Close",
    MY_EVENT: "My Events",
    DO_NOT_HAVE_ACCOUNT: "Do not have account? Register",
    HAVE_ACCOUNT: "Already using DaSkillz? Sign in",
    SIGN_UP: "Sign up",
    SIGNIN_GOOGLE: "Sign in with Google",
    SIGNUP_GOOGLE: "Sign up with Google",
    CANCEL: "Cancel",
    SAVE: "Save",
    SET_LIMIT: "Set limit",
    REMOVE_LIMIT: "Remove limit",
    SET_PRICE: "Set Price",
    REMOVE_PRICE: "Remove Price",
    CROP: "Crop",
    FEEDBACK: "Feedback",
    UPDATE_FEEDBACK: "Update my Feedback",
    CHECKIN: "Check In",
    CHECKEDIN: "Checked In",
    DEPOSIT: "Deposit",
    CANCEL_REQUEST: "Cancel this Request",
    CREATE: 'Create',
    SUPPORT_ORGANIZER_SPONSOR: "Support the organizer by sponsor this event, click here",
    BY_EVENT_ORG: "by event's organizer",
    MANAGE: "Manage",
    CONTACT_ORG: "Contact event's organizer",
    UPDATE_EVENT: "Update event",
    VIEW_LESS: "View less",
    REFUND: "Refund",
    ADD: "Add",
    VIEW_REQUEST: "View request",
    SPONSOR_REQUEST: "Sponsor Request",
    REQUESTED_TIME: "Requested time",
    DELETE_EVENT: "Delete this Event",
    VIEW: "View",
    ALREADY_HAVE_PROCESSING_TICKET_ORDER: "You already have a processing ticket order for this event, please complete the order.",
    PAYMENT: "Complete order",
    ACCEPT: "Accept",
    REJECT: "Reject",
    DELETE: 'Delete',
    UPGRADE: "Upgrade",
    ADD_CLOSE_DATE: "Add close date",
    MANAGE_QUESTIONS: "Manage Questions",
    BACK: "Back",
    DISCARD: "Discard",
    ADD_ANSWER: "Add answer",
    UPGRADE_ACCOUNT: "Upgrade account",
    VIEW_QUIZ: "View Quiz",
    ATTEMPT: "Attempt",
    CERTIFIED_SELECTED_PARTICIPANTS: "Certified selected participants",
    VIEW_CERTIFICATE: "View Certificate",
    SUBMIT: "Submit",
    START_QUIZ: "Start Quiz",
  },

  LABEL: {
    LANDING_TITLE: "Feel at ease when joining an event,\n Da Skillz is here!",
    LANDING_SUBTITLE:
      "Acting as a bridge between event organizers and attendees, Da Skillz confidently provides a diverse range of events and offers a seamless experience from interest-based search to convenient payment.",
    POPULAR_EVENTS: "YOUR JOURNEY BEGINS HERE",
    POPULAR_LOCATIONS: "POPULAR LOCATIONS",
    POPULAR_ORGANIZERS: "POPULAR ORGANIZERS",
    HOSTED_NUM_EVENTS: "Hosted %s events",
    NO_EVENT_FOUND: "No Event Found",
    NO_OGRANIZER_FOUND: "No Organizer Found",
    NO_LOCATION_FOUND: "No Location Found",
    EVENTS: "Events",
    LOGIN_HEADER: "Welcome to DaSkillz",
    LOGIN_SUB: "Host a memorable event and explore new discoveries",
    EMAIL: "Email",
    NAME: "Name",
    PHONE: "Phone",
    ALL: "All",
    SEARCH_EVENT: "Explore Event",
    SEARCH_EVENT_SUB: "Find your most suitable event",
    EVENT_NAME: "Event's Name",
    LOCATION: "Location",
    TAG: "Tag",
    FOUND_EVENTS: "Found %s events",
    NO_PHONE: "No phone number",
    EVENT_HOSTED: "Event Hosted",
    USER_NOT_FOUND: "USER NOT FOUND",
    COLOR_THEME_PICKER: "PICK YOUR FAVOURITE THEME COLOR",
    CUSTOM_COLOR: "Custom Color",
    ABOUT: "About",
    POLICY: "Policy",
    PRICING: "Pricing",
    HELP: "Help",
    COPYRIGHT: "Copyright 2024 by DaSkillz Group.",
    NO_UPCOMMING_EVENT: "You have no upcomming event",
    SENT_OTP: "We have sent OTP to %s",
    RESEND: "Resend",
    RESEND_IN: "in %ss",
    OTP: "OTP",
    ABORTED: "Aborted",
    COMING_SOON: "Coming Soon",
    ON_GOING: "On Going",
    DELETED: "Deleted",
    ENDED: "Ended",
    CANCEL: "Cancelled",
    VIRTUAL_LOCATION: "Virtual Location",
    EVENT_SIZE: "Event's Size",
    TAGS: "Tags",
    TICKET: "Ticket",
    THEME_COLOR: "Theme Color",
    START: "Start",
    END: "End",
    LOCATION_INSTRUCTION: "Realworld location or virtual location",
    LOCATION_INSTRUCTION_2: "Enter address or virtual link",
    CURRENT_LOCATION: "Current Location",
    USE: "Use",
    SUGGESTED_LOCATION: "Suggested Location",
    NO_SUGGESTION: "No suggestion",
    DESCRIPTION: "Description",
    EVENT_OPTIONS: "Event's Options",
    APPROVAL: "Approval",
    UNLIMITED: "Unlimited",
    FREE: "Free",
    SIZE_HINT_1: "Event will automatically reject when reached the limit.",
    SIZE_HINT_2:
      "When Approval is ON, only participants that have been approved count in the limit.",
    LIMIT: "Limit",
    FIND_TAG: "Find your tag",
    SELECTED: "Selected",
    UNSELECT_TAG_ACTION: "Click on any tag to unselect",
    SELECT_TAG_ACTION: "Click on any tag to select (Maximum %s)",
    SUGGESTION: "Suggestions",
    TICKET_PRICE: "Ticket Price (VND)",
    PAST: "Past",
    FUTURE: "Comming",
    CHECKEDIN_CONFIRMATION: "Checked In Confirmation",
    CHECKING_IN_FOR: "You are Checking in for %s with Email %z",
    DEPOSIT_MONEY: "Deposit Money",
    UNIT: "Unit: %s",
    PROCESSING: 'Processing',
    CONFIRMED: "Confirmed",
    REJECTED: "Rejected",
    YOUR_SPONSOR_REQUEST: "Your sponsor request",
    CONTACT_ORGANIZER: "Click to contact the event's organizer", 
    SUBMITTED_TIME: "Submitted Time",
    SPONSOR_TYPE: "Sponsor Type",
    AMOUNT: 'Amount',
    NOTE: 'Note',
    SPONSOR_REQUEST_WARNING: 'You can only cancel when this request in processing status',
    ORGANIZER_ANSWERED: 'The organizer have %s at %z',
    SPONSOR: "Sponsor",
    REGISTER: "Register",
    THIS_EVENT_HAS_BEEN: "This event has been",
    BECAUSE_OF_VIOLATION: "because of violation",
    YOU_HAVE: "You have been",
    CLICK_FEEDBACK: "click Feedback to feedback this event",
    THANKS_FOR_VIEWING: "Thanks for viewing, but",
    CLICK_TO_DEPOSIT_MONEY: "to deposit money to the organizer",
    CLICK_HERE: "Click here",
    TO_VIEW_SPONSOR_REQUEST: "to view your sponsor request",
    PENDING: "Pending",
    WELCOME: "Welcome",
    APART_OF_EVENT: 'You are now a part of this event, please click on "Checkin QR" to',
    VIEW_CHECKIN_CODE: "View your Checkin Code",
    ORGANIZER_REJECTED_REGISTRATION: "Organizer have rejected your registration",
    CONTACT_ORG_MISTAKE: "Please contact the organizer if this is a mistake",
    YOU_NEED_TO: "You need to",
    TO_REGSISTER: "to register this event",
    REGISTER_INS: "Welcome! To participate in the event, please register below",
    YOUR_CHECKIN_QR: "Your Checkin QR",
    CHECKIN_QR_INS: "Show this to Event's Organizer or Staffs to checkin",
    REGISTERED_AT: "Registered at",
    CAN_MANAGE_EVENT: "You can manage this event",
    HOSTED_BY: "Hosted by",
    FEATURED: "Featured in",
    TO_START_CHECKIN: "to start checkin Participants",
    EVENT_PAGE: "Event's Page",
    VIEW_ALL_FEEDBACKS: "View all of your participant's feedbacks",
    HOST: "Host",
    STAFF: "Staff",
    ADD_COMMITTEE: "Add new Committee",
    CLICK_TO_ADD: "Click to add",
    AS_A_STAFF: "As a Staff",
    PENDING_REQUEST: "Pending Requests",
    PENDING_REQUEST_DESCRIPTION: "People who registered your event and waiting for your approval",
    ORGANIZING_COMMITTEE: "Organizing Committee",
    ORGANIZING_COMMITTEE_DESCRIPTION: "People who have access to your events",
    SPONSOR_DESCRIPTION: "People who have sponsored your event",
    PARTICIPANTS: "Participants",
    PARTICIPANTS_DESCRIPTION: "People who have participated your event",
    NOT_CHECKIN: "Not checkin",
    PAYPAL_EMAIL: "Paypal Email",
    AMOUNT_UNIT: "Amount (%s)",
    CAN_NOT_BE_UNDO_WARNING: "This action can not be undo",
    TRANSACTION_ID: "Transaction ID",
    TIME: "Time",
    MESSAGE: "Message",
    STATUS: "Status",
    EVENT_TRANSACTIONS: "Event's Transactions",
    EVENT_TRANSACTIONS_DESCRIPTION: "All of your event's transactions, included sponsor and purchased tickets",
    ACTION: "Action",
    REQUEST: "Request",
    ROLE: "Role",
    EVENT_OVERVIEW: "Event's Overview",
    EVENT_OVERVIEW_DESCRIPTION: "This is your event's summary",
    EVENT_SUMMARY: "Event's Summary",
    NEED_TO_BE_APPROVE: "Need to be approve",
    DO_NOT_NEED_TO_BE_APPROVE: "Do not need to be approve",
    SPONSOR_REQUEST_DESCRIPTION: "People who want to sponsor your event",
    USERS_STATISTIC: "Users Statistics",
    USERS_STATISTIC_DESCRIPTION: "Statistic of users in your event",
    OTHERS: "Others",
    VISIBLE: "Visible",
    VISIBLE_WARNING: "Event start within %s hours will only change status to cancel",
    ACTION_CANNOT_UNDO: "This action will be permanent and can not undo",
    DELETE_CONFIRMATION: "Delete confirmation",
    DELETE_CAUTION: "After delete, you can not view this event anymore",
    CANCEL_CAUTION: "Your event will start within 6 hours so this action only switch your event to 'Cancel' status",
    TYPE: "Type",
    TO_CONFIRM: "to confirm this action",
    PAYMENTID: "Payment ID",
    CREATED_TIME: "Created Time",
    PAID_TIME: "Paid Time",
    EVENT: "Event",
    MY_TRANSACTION: "My Transactions",
    MY_TRANSACTION_DESCRIPTION: "All of your transactions history",
    PAYMENT_SUCCESS_STATUS: "Success",
    PAYMENT_FAILED_STATUS: "Failed",
    PAYMENT_PROCESSING_STATUS: "Waiting to complete",
    SPONSOR_CANCEL_CONFIRM: "Do you sure want to cancel this request?",
    ABOUT_VISION: "VISION",
    ABOUT_VISION_DESCRIPTION: "Forge connections between individuals and reputable organizations and communities, aiming to foster personal growth and expand individual experiences.",
    ABOUT_DESTINY: "MISSION",
    ABOUT_DESTINY_DESCRIPTION: "To empower individuals by providing them with the tools, resources, and supportive environment they need to develop their skills, build meaningful connections with the community, and make a positive impact on their lives.",
    ABOUT_VALUE: "CORE VALUES",
    ABOUT_VALUE_1_TITLE: "Creativity",
    ABOUT_VALUE_1_DESCRIPTION: " Promote creativity and innovation through participation in activities and practices.",
    ABOUT_VALUE_2_TITLE: "Quality",
    ABOUT_VALUE_2_DESCRIPTION: "Ensure high quality in the provision of entertainment and educational events and activities.",
    ABOUT_VALUE_3_TITLE: "Experience",
    ABOUT_VALUE_3_DESCRIPTION: "Connect events with experiences that enhance diverse knowledge and skills, tailored to the needs of every user.",
    ABOUT_VALUE_4_TITLE: "Learning",
    ABOUT_VALUE_4_DESCRIPTION: "Facilitates continuous learning and the development of new skills.",
    ABOUT_VALUE_5_TITLE: "Connection",
    ABOUT_VALUE_5_DESCRIPTION: "Connecting individuals and organizations with common passion and creativity. Build a community where creative professionals can share their events and goals.",
    POLICY_DESCRIPTION: "We are committed to providing customers with a safe and convenient payment experience when purchasing event tickets on our website. Below are specific terms related to the payment process",
    POLICY_1_TITLE: "Payment Methods",
    POLICY_1_DESCRIPTION: "We accept the following payment methods:\n-Credit/debit cards (Visa, MasterCard)\n-wallet payments (MoMo, ZaloPay)\n-Bank transfer",
    POLICY_2_TITLE: "QPayment Process",
    POLICY_2_DESCRIPTION: "After selecting tickets and confirming your order, you will be redirected to a secure payment page. Please enter your payment information and confirm to complete the transaction. The system will send a payment confirmation email and ticket after the transaction is successful.",
    POLICY_3_TITLE: "Refund Policy",
    POLICY_3_DESCRIPTION: "- Once a transaction is complete, tickets cannot be refunded, except in cases where the event is canceled or rescheduled.\n- If the event is canceled, the ticket price will be refunded to the original payment method within 7-10 business days.",
    POLICY_4_TITLE: "Payment Information Security",
    POLICY_4_DESCRIPTION: "We use advanced encryption methods to protect your payment information. Card data will not be stored on our system after the transaction is completed.",
    POLICY_5_TITLE: "Contact Support",
    POLICY_5_DESCRIPTION: "If you encounter any problems during the payment process, please contact our customer support department via email or hotline for prompt assistance.",
    TICKET_PAYMENT: "Ticket Payment",
    TICKET_PAYMENT_DESCRIPTION: "You have an incomplete ticket order. Please complete it to participate this event.",
    PAYMENT_WAIT: "You may refresh when complete, it may take a few minutes to update the order status.",
    SPONSOR_REQUEST: "Sponsor Request",
    HAVE_NOT_COMPLETE_SPONSOR: "You have an proccessing sponsor request with value %s, please complete it before create a new one.",
    CLICK_TO_VIEW_SPONSOR_HISTORY: "View sponsor history",
    BACK_TO_SPONSOR_PAGE: "Back to sponsor page",
    THIS_EVENT: "This event",
    QA_QUESTION_1: 'How do I create an event on the website?',
    QA_ANSWER_1: 'Step-by-step guide to registering an account, creating an event, and setting up details.',
    QA_QUESTION_2: 'What features does the website support for event organizers?',
    QA_ANSWER_2: 'Features like ticket management, payment processing, email notifications, and attendee list management.',
    QA_QUESTION_3: 'How do I track event attendance?',
    QA_ANSWER_3: 'Tools to track registrations, ticket sales, and real-time statistics.',
    QA_QUESTION_4: 'Can I customize the look and feel of the event page?',
    QA_ANSWER_4: 'The ability to customize interface elements, logos, images, and colors to match the event.',
    QA_QUESTION_5: 'Does the system integrate with marketing tools or CRM?',
    QA_ANSWER_5: 'Information about integration with email marketing platforms, Google Analytics, or CRM.',
    QA_QUESTION_6: 'How do I manage and send email notifications to attendees?',
    QA_ANSWER_6: 'Guide to using automated or custom email systems.',
    QA_QUESTION_7: 'How much does it cost to use the event website?',
    QA_ANSWER_7: 'Information about service packages, commission fees, and costs for using special features.',
    QA_QUESTION_8: 'Can discount codes or promotions be created?',
    QA_ANSWER_8: 'Features that support promotions, discount codes, or incentive packages for participants.',
    QA_QUESTION_9: 'Is the website multilingual?',
    QA_ANSWER_9: 'Ability to create events in multiple languages ​​and support translation for information pages.',
    QA_QUESTION_10: 'How do I register for an event?',
    QA_ANSWER_10: 'Instructions on how to register online, fill out information, and make payments (if applicable).',
    QA_QUESTION_11: 'What payment methods are available?',
    QA_ANSWER_11: 'Payment options: credit card, bank transfer, e-wallet, or on-site payment.',
    QA_QUESTION_12: 'After registering, how do I know if my registration is successful?',
    QA_ANSWER_12: 'Information about confirmation email, SMS, or personal account on the website.',
    QA_QUESTION_13: 'If I cannot attend the event, can I cancel or transfer my ticket?',
    QA_ANSWER_13: 'Cancellation, refund, or transfer policy.',
    QA_QUESTION_14: 'What do I need to bring to the event?',
    QA_ANSWER_14: 'Information about tickets, identification, or other required documents.',
    QA_QUESTION_15: 'How can I find out the event schedule details?',
    QA_ANSWER_15: 'Instructions for viewing the event schedule directly on the website or via email notification.',
    QA_QUESTION_16: 'Will there be any language or interpretation support at the event?',
    QA_ANSWER_16: 'Languages: English and Vietnamese.', 
    ACCOUNT_PACKAGE: "Account Packages",
    FREE_PACKAGE: "Member Package",
    PREMIUM_PACKAGE: "Business Package",
    FREE_PACKAGE_DESCRIPTION: "Create maximum 2 events per month|5% Fee on total tickets sold|Unlimited participants|Check-in with built in QR and QR Scanner|View basic event’s reports|Selling Ticket|Sponsor manage|Multiple payment method|Support refund|Support personal feedback QR|Notifications through Email",
    PREMIUM_PACKAGE_DESCRIPTION: "Create maximum 15 events per month|0% Fee for all services|Advance view of Event’s statistic|Custom Quiz for event|Custom Certificate for participants completion|Custom event theme|Report downloadable in CSV format",
    MONTHLY: "Monthly",
    FOREVER: "Forever",
    FREE_DESCRIBE: "Free packages features:",
    PREMIUM_DESCRIBE: "All free features and:",
    ACTIVE: "IN USE",
    TRANSACTION_NO_MORE_FEE: "All transactions will not arise any fee",
    ACCOUNT_PACKAGE_SUB: "Packages are designed to increase event's quality for host base on the scale",
    EVENT_ADVERTISEMENT: "Event advertisement",
    EVENT_ADVERTISEMENT_SUB: "In the time of advertising, your event will be appear on TOP of our home page and on TOP search",
    DAYS: "days",
    EVENT_ADVERTISEMENT_SUB2: "To advertise event, please go to Event's Manage page, select on 'Advertise' tab",
    QUIZ: "Quiz",
    QUIZ_DESCRIPTION: "These are all quizzes for your participants can take attempt",
    CREATE_NEW_QUIZ: "Create new quiz",
    QUIZNAME: "Quiz name",
    QUIZDESCRIPTION: "Quiz description",
    TOTALTIME: "Total time",
    ATTEMPTALLOW: "Number of attemp per participant",
    PICK_TIME_UNIT: "Unit: minutes",
    DUEDATE: "Quiz close time",
    DRAFT: "Draft",
    CLOSED: "Closed",
    ACTIVE_QUIZ: "Active",
    UPDATE: "Update",
    ATTEMP_ALLOWED: "%s Attemps",
    TOTAL_TIME: "%s Minutes",
    OPEN_DUE_TO: 'Open due to %s',
    NO_DEADLINE: "No deadline",
    LAST_CHANGE_NOT_SAVE: "Your changed are not save yet",
    NO_QUESTION_CHANGE: "You have not change any question yet",
    QUESTION_DISPLAY_COUNT: "%s questions (%z not save)",
    MULTIPLE_CHOICES: "Multiple choices",
    PARAGRAPH: "Paragraph",
    ANSWER: "Answer",
    QUESTION: "Question",
    GO_TO_PAYMENT: "Go to payment",
    QUIZ_DESCRIPTION_USER: "Event currently have %s quiz opening, you can take attempt by click on the button below",
    CERTIFICATE: "Certificate",
    CERTIFICATE_PREVIEW: "Certificate Preview",
    CERTIFICATE_PREVIEW_DESCRIPTION: "Preview certificate by your name",
    CERTIFICATE_MANAGE: "Certificate Manage",
    CERTIFICATE_MANAGE_DESCRIPTION: "You can certified by select participants and click on 'Certified selected participants' button",
    CERTIFICATE_RECEIVED: "The event's organizer have certified you at %s",
    YOUR_RESPONSE: "Your response",
    VIEW_ALL_RESPONSES: "View all of your responses for this quiz",
    ADVERTISE: "Advertise",
    ADVERTISE_DESCRIPTION: "Advertise your event to get more attention",
    ADVERTISE_HISTORY: "Advertised History",
    ADVERTISE_HISTORY_DESCRIPTION: "Your event advertise history",
    CREATED_DATE: "Created Date",
    START_DATE: "Start Date",
    END_DATE: "End Date",
    PRICE: "Price",
    TRENDING: "Trending",
    EXPIRED: "Expired",
    PARTICIPANTS_CHART: "Particpants change chart",
    PARTICIPANTS_CHART_DESCRIPTION: "View how many participants participate your event every day",
    MONEY_VND: "Income (VND)",
    TRANSACTIONS_INCOME: "Income Chart",
    TRANSACTIONS_INCOME_DESCRIPTION: "View how much money you have earned from tickets",
  },

  MESSAGE: {
    INVALID_EMAIL: "Invalid email",
    LOGGING_IN: "It may take a few seconds to sign in...",
    VERIFYING_OTP: "It may take a few seconds to verify OTP...",
    WELCOME_BACK: "Welcome back %s",
    SUSPENDED_ACCOUNT:
      "Your account has been suspended, please contact the administrator for more information",
    LOGIN_FAILED: "Failed to login",
    NAME_VALIDATION: "Name must be greater than %s characters",
    INVALID_PHONE: "Invalid phone number",
    REGISTERING: "It may take a moment to register your account",
    WELCOME_TO: "Welcome to DaSkillz, %s",
    RESEND_OTP: "Resending OTP",
    PROFILE_NO_CHANGE: "Profile have no change",
    UPDATED_PROFILE_SUCCESS: "Updated Profile successfully",
    START_TIME_VALIDATION: "Start time must be at least %s hours from now",
    END_TIME_VALIDATION:
      "End time must be in the future and greater than start time atleast %s minutes",
    PREVENT_TURN_ON_APPROVAL: "Event selling ticket can not turn on Approval",
    NEW_EVENT_NAME_ERR: "Name must be between %s and %z characters",
    NEW_EVENT_DESCRIPTION_ERR:
      "Description must be between %s and %z characters",
    NEW_EVENT_LOCATION_ERR: "Location is required",
    NEW_EVENT_IMAGE_ERR: "You must upload an event's image",
    NEW_EVENT_START_TIME_ATLEAST:
      "Start time must be at least %s hours from now'",
    CREATING_EVENT: "Creating event...",
    CREATE_EVENT_FAIL: "Failed to create new event",
    CREATE_EVENT_SUCCESS: "Created new event successfully",
    CREATE_EVENT_NEED_TO_SIGNIN: "You need to sign in before create an event",
    TAG_REACHED_LIMIT_SELECT_ERR:
      "You can only select maximum %s tags, unselect a tag to select a new one",
    TAG_REACHED_LIMIT_ADD_ERR:
      "You can only select maximum %s tags, unselect a tag to add new one",
    MAXIMUM_TAG_CHARACTERS_REACHED_ERR: "Maximum characters for tag is %s",
    FAILED_ADD_TAG: "Failed to add new tag",
    TICKET_PRICE_ERR: "Price of a ticket must greater than $s VND",
    FEEDBACK_FAILED: "Failed to Feedback",
    INVALID_CHECKIN_QR: "Invalid Checkin QR",
    STOP_SCAN_TO_CLOSE: "Please stop scanning before close the modal",
    CHECKEDIN_FAIL: "Failed to checkin",
    CHECKEDIN_SUCCESS: "Checked in successfully",
    SUBMIT_SPONSOR_REQ_SUCCESS: "Created sponsor request successfully",
    SUBMIT_SPONSOR_REQ_FAIL: "Failed to submit sponsor request",
    FAILED_TO_CANCEL: "Failed to cancel",
    CANCEL_SPOSOR_REQUEST_SUCCESS: "Cancelled your sponsor request",
    REGISTERED_SUCCESSFULLY: "You have registered successfully",
    WAIT_FOR_APPROVE: "please wait for the Organizer to approve",
    PREPARING_EVENT: "Preparing event...",
    EVENT_NOT_FOUND: "Event not found",
    CAN_NOT_EDIT_EVENT: "You can not edit this event anymore",
    START_TIME_FROM_CREATED_TIME: "Start time must be at least %s hours from created time",
    UPDATING_EVENT: "Updating event...",
    UPDATE_EVENT_FAIL: "Update event failed",
    UPDATE_EVENT_SUCCESS: "Updated event successfully",
    ADDED_SUCCESS: "Added successfully",
    FAILED_UPDATE_PARTIC_STATUS: "Failed to update participant status",
    UPDATING_PARTIC_STATUS: "Updating participant's status...",
    DELETE_SUCCESS: "Deleted successfully",
    ASK_REMOVE_STAFF: "Do you want to remove this staff?",
    UPDATED_STATUS_SUCCESS: "Updated status successfully",
    ORGANIZER_UPDATE_REQUEST: "Event's organizer have %s this requedt at %z",
    ACTION_PERFORMED_SUCCESS: "Action performed successfully",  
    PAYMENT_FAIL: "Failed to create new payment",
    PAYMENT_SUCCESS: "Create new payment successfully",
    NO_INTERNET: "No internet connection",
    INTERNAL_SERVER_ERROR: "Internal Server Error",
    SESSION_TIMEOUT: "Session Timedout",
    DELETE_EVENT_FAIL: "Failed to delete event",
    DESCRIPTION_VALIDATION: "Description must greater than %s characters",
    FAILED_TO_CREATE_QUIZ: "Failed to create quiz",
    SUCCESS_TO_CREATE_QUIZ: "Created quiz successfully",
    FAILED_TO_UPDATE_QUIZ: "Failed to update quiz",
    SUCCESS_TO_UPDATE_QUIZ: "Updated quiz successfully",
    CONFIRM_DELETE_QUIZ: "Do you want to delete this quiz?",
    DELETE_QUIZ_FAILED: "Failed to delete quiz",
    DELETE_QUIZ_SUCCESS: "Deleted quiz successfully",
    SOME_MULTIPLE_CHOICE_ERR: "Some multiple choices question do not have any answer",
    QUESTION_LOW_CHARS: "Some question have less than %s characters",
    SAVE_QUESTION_SUCCESS: "Save quiz successfully",
    SAVE_QUESTION_FAIL: "Failed to save quiz",
    ASK_DISCARD: "Do you sure want to discard?",
    ASK_DELETE: "Do you want to delete?",
    LOGIN_REQUIRED: "You need to login to perform this action",
    MULTIPLE_CHOICE_NO_CORRECT_ANS: "Some multiple choices question have no correct answer",
    SUBMIT_SUCCESS: "Submitted successfully",
    NOT_FINISH_QUESTION: "You have not finish question %s",
  },
  DAY: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
};
