import React from 'react'

type Props = {
    className?: string;
    height?: number;
    width?: number;
}

const WebCrystalIcon = ({className, height = 67, width = 66}: Props) => {
    return (
        <div className={className}>
            <svg width={width} height={height} viewBox={`0 0 ${height} ${width}`} fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_i_424_264)">
                    <path d="M30.5802 2.37365C31.3073 -0.707695 35.6927 -0.707695 36.4198 2.37365L41.2836 22.9856C41.5446 24.0917 42.4083 24.9554 43.5144 25.2164L64.1263 30.0802C67.2077 30.8073 67.2077 35.1927 64.1263 35.9198L43.5144 40.7836C42.4083 41.0446 41.5446 41.9083 41.2836 43.0144L36.4198 63.6263C35.6927 66.7077 31.3073 66.7077 30.5802 63.6263L25.7164 43.0144C25.4554 41.9083 24.5917 41.0446 23.4856 40.7836L2.87365 35.9198C-0.207695 35.1927 -0.207695 30.8073 2.87365 30.0802L23.4856 25.2164C24.5917 24.9554 25.4554 24.0917 25.7164 22.9856L30.5802 2.37365Z" fill="url(#paint0_linear_424_264)" />
                </g>
                <path d="M31.5535 2.60332C32.0382 0.549084 34.9618 0.549084 35.4465 2.60332L40.3103 23.2152C40.6584 24.6901 41.8099 25.8416 43.2848 26.1897L63.8967 31.0535C65.9509 31.5382 65.9509 34.4618 63.8967 34.9465L43.2848 39.8103C41.8099 40.1584 40.6584 41.3099 40.3103 42.7848L35.4465 63.3967C34.9618 65.4509 32.0382 65.4509 31.5535 63.3967L26.6897 42.7848C26.3416 41.3099 25.1901 40.1584 23.7152 39.8103L3.10332 34.9465C1.04908 34.4618 1.04908 31.5382 3.10332 31.0535L23.7152 26.1897C25.1901 25.8416 26.3416 24.6901 26.6897 23.2152L31.5535 2.60332Z" stroke="url(#paint1_diamond_424_264)" strokeWidth="2" />
                <defs>
                    <filter id="filter0_i_424_264" x="0.5625" y="0.0627441" width="71.275" height="68.8745" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="15" dy="3" />
                        <feGaussianBlur stdDeviation="2.7" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_424_264" />
                    </filter>
                    <linearGradient id="paint0_linear_424_264" x1="33.5" y1="-10" x2="33.5" y2="76" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#E6E8FF" />
                        <stop offset="0.5" stopColor="#837AFF" />
                        <stop offset="1" stopColor="#4F4999" />
                    </linearGradient>
                    <radialGradient id="paint1_diamond_424_264" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(33.5 33) rotate(90) scale(43)">
                        <stop stopColor="#FDFCDC" />
                        <stop offset="0.605" stopColor="#797979" />
                        <stop offset="0.87" stopColor="white" />
                    </radialGradient>
                </defs>
            </svg>

        </div>
    )
}

export default WebCrystalIcon