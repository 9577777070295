import { FaCamera, FaImage } from "react-icons/fa6";
import { useRef, useState } from "react";
import CropImage from "./CropImage";
import { Modal } from "antd";
import { handleImageValidation, readDataAsUrl } from "../../../Services/Utils/ImageUtils";
import Button from "../../../Components/Button/Button";

type Props = {
    imgSrc: string;
    setImgSrc: (imgSrc: string) => void;
}

const UploadImage = ({ imgSrc, setImgSrc }: Props) => {
    const uploadImageZone = useRef(null);
    const inputRef = useRef<HTMLInputElement>(null);

    // Control state
    const [isDraggingOver, setIsDraggingOver] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isShowCroppingModal, setIsShowCroppingModal] = useState<boolean>(false);

    const [originalUploadImage, setOriginalUploadedImage] = useState<string>(imgSrc);

    const handleDragOver = (e: any) => {
        setIsDraggingOver(true);
        e.preventDefault();
    }

    const handleDragLeave = (e: any) => {
        setIsDraggingOver(false);
    }

    const handleDrop = async (e: any) => {
        setIsDraggingOver(false);
        // setUpdatedSizeAlready(false);
        e.preventDefault();

        const currFiles = e.dataTransfer.files;

        if (currFiles.length === 0) return;

        // setCroppedFile(null);
        // setImgRatioErr(true);
        handleUploadImage(currFiles);
    }

    const handleInputChange = async (e: any) => {
        const currFiles = e.target.files;

        if (currFiles.length === 0) return;

        handleUploadImage(currFiles);
    }

    const handleUploadImage = async (currFiles: any) => {
        setIsUploading(true);

        const resultFile = await handleImageValidation(currFiles);

        if (resultFile) {
            const dataUrlFile = await readDataAsUrl(resultFile) as string;

            if (dataUrlFile) {
                setOriginalUploadedImage(dataUrlFile);
            }

            setIsShowCroppingModal(true);
        }

        setIsUploading(false);
    }

    const onCancel = () => {
        setIsShowCroppingModal(false)
    }

    const onFinishCropImage = (fileDataUrl: string) => {
        setImgSrc(fileDataUrl);
        setIsShowCroppingModal(false);
    }

    return (
        <div className={`imgWrapper`}
            ref={uploadImageZone}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            style={isDraggingOver ? { opacity: 0.8 } : {}}
        >
            <img src={imgSrc} className="userAvt" />

            {
                isUploading
                    ?
                    <div className="loading addImgBtn"></div>
                    :
                    <FaCamera className="changeAvatarIcon" onClick={() => inputRef.current?.click()} />
            }

            <Modal title="IMAGE CROP" open={isShowCroppingModal} footer={null} closable={false}>
                <CropImage originalUploadImage={originalUploadImage} onFinishCropImage={onFinishCropImage} onCancel={onCancel} />
            </Modal>

            <input type="file" onChange={(e) => handleInputChange(e)} ref={inputRef} hidden />
        </div>
    )
}

export default UploadImage