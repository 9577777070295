import { message } from "antd";
import heic2any from 'heic2any';


const handleImageValidation = async (currFiles: any) => {
    // Check amount of image user upload (allow 1)
    if (currFiles.length > 1) return;

    const uploadFile = currFiles[0];

    // const data = await readDataAsUrl(uploadFile);
    // console.log(data)
    if (!uploadFile || !uploadFile.type) return;

    let finalFile = null;

    if (uploadFile.type.split("/")[0] !== "image") {
        message.error('You can only upload image file');
    } else {
        const type = uploadFile.type.split("/")[1].toUpperCase()
        if (type === "HEIC" || type === "HEIF") {
            const convertedFile = await convertHeicFile(uploadFile);
            finalFile = convertedFile;
        } else {
            finalFile = uploadFile;
        }

        // Check size
        // if (finalFile.size > 5000 * 1000) {
        //     message.error('File ảnh quá lớn, vui lòng chọn ảnh khác hoặc cắt ảnh nhỏ lại');
        //     finalFile = null;
        // }
    }

    return finalFile;
}

const convertHeicFile = async (file: any) => {
    let blobURL = URL.createObjectURL(file);

    // convert "fetch" the new blob url
    let blobRes = await fetch(blobURL)

    // convert response to blob
    let blob = await blobRes.blob()

    // convert to PNG - response is blob
    let conversionResult = await heic2any({
        blob,
        toType: "image/jpeg",
    });

    return conversionResult;
}

const readDataAsUrl = async (file: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    const resultUrl = new Promise(resolve => {
        reader.onload = () => {
            resolve(reader.result);
        }
    });

    return resultUrl;
}

const convertDataUrlToFile = (dataUrl: string, filename: string) => {
    var arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/);
    if (arr && mime) {
        var mimeSingle = mime[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mimeSingle });
    }

}

const onCropImage = (imgDataUrl: string, cropX: number, cropY: number, cropWidth: number, cropHeight: number, canvasRef: any) => {
    // Create a canvas to draw the cropped image
    const ctx = canvasRef.getContext('2d') as CanvasRenderingContext2D;

    const img = new Image();
    img.src = imgDataUrl

    // Set canvas size to the dimensions of the cropped area
    canvasRef.width = cropWidth;
    canvasRef.height = cropHeight;

    // Draw the image on the canvas
    ctx.drawImage(img, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

    // Get the cropped image as a data URL
    const croppedDataURL = canvasRef.toDataURL('image/png');

    return croppedDataURL;
}

export { onCropImage, handleImageValidation, readDataAsUrl, convertDataUrlToFile }
