import { Col, Row, message } from 'antd'
import React, { useState } from 'react'
import { MdOutlineMailOutline, MdPersonalInjury } from 'react-icons/md'
import Dropdown from '../../../Components/Input/Dropdown'
import Password from '../../../Components/Input/Password'
import { FaAngleLeft, FaGoogle, FaPagelines, FaTransgender } from 'react-icons/fa6'
import { RiLockPasswordFill } from 'react-icons/ri'
import Button from '../../../Components/Button/Button'
import { AccountStatus, UserGender } from '../../../Shared/Enums/UsersEnum'
import { MenuDropDown } from '../../../Shared/Model/InputInterface'
import TextInput from '../../../Components/Input/TextInput'
import OTP from '../../../Components/OTP/OTP'
import { APIsAuthorizeUsers } from '../../../Services/APIs/APIsController'
import { ApiId } from '../../../Shared/Enums/APIsEnum'
import { useLoading } from '../../../Services/Hooks/useLoading'
import { emailValidation } from '../../../Services/Utils/StringUtils'
import { phoneValidation } from '../../../Services/Utils/NumberUtils'
import { useUser } from '../../../Services/Hooks/useUser'
import { useLanguage } from '../../../Services/Hooks/useLanguage'

type Props = {
  handleFormChange: React.MouseEventHandler;
  setIsLogin: (val: boolean) => void;
}

export const RegisterForm = ({ handleFormChange, setIsLogin }: Props) => {
  const { setUser } = useUser();
  const { updateLoading } = useLoading();
  const { onTranslate } = useLanguage();

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [otp, setOtp] = useState<string[]>(Array(6).fill(''));

  const [currStep, setCurrStep] = useState<number>(1);

  const onRegister = async () => {

    if (currStep === 1) {
      if (name.trim().length < 12) {
        message.error(onTranslate("MESSAGE.NAME_VALIDATION", 12));
        return;
      }
      if (!emailValidation(email)) {
        message.error(onTranslate("MESSAGE.INVALID_EMAIL"));
        return;
      }
      if (!phoneValidation(phone)) {
        message.error(onTranslate("MESSAGE.INVALID_PHONE"));
        return;
      }

      updateLoading(true, onTranslate("MESSAGE.REGISTERING"));

      const status = await APIsAuthorizeUsers(ApiId.REGISTER_OTP, { fullname: name, email });

      updateLoading(false);

      if (!status) return;
      setCurrStep(2);
      return;
    }

    // Call API to login
    const OTP = otp.join('')
    updateLoading(true, onTranslate("MESSAGE.VERIFYING_OTP"));

    const res = await APIsAuthorizeUsers(ApiId.VALIDATE_OTP, { email: email, otp: OTP });

    if (res && res !== AccountStatus.BLOCKED) {
      setUser(res);
      message.success(onTranslate("MESSAGE.WELCOME_TO", res.fullName));
      setIsLogin(false);
    } else {
      message.error(
        res === AccountStatus.BLOCKED
          ?
          onTranslate("MESSAGE.SUSPENDED_ACCOUNT")
          :
          onTranslate("MESSAGE.LOGIN_FAILED")
      );
    }

    updateLoading(false);
  }

  const onResendOtp = async () => {
    updateLoading(true, onTranslate("MESSAGE.RESEND_OTP"));

    const res = await APIsAuthorizeUsers(ApiId.SIGNIN_OTP, email);

    updateLoading(false);
  }

  const onSignInWithGoogle = async () => {
    updateLoading(true, onTranslate("MESSAGE.LOGGING_IN"));

    const res = await APIsAuthorizeUsers(ApiId.SIGNIN_WITH_GOOGLE);

    if (res && res !== AccountStatus.BLOCKED) {
      setUser(res);
      message.success(onTranslate("MESSAGE.WELCOME_BACK", res.fullName));
      setIsLogin(false);
    } else {
      message.error(
        res === AccountStatus.BLOCKED
          ?
          onTranslate("MESSAGE.SUSPENDED_ACCOUNT")
          :
          onTranslate("MESSAGE.LOGIN_FAILED")
      );
    }

    updateLoading(false);
  }

  return (
    <div className='register-form form-container'>
      {
        currStep === 1
          ?
          <>
            <div className='inputLabel'>{onTranslate("LABEL.NAME")}</div>
            <TextInput isTextArea={false} className='emailInput' placeholder='Your Name' onChange={setName} value={name} />

            <div style={{ margin: '20px 0 0 0' }}></div>
            <div className='inputLabel'>{onTranslate("LABEL.EMAIL")}</div>
            <TextInput isTextArea={false} className='emailInput' placeholder='your@email.com' onChange={setEmail} value={email} />

            <div style={{ margin: '20px 0 0 0' }}></div>
            <div className='inputLabel'>{onTranslate("LABEL.PHONE")}</div>
            <TextInput isTextArea={false} className='emailInput' placeholder='0345678912' onChange={setPhone} value={phone} />

            <div className='support-box'>
              <span onClick={handleFormChange} className='register'>{onTranslate("BUTTON.HAVE_ACCOUNT")}</span>
            </div>

          </>
          :
          <>
            <Button className='backBtn' type="secondary" onClick={() => setCurrStep(1)}><FaAngleLeft /></Button>
            <OTP otpReceiver={email} onChange={setOtp} resendOtp={onResendOtp} />
          </>
      }

      <Button className='button-login' type='primary' onClick={onRegister}>{onTranslate("BUTTON.SIGN_UP")}</Button>

      {
        currStep === 1 &&
        <>
          <div className='divide'>
            <span className='divider'></span>
          </div>
          <Button className='button-login' onClick={onSignInWithGoogle}>
            <FaGoogle className='icon-button' />
            <span>{onTranslate("BUTTON.SIGNUP_GOOGLE")}</span>
          </Button>
        </>
      }

    </div>
  )
}