import React, { useRef, useState } from 'react'
import Cropper from 'react-easy-crop'
// import "../UpdateEvent.scss";
import { onCropImage } from '../../../Services/Utils/ImageUtils';
import Button from '../../../Components/Button/Button';
import { useLanguage } from '../../../Services/Hooks/useLanguage';

type Props = {
    originalUploadImage: string;
    onFinishCropImage: (value: string) => void;
    onCancel: () => void;
}

const CropImage = ({ originalUploadImage, onFinishCropImage, onCancel }: Props) => {
    const { onTranslate } = useLanguage();
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);

    const [croppedImg, setCroppedImg] = useState<string | null>(null);

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);

    const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
        const newDataUrl = onCropImage(originalUploadImage, croppedAreaPixels.x, croppedAreaPixels.y, croppedAreaPixels.width, croppedAreaPixels.height, previewCanvasRef.current);

        setCroppedImg(newDataUrl);
    }

    const onSubmitCrop = () => {
        if (!croppedImg) return;

        onFinishCropImage(croppedImg);
    }

    return (
        <div>
            <div className='cropWrapper'>
                <Cropper image={originalUploadImage} crop={crop} zoom={zoom} aspect={1} onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom} />
            </div>

            <div className='cropImgBtnCtn'>
                <Button style={{ width: '49%' }} onClick={onCancel}>{onTranslate('BUTTON.CANCEL')}</Button>
                <Button type="primary" style={{ width: '49%' }} onClick={onSubmitCrop}>{onTranslate('BUTTON.CROP')}</Button>
            </div>
            <canvas ref={previewCanvasRef} style={{ maxWidth: '100%' }} hidden />
        </div>
    )
}

export default CropImage