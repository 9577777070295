import { useEffect, useState } from 'react';
import './Navbar.scss'
import { AppConfigurations } from '../../Shared/Constants/AppConfigurations';
import { AdminNavbar, LoggedInNavbar, UnLogInNavbar } from './NavbarBtns';
import { Link } from 'react-router-dom';
import { useUser } from '../../Services/Hooks/useUser';
import { Modal } from 'antd';
import Authentication from '../../Layout/Authentication/Authentication';
import WebLogo from '../WebLogo/WebLogo';

type Props = {}

const Navbar = (props: Props) => {
    const { user } = useUser();

    const [isGreaterScroll, setIsGreaterScroll] = useState<boolean>(false);
    const [isLogin, setIsLogin] = useState<boolean>(false);

    // Check scroll
    useEffect(() => {
        document.addEventListener("scroll", handleScroll);

        return () => {
            document.removeEventListener("scroll", handleScroll);
        }
    });

    const handleScroll = () => {
        if ((window.pageYOffset >= 10 && isGreaterScroll) || (window.pageYOffset < 10 && !isGreaterScroll)) return;

        setIsGreaterScroll(window.pageYOffset >= 10);
    }

    return (
        <>
            {isLogin && <Authentication setIsLogin={setIsLogin} />}
            <div className='Navbar' style={isGreaterScroll ? { boxShadow: '0 0 3px var(--quaternary-bg-color)', backdropFilter: 'blur(10px)' } : {}}>
                <Link to="/" className='logo'>
                    <WebLogo height={window.screen.availWidth <= 768 ? 40 : 60} width={window.screen.availWidth <= 768 ? 80 : 120} />
                </Link>
                {
                    !user
                        ?
                        <UnLogInNavbar setIsLogin={setIsLogin} isLogin={isLogin} />

                        :
                        user?.roleName === "Admin"
                            ?
                            <AdminNavbar />
                            :
                            <LoggedInNavbar />
                }
            </div>
        </>
    )
}

export default Navbar