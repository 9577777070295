import { addZeroBefore } from "./NumberUtils";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs, { Dayjs, ManipulateType } from 'dayjs';
import { AppConfigurations } from "../../Shared/Constants/AppConfigurations";
import { ITimeDifference } from "../../Shared/Model/Others";
import { en } from "../../Shared/Constants/Language/en";
import { vi } from "../../Shared/Constants/Language/vi";

export const formatDateTime = (value: number | string) => {
    const date = new Date(value);
    return `${addZeroBefore(date.getDate())}/${addZeroBefore(date.getMonth() + 1)}/${date.getFullYear()}`
}

export const formatTime = (value: number | string) => {
    const date = new Date(value);
    return `${addZeroBefore(date.getHours())}:${addZeroBefore(date.getMinutes())}`
}

export const formatDay = (value: number, lang = 'en') => {
    const date = new Date(value);
    let days
    switch (lang) {
        case 'en':
            days = en.DAY;
            break;
        case 'vi':
            days = vi.DAY;
            break;
        default:
            days = en.DAY;
            break;
    }

    return days[date.getDay()];
}

export const initTimezone = () => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.tz.setDefault(AppConfigurations.timezone);
}

export const compareDayjs = (greater: Dayjs, lower: Dayjs, difference: ITimeDifference = { number: 0, unit: 'second' }) => {
    const compareResult = greater.diff(lower, difference.unit);

    return compareResult >= difference.number;
}

export const checkFutureTimeDayjs = (time: Dayjs, value: number = 0, unit: ManipulateType = 'second') => {
    return compareDayjs(time, dayjs().add(value, unit));
}

export const eventPreviewDateFormat = (date: number | string) => {
    const originalTime = (new Date(date)).getTime();
    const formatedTime = `${formatTime(originalTime)} ${formatDay(originalTime)?.substring(0, 3)}, ${formatDateTime(originalTime)}`;

    return formatedTime;
}

export const onConvertMinuteToMinuteAndSecond = (time: number | undefined) => {
    if (!time) return;

    const minutes = Math.floor(time);
    const seconds = Math.round((time - minutes) * 60);
    return `${addZeroBefore(minutes)}:${addZeroBefore(seconds)}`
}

export const onConvertFormatToNumberType = (formattedTime: string) => {
    if (!formattedTime) return;
    const [minutes, seconds] = formattedTime.split(':');
    return Number(minutes) + Number(seconds) / 60;
}

export const convertDayjsToDate = (dayjsVal: Dayjs) => {
    return new Date(dayjsVal.format());
}

export const formatCertiDate = (dateStr: Date) => {
    return dateStr.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
}

export const convertSecondToMinuteAndSecond = (totalTime: string) => {
    const time = totalTime.split(':');
    const minutes = parseInt(time[0]);
    const seconds = parseInt(time[1]);
    let countdown = minutes * 60 + seconds;

    return countdown;
}