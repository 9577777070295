import axios from "axios"
import { addTransactionApi, authApiConfig, createPaypalPayment, getAllOrCreatePayment, getAllTransactionsApi, getAllTypeTransactionsChartApi, getEventTransactionsApi, getMyTransactionsApi, getPaymentByEvent, getPaymentByFilter, getPaymentByUser, getTransactionsChartApi, payoutApi } from "./APIsConfig"
import { APIErrorController } from "./APIsController";
import { IUserData } from "../../Shared/Model/UserInterface";
import { IResponseGetAll } from "../../Shared/Model/EventsInterface";
import { localStorageId } from "../../Shared/Enums/StorageEnum";
import { message } from "antd";
import { IZaloPaymentCreateOrder } from "../../Shared/Model/ZaloPayment";
import { PaymentStatus, PaymentSubscriptionType } from "../../Shared/Enums/PaymentEnum";
import { IChartParticipants } from "../../Shared/Model/Others";

const getPaypalAccessToken = 'https://api-m.sandbox.paypal.com/v1/oauth2/token';
const username = 'AZzAMY8HVIoBzEKxfy4Jt0B_aEeeu6Ff2Co1dR4u9zSvtES1u15uB1N6K-DO5DTXayOw4fbilNYCvTmn';
const password = 'EKl2Evow3SngSQw-7e-2AwML0fVHayaf4lAOlZ9yDcxGGzChRcvqsXVo_yFHY5tERP2-MpNESEAYKLby';
const payPalSecret = 'sk_test_51PThIGFm2mxz6mYaJ8mqmE1IwXVGdZKSui2dJCbj6UTalUIJEPcfcHHDcjFHEHzfc5L5QUldyYhh8zAiPQhIVvLL00WoV5GANY';
const paypalPub = 'pk_test_51PThIGFm2mxz6mYaHmgxUCIbb7jWVjM8AljZfD0GEvkpfKECsPuFo4btQFEytdhS3GkjH6XeScyusD55wEzqC8KW00Mz6hPUTK'

export const onCreatePaypalPayment = ({ eventId, message, amount, baseUrl }: any) => {
    const config = authApiConfig();
    return axios.post(createPaypalPayment, { eventId, message, amount, baseUrl }, config as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onProcessPaypalTicketPayment = (savedData: any, resultLink: any) => {
    console.log(savedData, resultLink);
    if (!savedData || !resultLink) return;
    // Redirect to paypal
    window.location.href = resultLink[1].href;

    // Save data to storage
    sessionStorage.setItem(localStorageId.PAYPAL_SAVED_DATA, JSON.stringify({ savedData, resultLink }));
}

export const onProcessSponsorPayment = (paypalSavedData: any) => {
    // console.log(paypalSavedData);
    if (!paypalSavedData) return;

    // Redirect to paypal
    window.location.href = paypalSavedData.resultLink[1].href;

    // Save data to storage
    sessionStorage.setItem(localStorageId.PAYPAL_SPONSOR_DATA, JSON.stringify(paypalSavedData));
}


export const onFindPaypalData = () => {
    const paypalSavedData = sessionStorage.getItem(localStorageId.PAYPAL_SAVED_DATA);
    return paypalSavedData ? JSON.parse(paypalSavedData) : null;
}

export const onFindSponsorData = () => {
    const paypalSavedData = sessionStorage.getItem(localStorageId.PAYPAL_SPONSOR_DATA);
    return paypalSavedData ? JSON.parse(paypalSavedData) : null;
}

export const onFinishPaypalTicketPayment = async (paypalData: any, updateLoading: any, onAddParticipantToEvent: any) => {
    const apiGetPayerEmail = paypalData.resultLink[0].href;
    const base64Credentials = btoa(`${username}:${password}`);

    // Get access token from paypal
    const config = {
        headers: {
            'Authorization': `Basic ${base64Credentials}`,
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }

    const formData = new URLSearchParams();
    formData.append('grant_type', 'client_credentials');

    updateLoading(true, "Validating Payment...");
    const data = await axios.post(getPaypalAccessToken, formData, config as any)
        .then((res) => res)
        .catch((err) => APIErrorController(err));
    updateLoading(false)
    if (!data) return;

    // Get paypal transactions data
    const accessToken = data.data.access_token;
    const configGetEmail = {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    }

    updateLoading(true, "Retrieving data...");
    const payerData = await axios.get(apiGetPayerEmail, configGetEmail)
        .then((res) => res.data)
        .catch((err) => APIErrorController(err))
    updateLoading(false, "Retrieve data...");

    const payerEmail = payerData.payer?.payer_info.email;

    if (!payerEmail) {
        message.error('Registed Fail because user not pay, please try again');
        sessionStorage.removeItem(localStorageId.PAYPAL_SAVED_DATA);

        return;
    }

    const savedData = { ...paypalData.savedData, emailPaypal: payerEmail }

    // Add transaction
    const authCfg = authApiConfig();
    updateLoading(true, "Saving transaction...");
    const savedResult = await axios.post(addTransactionApi, savedData, authCfg as any)
        .then((res) => res)
        .catch((err) => console.log(err))
    updateLoading(false, "Saving transaction...");

    // if (!savedResult) return;

    // Add participant to event
    message.success('Registered successfully')
    sessionStorage.removeItem(localStorageId.PAYPAL_SAVED_DATA);
    onAddParticipantToEvent();
}

export const onPayout = ({ eventId, emailReceiver, amount, emailSubject }: any) => {
    const authCfg = authApiConfig();
    return axios.post(payoutApi, { eventId, emailReceiver, amount, emailSubject }, authCfg as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))

}

export const onGetAllTransactions = () => {
    const authCfg = authApiConfig();
    return axios.get(`${getAllTransactionsApi}?pageNo=1&elementEachPage=20`, authCfg as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onFinishPaypalSponsorPayment = async (paypalData: any, updateLoading: any) => {
    const apiGetPayerEmail = paypalData.resultLink[0].href;
    const base64Credentials = btoa(`${username}:${password}`);

    // Get access token from paypal
    const config = {
        headers: {
            'Authorization': `Basic ${base64Credentials}`,
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }

    const formData = new URLSearchParams();
    formData.append('grant_type', 'client_credentials');

    updateLoading(true, "Validating Payment...");
    const data = await axios.post(getPaypalAccessToken, formData, config as any)
        .then((res) => res)
        .catch((err) => APIErrorController(err));
    updateLoading(false)
    if (!data) return;

    // Get paypal transactions data
    const accessToken = data.data.access_token;
    const configGetEmail = {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    }

    updateLoading(true, "Retrieving data...");
    const payerData = await axios.get(apiGetPayerEmail, configGetEmail)
        .then((res) => res.data)
        .catch((err) => APIErrorController(err))
    updateLoading(false, "Retrieve data...");

    const payerEmail = payerData.transactions[0].payee.email;

    if (!payerEmail) {
        message.error('Sponsor Fail because user not pay, please try again');
        sessionStorage.removeItem(localStorageId.PAYPAL_SAVED_DATA);

        return;
    }

    const savedData = { ...paypalData.savedData, emailPaypal: payerEmail }

    // Add transaction
    const authCfg = authApiConfig();
    updateLoading(true, "Saving transaction...");
    const savedResult = await axios.post(addTransactionApi, savedData, authCfg as any)
        .then((res) => res)
        .catch((err) => console.log(err))
    updateLoading(false, "Saving transaction...");

    // if (!savedResult) return;

    // Add participant to event
    sessionStorage.removeItem(localStorageId.PAYPAL_SPONSOR_DATA);
    return true;
}

export const onGetMyTransactions = (userId: string) => {
    const config = authApiConfig();
    return axios.get(getPaymentByUser(userId), config as any)
        .then(res => res.data.data)
        .catch(err => APIErrorController(err))
}

export const onGetEventTransactions = (eventId: string) => {
    return axios.get(getPaymentByEvent(eventId))
        .then((res) => res.data.data)
        .catch(err => APIErrorController(err))
}

export const onZaloCreateTransaction = (data: IZaloPaymentCreateOrder) => {
    return axios.post(getAllOrCreatePayment, data)
        .then(res => res.data.data)
        .catch(err => APIErrorController(err))
}

export const onFilterPaymentTransactions = (eventId?: string, userId?: string, status?: PaymentStatus, subscriptionType?: PaymentSubscriptionType) => {
    return axios.get(getPaymentByFilter(eventId, userId, status, subscriptionType))
        .then((res) => res.data.data)
        .catch(err => APIErrorController(err))
}

export const onGetTotalTransactionsChart = (data: IChartParticipants) => {
    const config = authApiConfig();

    return axios.get(getTransactionsChartApi(data), config)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onGetAllTypeTransactionsChart = (data: IChartParticipants) => {
    const config = authApiConfig();
    return axios.get(getAllTypeTransactionsChartApi(data), config)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}