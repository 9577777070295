import "./Quiz.scss"
import { IQuiz } from '../../../Shared/Model/QuizInterface'
import { avgColor } from "../../../Services/Utils/ThemeUtils"
import { DarkTheme } from "../../../Shared/Constants/Theme/DarkTheme"
import { useState } from "react"
import QuizLabel from "./QuizLabel"
import { FaPen, FaTrash } from "react-icons/fa6"
import { useLanguage } from "../../../Services/Hooks/useLanguage"
import { formatDateTime, formatTime } from "../../../Services/Utils/TimeUtils"
import { onDeleteQuiz } from "../../../Services/APIs/QuizAPIs"
import { Popconfirm, message } from "antd"
import { QuizStatusEnum } from "../../../Shared/Enums/QuizEnum"
import LoadingDots from "../../../Components/LoadingDots/LoadingDots"

type Props = {
    quiz: IQuiz,
    eventTheme: string | undefined,
    onClickUpdateQuiz: (value: IQuiz) => void;
    onInitQuizList: () => void;
}

const QuizView = ({ quiz, eventTheme, onClickUpdateQuiz, onInitQuizList }: Props) => {
    const { onTranslate } = useLanguage();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isShowMore, setIsShowMore] = useState<boolean>(false);

    const handleDescriptionShow = () => {
        const limit = 50;
        if (quiz.quizDescription?.length <= limit) return quiz.quizDescription;

        if (isShowMore) return (
            <>{quiz.quizDescription} <label onClick={() => setIsShowMore(false)} style={{ marginLeft: '5px', cursor: 'pointer' }}>Show less</label></>
        )

        return (
            <>
                {quiz.quizDescription?.slice(0, limit)}...
                <label onClick={() => setIsShowMore(true)} style={{ marginLeft: '5px', cursor: 'pointer' }}>Show more</label>
            </>
        )
    }

    const handleDeleteQuiz = async () => {
        if (!quiz.quizId) return;
        setIsLoading(true);
        const result = await onDeleteQuiz(quiz.quizId);
        setIsLoading(false);

        if (!result) return message.error(onTranslate('MESSAGE.DELETE_QUIZ_FAILED'));
        message.success(onTranslate('MESSAGE.DELETE_QUIZ_SUCCESS'));
        onInitQuizList();
    }

    return (
        <div className='quizView' style={{ background: avgColor(DarkTheme['--tertiary-bg-color'], eventTheme ?? '#fff') }}>
            <div className='name'>{quiz.quizName} <QuizLabel quizStatus={quiz.quizStatus} /></div>
            <div className='description'>{handleDescriptionShow()}</div>
            <ul className='additionalInfo'>
                <li>{quiz.attemptAllow === -1 ? onTranslate('LABEL.UNLIMITED') : onTranslate('LABEL.ATTEMP_ALLOWED', quiz.attemptAllow)}</li>
                <li>{onTranslate('LABEL.TOTAL_TIME', quiz.totalTime)}</li>
                <li>{quiz.dueDate ? onTranslate('LABEL.OPEN_DUE_TO', `${formatTime(quiz.dueDate)} ${formatDateTime(quiz.dueDate)}`) : onTranslate('LABEL.NO_DEADLINE')}</li>
            </ul>

            {
                [QuizStatusEnum.DELETED].includes(quiz.quizStatus)
                    ? <></>
                    : isLoading
                        ?
                        <div style={{position: 'absolute', right: '10px', top: '11px'}}>
                            <LoadingDots />
                        </div>
                        :
                        <div className="btnCtn">
                            <FaPen className="editBtn" onClick={() => onClickUpdateQuiz(quiz)} />
                            <Popconfirm title={onTranslate('MESSAGE.CONFIRM_DELETE_QUIZ')} onConfirm={handleDeleteQuiz}>
                                <FaTrash className="deleteBtn" />
                            </Popconfirm>
                        </div>
            }
        </div>
    )
}

export default QuizView