import { createContext, useEffect, useState } from 'react';
import './App.scss';
import { IconContext } from "react-icons";
import { InitTheme } from './Services/Utils/ThemeUtils';
import { RouterProvider } from 'react-router-dom';
import { Routers } from './Routers';
import { initTimezone } from './Services/Utils/TimeUtils';
import { ConfigProvider, Spin } from 'antd';
import { AppConfigurations } from './Shared/Constants/AppConfigurations';
import { IUserData } from './Shared/Model/UserInterface';
import { IContext, ILoading } from './Shared/Model/Others';
import { getDataLocalStorage } from './Services/Utils/StorageUtils';
import { localStorageId } from './Shared/Enums/StorageEnum';
import { onLoadLanguage } from './Services/Utils/LanguageUtils';
import { onLoadUserFromLocal, onSaveUserToLocal } from './Services/Utils/UserUtils';
import Certificate from './Components/DefaultCertification/Certificate';

export const DataContext = createContext<IContext | null>(null);

function App() {
  const [user, setUser] = useState<IUserData | null>(onLoadUserFromLocal());
  const [language, setLanguage] = useState<string>(onLoadLanguage());
  const [loading, setLoading] = useState<ILoading>({ status: false });

  // Init Values
  useEffect(() => {
    InitTheme();
    initTimezone();
  }, []);

  return (

    <DataContext.Provider value={{ setUser, user, setLoading, loading, language, setLanguage }}>
      <ConfigProvider theme={AppConfigurations.antDesignThemeCfg}>
        <Spin size="large" spinning={loading?.status} tip={loading?.tooltip}>
          <div className="App">
            <IconContext.Provider value={{}}>
              <RouterProvider router={Routers} />
            </IconContext.Provider>
          </div>
        </Spin>
      </ConfigProvider>
    </DataContext.Provider>
  );
}

export default App;
