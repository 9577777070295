/** @format */

export const vi = {
  BUTTON: {
    VIEW_MORE: "Xem thêm",
    EXPLORE: "Khám phá",
    CREATE_EVENT: "Tạo sự kiện",
    HOME: "Trang chủ",
    SIGNIN: "Đăng nhập",
    LOGOUT: "Đăng xuất",
    PROFILE: "Trang cá nhân",
    TRANSACTIONS: "Giao dịch",
    CLOSE_SIGNIN_FORM: "Đóng",
    MY_EVENT: "Sự kiện",
    DO_NOT_HAVE_ACCOUNT: "Bạn chưa có tài khoản? Đăng ký",
    HAVE_ACCOUNT: "Bạn đã có tài khoản? Đăng nhập",
    SIGN_UP: "Đăng ký",
    SIGNIN_GOOGLE: "Đăng nhập với Google",
    SIGNUP_GOOGLE: "Đăng ký với Google",
    CANCEL: "Huỷ",
    SAVE: "Lưu",
    SET_LIMIT: "Đặt giới hạn",
    REMOVE_LIMIT: "Bỏ giới hạn",
    SET_PRICE: "Đặt giá",
    REMOVE_PRICE: "Bỏ giá",
    CROP: "Cắt",
    FEEDBACK: "Đánh giá",
    UPDATE_FEEDBACK: "Cập nhật đánh giá",
    CHECKIN: "Check In",
    CHECKEDIN: "Đã Check In",
    DEPOSIT: "Chuyển tiền",
    CANCEL_REQUEST: "Huỷ yêu cầu",
    CREATE: "Tạo",
    SUPPORT_ORGANIZER_SPONSOR:
      "Hỗ trợ ban tổ chức bằng cách tài trợ cho sự kiện này, click vào đây",
    BY_EVENT_ORG: "bởi ban tổ chức",
    MANAGE: "Quản lý",
    CONTACT_ORG: "Liên hệ ban tổ chức",
    UPDATE_EVENT: "Cập nhật",
    VIEW_LESS: "Xem ít hơn",
    REFUND: "Hoàn tiền",
    ADD: "Thêm",
    DELETE_EVENT: "Xoá sự kiện",
    VIEW: "Xem",
    PAYMENT: "Thanh toán",
    ACCEPT: "Chấp nhận",
    REJECT: "Từ chối",
    DELETE: "Xoá",
    UPGRADE: "Nâng cấp",
    ADD_CLOSE_DATE: "Thêm ngày đóng",
    MANAGE_QUESTIONS: "Chỉnh sửa câu hỏi",
    BACK: "Quay lại",
    DISCARD: "Huỷ",
    ADD_ANSWER: "Thêm đáp án",
    UPGRADE_ACCOUNT: "Nâng cấp tài khoản",
    VIEW_QUIZ: "Xem Quiz",
    ATTEMPT: "Tham gia",
    CERTIFIED_SELECTED_PARTICIPANTS: "Cấp chứng nhận",
    VIEW_CERTIFICATE: "Xem chứng nhận",
    SUBMIT: "Nộp",
    START_QUIZ: "Bắt đầu",
  },

  LABEL: {
    THIS_EVENT: "Sự kiện này",
    LANDING_TITLE: "Bạn chỉ việc tham gia sự kiện,\n mọi thứ để Da Skillz lo!",
    LANDING_SUBTITLE:
      "Đóng vai trò như một cầu nối giữa các nhà tổ chức sự kiện và người tham dự, trang web Da Skillz tự tin cung cấp đa dạng các loại sự kiện và đem đến trải nghiệm liền mạch từ tìm kiếm theo sở thích đến thanh toán một cách thuận lợi.",
    POPULAR_EVENTS: "BẮT ĐẦU HÀNH TRÌNH",
    POPULAR_LOCATIONS: "ĐỊA ĐIỂM NỔI BẬT",
    POPULAR_ORGANIZERS: "NGƯỜI TỔ CHỨC NỔI BẬT",
    HOSTED_NUM_EVENTS: "Đã tổ chức %s sự kiện",
    NO_EVENT_FOUND: "Không tìm thấy sự kiện nào",
    NO_OGRANIZER_FOUND: "Không tìm thấy người tổ chức nào",
    NO_LOCATION_FOUND: "Không tìm thấy địa điểm nào",
    EVENTS: "Sự kiện",
    LOGIN_HEADER: "Chào mừng đến DaSkillz",
    LOGIN_SUB:
      "Hãy cùng tạo nên lịch su kiện đáng nhớ và khám phá những điều mới mẻ",
    EMAIL: "Email",
    NAME: "Tên",
    PHONE: "Điện thoại",
    ALL: "Toàn bộ",
    SEARCH_EVENT: "Khám phá",
    SEARCH_EVENT_SUB: "Tìm sự kiện phù hợp nhất với bạn",
    EVENT_NAME: "Tên",
    LOCATION: "Địa điểm",
    TAG: "Tag",
    FOUND_EVENTS: "Đã tìm thấy %s sự kiện",
    NO_PHONE: "Không có số điện thoại",
    EVENT_HOSTED: "Sự kiện đã tổ chức",
    USER_NOT_FOUND: "NGƯỜI DÙNG KHÔNG TỒN TẠI",
    COLOR_THEME_PICKER: "CHỌN MÀU SẮC YÊU THÍCH",
    CUSTOM_COLOR: "Tuỳ chỉnh",
    ABOUT: "Thông tin",
    POLICY: "Điều khoản",
    PRICING: "Dịch vụ",
    HELP: "Hỗ trợ",
    COPYRIGHT: "Bản quyền 2024 bởi DaSkillz Group.",
    NO_UPCOMMING_EVENT: "Bạn không có sự kiện sắp tới nào",
    SENT_OTP: "OTP đã gửi đến %s",
    RESEND: "Gửi lại",
    RESEND_IN: "trong %s giây",
    OTP: "OTP",
    ABORTED: "Đã bị gỡ bởi Admin",
    COMING_SOON: "Sắp diễn ra",
    ON_GOING: "Đang diễn ra",
    DELETED: "Đã bị xoá",
    ENDED: "Đã kết thúc",
    CANCEL: "Đã bị hoãn",
    VIRTUAL_LOCATION: "Địa điểm ảo",
    EVENT_SIZE: "Kích thước",
    TAGS: "Tags",
    TICKET: "Vé",
    THEME_COLOR: "Màu chủ đạo",
    START: "Bắt đầu",
    END: "Kết thúc",
    LOCATION_INSTRUCTION: "Địa điểm thật hoặc địa điểm ảo",
    LOCATION_INSTRUCTION_2: "Điền địa chỉ hoặc liên kết địa điểm ảo",
    CURRENT_LOCATION: "Địa điểm hiện tại",
    USE: "Sử dụng",
    SUGGESTED_LOCATION: "Đề xuất",
    NO_SUGGESTION: "Không có đề xuất",
    DESCRIPTION: "Mô tả",
    EVENT_OPTIONS: "Tuỳ chỉnh",
    APPROVAL: "Phê duyệt",
    UNLIMITED: "Không giới hạn",
    FREE: "Miễn phí",
    SIZE_HINT_1: "Sự kiện sẽ tự từ chối khi đạt đến giới hạn.",
    SIZE_HINT_2:
      "Khi bật phê duyệt, chỉ những người đã được phê duyệt mới được tính vào giới hạn.",
    LIMIT: "Giới hạn",
    FIND_TAG: "Tìm tag của bạn",
    SELECTED: "Đã chọn",
    UNSELECT_TAG_ACTION: "Click vào bất kì tag nào để bỏ chọn",
    SELECT_TAG_ACTION: "Click vào bất kì tag nào để chọn (Tối đa %s)",
    SUGGESTION: "Đề xuất",
    TICKET_PRICE: "Giá vé (VND)",
    PAST: "Đã diễn ra",
    FUTURE: "Sắp diễn ra",
    CHECKEDIN_CONFIRMATION: "Xác nhận Checkin",
    CHECKING_IN_FOR: "Bạn đang checkin %s với Email %z",
    DEPOSIT_MONEY: "Tài trợ tiền",
    UNIT: "Đơn vị: %s",
    PROCESSING: "Đang xử lý",
    CONFIRMED: "Đã xác nhận",
    REJECTED: "Đã từ chối",
    YOUR_SPONSOR_REQUEST: "Yêu cầu của bạn",
    CONTACT_ORGANIZER: "Liên hệ ban tổ chức",
    SUBMITTED_TIME: "Thời gian gửi",
    SPONSOR_TYPE: "Loại tài trợ",
    AMOUNT: "Số lượng",
    NOTE: "Ghi chú",
    SPONSOR_REQUEST_WARNING:
      "Bạn chỉ có thể huỷ yêu cầu trong trạng thái đang xử lý",
    ORGANIZER_ANSWERED: "Ban tổ chức đã %s vào lúc %z",
    SPONSOR: "Tài trợ",
    REGISTER: "Đăng ký",
    THIS_EVENT_HAS_BEEN: "Sự kiện này đã",
    BECAUSE_OF_VIOLATION: "vì vi phạm điều khoản",
    YOU_HAVE: "Bạn đã được",
    CLICK_FEEDBACK: "click đánh giá để đánh giá sự kiện này",
    THANKS_FOR_VIEWING: "Cảm ơn bạn đã quan tậm, nhưng",
    CLICK_TO_DEPOSIT_MONEY: "để chuyển tiền cho ban tổ chức",
    CLICK_HERE: "Click vào đây",
    TO_VIEW_SPONSOR_REQUEST: "để xem yêu cầu",
    PENDING: "Đang chờ",
    WELCOME: "Chào mừng",
    APART_OF_EVENT: 'Bạn đã tham gia thành công, click "Checkin QR" để',
    VIEW_CHECKIN_CODE: "Xem Checkin Code của bạn",
    ORGANIZER_REJECTED_REGISTRATION: "Bạn tổ chức đã từ chối yêu cầu của bạn",
    CONTACT_ORG_MISTAKE:
      "Vui lòng liên hệ ban tổ chức nếu bạn nghĩ đây là sự nhầm lẫn",
    YOU_NEED_TO: "Bạn cần phải",
    TO_REGSISTER: "để đăng ký sự kiện này",
    REGISTER_INS:
      "Xin chào! Để đăng ký, bạn vui lòng click nút Đăng ký phía dưới",
    YOUR_CHECKIN_QR: "Checkin QR của bạn",
    CHECKIN_QR_INS: "Hãy đưa mã QR này cho ban tổ chức để được checkin",
    REGISTERED_AT: "Đăng ký lúc",
    CAN_MANAGE_EVENT: "Bạn có toàn quyền với sự kiện này",
    HOSTED_BY: "Tổ chức bởi",
    FEATURED: "Thịnh hành ở",
    TO_START_CHECKIN: "để bắt đầu checkin người tham dự",
    EVENT_PAGE: "Trang sự kiện",
    VIEW_ALL_FEEDBACKS: "Xem tất cả đánh giá của người tham dự",
    HOST: "Quản lý",
    STAFF: "Thành viên ban tổ chức",
    ADD_COMMITTEE: "Thêm ban tổ chức",
    CLICK_TO_ADD: "Click để thêm",
    AS_A_STAFF: "với vị trí thành viên",
    PENDING_REQUEST: "Yêu cầu đang chờ",
    PENDING_REQUEST_DESCRIPTION:
      "Những người dùng đã đăng ký và đang chờ phản hồi của bạn",
    ORGANIZING_COMMITTEE: "Ban tổ chức",
    ORGANIZING_COMMITTEE_DESCRIPTION:
      "Những người có quyền truy cập vào sự kiện của bạn",
    SPONSOR_DESCRIPTION: "Những người đã tài trợ cho sự kiện của bạn",
    PARTICIPANTS: "Người tham dự",
    PARTICIPANTS_DESCRIPTION: "Những người dùng đã đăng ký tham gia thành công",
    NOT_CHECKIN: "Chưa checkin",
    PAYPAL_EMAIL: "Paypal Email",
    AMOUNT_UNIT: "Amount (%s)",
    CAN_NOT_BE_UNDO_WARNING: "This action can not be undo",
    TRANSACTION_ID: "Mã giao dịch",
    TIME: "Thời gian",
    MESSAGE: "Nội dung",
    STATUS: "Trạng thái",
    EVENT_TRANSACTIONS: "Giao dịch",
    EVENT_TRANSACTIONS_DESCRIPTION:
      "Tất cả giao dịch của sự kiện, bao gồm tài trợ và mua vé.",
    ACTION: "Hành động",
    REQUEST: "Yêu cầu",
    ROLE: "Vị trí",
    SPONSOR_REQUEST: "Yêu cầu tài trợ",
    REQUESTED_TIME: "Thời gian yêu cầu",
    EVENT_OVERVIEW: "Tổng quan",
    EVENT_OVERVIEW_DESCRIPTION:
      "Đây là tóm tắt toàn bộ thông tin về sự kiện của bạn",
    EVENT_SUMMARY: "Thiết lập",
    NEED_TO_BE_APPROVE: "Cần được phê duyệt",
    DO_NOT_NEED_TO_BE_APPROVE: "Không cần phê duyệt",
    SPONSOR_REQUEST_DESCRIPTION: "Những người muốn tài trợ",
    USERS_STATISTIC: "Thống kê người dùng",
    USERS_STATISTIC_DESCRIPTION: "Thống kê số liệu về các loại người dùng",
    OTHERS: "Khác",
    VISIBLE: "Chế độ hiển thị",
    VISIBLE_WARNING:
      "Sự kiện bắt đầu sau khoảng %s kể từ hiện tại sẽ không thể xoá mà chỉ chuyển thành trạng thái 'Huỷ'",
    ACTION_CANNOT_UNDO: "Hành động này là vĩnh viễn và không thể hoàn tác",
    DELETE_CONFIRMATION: "Xác nhận",
    DELETE_CAUTION: "Sau khi xoá bạn không thể truy cập sự kiện nữa",
    CANCEL_CAUTION:
      "Thời gian bắt đầu của sự kiện còn dưới 6 tiếng nên hành động này chỉ chuyển trạng thái sự kiện thành 'Huỷ'",
    TYPE: "Nhập",
    TO_CONFIRM: "để xác nhận hành động",
    PAYMENTID: "Mã giao dịch",
    CREATED_TIME: "Thời gian khởi tạo",
    PAID_TIME: "Thời gian hoàn tất",
    EVENT: "Sự kiện",
    MY_TRANSACTION: "Giao dịch",
    MY_TRANSACTION_DESCRIPTION: "Tất cả lịch sử giao dịch của bạn",
    PAYMENT_SUCCESS_STATUS: "Thành công",
    PAYMENT_FAILED_STATUS: "Thất bại",
    PAYMENT_PROCESSING_STATUS: "Đang chờ thanh toán",
    ABOUT_VISION: "TẦM NHÌN",
    ABOUT_VISION_DESCRIPTION:
      "Tạo ra mối liên kết giữa các cá nhân và những tổ chức cũng như cộng đồng có uy tín, nhằm mục đích phát triển cá nhân và mở rộng trải nghiệm cá nhân.",
    ABOUT_DESTINY: "SỨ MỆNH",
    ABOUT_DESTINY_DESCRIPTION:
      "Sứ mệnh cung cấp một nền tảng toàn diện, sáng tạo và thân thiện với người dùng, kết nối mọi người với những hoạt động và sự kiện yêu thích. Thúc đẩy gắn kết cộng đồng và lan tỏa những giá trị tích cực.",
    ABOUT_VALUE: "Giá trị cốt lõi",
    ABOUT_VALUE_1_TITLE: "Sáng Tạo",
    ABOUT_VALUE_1_DESCRIPTION:
      "Khuyến khích sự sáng tạo và đổi mới thông qua việc học hỏi và thực hành.",
    ABOUT_VALUE_2_TITLE: "Chất Lượng",
    ABOUT_VALUE_2_DESCRIPTION:
      "Đảm bảo chất lượng cao trong việc cung cấp các khóa học và hoạt động phát triển kỹ năng.",
    ABOUT_VALUE_3_TITLE: "Trải Nghiệm",
    ABOUT_VALUE_3_DESCRIPTION:
      "Kết nối các khóa học với trải nghiệm nâng cao kiến thức và kỹ năng đa dạng, phù hợp với nhu cầu của mọi người dùng.",
    ABOUT_VALUE_4_TITLE: "Học Hỏi",
    ABOUT_VALUE_4_DESCRIPTION:
      "Tạo điều kiện cho việc học hỏi liên tục và phát triển kỹ năng mới.",
    ABOUT_VALUE_5_TITLE: "Kết Nối",
    ABOUT_VALUE_5_DESCRIPTION:
      "Liên kết các cá nhân có cùng đam mê học hỏi và sáng tạo. Xây dựng một cộng đồng nơi các chuyên gia sáng tạo có thể chia sẻ kiến thức và kỹ năng của họ.",
    POLICY_DESCRIPTION:
      "Chúng tôi cam kết cung cấp cho khách hàng trải nghiệm thanh toán an toàn, tiện lợi khi mua vé sự kiện trên trang web. Dưới đây là các điều khoản cụ thể liên quan đến quy trình thanh toán",
    POLICY_1_TITLE: " Phương Thức Thanh Toán",
    POLICY_1_DESCRIPTION:
      "Chúng tôi chấp nhận các phương thức thanh toán sau:\n- Thẻ tín dụng/thẻ ghi nợ (Visa, MasterCard)\n- Thanh toán qua ví điện tử (MoMo, ZaloPay)\n- Chuyển khoản ngân hàng",
    POLICY_2_TITLE: "Quy Trình Thanh Toán",
    POLICY_2_DESCRIPTION:
      "Sau khi chọn vé và xác nhận đơn hàng, quý khách sẽ được chuyển hướng đến trang thanh toán bảo mật. Quý khách vui lòng điền thông tin thanh toán và xác nhận để hoàn tất giao dịch. Hệ thống sẽ gửi email xác nhận thanh toán và vé sau khi giao dịch thành công.",
    POLICY_3_TITLE: "Chính Sách Hoàn Tiền",
    POLICY_3_DESCRIPTION:
      "- Sau khi giao dịch thành công, vé sẽ không thể hoàn trả, ngoại trừ trường hợp sự kiện bị hủy hoặc thay đổi thời gian.\n- Nếu sự kiện bị hủy, tiền vé sẽ được hoàn lại theo phương thức thanh toán ban đầu trong vòng 7-10 ngày làm việc.",
    POLICY_4_TITLE: "Bảo Mật Thông Tin Thanh Toán",
    POLICY_4_DESCRIPTION:
      "Chúng tôi sử dụng các biện pháp  để bảo vệ thông tin thanh toán của quý khách. Dữ liệu thẻ sẽ không được lưu trữ trên hệ thống của chúng tôi sau khi giao dịch hoàn tất.",
    POLICY_5_TITLE: "Liên Hệ Hỗ Trợ",
    POLICY_5_DESCRIPTION:
      "Nếu có bất kỳ vấn đề nào phát sinh trong quá trình thanh toán, vui lòng liên hệ với bộ phận chăm sóc khách hàng qua email hoặc hotline để được hỗ trợ kịp thời.",
    TICKET_PAYMENT: "Thanh toán vé",
    TICKET_PAYMENT_DESCRIPTION:
      "Bạn đang có 1 đơn hàng mua vé chưa hoàn thành, vui lòng hoàn thành để tham gia sự kiện này",
    PAYMENT_WAIT:
      "Bạn vui lòng tải lại trang sau khi hoàn thành, sẽ mất ít phút để trạng thái giao dịch được cập nhật",
    HAVE_NOT_COMPLETE_SPONSOR:
      "Bạn có giao dịch tài trợ trị giá %s chưa hoàn thành, bạn vui lòng thanh toán trước khi tạo giao dịch mới",
    CLICK_TO_VIEW_SPONSOR_HISTORY: "Xem lịch sử tài trợ",
    BACK_TO_SPONSOR_PAGE: "Quay lại trang tài trợ",
    NO_ORGANIZER_FOUND: "Không tìm thấy ban tổ chức nào",
    QA_QUESTION_1: "Làm thế nào để tạo một sự kiện trên trang web?",
    QA_ANSWER_1:
      "Hướng dẫn các bước để đăng ký tài khoản, tạo sự kiện, và thiết lập thông tin chi tiết.",
    QA_QUESTION_2: "Trang web hỗ trợ những tính năng nào cho tổ chức sự kiện?",
    QA_ANSWER_2:
      "Các tính năng như quản lý vé, xử lý thanh toán, gửi email thông báo và quản lý danh sách người tham gia.",
    QA_QUESTION_3: "Làm thế nào để theo dõi số lượng người tham gia sự kiện?",
    QA_ANSWER_3:
      "Công cụ theo dõi số lượng đăng ký, bán vé, và thống kê theo thời gian thực.",
    QA_QUESTION_4: "Có thể tùy chỉnh giao diện trang sự kiện không?",
    QA_ANSWER_4:
      "Có thể tùy chỉnh các yếu tố giao diện, logo, hình ảnh và màu sắc để phù hợp với sự kiện.",
    QA_QUESTION_5:
      "Hệ thống có tích hợp với các công cụ marketing hoặc CRM không?",
    QA_ANSWER_5:
      "Thông tin về khả năng tích hợp với các nền tảng email marketing, Google Analytics, hoặc CRM.",
    QA_QUESTION_6:
      "Làm sao để quản lý và gửi email thông báo đến người tham gia?",
    QA_ANSWER_6: "Hướng dẫn sử dụng hệ thống gửi email tự động hoặc tùy chỉnh.",
    QA_QUESTION_7: "Chi phí sử dụng trang web tổ chức sự kiện là bao nhiêu?",
    QA_ANSWER_7:
      "Thông tin về các gói dịch vụ, phí hoa hồng và chi phí sử dụng các tính năng đặc biệt.",
    QA_QUESTION_8: "Có thể tạo mã giảm giá hoặc chương trình khuyến mãi không?",
    QA_ANSWER_8:
      "Có tính năng hỗ trợ khuyến mãi, mã giảm giá hoặc gói ưu đãi cho người tham gia.",
    QA_QUESTION_9: "Trang web có hỗ trợ đa ngôn ngữ không?",
    QA_ANSWER_9:
      "Khả năng tạo sự kiện bằng nhiều ngôn ngữ và hỗ trợ dịch thuật cho các trang thông tin.",
    QA_QUESTION_10: "Làm thế nào để đăng ký tham gia sự kiện?",
    QA_ANSWER_10:
      "Hướng dẫn về cách đăng ký trực tuyến, điền thông tin và thanh toán (nếu có) ở phần thông tin.",
    QA_QUESTION_11: "Có những phương thức thanh toán nào?",
    QA_ANSWER_11:
      "Các tùy chọn thanh toán: thẻ tín dụng, chuyển khoản ngân hàng, ví điện tử, hoặc thanh toán tại chỗ.",
    QA_QUESTION_12:
      "Sau khi đăng ký, làm thế nào để biết tôi đã đăng ký thành công?",
    QA_ANSWER_12:
      "Thông tin về email xác nhận, SMS, hoặc tài khoản cá nhân trên trang web thông qua email cá nhân của khán giả.",
    QA_QUESTION_13:
      "Nếu tôi không thể tham gia sự kiện, tôi có thể huỷ vé hay chuyển vé không?",
    QA_ANSWER_13: "Chính sách huỷ vé, hoàn tiền hoặc chuyển nhượng vé.",
    QA_QUESTION_14: "Tôi cần mang theo gì khi đến tham dự sự kiện?",
    QA_ANSWER_14:
      "Thông tin về vé, giấy tờ tùy thân hoặc các tài liệu cần thiết khác.",
    QA_QUESTION_15:
      "Làm thế nào để biết thông tin chi tiết về lịch trình sự kiện?",
    QA_ANSWER_15:
      "Khán giả xem lịch trình sự kiện trực tiếp trên trang web hoặc qua email thông báo.",
    QA_QUESTION_16: "Có hỗ trợ phiên dịch hoặc ngôn ngữ nào tại sự kiện không?",
    QA_ANSWER_16:
      "Các ngôn ngữ được hỗ trợ tại sự kiện: Tiếng Anh và Tiếng Việt.",
    ACCOUNT_PACKAGE: "Gói tài khoản",
    FREE_PACKAGE: "Gói Thành Viên",
    PREMIUM_PACKAGE: "Gói Doanh Nghiệp",
    FREE_PACKAGE_DESCRIPTION:
      "Tạo tối đa 2 sự kiện mỗi tháng|Phí 5% trên tổng số vé bán ra|Số lượng người tham gia không giới hạn|Check-in bằng QR tích hợp và máy quét QR|Xem báo cáo sự kiện cơ bản|Bán vé|Quản lý nhà tài trợ|Nhiều phương thức thanh toán|Hỗ trợ hoàn tiền|Hỗ trợ phản hồi cá nhân qua QR|Thông báo qua Email",
    PREMIUM_PACKAGE_DESCRIPTION:
      "Tạo tối đa 15 sự kiện mỗi tháng|0% phí cho tất cả dịch vụ|Xem trước thống kê sự kiện nâng cao|Tạo câu hỏi tùy chỉnh cho sự kiện|Tạo chứng nhận tùy chỉnh cho người tham gia hoàn thành|Tùy chỉnh giao diện sự kiện|Báo cáo có thể tải xuống dưới định dạng CSV",
    MONTHLY: "Hàng tháng",
    FOREVER: "Vĩnh viễn",
    FREE_DESCRIBE: "Các tính năng gói miễn phí:",
    PREMIUM_DESCRIBE: "Tất cả các tính năng miễn phí và:",
    ACTIVE: "ĐANG SỬ DỤNG",
    TRANSACTION_NO_MORE_FEE: "Tất cả giao dịch sẽ không phát sinh phí",
    ACCOUNT_PACKAGE_SUB: "Các gói tài khoản được thiết kế giành cho các nhà tổ chức sự kiện với nhu cầu khác nhau",
    EVENT_ADVERTISEMENT: "Chiến dịch quảng cáo sự kiện",
    EVENT_ADVERTISEMENT_SUB: "Khi sự kiện đang trong chiến dịch, sự kiện sẽ được hiển thị ở trang chủ và nằm trong TOP đầu tìm kiếm",
    DAYS: "ngày",
    EVENT_ADVERTISEMENT_SUB2: "Để tạo chiến dịch, vui lòng truy cập vào trang quản lý của sự kiện, chọn tab 'Quảng cáo'",
    QUIZ: "Quiz",
    QUIZ_DESCRIPTION: "Đây là nơi tổng hợp tất cả các quiz của bạn",
    CREATE_NEW_QUIZ: "Tạo quiz",
    QUIZNAME: "Tên",
    QUIZDESCRIPTION: "Mô tả",
    TOTALTIME: "Thời gian làm",
    ATTEMPTALLOW: "Số lần mỗi người tham dự được làm",
    PICK_TIME_UNIT: "Phút",
    DUEDATE: "Thời gian đóng quiz",
    DRAFT: "Nháp",
    CLOSED: "Đã đóng",
    ACTIVE_QUIZ: "Đang mở",
    UPDATE: "Cập nhật",
    ATTEMP_ALLOWED: "%s Lượt",
    TOTAL_TIME: "%s Phút",
    OPEN_DUE_TO: "Mở đến %s",
    NO_DEADLINE: "Không có hạn",
    LAST_CHANGE_NOT_SAVE: "Thay đổi chưa được lưu",
    NO_QUESTION_CHANGE: "Bạn chưa thay đổi câu hỏi nào",
    QUESTION_DISPLAY_COUNT: "%s câu hỏi (%z chưa lưu)",
    MULTIPLE_CHOICES: "Trắc nghiệm",
    PARAGRAPH: "Tự luận",
    ANSWER: "Đáp án",
    QUESTION: "Câu hỏi",
    GO_TO_PAYMENT: "Đến trang thanh toán",
    QUIZ_DESCRIPTION_USER: "Sự kiện này đang có %s quiz, hãy tham gia bằng cách click vào nút bên dưới",
    CERTIFICATE: "Chứng nhận",
    CERTIFICATE_PREVIEW: "Xem trước",
    CERTIFICATE_PREVIEW_DESCRIPTION: "Xem mẫu chứng nhận của bạn",
    CERTIFICATE_MANAGE: "Quản lý chứng nhận",
    CERTIFICATE_MANAGE_DESCRIPTION: "Bạn có thể cấp chứng nhận bằng cách chọn người tham dự và bấm vào nút 'Cấp chứng nhận'",
    CERTIFICATE_RECEIVED: "Ban tổ chức đã cấp chứng nhận cho bạn vào lúc %s",
    YOUR_RESPONSE: "Câu trả lời của bạn",
    VIEW_ALL_RESPONSES: "Xem tất cả câu trả lời của bạn",
    ADVERTISE: "Quảng cáo",
    ADVERTISE_DESCRIPTION: "Quảng cáo sự kiện của bạn để nó nổi bật hơn",
    ADVERTISE_HISTORY: "Lịch sử",
    ADVERTISE_HISTORY_DESCRIPTION: "Toàn bộ lịch sử quảng cáo của sự kiện",
    CREATED_DATE: "Ngày tạo",
    START_DATE: "Ngày bắt đầu",
    END_DATE: "Ngày kết thúc",
    PRICE: "Giá",
    TRENDING: "Nổi bật",
    EXPIRED: "Đã hết hạn",
    PARTICIPANTS_CHART: "Biểu đồ người tham dự",
    PARTICIPANTS_CHART_DESCRIPTION: "Biểu đồ thống kê số lượng người tham dự theo thời gian",
    MONEY_VND: "Tiền thu được (VND)",
    TRANSACTIONS_INCOME: "Thu nhập",
    TRANSACTIONS_INCOME_DESCRIPTION: "Tổng thu nhập từ tất cả các giao dịch bán vé",
  },

  MESSAGE: {
    INVALID_EMAIL: "Email không hợp lệ",
    LOGGING_IN: "Đang đăng nhập...",
    VERIFYING_OTP: "Đang kiểm tra OTP...",
    WELCOME_BACK: "Chào mừng %s",
    SUSPENDED_ACCOUNT:
      "Tài khoản của bạn đã bị hoá, hãy liên hệ Admin để biết thêm thông tin",
    LOGIN_FAILED: "Đăng nhập thất bại",
    NAME_VALIDATION: "Tên phải nhiều hơn %s ký tự",
    INVALID_PHONE: "Số điện thoại không hợp lệ",
    REGISTERING: "Đang tạo tài khoản",
    WELCOME_TO: "Chào mừng đến với DaSkillz, %s",
    RESEND_OTP: "Đang gửi OTP",
    PROFILE_NO_CHANGE: "Thông tin không có sự thay đổi",
    UPDATED_PROFILE_SUCCESS: "Cập nhật thông tin thành công",
    START_TIME_VALIDATION:
      "Thời gian bắt đầu phải cách hiện tại ít nhất %s tiếng",
    END_TIME_VALIDATION:
      "Thời gian kết thúc phải là tương lai và cách thời gian bắt đầu ít nhất %s phút",
    PREVENT_TURN_ON_APPROVAL: "Sự kiện bán vé không thể bật phê duyệt",
    NEW_EVENT_NAME_ERR: "Tên sự kiện phải từ %s đến %z ký tự",
    NEW_EVENT_DESCRIPTION_ERR: "Mô tả sự kiện phải từ %s đến %z ký tự",
    NEW_EVENT_LOCATION_ERR: "Bạn chưa thiết lập địa điểm tổ chức",
    NEW_EVENT_IMAGE_ERR: "Bạn chưa tải lên hình ảnh sự kiện",
    NEW_EVENT_START_TIME_ATLEAST:
      "Thời gian bắt đầu phải cách hiện tại ít nhất %s tiếng",
    CREATING_EVENT: "Đang tạo sự kiện...",
    CREATE_EVENT_FAIL: "Tạo sự kiện thất bại",
    CREATE_EVENT_SUCCESS: "Tạo sự kiện thành công",
    CREATE_EVENT_NEED_TO_SIGNIN: "Bạn cần đăng nhập để tạo sự kiện",
    TAG_REACHED_LIMIT_SELECT_ERR:
      "Bạn chỉ có thể chọn tối đa %s tags, Bỏ chọn để chọn tag mới",
    TAG_REACHED_LIMIT_ADD_ERR:
      "Bạn chỉ có thể chọn tối đa %s tags, Bỏ chọn để thêm tag mới",
    MAXIMUM_TAG_CHARACTERS_REACHED_ERR: "Giới hạn tối đa %s tags",
    FAILED_ADD_TAG: "Thêm tag mới thất bại",
    TICKET_PRICE_ERR: "Giá vé phải lớn hơn %s VND",
    FEEDBACK_FAILED: "Đánh giá thất bại",
    INVALID_CHECKIN_QR: "Checkin QR không hợp lệ",
    STOP_SCAN_TO_CLOSE: "Hãy ngưng scan để đóng",
    CHECKEDIN_FAIL: "Checkin thất bại",
    CHECKEDIN_SUCCESS: "Checkin thành công",
    SUBMIT_SPONSOR_REQ_SUCCESS: "Gửi yêu cầu tài trợ thành công",
    SUBMIT_SPONSOR_REQ_FAIL: "Gửi yêu cầu tài trợ thất bại",
    FAILED_TO_CANCEL: "Huỷ thất bại",
    CANCEL_SPOSOR_REQUEST_SUCCESS: "Đã huỷ yêu cầu tài trợ của bạn",
    REGISTERED_SUCCESSFULLY: "Bạn đã đăng ký thành công",
    WAIT_FOR_APPROVE: "vui lòng chờ ban tổ chức phê duyệt",
    PREPARING_EVENT: "Đang tải sự kiện...",
    EVENT_NOT_FOUND: "Sự kiện không tồn tại",
    CAN_NOT_EDIT_EVENT: "Bạn không thể cập nhật sự kiện này nữa",
    START_TIME_FROM_CREATED_TIME:
      "Thời gian bắt đầu mới phải cách thời gian tạo ít nhất %s tiếng",
    UPDATING_EVENT: "Đang cập nhật...",
    UPDATE_EVENT_FAIL: "Cập nhật thất bại",
    UPDATE_EVENT_SUCCESS: "Cập nhật thành công",
    ADDED_SUCCESS: "Thêm thành công",
    FAILED_UPDATE_PARTIC_STATUS: "Cập nhật trạng thái thất bại",
    UPDATING_PARTIC_STATUS: "Đang cập nhật trạng thái...",
    DELETE_SUCCESS: "Đã xoá thành công",
    ASK_REMOVE_STAFF: "Bạn có muốn xoá người này?",
    UPDATED_STATUS_SUCCESS: "Cập nhật trạng thái thành công",
    ACTION_PERFORMED_SUCCESS: "Thực thi thành công",
    PAYMENT_FAIL: "Tạo đơn hàng thất bại",
    PAYMENT_SUCCESS: "Đã tạo đơn hàng thành công",
    SPONSOR_CANCEL_CONFIRM: "Bạn có chắc chắn muốn huỷ yêu cầu này không?",
    ALREADY_HAVE_PROCESSING_TICKET_ORDER:
      "Bạn đang có 1 giao dịch mua vé của sự kiện này, vui lòng hoàn tất.",
    NO_INTERNET: "Không có kết nối mạng",
    INTERNAL_SERVER_ERROR: "Lỗi máy chủ",
    SESSION_TIMEOUT: "Phiên đăng nhập đã hết hạn",
    ORGANIZER_UPDATE_REQUEST: "Ban tổ chức đã %s yêu cầu này vào lúc %z",
    DELETE_EVENT_FAIL: "Xoá sự kiện thất bại",
    DESCRIPTION_VALIDATION: "Mô tả phải nhiều hơn %s ký tự",
    FAILED_TO_CREATE_QUIZ: "Tạo quiz thất bại",
    SUCCESS_TO_CREATE_QUIZ: "Tạo quiz thành công",
    FAILED_TO_UPDATE_QUIZ: "Cập nhật quiz thất bại",
    SUCCESS_TO_UPDATE_QUIZ: "Cập nhật quiz thành công",
    CONFIRM_DELETE_QUIZ: "Bạn có chắc chắn muốn xoá quiz này?",
    DELETE_QUIZ_FAILED: "Xoá quiz thất bại",
    DELETE_QUIZ_SUCCESS: "Xoá quiz thành công",
    SOME_MULTIPLE_CHOICE_ERR: "Một số câu trắc nghiệm không có đáp án nào",
    QUESTION_LOW_CHARS: "Một số câu có câu hỏi ngắn hơn %s ký tự",
    SAVE_QUESTION_SUCCESS: "Lưu thành công",
    SAVE_QUESTION_FAIL: "Lưu thất bại",
    ASK_DISCARD: "Bạn có chắc muốn huỷ toàn bộ thay đổi?",
    ASK_DELETE: "Bạn có chắc muốn xoá không?",
    LOGIN_REQUIRED: "Bạn cần đăng nhập để thực hiện hành động này",
    MULTIPLE_CHOICE_NO_CORRECT_ANS: "Câu hỏi trắc nghiệm phải có ít nhất 1 đáp án đúng",
    SUBMIT_SUCCESS: "Gửi thành công",
    NOT_FINISH_QUESTION: "Bạn chưa hoàn thành câu hỏi %s",
  },
  DAY: [
    "Chủ Nhật",
    "Thứ Hai",
    "Thứ Ba",
    "Thứ Tư",
    "Thứ Năm",
    "Thứ Sáu",
    "Thứ Bảy",
  ],
};
