import { FaArrowUpRightFromSquare, FaCalendarDays, FaCheckToSlot, FaEarthAsia, FaLocationDot, FaPen, FaRegCalendar, FaStar, FaTicket, FaUsb, FaUserCheck } from "react-icons/fa6"
import { avgColor } from "../../../Services/Utils/ThemeUtils"
import { IFeedbacks, IResponseGetAll } from "../../../Shared/Model/EventsInterface"
import "./Overview.scss"
import { eventPreviewDateFormat } from "../../../Services/Utils/TimeUtils"
import Progressbar from "../../../Components/Progressbar/Progressbar"
import { useEffect, useState } from "react"
import LoadingDots from "../../../Components/LoadingDots/LoadingDots"
import { useNavigate } from "react-router-dom"
import { TableColumn, TableSponsorRequestColumn } from "./TableColumns"
import Table from "../../../Components/Table/Table"
import { DarkTheme } from "../../../Shared/Constants/Theme/DarkTheme"
import Button from "../../../Components/Button/Button"
import { Modal } from "antd"
import UsersSponsorRequestPreview from "./UsersSponsorRequestPreview"
import DeleteEventConfirm from "./DeleteEventConfirm"
import { useLanguage } from "../../../Services/Hooks/useLanguage"

type Props = {
  currEvent: IResponseGetAll | undefined,
  feedbacks: IFeedbacks[] | null,
  checkinList: any,
  onInitCheckinList: () => void,
  setCurrNav: (nav: string) => void,
  sponsorRequests: any,
  onInitSponsorRequests: () => void,
}

const Overview = ({ setCurrNav, currEvent, feedbacks, checkinList, onInitCheckinList, sponsorRequests, onInitSponsorRequests }: Props) => {
  const { onTranslate } = useLanguage();
  const navigate = useNavigate();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
  const [currViewRequest, setCurrViewRequest] = useState<any>(null);
  const [feedbackGroups, setFeedbackGroups] = useState<any[]>(Array(5).fill([]));
  const [avgFback, setAvgFback] = useState<string>('-');
  const [columns, setColumns] = useState<any[]>(() => TableColumn(currEvent?.eventId as string, onInitCheckinList));
  const [sponsorReqsCol, setSponsorReqsCol] = useState<any[]>(() => TableSponsorRequestColumn(currEvent?.eventId as string, setCurrViewRequest));

  useEffect(() => {
    onFilterFeedbackGroups();
  }, [feedbacks]);

  const getBoxStyle = (): {} => {
    if (!currEvent) return {}
    return { background: avgColor(currEvent.theme, '#3c3c3d') }
  }

  const onFilterFeedbackGroups = () => {
    const fbackGroups: any = Array.from({ length: 5 }, () => []);
    let sumRating = 0;

    if (!feedbacks) return;

    feedbacks.forEach(feedback => {
      const groupIndex = feedback.rating - 1;
      sumRating += feedback.rating;
      fbackGroups[groupIndex].push(feedback);
    });

    setAvgFback(feedbacks?.length === 0 ? '-' : (sumRating / feedbacks?.length).toFixed(1).replace('.', ','));
    setFeedbackGroups(fbackGroups)
  }

  return (
    <div className="overview">

      <Modal open={Boolean(currViewRequest)} footer={null} onCancel={() => setCurrViewRequest(null)}>
        <UsersSponsorRequestPreview setCurrViewRequest={setCurrViewRequest} currViewRequest={currViewRequest} onInitSponsorRequests={onInitSponsorRequests} />
      </Modal>

      <Modal open={showConfirmDeleteModal} footer={null} onCancel={() => setShowConfirmDeleteModal(false)}>
        <DeleteEventConfirm currEvent={currEvent} />
      </Modal>

      <div className="title">{onTranslate('LABEL.EVENT_OVERVIEW')}</div>
      <div className="subtitle">{onTranslate('LABEL.EVENT_OVERVIEW_DESCRIPTION')}</div>

      <div className="statisticCtn">

        <div className="box" style={getBoxStyle()}>
          <div className="boxTitle">
            <label>{onTranslate('LABEL.EVENT_SUMMARY')}</label>
            <FaPen className="icon" onClick={() => navigate(`/events/${currEvent?.eventId}/update`)} />
          </div>
          <ul className="dataList">
            <li><FaCalendarDays className="icon" /> {currEvent && `${eventPreviewDateFormat(currEvent?.startDate)} - ${eventPreviewDateFormat(currEvent?.endDate)}`}</li>
            <li><FaLocationDot className="icon" /> {currEvent?.location.name}</li>
            <li><FaTicket className="icon" /> {currEvent?.fare === 0 ? onTranslate('LABEL.FREE') : `${currEvent?.fare?.toLocaleString()} VND`}</li>
            <li><FaUserCheck className="icon" /> {currEvent?.approval ? onTranslate('LABEL.NEED_TO_BE_APPROVE') : onTranslate('LABEL.DO_NOT_NEED_TO_BE_APPROVE')}</li>
            <li><FaEarthAsia className="icon" /> {currEvent?.capacity === -1 ? onTranslate('LABEL.UNLIMITED') : currEvent?.capacity}</li>
            <li><FaUsb className="icon" /> {currEvent?.status.toLocaleUpperCase()}</li>
          </ul>
        </div>

        <div className="box" style={getBoxStyle()}>
          <div className="boxTitle">
            <label>{onTranslate('BUTTON.FEEDBACK')}</label>
            <FaArrowUpRightFromSquare className="icon" onClick={() => setCurrNav('Feedbacks')} />
          </div>

          <div className="FeedbackCtn">
            {
              !feedbacks
                ?
                <span style={{ padding: '10px' }}>
                  <LoadingDots />
                </span>
                :
                <div className="feedbackOverall">
                  <div className="average">{avgFback}</div>
                  <div className="unit">{feedbacks ? feedbacks?.length : 0} {onTranslate('BUTTON.FEEDBACK')}</div>
                </div>
            }

            {
              currEvent &&
              <div className="fbackProgressCtn">
                {
                  feedbackGroups?.map((data, i) => (
                    <div className="fbackProgress" key={i}>
                      <label>{i + 1}<FaStar className="icon" /></label>
                      <Progressbar current={feedbackGroups[i]?.length} max={feedbacks ? feedbacks?.length : 0} progressColor={currEvent?.theme} backgroundColor={avgColor(currEvent?.theme, "#212325")} />
                    </div>
                  ))
                }
              </div>
            }
          </div>
        </div>
      </div>

      <div className="divider" style={{ margin: '0 0 25px 0' }}></div>

      <div className="title">{onTranslate('LABEL.SPONSOR_REQUEST')} ({checkinList[2]?.length})</div>
      <div className="subtitle">{onTranslate('LABEL.SPONSOR_REQUEST_DESCRIPTION')}</div>
      <Table columns={sponsorReqsCol} data={sponsorRequests} color={currEvent?.theme} />

      <div className="divider" style={{ margin: '25px 0 0 0' }}></div>

      <div className="participants">
        <div className="title">{onTranslate('LABEL.PENDING_REQUEST')} ({checkinList[2]?.length})</div>
        <div className="subtitle">{onTranslate('LABEL.PENDING_REQUEST_DESCRIPTION')}</div>
        <Table columns={columns} data={checkinList[2]} color={currEvent?.theme} />
      </div>

      <div className="divider"></div>

      <div className="participants">
        <div className="headerCtn">
          <div className="info">
            <div className="title">{onTranslate('LABEL.USERS_STATISTIC')}</div>
            <div className="subtitle">{onTranslate('LABEL.USERS_STATISTIC_DESCRIPTION')}</div>
          </div>
          <Button className="addComitteeBtn" onClick={() => setCurrNav("Users")}>{onTranslate('BUTTON.VIEW')}</Button>
        </div>

        <div className="title" style={{ margin: '12px 0 0 0' }}>{onTranslate('LABEL.PARTICIPANTS')} ({checkinList[4]?.length + checkinList[5]?.length})</div>
        <div className="progressLine" style={{ background: avgColor(`${currEvent?.theme}`, DarkTheme["--primary-bg-color"]) }}>
          <div className="checkedIn" style={{ width: `${(checkinList[4]?.length / (checkinList[4]?.length + checkinList[5]?.length + 0.1)) * 100}%`, height: '100%', background: currEvent?.theme }}></div>
        </div>
        <div className="progressSub">
          <div className="sub">
            <div className="color" style={{ background: currEvent?.theme }}></div>
            <label>{onTranslate('BUTTON.CHECKEDIN')}</label>
          </div>
          <div className="sub">
            <div className="color" style={{ background: avgColor(`${currEvent?.theme}`, DarkTheme["--primary-bg-color"]) }}></div>
            <label>{onTranslate('LABEL.NOT_CHECKIN')}</label>
          </div>
        </div>

        <div className="title" style={{ margin: '12px 0 0 0' }}>{onTranslate('LABEL.OTHERS')}</div>
        <div className="statisticCtn">
          <div className="box" style={getBoxStyle()}>
            <div className="boxTitle">
              <label>{onTranslate('LABEL.ORGANIZING_COMMITTEE')}</label>
            </div>
            <div className="boxContent">{checkinList[0]?.length}</div>
          </div>

          <div className="box" style={getBoxStyle()}>
            <div className="boxTitle">
              <label>{onTranslate('LABEL.SPONSOR')}</label>
            </div>
            <div className="boxContent">{checkinList[1]?.length}</div>
          </div>
        </div>

        <div className="divider"></div>

        <div className="title" style={{ margin: '12px 0 0 0' }}>{onTranslate('LABEL.VISIBLE')}</div>
        <div className="subtitle">{onTranslate('LABEL.VISIBLE_WARNING', 6)}</div>

        <Button className="deleteEventBtn" type="primary" onClick={() => setShowConfirmDeleteModal(true)}>{onTranslate('BUTTON.DELETE_EVENT')}</Button>
        <div className="subtitle deleteSub">{onTranslate('LABEL.ACTION_CANNOT_UNDO')}</div>

      </div>
    </div>
  )
}

export default Overview