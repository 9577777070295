import { DatePicker, TimePicker as AntdTimePicker } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import React from 'react'

type Props = {
    value: Dayjs;
    onChange: (newValue: Dayjs) => void;
}

const TimePicker = ({ value, onChange }: Props) => {

    const onDateChange = (e: Dayjs) => {
        const dayjsVal = dayjs(value);
        
        const newDate = e.date();
        const newMonth = e.month();
        const newYear= e.year();

        const newDayjsVal = dayjsVal.date(newDate).month(newMonth).year(newYear);

        onChange(newDayjsVal)
    }

    const onTimeChange = (e: Dayjs) => {
        onChange(e)
    }

    return (
        <div className='timePicker'>
            <DatePicker className="originDate" format="ddd, DD/MM/YYYY" value={value} onChange={onDateChange} />
            <AntdTimePicker format="HH:mm" value={value} onChange={onTimeChange} />
        </div>
    )
}

export default TimePicker