export enum StatusCodeEnum {
    UNAUTHORIZED = 403
}

export enum ApiId {
    // Account
    SIGNIN_WITH_GOOGLE,
    SIGNIN_OTP,
    REGISTER_OTP,
    SIGNOUT,
    VALIDATE_OTP,
    REFRESH_TOKEN,
    GET_USER_BY_KEYWORD,

    // Tags
    GET_ALL_TAGS,
    GET_TAGS_BY_KEYWORD,
    ADD_NEW_TAG,

    // Events
    VIEW_EVENT,
    CREATE_EVENT,
    UPDATE_EVENT,
    DELETE_EVENT,
    GET_ALL_EVENTS,
    GET_EVENT_DETAILS,
    REGISTER_EVENT,
    GET_USER_INFO_IN_EVENT,
    PROCESS_TICKET,
    MY_EVENT,
    ADD_USER_TO_EVENT,
    REMOVE_PARTICIPANT,

    // Checkin
    GET_CHECKIN_LIST,

    // Feedbacks
    GET_FEEDBACKS,
    SEND_FEEDBACK,
    UPDATE_FEEDBACK,
    GET_MY_FEEDBACK,

    // Organizers
    GET_POPULAR_ORGANIZERS,

    // Places & Map
    GET_PLACE,
    GET_PLACE_DETAILS,
    GET_POPULAR_LOCATIONS,

    // Sponsor
    CREATE_SPONSOR_REQUEST,
    GET_MY_SPONSOR_REQUEST,
    CANCEL_MY_SPONSOR_REQUEST,
    GET_ALL_EVENT_SPONSOR_REQUESTS,
    UPDATE_SPONSOR_REQUEST_STATUS,

    // Payment
    CREATE_PAYMENT_PAYPAL,
    PAYMENT_PAYOUT,

    // Admin
    GET_TOTAL_USERS,
    GET_MONTHLY_USERS,
    GET_TOTAL_EVENTS,
    GET_MONTHLY_EVENTS,
    GET_ALL_USERS
}