import React, { useEffect, useState } from 'react'
import { avgColor } from '../../../Services/Utils/ThemeUtils';
import { IResponseGetAll } from '../../../Shared/Model/EventsInterface';
import { DarkTheme } from '../../../Shared/Constants/Theme/DarkTheme';
import { FaStar } from 'react-icons/fa6';
import { useLanguage } from '../../../Services/Hooks/useLanguage';

type Props = {
    fback: any;
    currEvent: IResponseGetAll | undefined
}
// const test = 'amazing abcasdasdasdasdasda1 23123123123sdasd asdasdasdasdasdas asdasdas dasdasdas asdasdas dasdasdas asdasda sdasdasdas sdasdasdas  sdasdasdas sdasdasdasasdasdas'
const FeedbackTemplate = ({ fback, currEvent }: Props) => {
    const { onTranslate } = useLanguage();
    const maxLength = 120;

    const [fbackContent, setFbackContent] = useState('');
    const [isShowFull, setIsShowFull] = useState(false);

    useEffect(() => {
        onInitFbackContent();
    }, [fback.content])

    const onInitFbackContent = () => {
        if (fback.content.length > maxLength) {
            setFbackContent(`${fback.content.substring(0, maxLength)}...`);
            setIsShowFull(false);
            return;
        }

        setFbackContent(fback.content)
    }
    return (
        <div className='Fback' style={{ background: avgColor(`${currEvent?.theme}`, DarkTheme["--primary-bg-color"]), border: `1px solid ${avgColor(`${currEvent?.theme}`, DarkTheme["--tertiary-bg-color"])}` }}>
            <div className="fbackUser">
                <img src={fback.avatar} />
                <div className="name">{fback.name}</div>
                <div className="fbackStarsEmCtn">
                    {
                        Array(5).fill('').map((val, i) => (
                            <FaStar className="star" style={i < fback.rating ? {} : { color: avgColor(`${currEvent?.theme}`, '#535557') }} key={i} />
                        ))
                    }
                </div>
            </div>
            <div className="fbackContent">{isShowFull ? fback.content : fbackContent} {fback.content.length > maxLength && <b onClick={() => setIsShowFull(!isShowFull)} style={{ cursor: 'pointer', marginLeft: '5px' }}>{isShowFull ? onTranslate('BUTTON.VIEW_LESS') : onTranslate('BUTTON.VIEW_MORE')}</b>}</div>
        </div>
    )
}

export default FeedbackTemplate