import { Col, Row } from "antd";
import "./Footer.scss";
import { AppConfigurations } from "../../Shared/Constants/AppConfigurations";
import Button from "../Button/Button";
import { FaEnvelope, FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa6";
import WebStarIcon from "../WebLogo/WebStarIcon";
import { useLanguage } from "../../Services/Hooks/useLanguage";

type Props = {}

const Footer = (props: Props) => {
    const { onTranslate } = useLanguage();
    return (
        <Row className="Footer">
            <Col span={AppConfigurations.blankCol.span} sm={AppConfigurations.blankCol.sm}></Col>
            <Col span={AppConfigurations.contentCol.span} sm={AppConfigurations.contentCol.sm}>
                <div className="content">
                    <ul className="btnItems">
                        <li>
                            <WebStarIcon width={35} height={35} />
                        </li>
                        <li><Button type='link' onLinkTo="/about">{onTranslate("LABEL.ABOUT")}</Button></li>
                        <li><Button type='link' onLinkTo="/policy">{onTranslate("LABEL.POLICY")}</Button></li>
                        <li><Button type='link' onLinkTo="/pricing">{onTranslate("LABEL.PRICING")}</Button></li>
                        <li><Button type='link' onLinkTo="/help">{onTranslate("LABEL.HELP")}</Button></li>
                    </ul>
                    <ul className="btnItems">
                        {/* <li><Button type='link'><FaTwitter /></Button></li> */}
                        <li><Button target="blank" type='link' onLinkTo="https://www.facebook.com/profile.php?id=61566457945682"><FaFacebook /></Button></li>
                        <li><Button target="blank" type='link' onLinkTo="https://www.instagram.com/da.skillz/"><FaInstagram /></Button></li>
                        <li><Button type='link' onLinkTo="mailto:daskillz.atwork@gmail.com"><FaEnvelope /></Button></li>
                    </ul>
                </div>
                <div className="copyright">© {onTranslate("LABEL.COPYRIGHT")}</div>
            </Col>
            <Col span={AppConfigurations.blankCol.span} sm={AppConfigurations.blankCol.sm}></Col>
        </Row>
    )
}

export default Footer