import React, { useState } from 'react'
import { IResponseGetAll } from '../../../Shared/Model/EventsInterface'
import dayjs, { Dayjs } from 'dayjs';
import { compareDayjs } from '../../../Services/Utils/TimeUtils';
import Button from '../../../Components/Button/Button';
import { APIsAuthorizeUsers } from '../../../Services/APIs/APIsController';
import { ApiId } from '../../../Shared/Enums/APIsEnum';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import LoadingDots from '../../../Components/LoadingDots/LoadingDots';
import { useLanguage } from '../../../Services/Hooks/useLanguage';

type Props = {
    currEvent: IResponseGetAll | undefined,
}

const DeleteEventConfirm = ({ currEvent }: Props) => {
    const { onTranslate } = useLanguage();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [keyword, setKeyword] = useState<string | undefined>(currEvent?.host.email.split('@')[0]);
    const [userInput, setUserInput] = useState<string>('');

    const compareTime = () => {
        if (!currEvent) return;

        const now = dayjs();
        const startTime = dayjs(dayjs(currEvent.startDate));

        const compareResult = compareDayjs(startTime, now, { number: 6, unit: 'hour' });
        return compareResult;
    }

    const onConfirmDelete = async () => {
        if (!currEvent) return;

        setIsLoading(true);
        const result = await APIsAuthorizeUsers(ApiId.DELETE_EVENT, currEvent.eventId);
        setIsLoading(false);

        if (!result) return;

        message.success(onTranslate('MESSAGE.ACTION_PERFORMED_SUCCESS'));
        navigate(`/events/${currEvent.eventId}`);
    }

    return (
        <div className='showConfirmDeleteCtn'>
            <div className='title'>{onTranslate('LABEL.DELETE_CONFIRMATION')}</div>
            <div className='subtitle'>
                {
                    compareTime()
                        ? onTranslate('LABEL.DELETE_CAUTION')
                        : onTranslate('LABEL.CANCEL_CAUTION')
                }
            </div>

            {
                isLoading
                    ?
                    <div style={{ margin: '0 0 25px 0' }}>
                        <LoadingDots />
                    </div>
                    :
                    <>
                        <div className='typeEmailText'>{onTranslate('LABEL.TYPE')} <b>{keyword}</b> {onTranslate('LABEL.TO_CONFIRM')}</div>
                        <input type="text" className='confirmInput' value={userInput} onChange={(e) => setUserInput(e.target.value)} />

                        <Button onClick={onConfirmDelete} className="deleteEventBtn" style={{ width: '95%', margin: '30px 0 0 0' }} type="primary" disabled={userInput !== keyword}>{compareTime() ? onTranslate('BUTTON.DELETE') : onTranslate('BUTTON.CANCEL')} {onTranslate('LABEL.THIS_EVENT')}</Button>
                        <div className="subtitle deleteSub" style={{ textAlign: 'center' }}>{onTranslate('LABEL.ACTION_CANNOT_UNDO')}</div>
                    </>
            }
        </div>
    )
}

export default DeleteEventConfirm