import React, { useEffect, useState } from 'react'
import Button from '../../Components/Button/Button'
import { getTextColorBaseOnBackground } from '../../Services/Utils/ThemeUtils'
import { IResponseGetAll } from '../../Shared/Model/EventsInterface'
import { useUser } from '../../Services/Hooks/useUser'
import { APIsAuthorizeUsers } from '../../Services/APIs/APIsController'
import { ApiId } from '../../Shared/Enums/APIsEnum'
import { EventRole, EventStatus, ParticipantEventStatusEnum } from '../../Shared/Enums/EventsEnum'
import { message } from 'antd'
import { IParticipantDataInEvent } from '../../Shared/Model/UserInterface'
import LoadingDots from '../../Components/LoadingDots/LoadingDots'
import Feedback from './Feedback'
import SupportSponsorText from './SupportSponsorText'
import { onFilterPaymentTransactions, onFindPaypalData, onFindSponsorData, onFinishPaypalSponsorPayment, onFinishPaypalTicketPayment, onProcessPaypalTicketPayment, onZaloCreateTransaction } from '../../Services/APIs/PaymentAPIs'
import { useLoading } from '../../Services/Hooks/useLoading'
import { useLanguage } from '../../Services/Hooks/useLanguage'
import { IZaloPaymentCreateOrder } from '../../Shared/Model/ZaloPayment'
import { PaymentStatus, PaymentSubscriptionType } from '../../Shared/Enums/PaymentEnum'
import QuizList from './QuizList'
import { IQuiz } from '../../Shared/Model/QuizInterface'
import { onFilterCertificate } from '../../Services/APIs/CertificateAPIs'
import { useNavigate } from 'react-router-dom'
import { formatDateTime, formatTime } from '../../Services/Utils/TimeUtils'

type Props = {
    currEvent: IResponseGetAll,
    onViewCheckinCode: (value: IParticipantDataInEvent) => void,
    setisShowSponsorModal: (val: boolean) => void;
    showCheckinQrParam: boolean
    setShowSponsorModal: (val: boolean) => void;
    setIsCheckingIn: (val: boolean) => void;
    setIsViewQuiz: (val: boolean) => void;
    quizzesList: IQuiz[];
}

const ViewEventAction = ({ quizzesList, setIsViewQuiz, setIsCheckingIn, currEvent, onViewCheckinCode, setisShowSponsorModal, showCheckinQrParam, setShowSponsorModal }: Props) => {
    const navigate = useNavigate();
    const { onTranslate } = useLanguage();
    const { user } = useUser();
    const [currUserData, setCurrUserData] = useState<IParticipantDataInEvent | null>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCertificateLoading, setIsCertificateLoading] = useState<boolean>(false);
    const [loadingCheckImcompleteOrder, setLoadingCheckImcompleteOrder] = useState<boolean>(false);
    const [inCompleteOrder, setImCompleteOrder] = useState<any>(null);
    const [certificate, setCertificate] = useState<any>(null);

    useEffect(() => {
        onGetCurrUserInEvent();
        onCheckUserHaveInCompletePayment();
        onCheckUserSponsor();
        onInitCertificate();
    }, [user]);


    const onCheckUserHaveInCompletePayment = async () => {
        if (currEvent.fare === 0 || !user) return;

        setLoadingCheckImcompleteOrder(true);
        const result = await onFilterPaymentTransactions(currEvent.eventId, user?.userId, PaymentStatus.PROCESSING, PaymentSubscriptionType.TICKET);
        if (result && result.length > 0) setImCompleteOrder(result[0]);
        setLoadingCheckImcompleteOrder(false);
    }

    const onCheckUserSponsor = async () => {
        if (!user) return;
        // const paypalData = onFindSponsorData();
        // if (!paypalData || paypalData.savedData.userId !== user?.userId) return;

        // const result = await onFinishPaypalSponsorPayment(paypalData, updateLoading);

        // if (result) return message.success('Deposit money to event successfully');
    }

    const onRegisterEvent = async () => {
        if (!user || !currEvent) return;

        // Event have payment
        if (Number(currEvent.fare) > 0) {
            setIsLoading(true);

            // const redirectUrl = ;

            const zaloPaymentData: IZaloPaymentCreateOrder = {
                userId: user.userId,
                description: `Ticket payment: ${user.fullName} pay ${currEvent.eventName} - `,
                amount: `${currEvent.fare}`,
                eventId: currEvent.eventId,
                subscriptionType: PaymentSubscriptionType.TICKET,
                redirectUrl: window.location.href
            }
            const result = await onZaloCreateTransaction(zaloPaymentData);

            if (!result) return message.error(onTranslate('MESSAGE.PAYMENT_FAIL'));

            // Create new payment success
            message.success(onTranslate('MESSAGE.PAYMENT_SUCCESS'));
            window.open(result.order_url);
            setIsLoading(false);
            return;
        }

        // Event don't have payment
        onAddParticipantToEvent();
    }

    const onClickOnPaymentBtn = () => {
        window.open(inCompleteOrder.orderUrl);
    }

    const onAddParticipantToEvent = async () => {
        setIsLoading(true);
        const registerStatus = await APIsAuthorizeUsers(ApiId.REGISTER_EVENT, currEvent.eventId);
        setIsLoading(false);
        onGetCurrUserInEvent();
    }

    const onGetCurrUserInEvent = async () => {
        if (!user) return;

        setIsLoading(true);
        const result = await APIsAuthorizeUsers(ApiId.GET_USER_INFO_IN_EVENT, currEvent.eventId);
        setIsLoading(false);
        if (!result) return;
        const data = result.data;
        if ([ParticipantEventStatusEnum.CONFIRMED].includes(data.status) && showCheckinQrParam) onViewCheckinCode(data);
        setCurrUserData(data);
    }

    const onInitCertificate = async () => {
        if (!user || !currEvent) return;

        setIsCertificateLoading(true);
        const result = await onFilterCertificate({ eventId: currEvent.eventId, userId: user?.userId });
        setIsCertificateLoading(false);

        if(result[0]?.certificate) setCertificate(result[0].certificate);
        else setCertificate(null);
    }

    if (isLoading || isCertificateLoading) return (
        <LoadingDots />
    )

    if (certificate) return (
        <div className="register">
            <div className="registerTitle">{onTranslate('LABEL.CERTIFICATE')}</div>
            <div className="registerSub">{onTranslate('LABEL.CERTIFICATE_RECEIVED', `${formatTime(certificate.issueDate)} ${formatDateTime(certificate.issueDate)}`)}</div>
            <Button type="primary" className="regBtn" style={{ color: currEvent ? getTextColorBaseOnBackground(currEvent.theme) : 'black' }} onClick={() => navigate(`/c/${certificate.certicateId}`)}>{onTranslate('BUTTON.VIEW_CERTIFICATE')}</Button>
        </div>
    )

    if ([EventStatus.ABORTED].includes(currEvent?.status as any))
        return (
            <div className="register">
                <div className="registerTitle">{onTranslate('LABEL.ABORTED')}</div>
                <div className="registerSub">{onTranslate('LABEL.THIS_EVENT_HAS_BEEN')} <b>{onTranslate('LABEL.ABORTED')}</b> {onTranslate('LABEL.BECAUSE_OF_VIOLATION')}</div>
            </div>
        )

    if ([EventStatus.CANCEL].includes(currEvent?.status as any))
        return (
            <div className="register">
                <div className="registerTitle">{onTranslate('LABEL.CANCEL')}</div>
                <div className="registerSub">{onTranslate('LABEL.THIS_EVENT_HAS_BEEN')} <b>{onTranslate('LABEL.CANCEL')}</b> {onTranslate('LABEL.BY_EVENT_ORG')}</div>
            </div>
        )


    if (![EventStatus.ENDED, EventStatus.ON_GOING].includes(currEvent?.status as any) && ([EventRole.CHECKING_STAFF, EventRole.EVENT_OPERATOR].includes(currUserData?.roleEventId as EventRole)) || user?.userId === currEvent.host.id)
        return (
            <div className="register">
                <div className="registerTitle">{onTranslate('BUTTON.CHECKIN')}</div>
                <div className="registerSub">Click <b>{onTranslate('BUTTON.CHECKIN')}</b> {onTranslate('LABEL.TO_START_CHECKIN')}</div>
                <Button type="primary" className="regBtn" style={{ color: currEvent ? getTextColorBaseOnBackground(currEvent.theme) : 'black' }} onClick={() => setIsCheckingIn(true)}>{onTranslate('BUTTON.CHECKIN')}</Button>
            </div>
        )


    if (currUserData?.checkedIn) return (
        <div className="register">
            <div className="registerTitle">{onTranslate('BUTTON.FEEDBACK')}</div>
            <div className="registerSub">{onTranslate('LABEL.YOU_HAVE')} <b>{onTranslate('MESSAGE.CHECKEDIN_SUCCESS')}</b>, {onTranslate('LABEL.CLICK_FEEDBACK')}</div>
            <Feedback currEvent={currEvent} />
        </div>
    )

    if ([EventStatus.ENDED, EventStatus.ON_GOING, EventStatus.ABORTED].includes(currEvent?.status as any)) return (
        <div className="register">
            <div className="registerTitle">{currEvent?.status}</div>
            <div className="registerSub">{onTranslate('LABEL.THANKS_FOR_VIEWING')} {onTranslate('LABEL.THIS_EVENT_HAS_BEEN')} <b>{currEvent?.status}</b></div>
            <Button type="primary" className="regBtn" style={{ color: currEvent ? getTextColorBaseOnBackground(currEvent.theme) : 'black' }} disabled>{currEvent?.status}</Button>
        </div>
    )

    // Sponsor
    if ([EventRole.SPONSOR].includes(currUserData?.roleEventId as EventRole))
        return (
            <div className="register">
                <div className="registerTitle">{onTranslate('LABEL.SPONSOR')}</div>
                <div className="registerSub">Click <b>{onTranslate('LABEL.SPONSOR')}</b> {onTranslate('LABEL.CLICK_TO_DEPOSIT_MONEY')}</div>
                <Button type="primary" className="regBtn" style={{ color: currEvent ? getTextColorBaseOnBackground(currEvent.theme) : 'black' }} onClick={() => setShowSponsorModal(true)}>{onTranslate('LABEL.SPONSOR')}</Button>
                <div className="registerSub" style={{ margin: '2px 0 0 5px', cursor: 'pointer' }} onClick={() => setisShowSponsorModal(true)}><b>{onTranslate('LABEL.CLICK_HERE')}</b> {onTranslate('LABEL.TO_VIEW_SPONSOR_REQUEST')}</div>
            </div>
        )

    switch (currUserData?.status) {
        case ParticipantEventStatusEnum.PENDING:
            return (
                <div className="register">
                    <SupportSponsorText setisShowSponsorModal={setisShowSponsorModal} />

                    <div className="registerTitle">{onTranslate('LABEL.REGISTER')}</div>
                    <div className="registerSub">{onTranslate('MESSAGE.REGISTERED_SUCCESSFULLY')}, <b>{onTranslate('MESSAGE.WAIT_FOR_APPROVE')}</b></div>
                    <Button type="primary" className="regBtn pending" style={{ color: currEvent ? getTextColorBaseOnBackground(currEvent.theme) : 'black' }} disabled>{onTranslate('LABEL.PENDING')}
                        <ul className='waitingAnim'>
                            <li>.</li>
                            <li>.</li>
                            <li>.</li>
                        </ul>
                    </Button>
                </div>
            )
        case ParticipantEventStatusEnum.CONFIRMED:
            return (
                <div className="register">
                    {/* <SupportSponsorText setisShowSponsorModal={setisShowSponsorModal} /> */}

                    {
                        quizzesList?.length > 0
                        &&
                        <>
                            <div className="registerTitle">{onTranslate('LABEL.QUIZ')}</div>
                            <div className="registerSub">{onTranslate('LABEL.QUIZ_DESCRIPTION_USER', quizzesList?.length)}</div>
                            <Button type="primary" className="regBtn" style={{ color: currEvent ? getTextColorBaseOnBackground(currEvent.theme) : 'black' }} onClick={() => setIsViewQuiz(true)}>{onTranslate('BUTTON.VIEW_QUIZ')}</Button>
                        </>
                    }

                    <div className="registerTitle" style={{ margin: '20px 0 0 0' }}>{onTranslate('LABEL.WELCOME')}, {user?.fullName}</div>
                    <div className="registerSub">{onTranslate('LABEL.APART_OF_EVENT')} <b>{onTranslate('LABEL.VIEW_CHECKIN_CODE')}</b></div>
                    <Button type="primary" className="regBtn" style={{ color: currEvent ? getTextColorBaseOnBackground(currEvent.theme) : 'black' }} onClick={() => onViewCheckinCode(currUserData)}>QR Checkin</Button>
                </div>
            )
        case ParticipantEventStatusEnum.BLOCKED:
            return (
                <div className="register">
                    <SupportSponsorText setisShowSponsorModal={setisShowSponsorModal} />

                    <div className="registerTitle">{onTranslate('LABEL.REGISTER')}</div>
                    <div className="registerSub"><b>{onTranslate('LABEL.ORGANIZER_REJECTED_REGISTRATION')}</b>, {onTranslate('LABEL.CONTACT_ORG_MISTAKE')}</div>
                    <Button type="primary" className="regBtn" style={{ color: currEvent ? getTextColorBaseOnBackground(currEvent.theme) : 'black' }} disabled>{onTranslate('LABEL.REJECTED')}</Button>
                </div>
            )
        case ParticipantEventStatusEnum.CANCEL:
            return (
                <div className="register">
                    <div className="registerTitle">{onTranslate('LABEL.REGISTER')}</div>
                    <div className="registerSub">{onTranslate('LABEL.THIS_EVENT_HAS_BEEN')} <b>{onTranslate('LABEL.CANCEL')}</b></div>
                    <Button type="primary" className="regBtn" style={{ color: currEvent ? getTextColorBaseOnBackground(currEvent.theme) : 'black' }} disabled>{onTranslate('LABEL.CANCEL')}</Button>
                </div>
            )
        default:
            return (
                <div className="register">
                    <SupportSponsorText setisShowSponsorModal={setisShowSponsorModal} />

                    {
                        loadingCheckImcompleteOrder
                            ?
                            <LoadingDots />
                            :
                            inCompleteOrder
                                ?
                                <>
                                    <div className="registerTitle">{onTranslate('LABEL.TICKET_PAYMENT')}</div>
                                    <div className="registerSub">{onTranslate('LABEL.TICKET_PAYMENT_DESCRIPTION')}</div>
                                    <Button type="primary" className="regBtn" style={{ color: currEvent ? getTextColorBaseOnBackground(currEvent.theme) : 'black' }} onClick={onClickOnPaymentBtn} disabled={!user ? true : false}>{onTranslate('BUTTON.PAYMENT')}</Button>
                                    <div className="currUser">{onTranslate('LABEL.PAYMENT_WAIT')}</div>
                                </>
                                :
                                <>
                                    <div className="registerTitle">{onTranslate('LABEL.REGISTER')}</div>
                                    <div className="registerSub">{onTranslate('LABEL.REGISTER_INS')}</div>
                                    <Button type="primary" className="regBtn" style={{ color: currEvent ? getTextColorBaseOnBackground(currEvent.theme) : 'black' }} onClick={onRegisterEvent} disabled={!user ? true : false}>{onTranslate('LABEL.REGISTER')}</Button>
                                    {
                                        user
                                            ?
                                            <></>
                                            :
                                            <div className="currUser">{onTranslate('LABEL.YOU_NEED_TO')} <b>{onTranslate('BUTTON.SIGNIN')}</b> {onTranslate('LABEL.TO_REGSISTER')}</div>
                                    }
                                </>
                    }

                </div>
            )
    }
}

export default ViewEventAction