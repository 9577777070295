import { Col, Popover, Row } from "antd"
import "./MyTransactions.scss"
import { onGetMyTransactions } from "../../Services/APIs/PaymentAPIs"
import { useEffect, useState } from "react"
import LoadingDots from "../../Components/LoadingDots/LoadingDots"
import { DarkTheme } from "../../Shared/Constants/Theme/DarkTheme"
import Table from "../../Components/Table/Table"
import { useUser } from "../../Services/Hooks/useUser"
import { FaChevronRight } from "react-icons/fa6"
import { formatDateTime, formatTime } from "../../Services/Utils/TimeUtils"
import { useLanguage } from "../../Services/Hooks/useLanguage"
import { PaymentStatus } from "../../Shared/Enums/PaymentEnum"

type Props = {}

const MyTransactions = (props: Props) => {
    const { onTranslate } = useLanguage();
    const { user } = useUser();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [myTransactions, setMyTransactions] = useState<any>();

    useEffect(() => {
        handleGetUserTransactions();
    }, []);

    const myTransactionColumn = [
        {
            title: onTranslate('LABEL.PAYMENTID'),
            key: 'appTransId',
            render: (row: any) => (
                <Popover content={row.appTransId}>
                    <div style={{ maxWidth: '13vw', overflow: 'hidden', textWrap: 'nowrap', textOverflow: 'ellipsis' }}>{row.appTransId}</div>
                </Popover>
            )
        },
        {
            title: onTranslate('LABEL.CREATED_TIME'),
            key: 'timestamp',
            render: (row: any) => (
                <>{formatTime(row.timestamp)} {formatDateTime(row.timestamp)}</>
            )
        },
        {
            title: onTranslate('LABEL.AMOUNT'),
            key: 'amount',
            render: (row: any) => (
                <>{Number(row?.amount).toLocaleString()} VND</>
            )
        },
        {
            title: onTranslate('LABEL.MESSAGE'),
            key: 'description',
            render: (row: any) => (
                <Popover content={row.description}>
                    <div style={{ maxWidth: '13vw', overflow: 'hidden', textWrap: 'nowrap', textOverflow: 'ellipsis' }}>{row.description}</div>
                </Popover>
            )
        },
        {
            title: onTranslate('LABEL.STATUS'),
            key: 'status',
            dataIndex: 'status',
            render: (row: any) => {
                switch (row.status) {
                    case PaymentStatus.FAIL:
                        return onTranslate('LABEL.PAYMENT_FAILED_STATUS');
                    case PaymentStatus.SUCCESS:
                        return onTranslate('LABEL.PAYMENT_SUCCESS_STATUS');;
                    case PaymentStatus.PROCESSING:
                        return onTranslate('LABEL.PAYMENT_PROCESSING_STATUS');;
                }
            }
        },
        {
            // title: onTranslate('LABEL.EVENT'),
            key: 'event',
            render: (row: any) => (
                <>
                    {
                        row.status === PaymentStatus.PROCESSING &&
                        <span style={{ display: 'flex', cursor: 'pointer' }} onClick={() => { window.open(row.orderUrl) }}>
                            <div style={{ maxWidth: '12vw', overflow: 'hidden', textWrap: 'nowrap', textOverflow: 'ellipsis' }}>{onTranslate('LABEL.GO_TO_PAYMENT')}</div>
                            <FaChevronRight style={{ fontSize: '10px', padding: 0, margin: '0 0 0 6px', transform: 'translateY(50%)' }} />
                        </span>
                    }
                </>
            )
        },
    ]

    const handleGetUserTransactions = async () => {
        if (!user) return;

        setIsLoading(true);
        const result = await onGetMyTransactions(user.userId);
        setIsLoading(false);

        if (!result) return;
        setMyTransactions(result.sort((a: any, b: any) => b.timestamp - a.timestamp));
    }

    return (
        <Row className='myTransactionCtn linearBG'>
            <Col span={3}></Col>
            <Col span={18}>
                <div className="title">{onTranslate('LABEL.MY_TRANSACTION')}</div>
                <div className="subtitle">{onTranslate('LABEL.MY_TRANSACTION_DESCRIPTION')}</div>
                {
                    isLoading
                        ?
                        <LoadingDots color={DarkTheme["--brand-color"]} />
                        :
                        <Table color={DarkTheme["--brand-color"]} data={myTransactions} columns={myTransactionColumn} />
                }
            </Col>
            <Col span={3}></Col>
        </Row>
    )
}

export default MyTransactions