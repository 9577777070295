import { avgColor } from "../../Services/Utils/ThemeUtils";
import { DarkTheme } from "../../Shared/Constants/Theme/DarkTheme";
import "./Table.scss";

type Props = {
    columns: any[],
    data: any[],
    color?: string
}

const Table = ({ columns, data, color = 'black' }: Props) => {
    return (
        <>
            {
                !columns
                    ?
                    <></>
                    :
                    <table className='customTable'>
                        <thead style={{ background: avgColor(color, DarkTheme["--quaternary-bg-color"]) }}>
                            <tr>
                                {columns.map((column, index) => (
                                    <th key={index}>{column.title}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody style={{ background: avgColor(color, DarkTheme["--primary-bg-color"]) }}>
                            {data?.map((row, index) => (
                                <tr key={index}>
                                    {columns.map((column, index) => {
                                        return <td key={row.key}>{column.render ? column.render(row) : row[column.dataIndex]}</td>
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
            }
        </>
    )
}

export default Table