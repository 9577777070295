import React from 'react'
import Overview from './Overview/Overview';
import { IResponseGetAll } from '../../Shared/Model/EventsInterface';
import Participants from './Participants/Participants';
import EMFeedback from './EMFeedback/EMFeedback';
import Transactions from './Transactions/Transactions';
import Advertise from './Advertise/Advertise';
import Quiz from './Quiz/Quiz';
import { useUser } from '../../Services/Hooks/useUser';
import CertificateEManage from './Certificate/Certificate';

type Props = {
    currNav: string,
    feedbacks: any[] | null,
    currEvent: IResponseGetAll | undefined,
    checkinList: any,
    onInitCheckinList: () => void;
    setCurrNav: (nav: string) => void;
    sponsorRequests: any;
    onInitSponsorRequests: () => void;
    transactions: any[];
}

const EventManageContent = ({ transactions, onInitSponsorRequests, currNav, currEvent, feedbacks, checkinList, onInitCheckinList, setCurrNav, sponsorRequests }: Props) => {
    switch (currNav) {
        case 'Overview':
            return <Overview currEvent={currEvent} feedbacks={feedbacks} checkinList={checkinList} onInitCheckinList={onInitCheckinList} setCurrNav={setCurrNav} onInitSponsorRequests={onInitSponsorRequests} sponsorRequests={sponsorRequests} />
        case 'Users':
            return <Participants currEvent={currEvent} checkinList={checkinList} onInitCheckinList={onInitCheckinList} sponsorRequests={sponsorRequests} />
        case 'Feedbacks':
            return <EMFeedback feedbacks={feedbacks} currEvent={currEvent} />
        case 'Transactions':
            return <Transactions currEvent={currEvent} transactions={transactions} eventTheme={currEvent?.theme} />
        case 'Quiz':
            return <Quiz eventTheme={currEvent?.theme} />
        case 'Advertise':
            return <Advertise currEvent={currEvent} />
        case 'Certificates':
            return <CertificateEManage currEvent={currEvent} />
        default:
            return <></>
    }
}

export default EventManageContent