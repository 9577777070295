import { Col, Row, message } from "antd"
import "./AttemptQuiz.scss"
import { AppConfigurations } from "../../Shared/Constants/AppConfigurations"
import { useEffect, useState } from "react"
import { IQuiz, ISubmitQuiz } from "../../Shared/Model/QuizInterface"
import { useParams } from "react-router-dom"
import { onGetAllQuizInfo, onSubmitQuiz } from "../../Services/APIs/QuizAPIs"
import LoadingDots from "../../Components/LoadingDots/LoadingDots"
import { useLanguage } from "../../Services/Hooks/useLanguage"
import { FaAngleLeft, FaInfinity } from "react-icons/fa6"
import { convertSecondToMinuteAndSecond, formatDateTime, formatTime } from "../../Services/Utils/TimeUtils"
import Button from "../../Components/Button/Button"
import QuestionsAttempt from "./QuestionsAttempt"
import CountdownClock from "./CountdownClock"
import { isGuid } from "../../Services/Utils/StringUtils"
import { addZeroBefore } from "../../Services/Utils/NumberUtils"
import { useLoading } from "../../Services/Hooks/useLoading"
import ViewAllResponses from "./ViewAllResponses"

type Props = {}

const AttemptQuiz = (props: Props) => {
    const { onTranslate } = useLanguage();
    const { updateLoading } = useLoading();
    const { quizId } = useParams();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [notFinishQuestion, setNotFinishQuestion] = useState<number[]>([]);
    const [quiz, setQuiz] = useState<any>(null);
    const [countDown, setCountDown] = useState<number>(0);
    const [userAnswered, setUserAnswered] = useState<any[]>([]);
    const [currCountDown, setCurrCountDown] = useState<number>(0);
    const [isViewAllResponse, setIsViewAllResponse] = useState<boolean>(false);

    useEffect(() => {
        onInitQuiz();
    }, []);

    const onInitQuiz = async () => {
        if (!quizId) return;

        setIsLoading(true);
        const result = await onGetAllQuizInfo(quizId);
        setIsLoading(false);

        if (!result) return;
        setQuiz(result);
    }

    const onStartQuiz = () => {
        if (!quiz || countDown > 0) return;

        setCountDown(3);
        const countdownStartInterv = setInterval(() => {
            setCountDown((prev: number) => {
                if (prev === 1) {
                    clearInterval(countdownStartInterv);
                    onHandleUpdateQuizStart();
                    return 0;
                }
                return prev - 1;
            })
        }, 1000)
    }

    const onHandleUpdateQuizStart = () => {
        if (!quiz.questions) return;
        const answerFormatted = quiz.questions.map((q: any, index: number) => {
            return {
                ...q,
                answered: null,
                qNo: index + 1
            }
        })
        setUserAnswered(answerFormatted);
    }

    const onHandleUpdateAnswer = (questionId: string, answer: any) => {
        const cloneUserAnswered = [...userAnswered];
        const updatedQuestion = cloneUserAnswered.find((q: any) => q.questionId === questionId);
        if (!updatedQuestion) return;

        updatedQuestion.answered = answer;
        setUserAnswered(cloneUserAnswered);
    }

    const onEndQuiz = async () => {
        if (!quizId) return;

        const _notFinishQuestion: number[] = []
        const submittedAnswer: ISubmitQuiz[] = userAnswered.map((fullAns: any) => {
            if (!fullAns.answered) _notFinishQuestion.push(fullAns.qNo);
            return {
                questionId: fullAns.questionId,
                answerId: isGuid(fullAns.answered) ? fullAns.answered : null,
                answerContent: isGuid(fullAns.answered) ? null : fullAns.answered
            }
        });

        if (_notFinishQuestion.length > 0) {
            setNotFinishQuestion(_notFinishQuestion);
            message.error(onTranslate('MESSAGE.NOT_FINISH_QUESTION', _notFinishQuestion.join(', ')));
            return;
        }

        const usedTime = `${addZeroBefore(Math.floor(currCountDown / 60))}:${addZeroBefore(currCountDown - (Math.floor(currCountDown / 60) * 60))}`;

        updateLoading(true);
        const submitResult = await onSubmitQuiz(quizId, usedTime, submittedAnswer);
        updateLoading(false);

        if (!submitResult) return message.error(onTranslate('MESSAGE.SUBMIT_FAILED'));
        message.success(onTranslate('MESSAGE.SUBMIT_SUCCESS'));
        setUserAnswered([]);
        setNotFinishQuestion([]);
        setCountDown(0);
        setCurrCountDown(0);
    }

    return (
        <Row className="attemptQuizCtn">
            <Col span={AppConfigurations.blankCol.span} sm={AppConfigurations.blankCol.sm}></Col>
            <Col span={AppConfigurations.contentCol.span} sm={AppConfigurations.contentCol.sm}>
                {
                    isLoading
                        ? <LoadingDots />
                        : quiz &&
                        <>
                            <div className="quizName">{quiz.quiz.quizName}</div>
                            <div className="quizDescription">{quiz.quiz.quizDescription}</div>
                            {
                                userAnswered.length === 0
                                    ?
                                    <ul className='infoCtn'>
                                        <li>{quiz.quiz.attemptAllow === - 1 ? <FaInfinity className='icon' /> : quiz.quiz.attemptAllow} {onTranslate('LABEL.ATTEMP_ALLOWED', '')}</li>
                                        <li>{onTranslate('LABEL.TOTAL_TIME', quiz.quiz.totalTime)} </li>
                                        <li>{quiz.quiz.dueDate ? onTranslate('LABEL.OPEN_DUE_TO', `${formatTime(quiz.quiz.dueDate)} ${formatDateTime(quiz.quiz.dueDate)}`) : onTranslate('LABEL.NO_DEADLINE')}</li>
                                    </ul>
                                    : <></>
                            }


                            {
                                userAnswered.length === 0
                                    ?
                                    countDown > 0
                                        ? <div className="countDown">{countDown}</div>
                                        :
                                        <>
                                            <Button onClick={onStartQuiz} type="primary" className="startQuizBtn" disabled={isViewAllResponse}>{onTranslate('BUTTON.START_QUIZ')}</Button>
                                            {
                                                !isViewAllResponse
                                                    ?
                                                    <>

                                                        <div className="viewAllRes" onClick={() => setIsViewAllResponse(true)}>{onTranslate('LABEL.VIEW_ALL_RESPONSES')}</div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="back" onClick={() => setIsViewAllResponse(false)}><FaAngleLeft className="icon" /> {onTranslate('BUTTON.BACK')}</div>
                                                        <ViewAllResponses quizId={quizId} />
                                                    </>

                                            }
                                        </>
                                    :
                                    <div className="questionCtn">
                                        <div className="divider" style={{ margin: '30px 0' }}></div>
                                        <CountdownClock currCountDown={currCountDown} setCurrCountDown={setCurrCountDown} totalTime={quiz.quiz.totalTime} />
                                        <Button className="submitBtn" onClick={onEndQuiz}>{onTranslate('BUTTON.SUBMIT')}</Button>
                                        {
                                            userAnswered.map((q: any) => <QuestionsAttempt notFinishQuestion={notFinishQuestion} onHandleUpdateAnswer={onHandleUpdateAnswer} key={q.questionId} userAnswered={q} />)
                                        }
                                    </div>
                            }

                        </>
                }
            </Col>
            <Col span={AppConfigurations.blankCol.span} sm={AppConfigurations.blankCol.sm}></Col>
        </Row>
    )
}

export default AttemptQuiz