import "./HomePage.scss";
import WebStarIcon from "../../Components/WebLogo/WebStarIcon";
import EventsPreviewList from "../../Components/EventPreview/EventsPreviewList";
import LoadingDots from "../../Components/LoadingDots/LoadingDots";
import { useEffect, useRef, useState } from "react";
import { IGetAllEventsFormat, IResponseGetAll } from "../../Shared/Model/EventsInterface";
import { APIsAuthorizeUsers } from "../../Services/APIs/APIsController";
import { ApiId } from "../../Shared/Enums/APIsEnum";
import { EventStatus } from "../../Shared/Enums/EventsEnum";
import { useNavigate } from "react-router-dom";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { Col, Row } from "antd";
import { AppConfigurations } from "../../Shared/Constants/AppConfigurations";
import Locations from "../Explore/Locations";
import { onGetPopularOrganizers } from "../../Services/APIs/EventsApis";
import Heart from "../../Components/Heart/Heart";
import { DarkTheme } from "../../Shared/Constants/Theme/DarkTheme";
import EventSlider from "../../Components/EventSlider/EventSlider";
import WebCrystalIcon from "../../Components/WebLogo/WebCrystalIcon";
import Button from "../../Components/Button/Button";
import { useLanguage } from "../../Services/Hooks/useLanguage";
import { onGetAdvertisedEvent } from "../../Services/APIs/Advertise";

type Props = {}

const HomeTitle = ({ children }: any) => {
    const starSize = 50;
    return (
        <div className="homeTitle">
            <WebStarIcon width={starSize} height={starSize} />
            <label>{children}</label>
            <WebStarIcon width={starSize} height={starSize} />
        </div>
    )
}

const HomePage = (props: Props) => {
    const { onTranslate } = useLanguage();
    const introRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    const [popularOrgs, setPopularOrgs] = useState<any[]>([]);
    const [eventsList, setEventsList] = useState<IResponseGetAll[]>([]);
    const [eventsLoading, setEventsLoading] = useState<boolean>(false);
    const [organizersLoading, setOrganizersLoading] = useState<boolean>(false);
    const [stars, setStars] = useState<any[]>([]);

    useEffect(() => {
        onGetAllEvents();
        handleLoadPopularOrganizers();
        onGenerateRandomStar();
    }, []);

    const onGetAllEvents = async () => {
        setEventsLoading(true);

        let advertisedEvent = await onGetAdvertisedEvent();
        let events = await APIsAuthorizeUsers(ApiId.GET_ALL_EVENTS, { pageNo: 1, elementEachPage: 12, status: EventStatus.NOT_YET } as IGetAllEventsFormat);

        setEventsLoading(false);

        if(!advertisedEvent) advertisedEvent = [];
        if(!events) events = [];

        const filteredEvents = advertisedEvent.map((e: IResponseGetAll) => {
            return {
                ...e,
                isAdvertised: true
            }
        });

        events.forEach((noAdvEvent: IResponseGetAll) => {
            const isAdvertised = filteredEvents.findIndex((advEvent: IResponseGetAll) => advEvent.eventId === noAdvEvent.eventId) !== -1;
            !isAdvertised && filteredEvents.push(noAdvEvent);
        })


        setEventsList(filteredEvents);
    }

    const handleLoadPopularOrganizers = async () => {
        setOrganizersLoading(true);
        const organizers = await onGetPopularOrganizers();
        setOrganizersLoading(false);

        organizers && setPopularOrgs(organizers)
    }

    const onGenerateRandomStar = () => {
        const introEle = introRef.current;
        if (!introEle) return;

        let starsArr = []
        for (let i = 0; i < 12; i++) {
            starsArr.push({
                id: i,
                x: Math.random() * (introEle.clientWidth - 100) + 20,
                y: Math.random() * introEle.clientHeight
            })
        }

        setStars(starsArr);
    }

    const onScrollToExplore = () => {
        const intro = introRef.current;
        if (!intro) return;

        window.scrollTo({
            top: intro.clientHeight + 100,
            behavior: "smooth"
        })
    }

    return (
        <>
            <Row className="IntroductionSection" ref={introRef}>
                {
                    stars.map((star, i) => (
                        <WebStarIcon style={{ left: star.x, top: star.y, animationDelay: `${i / 2}s` }} key={star.id} className="starIcon" width={30} height={30} />
                    ))
                }
                <Col span={24} sm={14}>
                    <div className="headline" dangerouslySetInnerHTML={{ __html: onTranslate("LABEL.LANDING_TITLE") }}></div>
                    <div className="content">{onTranslate("LABEL.LANDING_SUBTITLE")}</div>

                    <Button className="exploreBtn" onClick={onScrollToExplore}>{onTranslate("BUTTON.EXPLORE")}</Button>
                    <Button className="createEventBtn" type="primary" onClick={() => navigate('/new-event')}>{onTranslate("BUTTON.CREATE_EVENT")}</Button>
                </Col>
                <Col span={24} sm={10} className="cyrstalCtn">
                    <WebCrystalIcon className="Crystal" />
                </Col>
            </Row>

            <Row className='HomePage linearBG' id="explore">
                <Col span={AppConfigurations.blankCol.span} sm={AppConfigurations.blankCol.sm}></Col>

                <Col span={AppConfigurations.contentCol.span} sm={AppConfigurations.contentCol.sm}>
                    <div className="homeSection" id="eventsList">
                        <HomeTitle>{onTranslate("LABEL.POPULAR_EVENTS")}</HomeTitle>
                        <div className="viewmore" onClick={() => navigate('/search-event')}>
                            {onTranslate("BUTTON.VIEW_MORE")} <FaArrowUpRightFromSquare className="icon" />
                        </div>
                        {
                            eventsLoading
                                ?
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <LoadingDots color="var(--brand-color)" />
                                </div>
                                :
                                (!eventsList || eventsList && eventsList.length > 0)
                                    ? <EventSlider eventsList={eventsList} />
                                    : <div className="empty">{onTranslate("LABEL.NO_EVENT_FOUND")}</div>
                        }

                    </div>

                    <div className="homeSection">
                        <HomeTitle>{onTranslate("LABEL.POPULAR_LOCATIONS")}</HomeTitle>
                        <Locations />
                    </div>

                    <div className="homeSection">
                        <HomeTitle>{onTranslate("LABEL.POPULAR_ORGANIZERS")}</HomeTitle>
                        {
                            organizersLoading
                                ? <LoadingDots />
                                : popularOrgs.length > 0
                                    ?
                                    <div className="popularOrganizersCtn">
                                        {
                                            popularOrgs.map((org, index) => (
                                                <div key={index} className="orgCard">
                                                    <Heart className="heartSizeCtl" color={DarkTheme["--event-theme-color"]} style={{ position: 'absolute', right: '10px', top: '8px' }} />
                                                    <img src={org.avatar} alt={org.fullName} />
                                                    <div className="orgName" onClick={() => navigate(`/profile/${org.userId}`)}>{org.fullName}</div>
                                                    <div className="totalEvents">{onTranslate("LABEL.HOSTED_NUM_EVENTS", org.totalevent)}</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    : <div className="empty">{onTranslate("LABEL.NO_ORGANIZER_FOUND")}</div>
                        }
                    </div>
                </Col>

                <Col span={AppConfigurations.blankCol.span} sm={AppConfigurations.blankCol.sm}></Col>
            </Row>
        </>
    )
}

export default HomePage;