export enum PaymentStatus {
    SUCCESS = 1,
    FAIL = 2,
    PROCESSING = 3
}

export enum PaymentSubscriptionType {
    TICKET = 1,
    SPONSOR = 2,
    ADVERTISE = 3,
    SUBSCRIPTION = 4
}