import { useContext } from "react";
import { IContext } from "../../Shared/Model/Others";
import { DataContext } from "../../App";
import { en } from "../../Shared/Constants/Language/en";
import { vi } from "../../Shared/Constants/Language/vi";
import { getDataLocalStorage, setDataLocalStorage } from "../Utils/StorageUtils";
import { localStorageId } from "../../Shared/Enums/StorageEnum";
import { AppConfigurations } from "../../Shared/Constants/AppConfigurations";
import { onTranslateOutsideHook } from "../Utils/LanguageUtils";

export const useLanguage = () => {
    const { language, setLanguage } = useContext(DataContext) as IContext;

    const onTranslate = (translateKey: string, replacementKey?: any, secondReplacementKey?: any) => {
        return onTranslateOutsideHook(language, translateKey, replacementKey, secondReplacementKey)
    }

    const onGetCurrLang = () => {
        return language
    }

    const onChangeLanguage = (newLang: string) => {
        if (newLang === language) return;
        setLanguage(newLang);
        setDataLocalStorage(localStorageId.LANGUAGE, newLang);
    }

    return { onTranslate, onGetCurrLang, onChangeLanguage };
}