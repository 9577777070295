import { useContext } from "react"
import { IContext } from "../../Shared/Model/Others";
import { Location, useNavigate } from "react-router-dom";
import { DataContext } from "../../App";
import { userRouteAllowedAccess } from "../../Shared/Constants/AuthorizeCfg/UserRouteAuthorizeCfg";
import { onCheckPremium } from "../APIs/UserApis";
import { onSaveUserToLocal } from "../Utils/UserUtils";

export const useUser = () => {
    const { user, setUser } = useContext(DataContext) as IContext;
    const navigate = useNavigate();

    const userRouteAccessValidation = (newLocation: Location) => {
        let allowedRouteList = !user ? userRouteAllowedAccess.unauth : user.roleName === "Admin" ? userRouteAllowedAccess.admin : userRouteAllowedAccess.auth;

        if (allowedRouteList.includes(`/${newLocation.pathname.split('/')[1]}`)) return;

        // Not allow to access
        if (!user) return navigate('/');
        navigate(user.roleName === "Admin" ? "/dashboard" : "/");
        // message.error("You don't have permission to access");
    }

    const validatePremium = () => {
        return user?.isPremiumUser;
    }

    const onLoadPremium = async () => {
        if (!user) return;

        const result = await onCheckPremium(user.userId);
        if (!result || result.isPremium === user.isPremiumUser) return;

        // Have premium status change
        const newUserData = { ...user, isPremiumUser: result.isPremium };
        onSaveUserToLocal(newUserData);
        setUser({ ...user, isPremiumUser: result.isPremium });
    }

    return { validatePremium, user, setUser, userRouteAccessValidation, onLoadPremium };
}