export const addZeroBefore = (value: number) => {
    return value < 10 ? `0${value}` : value;
}

export const ParseIntFromString = (value: string) => {
    const parseValue = parseInt(value);

    return value === '' ? null : isNaN(parseValue) ? null : parseValue;
}

export const phoneValidation = (phone: number | string) => {
    const vnPhoneReg = /^(03|05|07|08|09)\d{8}$/;

    return vnPhoneReg.test(`${phone}`);
}