import { Col, Row } from "antd";
import "./Timeline.scss";
import TimelineEvents from "./TimelineEvents";
import TimelineDate from "./TimelineDate";
import NoEvent from "../NoEvent/NoEvent";
import { useLanguage } from "../../Services/Hooks/useLanguage";

type Props = {
    mockArr: any[];
}

const Timeline = ({ mockArr }: Props) => {
    const { onTranslate } = useLanguage();
    return (
        <div className='Timeline'>
            {
                mockArr.length === 0
                    ?
                    <NoEvent label={onTranslate("LABEL.NO_EVENT_FOUND")} />
                    :
                    mockArr.map((dateEvents, i) => (
                        <TimelineDate key={i} dateEvents={dateEvents} />
                    ))
            }
        </div>
    )
}

export default Timeline