import React, { useState } from 'react'
import { ParseIntFromString } from '../../Services/Utils/NumberUtils';

type Props = {
    setValue?: (value: number) => void;
    value?: number;
    min?: number;
}

const NumberInput = ({ min = 0, value = 0, setValue = (val) => { } }: Props) => {
    const [numberVal, setNumberVal] = useState<number | ''>(value);

    const handleOnChange = (e: any) => {
        let parsedValue = e.target.value.trim() === '' ? '' : ParseIntFromString(e.target.value);
        // console.log(parsedValue)
        // if(!parsedValue || parsedValue < min) return;

        if (parsedValue === null) return;
        setValue(parsedValue === '' ? 0 : parsedValue as number);
        setNumberVal(parsedValue as number | '');
    }

    return (
        <input value={numberVal} onChange={handleOnChange} className='numberInput' type="text" />
    )
}

export default NumberInput