import { Col, Modal, Row, message } from "antd"
import "./Profile.scss"
import { useUser } from "../../Services/Hooks/useUser"
import { avgColor } from "../../Services/Utils/ThemeUtils"
import { DarkTheme } from "../../Shared/Constants/Theme/DarkTheme"
import { FaCamera, FaPen } from "react-icons/fa6"
import { useEffect, useState } from "react"
import Button from "../../Components/Button/Button"
import { useNavigate, useParams } from "react-router-dom"
import { IResponseGetAll } from "../../Shared/Model/EventsInterface"
import { onGetEventUserHosted } from "../../Services/APIs/EventsApis"
import TimelineEvents from "../../Components/Timeline/TimelineEvents"
import Timeline from "../../Components/Timeline/Timeline"
import LoadingDots from "../../Components/LoadingDots/LoadingDots"
import { phoneValidation } from "../../Services/Utils/NumberUtils"
import { onGetUserProfile, onUpdateProfile } from "../../Services/APIs/UserApis"
import { localStorageId } from "../../Shared/Enums/StorageEnum"
import { setDataLocalStorage } from "../../Services/Utils/StorageUtils"
import UploadImage from "./Avatar/UploadImage"
import { useLanguage } from "../../Services/Hooks/useLanguage"

type Props = {}

const Profile = (props: Props) => {
    const { userId } = useParams();
    const { onTranslate } = useLanguage();
    const navigate = useNavigate();
    const { user, setUser } = useUser();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isEditProfile, setIsEditProfile] = useState<boolean>(false);
    const [isLoadingEvents, setIsLoadingEvents] = useState<boolean>(false);
    const [isUpdateLoading, setIsUpdateLoading] = useState<boolean>(false);
    const [currViewUser, setCurrViewUser] = useState<any>(null);
    const [userHostedEvents, setUserHostedEvents] = useState<IResponseGetAll[]>([]);

    const [editedName, setEditedName] = useState<string>('');
    const [editedPhone, setEditedPhone] = useState<string>('');
    const [editedAvatar, setEditedAvatar] = useState<string>('');

    useEffect(() => {
        if (user && currViewUser?.userId === user.userId) return;
        onCheckUser();
    }, [user])

    const onCheckUser = async () => {
        if (!userId && !user) return navigate('/');

        if (user?.userId === userId) navigate('/profile');

        if (userId) {
            setIsLoading(true);
            const userProfile = await onGetUserProfile(userId);
            setIsLoading(false);

            if (!userProfile) return;
            setCurrViewUser(userProfile);
            onGetEventsUserHosted(userProfile.userId);

            return;
        }

        if (!user) return;
        setCurrViewUser(user);
        onGetEventsUserHosted(user.userId);
    }

    const onGetEventsUserHosted = async (userId: string) => {
        setIsLoadingEvents(true);
        const result = await onGetEventUserHosted(userId);
        setIsLoadingEvents(false);

        if (!result) return;
        setUserHostedEvents(result);
    }

    const onCancelEdit = () => {
        setIsEditProfile(false);
        setEditedName('');
        setEditedPhone('');
        setEditedAvatar('');
    }

    const onSwitchToEditMode = () => {
        setIsEditProfile(true);
        setEditedName(currViewUser.fullName);
        setEditedPhone(currViewUser.phone);
        setEditedAvatar(currViewUser.avatar);
    }

    const onSaveUserData = async () => {
        const phoneErr = !phoneValidation(editedPhone);
        const nameErr = editedName.trim().length < 5;

        if (phoneErr) return message.error(onTranslate('MESSAGE.INVALID_PHONE'));
        if (nameErr) return message.error(onTranslate('MESSAGE.NAME_VALIDATION', 5));

        if (editedPhone === currViewUser.phone && editedAvatar === currViewUser.avatar && editedName === currViewUser.fullName) {
            setIsEditProfile(false);
            message.info(onTranslate('MESSAGE.PROFILE_NO_CHANGE'));
            return;
        }

        setIsUpdateLoading(true);
        const result = await onUpdateProfile(currViewUser.email, editedName, editedPhone, editedAvatar.replace('data:image/png;base64,', ''));
        setIsUpdateLoading(false);

        if (!result) return;
        message.success(onTranslate('MESSAGE.UPDATED_PROFILE_SUCCESS'));
        const newUserData = {
            ...user,
            avatar: result.avatar,
            fullName: result.fullName,
            phone: result.phone
        }

        setUser(newUserData as any);
        setCurrViewUser(newUserData);
        setDataLocalStorage(localStorageId.USER_DATA, newUserData);
        setIsEditProfile(false);
    }


    return (
        <Row className="profileCtn">
            <Col span={1} sm={5}></Col>
            <Col span={22} sm={14}>
                {
                    isLoading
                        ?
                        <LoadingDots color={DarkTheme["--brand-color"]} />
                        :
                        currViewUser
                            ?
                            <>

                                <div className="infoCtn" style={{ background: avgColor(DarkTheme["--brand-color"], DarkTheme["--primary-bg-color"]) }}>
                                    {
                                        isEditProfile
                                            ?
                                            <UploadImage imgSrc={editedAvatar} setImgSrc={setEditedAvatar} />
                                            :
                                            <img src={currViewUser.avatar} className="userAvt" />
                                    }
                                    {
                                        isEditProfile
                                            ?
                                            <div className="edit">
                                                <input className="main" value={editedName} placeholder="Your name" onChange={(e) => setEditedPhone(e.target.value)} />
                                                <div className="email" >{currViewUser?.email}</div>
                                                <input value={editedPhone} placeholder="0345678912" onChange={(e) => setEditedPhone(e.target.value)} />
                                                {
                                                    isUpdateLoading
                                                        ?
                                                        <div style={{ margin: '15px 0 25px 0' }}>
                                                            <LoadingDots color={DarkTheme["--brand-color"]} />
                                                        </div>
                                                        :
                                                        <div className="btnCtn">
                                                            <Button type="secondary" className="cancel" onClick={onCancelEdit}>{onTranslate("BUTTON.CANCEL")}</Button>
                                                            <Button type="primary" className="save" onClick={onSaveUserData}>{onTranslate("BUTTON.SAVE")}</Button>
                                                        </div>
                                                }
                                            </div>
                                            :
                                            <div className="unedit">
                                                <div className="name" >{currViewUser?.fullName}</div>
                                                <div className="email" >{currViewUser?.email}</div>
                                                {currViewUser?.userId === user?.userId && <div className="email" >{!currViewUser?.phone ? onTranslate("LABEL.NO_PHONE") : currViewUser?.phone}</div>}
                                            </div>
                                    }

                                    {!isEditProfile && currViewUser?.userId === user?.userId && <FaPen className="editIcon" onClick={onSwitchToEditMode} />}
                                </div>

                                <div className="title">{onTranslate("LABEL.EVENT_HOSTED")}</div>
                                <div className="subtitle">{currViewUser?.fullName}  {onTranslate("LABEL.HOSTED_NUM_EVENTS", userHostedEvents.length)}</div>
                                {
                                    isLoadingEvents
                                        ?
                                        <LoadingDots color={DarkTheme["--brand-color"]} />
                                        :
                                        <Timeline mockArr={userHostedEvents} />
                                }
                            </>
                            :
                            <div className="userNotFound">{onTranslate("LABEL.USER_NOT_FOUND")}</div>
                }
            </Col>
            <Col span={1} sm={5}></Col>
        </Row>
    )
}

export default Profile