import { useEffect, useState } from "react";
import { ParseIntFromString } from "../../Services/Utils/NumberUtils";
import "./OTP.scss"
import { AppConfigurations } from "../../Shared/Constants/AppConfigurations";
import dayjs from "dayjs";
import { useLanguage } from "../../Services/Hooks/useLanguage";

type Props = {
    otpReceiver: string;
    onChange: (otpArr: string[]) => void;

    resendOtp: () => void;
}

const OTP = ({ otpReceiver, onChange, resendOtp }: Props) => {
    const { onTranslate } = useLanguage();

    const [otp, setOtp] = useState<string[]>(Array(6).fill(''));
    const [countdown, setCountdown] = useState<number>(AppConfigurations.otpResendTime);

    useEffect(() => {
        onChange(otp);
    }, [otp]);

    useEffect(() => {
        onCountdown();
    }, []);


    const onCountdown = () => {
        let startTime = new Date().getTime();

        const countdownInterval = setInterval(() => {
            const now = new Date()
            const countdownTime = AppConfigurations.otpResendTime - ((now.getTime() - startTime) / 1000);
            setCountdown(Math.round(countdownTime));

            countdownTime <= 0 && clearInterval(countdownInterval);
        }, 1000);
    }

    const onResendOtp = () => {
        if (countdown > 0) return;

        setCountdown(AppConfigurations.otpResendTime);
        onCountdown();
    }

    const onKeyDownOtp = (e: any, inputIndex: number) => {
        const keyCode = e.code;
        let nextIndexToFocus = null;

        switch (keyCode) {
            case 'ArrowLeft':
                nextIndexToFocus = inputIndex - 1;
                break;

            case 'ArrowRight':
                nextIndexToFocus = inputIndex + 1;
                break;

            case 'Backspace':
                const otpClone = [...otp];
                if (otp[inputIndex] !== '') {
                    otpClone[inputIndex] = '';
                } else {
                    otpClone[inputIndex - 1] = '';
                    nextIndexToFocus = inputIndex - 1;
                }
                setOtp(otpClone);
                break;

            default:
                break;
        }

        nextIndexToFocus !== null && document.getElementById(`otpNo${nextIndexToFocus}`)?.focus();
    }

    const onInputOtp = (arrIndex: number, val: string) => {
        if (!ParseIntFromString(val) && val !== '' && val !== '0') return;

        // Handle Update OTP
        const otpClone = [...otp];
        otpClone[arrIndex] = val;
        setOtp(otpClone);

        // Handle cursor position
        if (val === '') return;

        document.getElementById(`otpNo${arrIndex}`)?.blur();
        document.getElementById(`otpNo${arrIndex + 1}`)?.focus();
    }
    return (
        <>
            <div className='inputLabel' style={{ margin: '0' }}>{onTranslate('LABEL.OTP')}</div>
            <label className='addOnOtp'>{onTranslate('LABEL.SENT_OTP', otpReceiver)}</label>
            <div className='otpCtn'>
                <ul>
                    {
                        Array(6).fill(null).map((val, i) => (
                            <li key={i}>
                                <input
                                    id={`otpNo${i}`}
                                    maxLength={1}
                                    onChange={(e) => onInputOtp(i, e.target.value)}
                                    onKeyDown={(e) => onKeyDownOtp(e, i)}
                                    value={otp[i]}
                                    autoComplete="off"
                                />
                            </li>
                        ))
                    }
                </ul>
                <div className={`resendOtp ${countdown > 0 ? 'notYet' : ''}`} onClick={onResendOtp} >{onTranslate('LABEL.RESEND')} {countdown > 0 && onTranslate('LABEL.RESEND_IN', countdown)}</div>
            </div>
        </>
    )
}

export default OTP