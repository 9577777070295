import React, { useEffect, useState } from 'react'
import { avgColor } from '../../../Services/Utils/ThemeUtils'
import { FaStar } from 'react-icons/fa6'

type Props = {
    feedbacks: any[] | null,
    theme: string | undefined
}


const BackProgressBar = ({ feedbacks, theme = '#ffff' }: Props) => {
    const [feedbacksGroups, setFeedbackGroups] = useState<any[]>(Array(5).fill([]));

    useEffect(() => {
        onFilterFeedbackGroups();
    }, [feedbacks]);

    const fbacksGroupsDisplay = [
        avgColor(theme, '#2b2b2b'),
        avgColor(theme, '#595959'),
        avgColor(theme, '#808080'),
        avgColor(theme, '#a7a8a7'),
        avgColor(theme, '#dedede'),
    ]

    const onFilterFeedbackGroups = () => {
        const fbackGroups: any = Array.from({ length: 5 }, () => []);
        let sumRating = 0;

        if (!feedbacks) return;

        feedbacks.forEach(feedback => {
            const groupIndex = feedback.rating - 1;
            sumRating += feedback.rating;
            fbackGroups[groupIndex].push(feedback);
        });

        setFeedbackGroups(fbackGroups)
    }
    return (
        <div className='fbackprogressbarCtn'>
            {feedbacks &&
                <>
                    <div className="progressFullbar">
                        {
                            feedbacksGroups.map((rating, i) => (
                                <>
                                    <div className='progress' style={{ background: fbacksGroupsDisplay[i], width: `${(feedbacksGroups[i].length / feedbacks.length) * 100}%` }} key={i}>
                                        <div className='subContent'>{feedbacksGroups[i].length === 0 ? '' : feedbacksGroups[i].length}</div>
                                    </div>
                                </>
                            ))
                        }
                    </div>
                    <div className='labelCtn'>
                        {
                            fbacksGroupsDisplay.map((color, i) => (
                                <div className='label'>
                                    <div className='color' style={{ background: color }}></div>
                                    <div className='text'>{i + 1} <FaStar className='icon' /></div>
                                </div>
                            ))
                        }
                    </div>
                </>
            }
        </div>
    )
}

export default BackProgressBar