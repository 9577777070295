import axios from "axios"
import { authApiConfig, monthlyUsersApi, totalEventsApi, totalUsersApi, monthlyEventsApi } from "./APIsConfig"
import { APIErrorController } from "./APIsController";

export const onGetTotalUsers = () => {
    const config = authApiConfig();
    return axios.get(totalUsersApi, config as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onGetMonthlyUsers = () => {
    const year = (new Date()).getFullYear();
    const config = authApiConfig();
    return axios.get(`${monthlyUsersApi}?year=${year.toString()}`, config as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onGetTotalEvents = () => {
    const config = authApiConfig();
    return axios.get(totalEventsApi, config as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onGetMonthlyEvents = () => {
    const year = (new Date()).getFullYear()
    const startDate = (new Date(`1/1/${year}`)).toDateString();
    const endDate = (new Date()).toDateString();

    const config = authApiConfig();
    return axios.get(`${monthlyEventsApi}?startDate=${startDate}&endDate=${endDate}`, config as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}
