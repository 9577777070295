import React, { useEffect, useState } from 'react'
import { useLanguage } from '../../../Services/Hooks/useLanguage';
import { IQuiz } from '../../../Shared/Model/QuizInterface';
import "./Quiz.scss"
import TextInput from '../../../Components/Input/TextInput';
import { Slider, message } from 'antd';
import { convertDayjsToDate, onConvertFormatToNumberType, onConvertMinuteToMinuteAndSecond } from '../../../Services/Utils/TimeUtils';
import { QuizStatusEnum } from '../../../Shared/Enums/QuizEnum';
import Button from '../../../Components/Button/Button';
import LoadingDots from '../../../Components/LoadingDots/LoadingDots';
import { onCreateQuiz, onUpdateQuiz } from '../../../Services/APIs/QuizAPIs';
import { FaPlus, FaTrash } from 'react-icons/fa6';
import dayjs, { Dayjs } from 'dayjs';
import TimePicker from '../../../Components/Input/TimePicker';
import QuizLabel from './QuizLabel';

type Props = {
    eventId: string;
    updateData?: IQuiz | null;
    onCloseModal: () => void;
    onInitQuizList: () => void;
    onManageQuizQuestion: (value: IQuiz) => void;
}

type Key = 'attemptAllow' | 'totalTime';
const sliderOptions = {
    attemptAllow: {
        max: 10,
        min: 0,
        step: 1,
        value: (val: any) => val
    },
    totalTime: {
        max: 60,
        min: 1,
        step: 5 / 60,
        value: (val: any) => onConvertFormatToNumberType(val)
    }
}

const CreateQuizModal = ({ eventId, onCloseModal, onInitQuizList, updateData, onManageQuizQuestion }: Props) => {
    const { onTranslate } = useLanguage();

    const defaultQuizInfo = {
        eventId: eventId,
        quizName: '',
        quizDescription: '',
        quizStatus: QuizStatusEnum.DRAFTED,
        totalTime: '05:00',
        attemptAllow: 0,
        dueDate: null
    }

    const [quizInfo, setQuizInfo] = useState<IQuiz>(updateData ?? defaultQuizInfo);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDirty, setIsDirty] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => setIsDirty(false), 1);
        if (updateData) {
            setQuizInfo(updateData);
            return;
        }

        setQuizInfo(defaultQuizInfo);
    }, [updateData]);

    useEffect(() => {
        setIsDirty(true);
    }, [quizInfo])

    const onClickSubmitBtn = () => {
        const errsList = [];
        if (quizInfo.quizName.trim().length < 5) errsList.push(onTranslate('MESSAGE.NAME_VALIDATION', 5));
        if (quizInfo.quizDescription.trim().length < 15) errsList.push(onTranslate('MESSAGE.DESCRIPTION_VALIDATION', 15));

        if (errsList.length > 0) {
            message.error(errsList.join(', '));
            return;
        }

        const newData: IQuiz = {
            ...quizInfo,
            attemptAllow: Number(quizInfo.attemptAllow) === 0 ? -1 : Number(quizInfo.attemptAllow)
        }

        if (updateData) onHandleUpdate(newData);
        else onHandleCreate(newData);
    }

    const onHandleUpdate = async (updateData: IQuiz) => {
        setIsLoading(true);
        const result = await onUpdateQuiz(updateData);
        setIsLoading(false);

        if (!result) return message.error(onTranslate('MESSAGE.FAILED_TO_UPDATE_QUIZ'));
        message.success(onTranslate('MESSAGE.SUCCESS_TO_UPDATE_QUIZ'));
        onCloseModal();
        onInitQuizList();
    }

    const onHandleCreate = async (createData: IQuiz) => {
        setIsLoading(true);
        const result = await onCreateQuiz(createData);
        setIsLoading(false);

        if (!result) return message.error(onTranslate('MESSAGE.FAILED_TO_CREATE_QUIZ'));
        message.success(onTranslate('MESSAGE.SUCCESS_TO_CREATE_QUIZ'));
        onCloseModal();
        onInitQuizList();
    }

    const onHandleSliderChange = (key: string, val: number) => {
        switch (key) {
            case 'attemptAllow':
                setQuizInfo({ ...quizInfo, attemptAllow: val });
                break;
            case 'totalTime':
                setQuizInfo({ ...quizInfo, totalTime: `${onConvertMinuteToMinuteAndSecond(val)}` });
                break;
            default:
                break;
        }
    }

    return (
        <div className='createQuizModal'>
            <div className='titleModal'>{onTranslate('LABEL.CREATE_NEW_QUIZ')}</div>
            {
                Object.keys(quizInfo).map((key) => (
                    !['quizStatus', 'eventId', 'quizId'].includes(key) &&
                    <div className='inputField'>
                        <div className='label'>
                            {onTranslate(`LABEL.${key.toUpperCase()}`)}
                            {
                                key === 'totalTime' && ` (${onTranslate('LABEL.PICK_TIME_UNIT')})`
                            }
                        </div>
                        {
                            !['totalTime', 'attemptAllow', 'dueDate'].includes(key)
                                ? <TextInput value={quizInfo[key as keyof IQuiz] as string} className='input' onChange={(e) => setQuizInfo({ ...quizInfo, [key]: e })} />
                                : key !== 'dueDate'
                                    ? <Slider
                                        value={sliderOptions[key as Key].value(quizInfo[key as keyof IQuiz])}
                                        min={sliderOptions[key as Key].min}
                                        max={sliderOptions[key as Key].max}
                                        step={sliderOptions[key as Key].step}
                                        tooltip={key === 'attemptAllow' ? { formatter: (val) => Number(val) === 0 ? onTranslate('LABEL.UNLIMITED') : val } : { formatter: (val) => onConvertMinuteToMinuteAndSecond(val) }}
                                        onChange={(e) => onHandleSliderChange(key, e)} />
                                    :
                                    <>
                                        {
                                            quizInfo.dueDate
                                                ?
                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                    <TimePicker value={dayjs(quizInfo.dueDate)} onChange={(e) => setQuizInfo({ ...quizInfo, [key]: convertDayjsToDate(e).getTime() })} />
                                                    <FaTrash style={{ marginLeft: '10px', color: 'red', cursor: 'pointer' }} onClick={() => setQuizInfo({ ...quizInfo, [key]: null })} />
                                                </span>
                                                : <div className='addDeadline'
                                                    onClick={() => setQuizInfo({ ...quizInfo, [key]: (new Date()).getTime() + (100 * 60 * 60 * 5) })}>
                                                    <FaPlus className='icon' /> {onTranslate('BUTTON.ADD_CLOSE_DATE')}</div>
                                        }
                                    </>
                        }
                    </div>
                ))
            }
            {
                updateData &&
                <div className='inputField'>
                    <div className='label'>{onTranslate('LABEL.STATUS')}</div>
                    {
                        Object.values(QuizStatusEnum).map((val) => (
                            typeof (val) === 'number' &&
                            <QuizLabel quizStatus={val}
                                style={{
                                    opacity: quizInfo.quizStatus === val ? '1' : '0.5',
                                    margin: '0 5px 0 5px',
                                    cursor: 'pointer',
                                    fontSize: 'calc(var(--pc-font-medium) - 1px)'
                                }}
                                onClick={() => setQuizInfo({...quizInfo, quizStatus: val})}
                            />
                        ))
                    }

                </div>
            }
            {
                updateData &&
                <Button onClick={() => onManageQuizQuestion(quizInfo)} className='manageQuestionBtn'>{onTranslate('BUTTON.MANAGE_QUESTIONS').toUpperCase()}</Button>
            }
            {
                isLoading
                    ? <LoadingDots />
                    : <Button onClick={onClickSubmitBtn} className='createBtn' type="primary">{onTranslate(!updateData ? 'BUTTON.CREATE' : 'LABEL.UPDATE').toUpperCase()}</Button>
            }
            {
                !isLoading && isDirty && <div className='lastChangeNoti'>{onTranslate('LABEL.LAST_CHANGE_NOT_SAVE')}</div>
            }
        </div>
    )
}

export default CreateQuizModal