import axios from "axios";
import { IQuestion, IQuiz, ISubmitQuiz } from "../../Shared/Model/QuizInterface";
import { CRUDQuiz, CUDQuizQuestion, attempQuiz, authApiConfig, getAllQuizInfo, getAnsweredByUser, getQuizInfo } from "./APIsConfig";
import { APIErrorController } from "./APIsController";
import { QuizStatusEnum } from "../../Shared/Enums/QuizEnum";

export const onCreateQuiz = (quizData: IQuiz) => {
    const config = authApiConfig();
    return axios.post(CRUDQuiz, quizData, config as any)
        .then(res => res.data.data)
        .catch(err => APIErrorController(err))
}

export const onGetQuizByEvent = (eventId: string) => {
    return axios.get(`${CRUDQuiz}?EventId=${eventId}`)
        .then(res => res.data.data)
        .catch(err => APIErrorController(err))
}

export const onUpdateQuiz = (quiz: IQuiz) => {
    const config = authApiConfig();
    return axios.put(`${CRUDQuiz}?QuizId=${quiz.quizId}`, quiz, config as any)
        .then(res => res.data.data)
        .catch(err => APIErrorController(err))
}

export const onDeleteQuiz = (quizId: string) => {
    const config = authApiConfig();
    return axios.delete(`${CRUDQuiz}?QuizId=${quizId}`, config as any)
        .then(res => res.data.data)
        .catch(err => APIErrorController(err))
}

export const onGetQuestionsByQuiz = (quizId: string) => {
    const config = authApiConfig();
    return axios.get(`${getQuizInfo}?QuizId=${quizId}`, config)
        .then(res => res.data.data)
        .catch(err => APIErrorController(err))
}

export const onDeleteQuestion = (questionId: string) => {
    const config = authApiConfig();
    return axios.delete(`${CUDQuizQuestion}?QuestionId=${questionId}`, config)
        .then(res => res.data.data)
        .catch(err => APIErrorController(err))
}

export const onSaveQuestions = (quizId: string, updatedQuestion: IQuestion[]) => {
    const config = authApiConfig();
    return axios.put(`${CUDQuizQuestion}?QuizId=${quizId}`, updatedQuestion, config)
        .then(res => res.data.data)
        .catch(err => APIErrorController(err))
}

export const onGetQuizForParticipants = (eventId: string) => {
    return axios.get(`${CRUDQuiz}?EventId=${eventId}&status=${QuizStatusEnum.ACTIVE}`)
        .then(res => res.data.data)
        .catch(err => APIErrorController(err))
}

export const onGetAllQuizInfo = (quizId: string) => {
    return axios.get(getAllQuizInfo(quizId))
        .then(res => res.data.data)
        .catch(err => APIErrorController(err))
}

export const onSubmitQuiz = (quizId: string, totalTime: string, userAnswered: ISubmitQuiz[]) => {
    const cfg = authApiConfig();
    return axios.post(attempQuiz(quizId, totalTime), userAnswered, cfg as any)
        .then(res => res.data.data)
        .catch(err => APIErrorController(err))
}

export const onGetUserResponseOfQuiz = (quizId: string, userId: string) => {
    return axios.get(getAnsweredByUser(quizId, userId))
        .then(res => res.data.data)
        .catch(err => APIErrorController(err))
}