import React from 'react'
import { IQuiz } from '../../Shared/Model/QuizInterface'
import QuizView from '../EventManage/Quiz/QuizView'
import { avgColor } from '../../Services/Utils/ThemeUtils'
import { DarkTheme } from '../../Shared/Constants/Theme/DarkTheme'
import { IResponseGetAll } from '../../Shared/Model/EventsInterface'
import { FaInfinity } from 'react-icons/fa6'
import { useLanguage } from '../../Services/Hooks/useLanguage'
import { formatDateTime, formatTime } from '../../Services/Utils/TimeUtils'
import Button from '../../Components/Button/Button'
import { useNavigate } from 'react-router-dom'

type Props = {
    quizzesList: IQuiz[];
    currEvent: IResponseGetAll
}

const QuizList = ({ quizzesList, currEvent }: Props) => {
    const { onTranslate } = useLanguage()
    const navigate = useNavigate();

    return (
        <div className='quizCtn'>
            {
                quizzesList.map((quiz, i) => (
                    <div className='quiz' style={{ background: avgColor(DarkTheme['--primary-bg-color'], currEvent.theme), border: `1px solid ${avgColor(DarkTheme['--tertiary-bg-color'], currEvent.theme)}` }}>
                        <div className='name'>{quiz.quizName}</div>
                        <div className='description'>{quiz.quizDescription}</div>

                        <ul className='infoCtn'>
                            <li>{quiz.attemptAllow === - 1 ? <FaInfinity className='icon' /> : quiz.attemptAllow} {onTranslate('LABEL.ATTEMP_ALLOWED', '')}</li>
                            <li>{onTranslate('LABEL.TOTAL_TIME', quiz.totalTime)} </li>
                            <li>{quiz.dueDate ? onTranslate('LABEL.OPEN_DUE_TO', `${formatTime(quiz.dueDate)} ${formatDateTime(quiz.dueDate)}`) : onTranslate('LABEL.NO_DEADLINE')}</li>
                        </ul>

                        <Button className='attemptBtn' type="primary" onClick={() => navigate(`/quiz/${quiz.quizId}`)}>{onTranslate('BUTTON.ATTEMPT').toUpperCase()}</Button>
                    </div>
                ))
            }
        </div>
    )
}

export default QuizList