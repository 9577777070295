import { ApiId } from "../../Enums/APIsEnum";

export const APIsAuthorize = [
    // Skip 0
    [],

    // Guest = 1
    [
        ApiId.VIEW_EVENT,
        ApiId.SIGNIN_WITH_GOOGLE,
        ApiId.SIGNIN_OTP,
        ApiId.REGISTER_OTP,
        ApiId.VALIDATE_OTP,
        ApiId.SIGNOUT,
        ApiId.REFRESH_TOKEN,
        ApiId.GET_PLACE,
        ApiId.GET_PLACE_DETAILS,
        ApiId.GET_ALL_EVENTS,
        ApiId.GET_ALL_TAGS,
        ApiId.GET_EVENT_DETAILS,
        ApiId.GET_TAGS_BY_KEYWORD,
        ApiId.ADD_NEW_TAG,
        ApiId.GET_FEEDBACKS,
        ApiId.GET_POPULAR_ORGANIZERS,
        ApiId.GET_POPULAR_LOCATIONS,
        ApiId.GET_USER_BY_KEYWORD,
        ApiId.MY_EVENT,
        ApiId.UPDATE_EVENT,
        ApiId.CREATE_EVENT,
        ApiId.DELETE_EVENT,
        ApiId.REGISTER_EVENT,
        ApiId.GET_USER_INFO_IN_EVENT,
        ApiId.GET_CHECKIN_LIST,
        ApiId.PROCESS_TICKET,
        ApiId.SEND_FEEDBACK,
        ApiId.GET_MY_FEEDBACK,
        ApiId.UPDATE_FEEDBACK,
        ApiId.CREATE_SPONSOR_REQUEST,
        ApiId.ADD_USER_TO_EVENT,
        ApiId.REMOVE_PARTICIPANT,
        ApiId.GET_MY_SPONSOR_REQUEST,
        ApiId.CANCEL_MY_SPONSOR_REQUEST,
        ApiId.GET_ALL_EVENT_SPONSOR_REQUESTS,
        ApiId.UPDATE_SPONSOR_REQUEST_STATUS,
        ApiId.CREATE_PAYMENT_PAYPAL,
        ApiId.PAYMENT_PAYOUT
    ],

    // User = 2
    [

    ],

    // Admin = 3
    [
        ApiId.GET_TOTAL_EVENTS,
        ApiId.GET_MONTHLY_EVENTS,
        ApiId.GET_TOTAL_USERS,
        ApiId.GET_MONTHLY_USERS,
        ApiId.GET_ALL_USERS
    ],

]