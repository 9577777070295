import { Col, Row } from "antd";
import "./SearchEvent.scss";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { onSearchEvent } from "../../Services/APIs/EventsApis";
import { IEventLocation, IResponseGetAll } from "../../Shared/Model/EventsInterface";
import LoadingDots from "../../Components/LoadingDots/LoadingDots";
import EventsPreviewList from "../../Components/EventPreview/EventsPreviewList";
import { AllowedLocationsData } from "../../Shared/Data/AllowedLocationsData";
import { EventStatus } from "../../Shared/Enums/EventsEnum";
import Button from "../../Components/Button/Button";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { ITag } from "../../Shared/Model/TagsInterface";
import { onGetTagById, onGetTrendingTags } from "../../Services/APIs/TagsAPIs";
import { APIsAuthorizeUsers } from "../../Services/APIs/APIsController";
import { ApiId } from "../../Shared/Enums/APIsEnum";
import { useLanguage } from "../../Services/Hooks/useLanguage";

type Props = {}

const SearchEvent = (props: Props) => {
    const location = useLocation();
    const { onTranslate } = useLanguage();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [eventsList, setEventsList] = useState<IResponseGetAll[]>([]);
    const [tagsList, setTagsList] = useState<ITag[]>([]);
    const [locationList, setLocationList] = useState<any[]>([])

    const [currSearchKeyword, setCurrentSearchKeyword] = useState<string>('');
    const [currSearchLocationId, setCurrSearchLocationId] = useState<string>('all');
    const [currSearchTagId, setCurrSearchTagId] = useState<string>('all');

    useEffect(() => {
        onInitTrendingTags();
        onSearchEventByQuery();
        onInitTrendingLocations();
    }, []);

    const onSearchEventByQuery = async () => {
        let queryArr = [`status=${EventStatus.NOT_YET}`];

        const searchParams = new URLSearchParams(location.search);
        const tagId = searchParams.get('tagId');
        const locationId = searchParams.get('locationId');

        if (tagId) {
            tagId && queryArr.push(`TagId=${tagId}`);
            onGetSearchTag(tagId);
        }
        if (locationId) {
            queryArr.push(`LocationId=${locationId}`);
            setCurrSearchLocationId(locationId);
        }

        const queryString = queryArr.join('&');
        window.history.pushState({}, 'location', location.pathname)

        setIsLoading(true);
        const result = await onSearchEvent(queryString);
        setIsLoading(false);

        if (!result) return;

        setEventsList(result);
    }

    const onInitTrendingLocations = async () => {
        setIsLoading(true);
        const fetchedResult = await APIsAuthorizeUsers(ApiId.GET_POPULAR_LOCATIONS);
        setIsLoading(false);

        if (!fetchedResult) return;
        setLocationList(fetchedResult);
    }

    const onInitTrendingTags = async () => {
        const result = await onGetTrendingTags();
        if (!result) return;
        setTagsList([...new Set(result) as any]);
    }

    const onGetSearchTag = async (tagId: string) => {
        const result = await onGetTagById(tagId);

        if (!result) return;

        // setTagsList((prev) => {
        //     const newList = [...new Set([...prev, result]) as any];
        //     return newList;
        // });
        setCurrSearchTagId(result.tagId);
    }

    const handleSearchEvent = async () => {
        let queryArr = [`status=${EventStatus.NOT_YET}`];

        currSearchKeyword.trim() !== '' && queryArr.push(`EventName=${currSearchKeyword}`);
        currSearchTagId !== 'all' && queryArr.push(`TagId=${currSearchTagId}`);
        currSearchLocationId !== 'all' && queryArr.push(`LocationId=${currSearchLocationId}`);

        const queryString = queryArr.join('&');

        setIsLoading(true);
        const result = await onSearchEvent(queryString);
        setIsLoading(false);

        if (!result) return;

        setEventsList(result);
    }

    return (
        <Row className='searchEventCtn linearBG'>
            <Col span={1} sm={4}></Col>
            <Col span={22} sm={16}>
                <div className="title">{onTranslate("LABEL.SEARCH_EVENT")}</div>
                <div className="subtitle">{onTranslate("LABEL.SEARCH_EVENT_SUB")}</div>

                <div className="searchCtn">
                    <div className="inputPart Keyword">
                        <div className="label">{onTranslate("LABEL.EVENT_NAME")}</div>
                        <input placeholder="Lô tô Fes" value={currSearchKeyword} onChange={(e) => setCurrentSearchKeyword(e.target.value)} />
                    </div>
                    <div className="inputPart location">
                        <div className="label">{onTranslate("LABEL.LOCATION")}</div>
                        <select onChange={(e) => setCurrSearchLocationId(e.target.value)}>
                            <option value='all' selected={currSearchLocationId === 'all'}>{onTranslate("LABEL.ALL")}</option>
                            {
                                locationList?.map((location, i) => (
                                    <option
                                        key={location?.locationId}
                                        value={location?.locationId}
                                        selected={currSearchLocationId === location?.locationId}>{location?.location}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="inputPart tag">
                        <div className="label">{onTranslate("LABEL.TAG")}</div>
                        <select onChange={(e) => setCurrSearchTagId(e.target.value)}>
                            <option value='all' selected={currSearchTagId === 'all'}>{onTranslate("LABEL.ALL")}</option>
                            {
                                tagsList.map((tag, i) => (
                                    <option
                                        key={tag?.tagId}
                                        value={tag?.tagId}
                                        selected={currSearchTagId === tag?.tagId}>{tag?.tagName}</option>
                                ))
                            }
                        </select>
                    </div>

                    <Button className="searchBtn" type="link" onClick={handleSearchEvent}><FaMagnifyingGlass className="icon" /></Button>
                </div>

                {
                    isLoading
                        ?
                        <LoadingDots />
                        :
                        <>
                            <div className="subSearchContent">{onTranslate("LABEL.FOUND_EVENTS", eventsList.length)}</div>
                            <EventsPreviewList eventsList={eventsList} />
                        </>
                }
            </Col>
            <Col span={1} sm={4}></Col>
        </Row>
    )
}

export default SearchEvent