import axios from "axios";
import { IFilterCerti } from "../../Shared/Model/CertificateInterface";
import { authApiConfig, createCertificateAPI, filterCertificateAPI } from "./APIsConfig";
import { APIErrorController } from "./APIsController";

export const onFilterCertificate = (data: IFilterCerti) => {
    return axios.get(filterCertificateAPI(data))
        .then(res => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onCertifiedUser = (eventId: string, userIds: string[]) => {
    const authCfg = authApiConfig();
    console.log({ eventId, userIds })
    return axios.post(createCertificateAPI, { eventId, userIds }, authCfg)   
        .then(res => res.data.data)
        .catch((err) => APIErrorController(err))
}