import React, { useEffect, useState } from 'react'
import { APIsAuthorizeUsers } from '../../Services/APIs/APIsController'
import { ApiId } from '../../Shared/Enums/APIsEnum'
import Table from '../../Components/Table/Table'
import { AdvertiseDetailsTableColumn, EventsAdvertiseTableColumn, EventsTableColumn, UsersTableColumn } from './TableColumns'
import { IResponseGetAll } from '../../Shared/Model/EventsInterface'
import LoadingDots from '../../Components/LoadingDots/LoadingDots'
import { onGetAdvertisedEvent, onGetAdvertisedEventDetails, onGetAdvertisedEventStats } from '../../Services/APIs/Advertise'
import { Modal, Popover } from 'antd'
import { useLoading } from '../../Services/Hooks/useLoading'
import { formatDateTime, formatTime } from '../../Services/Utils/TimeUtils'

type Props = {}

const Event = (props: Props) => {
    const { updateLoading } = useLoading();
    const [events, setEvents] = useState<IResponseGetAll[]>([]);
    const [sponsoredEvent, setSponsoredEvents] = useState<IResponseGetAll[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [advertisedInfo, setAdvertisedInfo] = useState<any[]>([]);

    useEffect(() => {
        getAllEvents();
        getAllAdvertiseEvents();
    }, [])

    const getAllAdvertiseEvents = async () => {
        setIsLoading(true);
        const result = await onGetAdvertisedEvent();
        setIsLoading(false);

        if (!result) return;

        const resultAdvInfo: any[] = await onGetAdvertisedEventStats();
        const finalResult = result.map((event: IResponseGetAll) => {
            const infoObj = resultAdvInfo.find(ev => ev.eventId === event.eventId);
            if (infoObj) return {
                ...event,
                totalAdvertise: infoObj.numOfPurchasing,
                totalMoney: infoObj.totalMoney
            }

            return event;
        });

        setSponsoredEvents(finalResult)
    }

    const getAllEvents = async () => {
        setIsLoading(true);
        const result = await APIsAuthorizeUsers(ApiId.GET_ALL_EVENTS, { pageNo: 1, elementEachPage: 20 });
        setIsLoading(false);

        if (!result) return;
        setEvents(result)
    }

    const onViewEventAdvertiseDetails = async (eventId: string) => {
        updateLoading(true)
        const result = await onGetAdvertisedEventDetails(eventId);

        updateLoading(false)
        if (!result) return;
        setAdvertisedInfo(result)
    }

    return (
        <div style={{ margin: '30px 0 0 0' }}>

            <Modal footer={null} open={advertisedInfo.length > 0} onCancel={() => setAdvertisedInfo([])}>
                <div>Advertised details</div>
                {
                    <Table data={advertisedInfo} columns={AdvertiseDetailsTableColumn} />
                }
            </Modal>

            <div className="title">Events list</div>
            <div className="subtitle" style={{ margin: '0 0 20px 0' }}>View events' details</div>
            {
                isLoading
                    ?
                    <LoadingDots />
                    :
                    <Table columns={EventsTableColumn} data={events} />
            }

            <div className="title" style={{ margin: '30px 0 0 0' }}>Advertised Events list</div>
            {
                isLoading
                    ?
                    <LoadingDots />
                    :
                    <Table columns={EventsAdvertiseTableColumn(onViewEventAdvertiseDetails)} data={sponsoredEvent} />
            }
        </div>
    )
}

export default Event