import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit'
import ToggleTool from './ToggleTool';
import Button from '../../../Components/Button/Button';
import { useState } from 'react';
import { useLanguage } from '../../../Services/Hooks/useLanguage';

type Props = {
    descriptions: string;

    setIsUpdatingDescription: (value: boolean) => void;
    setDescriptions: (value: string) => void;
}

const DescriptionEditor = ({ setIsUpdatingDescription, setDescriptions, descriptions }: Props) => {
    const { onTranslate } = useLanguage();
    const [inputHTML, setInputHTML] = useState<string>(descriptions);
    // Editor
    const editor = useEditor({
        extensions: [
            StarterKit
        ],
        content: inputHTML,
        onUpdate: (e) => {
            setInputHTML(e.editor.getHTML())
        },
        editorProps: {
            attributes: {
                class: 'descriptionEditor'
            }
        }
    });

    const onSave = () => {
        setDescriptions(inputHTML);
        setIsUpdatingDescription(false);
    }

    return (
        <div className='descriptionEditorCtn'>
            <div className="eventDescrip">{onTranslate('LABEL.DESCRIPTION')}</div>
            <ToggleTool editor={editor} />
            <EditorContent editor={editor} />

            <div className='btnCtn'>
                <Button type="secondary" onClick={() => setIsUpdatingDescription(false)}>{onTranslate('BUTTON.CANCEL')}</Button>
                <Button type="primary" onClick={onSave}>{onTranslate('BUTTON.SAVE')}</Button>
            </div>
        </div>
    )
}

export default DescriptionEditor