import "./TagsModal.scss";
import TextInput from '../../../Components/Input/TextInput'
import { useEffect, useState } from "react";
import { APIsAuthorizeUsers } from "../../../Services/APIs/APIsController";
import { ApiId } from "../../../Shared/Enums/APIsEnum";
import { ITag } from "../../../Shared/Model/TagsInterface";
import Button from "../../../Components/Button/Button";
import { FaHashtag } from "react-icons/fa6";
import { useDebouncedCallback } from "use-debounce";
import { addNewTag } from "../../../Services/APIs/TagsAPIs";
import { message } from "antd";
import { useLanguage } from "../../../Services/Hooks/useLanguage";

type Props = {
    finalSelectedTags: ITag[];
    setFinalSelectedTags: (value: ITag[]) => void;

    setIsShowTagsModal: (value: boolean) => void;
}

const TagsModal = ({ setFinalSelectedTags, setIsShowTagsModal, finalSelectedTags }: Props) => {
    const { onTranslate } = useLanguage();

    const [opts, setOpts] = useState<ITag[] | null>(null);
    const [selectedTags, setSelectedTags] = useState<ITag[]>(finalSelectedTags);
    const [currentSearch, setCurrentSearch] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setSelectedTags(finalSelectedTags);
        initTags();
    }, []);

    const initTags = async () => {
        setIsLoading(true);
        const tags = await APIsAuthorizeUsers(ApiId.GET_ALL_TAGS)

        setIsLoading(false);
        if (!tags) return;
        setOpts(tags);
    }

    const onSelectTag = (tag: ITag) => {

        if (checkIfTagSelected(tag.tagId)) {
            const clonnedSelectedTag = selectedTags.filter((filterTag) => (
                tag.tagId !== filterTag.tagId
            ));

            setSelectedTags(clonnedSelectedTag);
            return;
        }

        if (selectedTags.length >= 5) {
            message.error(onTranslate('MESSAGE.TAG_REACHED_LIMIT_SELECT_ERR', 5));
            return;
        }

        setSelectedTags([...selectedTags, tag]);
    }

    const checkIfTagSelected = (tagId: string) => {
        return selectedTags.find((tag) => tagId === tag.tagId);
    }

    const onCancel = () => {
        setIsShowTagsModal(false);
        setSelectedTags(finalSelectedTags)
    }

    const onSave = () => {
        setFinalSelectedTags(selectedTags);
        setIsShowTagsModal(false);
    }

    const onSearchingTag = async (e: any) => {
        if (e.trim().length === 0) {
            initTags();
            return;
        }

        setIsLoading(true);
        const result = await APIsAuthorizeUsers(ApiId.GET_TAGS_BY_KEYWORD, e);
        setOpts(result);
        setIsLoading(false);
    }

    const searchingTagDebounce = useDebouncedCallback(onSearchingTag, 600);

    const onTypingTag = (e: string) => {
        setCurrentSearch(e.trim().replace(/ /g, '_'));
        searchingTagDebounce(e);
    }

    const onAddingNewTag = async () => {
        if (selectedTags.length >= 5) {
            message.error(onTranslate('MESSAGE.TAG_REACHED_LIMIT_ADD_ERR', 5));
            return;
        }

        if (currentSearch.length > 20) {
            message.error(onTranslate('MESSAGE.MAXIMUM_TAG_CHARACTERS_REACHED_ERR', 20));
            return;
        }

        setIsLoading(true);
        const res = await addNewTag(currentSearch);
        setIsLoading(false);

        if (!res || !res.data.data) {
            message.error(onTranslate('MESSAGE.FAILED_ADD_TAG'));
            return;
        }

        setCurrentSearch('');
        initTags();
        const newTag = res.data.data;
        setSelectedTags([...selectedTags, newTag]);
    }

    return (
        <div>
            <TextInput className="tagsModalInput" placeholder={onTranslate('LABEL.FIND_TAG')} value={currentSearch} onChange={onTypingTag}></TextInput>
            {(!isLoading && (!opts || opts?.length === 0) && currentSearch.length > 3) && <div className="addHashtag" onClick={onAddingNewTag}>Add new tag '#{currentSearch}'</div>}
            <div className="tagsTitle">{onTranslate('LABEL.SELECTED')} ({selectedTags?.length})</div>
            <div className="addOnTextTag">{onTranslate('LABEL.UNSELECT_TAG_ACTION')}</div>
            <div className="tagsCtn">
                {
                    selectedTags?.map((opt: ITag, i: number) => (
                        <div className="tagsOption" onClick={() => onSelectTag(opt)} key={opt.tagId}>#{opt.tagName}</div>
                    ))
                }
            </div>

            <div className="tagsTitle">{onTranslate('LABEL.SUGGESTION')} {isLoading && <FaHashtag className="tagIcon" />} </div>
            <div className="addOnTextTag">{onTranslate('LABEL.SELECT_TAG_ACTION', 5)}</div>
            <div className="tagsCtn">
                {
                    opts?.map((opt: ITag, i: number) => (
                        <div className={`tagsOption ${checkIfTagSelected(opt.tagId) && 'selected'}`} onClick={() => onSelectTag(opt)} key={opt.tagId}>#{opt.tagName}</div>
                    ))
                }
            </div>

            <div className="tagsBtnCtn">
                <Button onClick={() => onCancel()}>{onTranslate('BUTTON.CANCEL')}</Button>
                <Button type="primary" onClick={() => onSave()}>{onTranslate('BUTTON.SAVE')}</Button>
            </div>
        </div>
    )
}

export default TagsModal