import { useEffect, useState } from "react";
import { convertDayjsToDate, formatDateTime, formatTime } from "../../../Services/Utils/TimeUtils";
import "./Transactions.scss";
import Table from "../../../Components/Table/Table";
import { DatePicker, Popover } from "antd";
import Button from "../../../Components/Button/Button";
import { FaArrowRight, FaChevronRight, FaLock, FaMagnifyingGlass } from "react-icons/fa6";
import { useLanguage } from "../../../Services/Hooks/useLanguage";
import { PaymentStatus, PaymentSubscriptionType } from "../../../Shared/Enums/PaymentEnum";
import LoadingDots from "../../../Components/LoadingDots/LoadingDots";
import { IResponseGetAll } from "../../../Shared/Model/EventsInterface";
import dayjs, { Dayjs } from "dayjs";
import { useUser } from "../../../Services/Hooks/useUser";
import { IChartParticipants } from "../../../Shared/Model/Others";
import { onGetTotalTransactionsChart } from "../../../Services/APIs/PaymentAPIs";
import TransactionsChart from "./TransactionsChart";

type Props = {
    transactions: any;
    currEvent: IResponseGetAll | undefined;
    eventTheme: string | undefined;
}

const Transactions = ({ transactions, eventTheme, currEvent }: Props) => {
    const { onTranslate } = useLanguage();
    const {user} = useUser();

    const [chartTransactions, setChartTransactions] = useState<any[]>([]);
    const [searchStartDate, setSearchStartDate] = useState<Dayjs>(dayjs().subtract(7, 'day'));
    const [searchEndDate, setSearchEndDate] = useState<Dayjs>(dayjs());
    const [isSearching, setIsSearching] = useState<boolean>(false);

    useEffect(() => {
        onInitParticipantsChart();
    }, []);

    const onInitParticipantsChart = async () => {
        if (!currEvent || !user?.isPremiumUser || isSearching) return;

        setIsSearching(true);
        const data: IChartParticipants = {
            eventId: currEvent.eventId,
            startDate: convertDayjsToDate(searchStartDate).toISOString(),
            endDate: convertDayjsToDate(searchEndDate).toISOString(),
            isDay: true,
            TransactionType: PaymentSubscriptionType.TICKET,
        }
        const result = await onGetTotalTransactionsChart(data);
        console.log(result)
        setIsSearching(false);

        if (!result) return
        setChartTransactions(result.dailyTransactions);
    }

    const transactionsTableColumn = [
        {
            title: onTranslate('LABEL.TRANSACTION_ID'),
            key: 'appTransId',
            render: (row: any) => (
                <Popover content={row.id}>
                    <div style={{ maxWidth: '10vw', overflow: 'hidden', textWrap: 'nowrap', textOverflow: 'ellipsis' }}>{row.appTransId}</div>
                </Popover>
            )
        },
        {
            title: onTranslate('LABEL.TIME'),
            key: 'timestamp',
            render: (row: any) => (
                <>{formatTime(row.timestamp)} {formatDateTime(row.timestamp)}</>
            )
        },
        {
            title: onTranslate('LABEL.AMOUNT'),
            key: 'amount',
            render: (row: any) => (
                <>{Number(row.amount)?.toLocaleString()} VND</>
            )
        },
        {
            title: onTranslate('LABEL.MESSAGE'),
            key: 'description',
            render: (row: any) => (
                <Popover content={row.tranMessage}>
                    <div style={{ maxWidth: '15vw', overflow: 'hidden', textWrap: 'nowrap', textOverflow: 'ellipsis' }}>{row.description}</div>
                </Popover>
            )
        },
        {
            title: onTranslate('LABEL.STATUS'),
            key: 'status',
            render: (row: any) => {
                switch (row.status) {
                    case PaymentStatus.FAIL:
                        return onTranslate('LABEL.PAYMENT_FAILED_STATUS');
                    case PaymentStatus.SUCCESS:
                        return onTranslate('LABEL.PAYMENT_SUCCESS_STATUS');;
                    case PaymentStatus.PROCESSING:
                        return onTranslate('LABEL.PAYMENT_PROCESSING_STATUS');;
                }
            }
        },
        {
            // title: onTranslate('LABEL.EVENT'),
            key: 'event',
            render: (row: any) => (
                <>
                    {
                        row.status === PaymentStatus.PROCESSING &&
                        <span style={{ display: 'flex', cursor: 'pointer' }} onClick={() => { window.open(row.orderUrl) }}>
                            <div style={{ maxWidth: '12vw', overflow: 'hidden', textWrap: 'nowrap', textOverflow: 'ellipsis' }}>{onTranslate('LABEL.GO_TO_PAYMENT')}</div>
                            <FaChevronRight style={{ fontSize: '10px', padding: 0, margin: '0 0 0 6px', transform: 'translateY(50%)' }} />
                        </span>
                    }
                </>
            )
        },
        // {
        //     title: 'Refund',
        //     key: 'refund',
        //     render: (row: any) => (
        //         <Popover content="Refund this transaction">
        //             <Popconfirm title="Do you sure want to refund?">
        //                 <FaMoneyBillTransfer style={{ color: '#fc4432', fontSize: '17px', cursor: 'pointer' }} />
        //             </Popconfirm>
        //         </Popover>
        //     )
        // },
    ]

    return (
        <div className='transactionsCtn'>
            <div className="title">{onTranslate('LABEL.EVENT_TRANSACTIONS')}</div>
            <div className="subtitle">{onTranslate('LABEL.EVENT_TRANSACTIONS_DESCRIPTION')}</div>
            <Table color={eventTheme} data={transactions} columns={transactionsTableColumn} />

            <div className="title" style={{ marginTop: '30px' }}>{onTranslate('LABEL.TRANSACTIONS_INCOME')}</div>
            <div className="subtitle">{onTranslate('LABEL.TRANSACTIONS_INCOME_DESCRIPTION')}</div>
            <div className="searchCtn">
                <DatePicker size="small" style={{ marginRight: '10px' }} value={searchStartDate} format="DD/MM/YYYY" onChange={(e) => setSearchStartDate(e)} />
                <FaArrowRight style={{ marginRight: '10px' }} />
                <DatePicker size="small" style={{ marginRight: '10px' }} value={searchEndDate} format="DD/MM/YYYY" onChange={(e) => setSearchEndDate(e)} />
                {
                    !user?.isPremiumUser
                        ? <FaLock style={{ color: 'red' }} />
                        :
                        isSearching
                            ? <LoadingDots color={currEvent?.theme} />
                            :
                            <Button className="searchBtn" type="link" onClick={onInitParticipantsChart}>

                                <FaMagnifyingGlass />
                            </Button>
                }
            </div>
            <div className="chartCtn" style={{ position: 'relative', marginBottom: '20px' }}>
                {!user?.isPremiumUser && <FaLock style={{ fontSize: '35px', color: 'red', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}
                <div style={!user?.isPremiumUser ? { filter: 'blur(5px)' } : {}}>
                    <TransactionsChart monthlyEvents={chartTransactions} eventThemeColor={currEvent?.theme as string} />
                </div>
            </div>
        </div>
    )
}

export default Transactions;