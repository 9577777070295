import React, { useEffect, useState } from 'react'
import { IQuestion, IQuiz } from '../../../../Shared/Model/QuizInterface'
import Button from '../../../../Components/Button/Button'
import { FaAngleLeft } from 'react-icons/fa6'
import { onDeleteQuestion, onGetQuestionsByQuiz, onSaveQuestions } from '../../../../Services/APIs/QuizAPIs'
import LoadingDots from '../../../../Components/LoadingDots/LoadingDots'
import QuestionComponent from './QuestionComponent'
import { generateGUID } from '../../../../Services/Utils/StringUtils'
import { Popconfirm, message } from 'antd'
import { useLanguage } from '../../../../Services/Hooks/useLanguage'

type Props = {
    manageQuestionQuiz: IQuiz;
    setManageQuestionQuiz: (val: IQuiz | null) => void;
}

const QuestionsManage = ({ manageQuestionQuiz, setManageQuestionQuiz }: Props) => {
    const {onTranslate} = useLanguage();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [questionsList, setQuestionsList] = useState<IQuestion[]>([]);
    const [apiQuestionList, setApiQuestionList] = useState<IQuestion[]>([]);
    const [questionHaveChanged, setQuestionHaveChaged] = useState<string[]>([]);

    useEffect(() => {
        onInitQuestions();
    }, [manageQuestionQuiz]);

    const onInitQuestions = async () => {
        setQuestionHaveChaged([]);

        if (!manageQuestionQuiz.quizId) {
            setApiQuestionList([]);
            setQuestionsList([]);
            return
        }

        setIsLoading(true);
        const result = await onGetQuestionsByQuiz(manageQuestionQuiz.quizId);
        setIsLoading(false);

        if (!result) return;
        setQuestionsList(result);
        // deep clone
        setApiQuestionList(JSON.parse(JSON.stringify(result)));
    }

    const handleAddQuestion = () => {
        const newQuestion: IQuestion = {
            questionId: generateGUID(),
            questionName: '',
            isMultipleAnswers: true,
            answers: [],
        }

        setQuestionsList([...questionsList, newQuestion]);
        setQuestionHaveChaged([...questionHaveChanged, newQuestion.questionId]);
    }

    const handleUpdateQuestion = (question: IQuestion) => {
        const cloneArrQuestions = [...questionsList];
        const updatedQuestion = cloneArrQuestions.find(q => q.questionId === question.questionId);
        if (!updatedQuestion) return;

        const ifSaveChange = questionHaveChanged?.find(qid => qid === question.questionId);
        if (!ifSaveChange) setQuestionHaveChaged([...questionHaveChanged, question.questionId]);
        Object.assign(updatedQuestion, question);
        setQuestionsList(cloneArrQuestions);
    }

    const handleDeleteQuestion = async (question: IQuestion) => {
        const questionInApiList = apiQuestionList.find(q => q.questionId === question.questionId);

        if (questionInApiList) {
            setIsLoading(true);
            const result = await onDeleteQuestion(question.questionId);
            setIsLoading(false);
            if (!result) return message.error('Delete question failed');
        }

        const filteredQChanged = questionHaveChanged.filter(qid => qid !== question.questionId);
        setQuestionHaveChaged(filteredQChanged);

        const newQList = questionsList.filter(q => q.questionId !== question.questionId);
        setQuestionsList(newQList);
        // message.success('Delete question successfully');
    }

    const onSubmit = async () => {
        const updatedQuestion: IQuestion[] = [];
        let isErr = false;
        let qErr = false;
        let isNoCA = false;

        questionsList.forEach((question) => {
            if(isErr || qErr || isNoCA) return;
            isErr = question.isMultipleAnswers && question.answers.length === 0;
            qErr = question.questionName.trim().length < 5;
            isNoCA = question.isMultipleAnswers && question.answers.findIndex(a => a.isCorrectAnswer) === -1;

            const isChange = questionHaveChanged.findIndex(qid => qid === question.questionId);
            if (isChange !== -1) updatedQuestion.push(question);
        })

        if(isErr) return message.error(onTranslate('MESSAGE.SOME_MULTIPLE_CHOICE_ERR'));
        if(qErr) return message.error(onTranslate('MESSAGE.QUESTION_LOW_CHARS', 5));
        if(isNoCA) return message.error(onTranslate(`MESSAGE.MULTIPLE_CHOICE_NO_CORRECT_ANS`));
        if(!manageQuestionQuiz.quizId) return;

        setIsLoading(true);

        const result = await onSaveQuestions(manageQuestionQuiz.quizId, updatedQuestion);

        setIsLoading(false);

        if(result === undefined) return message.error(onTranslate('MESSAGE.SAVE_QUESTION_FAIL'));
        message.success(onTranslate('MESSAGE.SAVE_QUESTION_SUCCESS'));
        onInitQuestions();
    }

    const onDiscard = () => {
        setQuestionsList(apiQuestionList);
        setQuestionHaveChaged([]);
    }

    return (
        <div className='questionManaged'>
            <Button type="link" onClick={() => setManageQuestionQuiz(null)}><FaAngleLeft style={{ fontSize: '10px' }} /> {onTranslate('BUTTON.BACK')}</Button>
            <Button className='addQuestionBtn' onClick={handleAddQuestion}>{onTranslate('BUTTON.ADD')}</Button>
            <div className='quizName'>{manageQuestionQuiz.quizName}</div>
            {
                isLoading
                    ? <LoadingDots />
                    :
                    <>
                        <div className='numOfQues'>{onTranslate('LABEL.QUESTION_DISPLAY_COUNT', questionsList.length, questionHaveChanged.length)}</div>
                        {
                            questionsList.map((question, index) => (
                                <QuestionComponent handleDeleteQuestion={handleDeleteQuestion} key={index} question={question} handleUpdateQuestion={handleUpdateQuestion} />
                            ))
                        }
                        <div className='mainBtnCtn'>
                            {
                                questionHaveChanged.length > 0
                                    ?
                                    <>
                                        <Popconfirm title={onTranslate('MESSAGE.ASK_DISCARD')} onConfirm={onDiscard}>
                                            <Button>{onTranslate('BUTTON.DISCARD')}</Button>
                                        </Popconfirm>
                                        <Button type="primary" onClick={onSubmit}>{onTranslate('BUTTON.SAVE')}</Button>
                                    </>
                                    :
                                    <div className='notChangeText'>{onTranslate('LABEL.NO_QUESTION_CHANGE')}</div>
                            }

                        </div>
                    </>
            }


        </div>
    )
}

export default QuestionsManage