import { FaHeart } from "react-icons/fa6"
import "./Heart.scss"
import { avgColor } from "../../Services/Utils/ThemeUtils";
import { useState } from "react";
import { onGenerateRandomID } from "../../Services/Utils/StringUtils";
import { useDebouncedCallback } from "use-debounce";

type Props = {
    color?: string;
    style?: Object;
    className?: string;
}

interface IHeart {
    id: string;
    x: number;
    y: number;
}


const Heart = ({className = '', color = "#ffffff", style = {} }: Props) => {
    const [hearts, setHearts] = useState<IHeart[]>([]);

    const onClearHeart = () => {
        setHearts([])
    }

    const clearHeartDebounce = useDebouncedCallback(onClearHeart, 5000);

    const onClickHeart = (e: any) => {
        const heartObj: IHeart = {
            id: onGenerateRandomID(),
            x: e.pageX,
            y: e.pageY
        }

        setHearts([...hearts, heartObj]);
        clearHeartDebounce()
    }
    
    return (
        <div style={{ position: 'relative' }}>
            <FaHeart className={`followBtn ${className}`} style={{ color: avgColor(color, '#7375775d'), ...style }} onClick={onClickHeart}></FaHeart>
            {
                hearts.map((heart) => {
                    return <FaHeart className="flyingHeart" key={heart.id} style={{ color: avgColor(color, '#7375775d'), ...style }} />
                })
            }
        </div>
    )
}

export default Heart