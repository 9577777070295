import { DarkTheme } from "./Theme/DarkTheme";

export const AppConfigurations: any = {
    metaData: {
        name: 'FPT Event Management',
        shortName: 'FEM',
    },
    timezone: 'Asia/Ho_Chi_Minh',
    blankCol: {
        span: 1,
        sm: 4,
    },
    contentCol: {
        span: 22,
        sm: 16
    },
    antDesignThemeCfg: {
        token: {
            colorPrimary: DarkTheme['--brand-color'],
            colorInfo: DarkTheme['--brand-color'],
            colorSuccess: DarkTheme['--success-btn-bg-color'],
            colorError: DarkTheme['--error-btn-bg-color'],
            colorTextBase: DarkTheme['--primary-text-color'],
            colorBgBase: "#212325",
            fontSize: 17,
            controlItemBgActive: DarkTheme['--brand-color'],
        },
    },
    timeDifferenceBetweenStartAndEnd: {
        number: 30,
        unit: 'minute'
    },
    eventDefaultSize: 50,
    mapCfg: {
        locationRelatedGeo: {
            // HCMC
            lat: '10.762622',
            long: '106.660172'
        },
        numberOfResults: 5,
        radius: 200, // km
    },
    otpResendTime: 60, // seconds
    defaultLanguage: 'vi'
}