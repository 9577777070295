import React from 'react'
import { useLoading } from '../../Services/Hooks/useLoading'
import { APIsAuthorizeUsers } from '../../Services/APIs/APIsController';
import { ApiId } from '../../Shared/Enums/APIsEnum';
import { useLanguage } from '../../Services/Hooks/useLanguage';

type Props = {}

const LogoutBtn = (props: Props) => {
    const { onTranslate } = useLanguage();
    const { updateLoading } = useLoading();

    const onHandleSignout = async () => {
        updateLoading(true, 'Logging out...');

        await APIsAuthorizeUsers(ApiId.SIGNOUT);

        updateLoading(false);
    }

    return (
        <div onClick={onHandleSignout}>{onTranslate("BUTTON.LOGOUT")}</div>
    )
}

export default LogoutBtn