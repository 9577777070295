import { AppConfigurations } from "../../Shared/Constants/AppConfigurations";
import { EventStatus, SponsorRequestStatusEnum } from "../../Shared/Enums/EventsEnum";
import { PaymentStatus, PaymentSubscriptionType } from "../../Shared/Enums/PaymentEnum";
import { tokenId } from "../../Shared/Enums/StorageEnum";
import { IFilterCerti } from "../../Shared/Model/CertificateInterface";
import { IChartParticipants } from "../../Shared/Model/Others";
import { getCookie } from "../Utils/StorageUtils";

const femEndPoint = process.env.REACT_APP_API_END_POINT;
const goongjsEndPoint = process.env.REACT_APP_GOONGJS_END_POINT;

export const authApiConfig = () => {
    const accessToken = getCookie(tokenId.ACCESS_TOKEN);
    return (
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }
    )
}

export const contentTypeHeader = {
    'Content-Type': 'application/json'
}

// Auth
export const signInWithGoogleApi = `${femEndPoint}auth/sign-in/google`;
export const signInWithOTPApi = `${femEndPoint}auth/sign-in/otp`;
export const signUpWithOTPApi = `${femEndPoint}auth/sign-up/otp`;
export const validateOTPApi = `${femEndPoint}auth/otp/validate`;
export const logoutApi = `${femEndPoint}auth/sign-out`;
export const refreshApi = `${femEndPoint}auth/refresh`;

// Events
export const eventsApi = `${femEndPoint}events`; // POST PUT DELETE
export const getAllEventsApi = (pageNo: number, elementEachPage: number, status?: EventStatus | null) => {
    let queryStr = `${femEndPoint}events?pageNo=${pageNo}&elementEachPage=${elementEachPage}`;
    return status ? queryStr += `&Status=NotYet` : queryStr;
};
export const eventsDetailsApi = (eventId: string) => `${femEndPoint}/events/info?eventId=${eventId}`
export const getEventParticipantApi = `${femEndPoint}events/UserParticipated`;
export const uploadEventFileApi = `${femEndPoint}events/file-upload`;
export const getAllEventsFileApi = `${femEndPoint}events/get-all-file`;
export const getMyEventsApi = `${femEndPoint}events/user-past-and-incoming`;
export const getUserByKeywordApi = (kw: string) => `${femEndPoint}users/keyword?keyword=${kw}`;
export const getEventHostedByUser = (userId: string) => `${femEndPoint}events/user-hosted?userId=${userId}`;
export const addSponsorLogoApi = `${femEndPoint}events/logo-upload`;

// Participants
export const getParticipantsApi = `${femEndPoint}participants`;
export const registerEventApi = `${femEndPoint}participants/register`;
export const updateParticipantRoleApi = `${femEndPoint}participants/role`;
export const deleteParticipantApi = `${femEndPoint}participants`;
export const currentUserInfoInEvent = `${femEndPoint}participants/current-user`;
export const processTicketApi = `${femEndPoint}participants/process-ticket`;
export const addUserToEventApi = `${femEndPoint}participants/add`;

// Checkin
export const getCheckinListApi = (eventId: string, page: number, pageSize: number) => `${femEndPoint}participants/checkin?eventId=${eventId}&page=${page}&eachPage=${pageSize}`;
export const checkinApi = (eventId: string, userId: string) => `${femEndPoint}participants/checkin-user?eventId=${eventId}&userId=${userId}`;

// Tags
export const tagsApi = `${femEndPoint}tags`; // POST PUT DELETE
export const getAllTagsApi = `${femEndPoint}tags/all?page=1&eachPage=30`;
export const getTagById = (kw: string) => `${femEndPoint}tags//keyword?searchTerm=${kw}`;
export const getTrendingTag = `${femEndPoint}tags/trending`

// Feedbacks
export const getFeedbackApi = (eventId: string) => `${femEndPoint}feedback/event?eventId=${eventId}`;
export const submitFeedbackApi = `${femEndPoint}feedback`;
export const getUserFeedback = (eventId: string) => `${femEndPoint}feedback/event/user?eventId=${eventId}`;

// Sponsors
export const createSponsorRequestApi = `${femEndPoint}sponsor/request`;
export const getMySponsorRequestApi = (eventId: string) => `${femEndPoint}sponsor/requested-detail?eventId=${eventId}`;
export const onGetSponsorRequestsFilter = (eventId: string, page: number, eachpage: number, status?: SponsorRequestStatusEnum) => {
    let queryStr = `${femEndPoint}sponsor/event-filter?eventId=${eventId}&page=${page}&eachPage=${eachpage}`;
    if (status) queryStr += `&status=${status}`;
    return queryStr;
}
export const updateSponsorRequestStatusApi = `${femEndPoint}sponsor/request-status`;

// Organizers
export const getPopularOrganizersApi = `${femEndPoint}events/popular/organizers`;

// Places
export const getPopularLocationsApi = `${femEndPoint}events/popular/locations`;

// Users
export const userApi = `${femEndPoint}users/all`;
export const updateProfileApi = `${femEndPoint}users/update`;
export const getUserProfileApi = (userId: string) => `${femEndPoint}users/id?userId=${userId}`;
export const deleteUserApi = (userId: string) => `${femEndPoint}users/delete?userId=${userId}`;
export const checkPremiumApi = (userId: string) => `${femEndPoint}users/check-premium?userId=${userId}`;

// GoongJS - Map
export const getPlacesApi = (input: string) => {
    const encodedInput = encodeURIComponent(input);
    const relatedLocation = `${AppConfigurations.mapCfg.locationRelatedGeo.lat},${AppConfigurations.mapCfg.locationRelatedGeo.long}`;
    const searchLimit = AppConfigurations.mapCfg.numberOfResults;
    const searchRadius = AppConfigurations.mapCfg.radius;

    const searchUrl = `${goongjsEndPoint}Place/AutoComplete?api_key=${process.env.REACT_APP_GOONGJS_API_KEY}&location=${relatedLocation}&radius=${searchRadius}&input=${encodedInput}`;
    return searchUrl;
}

export const getPlaceDetailsApi = (placeId: string) => {
    const searchUrl = `${goongjsEndPoint}Place/Detail?place_id=${placeId}&api_key=${process.env.REACT_APP_GOONGJS_API_KEY}`;

    return searchUrl;
}

// Payment
export const createPaypalPayment = `${femEndPoint}payment/paypal`;
export const addTransactionApi = `${femEndPoint}transaction`;
export const payoutApi = `${femEndPoint}payment/payout`;
export const getAllTransactionsApi = `${femEndPoint}transaction`;
export const getMyTransactionsApi = `${femEndPoint}transaction/person`;
export const getEventTransactionsApi = (eventId: string) => `${femEndPoint}transaction/event?eventId=${eventId}`;

// ZALO PAY (NEW PAYMENT)
export const getPaymentStatus = (transId: string) => `${femEndPoint}payment/query-status?query=${transId}`;
export const getAllOrCreatePayment = `${femEndPoint}payment`;
export const getPaymentByUser = (uid: string) => `${femEndPoint}payment/user?guid=${uid}`;
export const getPaymentByEvent = (eventId: string) => `${femEndPoint}payment/event?guid=${eventId}`;
export const getPaymentByFilter = (eventId?: string, userId?: string, status?: PaymentStatus, subscriptionType?: PaymentSubscriptionType) => {
    let queryParam = [];
    if (eventId) queryParam.push(`eventId=${eventId}`);
    if (userId) queryParam.push(`userId=${userId}`);
    if (status) queryParam.push(`status=${status}`);
    if (subscriptionType) queryParam.push(`subscriptionType=${subscriptionType}`);

    return `${femEndPoint}payment/filter?${queryParam.join('&')}`;
}
export const paymentCallback = `${femEndPoint}payment/callback`;
export const paymentRefund = `${femEndPoint}payment/refund`;

// Admin
export const totalEventsApi = `${femEndPoint}admin/event/status`;
export const monthlyEventsApi = `${femEndPoint}admin/event/monthly`;
export const totalUsersApi = `${femEndPoint}admin/users/total`;
export const monthlyUsersApi = `${femEndPoint}admin/users/monthly`;
export const getAllUsersApi = `${femEndPoint}users/all??pageNo=1&eachPage=20`;

// QUIZ
export const CRUDQuiz = `${femEndPoint}quiz`;
export const CUDQuizQuestion = `${femEndPoint}quiz/question`;
export const getQuizInfo = `${femEndPoint}quiz/info`;
export const getAllQuizInfo = (quizId: string) => `${femEndPoint}quiz/attempt/show-question?QuizId=${quizId}`;
export const attempQuiz = (quizId: string, totalTime: string) => `${femEndPoint}quiz/attempt?quizId=${quizId}&totalTime=${totalTime}`;
export const getAnsweredByUser = (quizId: string, userId: string) => `${femEndPoint}quiz/users-participated/answers?quizId=${quizId}&userId=${userId}`;

// PRICE
export const CRUDPrice = `${femEndPoint}price?isAscending=true`;
export const getPriceInfo = `${femEndPoint}price/info`;

// CERTIFICATE  
export const createCertificateAPI = `${femEndPoint}certificate`;
export const filterCertificateAPI = (data: IFilterCerti) => {
    let queryStr = `${femEndPoint}certificate/filter?`;

    if (data['issueDate']) {
        const date = new Date(data['issueDate']);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        data['issueDate'] = `${year}-${month}-${day}`;
    }

    Object.keys(data).forEach((key: string) => {
        const typedKey = key as keyof typeof data;
        if (data[typedKey]) {
            queryStr += `${typedKey}=${data[typedKey]}&`;
        }
    });

    return queryStr;
}

// ADVERTISE
export const getEventAdvertisedApi = `${femEndPoint}advertised/all`;
export const getAdvertisedByEvent = (eventId: string) => `${femEndPoint}advertised/ad-info?eventId=${eventId}`;
export const getAdvertisedByStatus = (status: PaymentStatus) => `${femEndPoint}advertised/ad-info?status=${status}`;
export const getAdvertiseStatistic = `${femEndPoint}advertised/ad-statistic`;
export const getDetailsAdvertiseEvent = (eventId: string) => `${femEndPoint}advertised/ad-info?eventId=${eventId}`;

// CHART
export const getTotalParticipantsChartApi = (data: IChartParticipants) => {
    let queryStr = `${femEndPoint}admin/total-participants?`;

    Object.keys(data).forEach((key: string) => {
        const typedKey = key as keyof typeof data;
        if (data[typedKey]) {
            queryStr += `${typedKey}=${data[typedKey]}&`;
        }
    });

    return queryStr;
}

export const getTransactionsChartApi = (data: IChartParticipants) => {
    let queryStr = `${femEndPoint}admin/total-transactions?`;

    Object.keys(data).forEach((key: string) => {
        const typedKey = key as keyof typeof data;
        if (data[typedKey]) {
            queryStr += `${typedKey}=${data[typedKey]}&`;
        }
    });

    return queryStr;
}

export const getAllTypeTransactionsChartApi = (data: IChartParticipants) => {
    let queryStr = `${femEndPoint}admin/total-type-transactions?`;

    Object.keys(data).forEach((key: string) => {
        const typedKey = key as keyof typeof data;
        if (data[typedKey]) {
            queryStr += `${typedKey}=${data[typedKey]}&`;
        }
    });

    return queryStr;
}
