import { localStorageId, tokenId } from "../../Shared/Enums/StorageEnum";

export const setCookie = (tokenId: tokenId, token: string | null) => {
    // token = null -> Remove cookie
    document.cookie = token ? `${tokenId}=${token}` : `${tokenId}=; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
}

export const getCookie = (tokenId: tokenId) => {
    const allCookies = document.cookie.split(';');
    const matchesCookie = allCookies.find((cookie) => cookie.includes(tokenId));

    return matchesCookie ? matchesCookie.split('=')[1] : null;
}

export const setDataLocalStorage = (localStorageId: localStorageId, data: any) => {
    // console.log(data, JSON.stringify(data));
    data
        ?
        localStorage.setItem(localStorageId, JSON.stringify(data))
        :
        localStorage.removeItem(localStorageId);
}

export const getDataLocalStorage = (localStorageId: localStorageId) => {
    const data = localStorage.getItem(localStorageId);
    return data ? JSON.parse(data) : null;
}