import { Col, Row } from "antd";
import { useLanguage } from "../../Services/Hooks/useLanguage"
import "./Policy.scss"
import { AppConfigurations } from "../../Shared/Constants/AppConfigurations";

type Props = {}

const Policy = (props: Props) => {
    const { onTranslate } = useLanguage();
    return (
        <Row className="policy">
            <Col span={AppConfigurations.blankCol.span} sm={AppConfigurations.blankCol.sm}></Col>
            <Col span={AppConfigurations.contentCol.span} sm={AppConfigurations.contentCol.sm}>
                <div className="mainTitle">{onTranslate('LABEL.POLICY')}</div>
                <div className="group">
                    <div className="title">1. {onTranslate('LABEL.POLICY_1_TITLE')}</div>
                    <div className="description" dangerouslySetInnerHTML={{__html: onTranslate('LABEL.POLICY_1_DESCRIPTION')}}></div>
                </div>
                <div className="group">
                    <div className="title">2. {onTranslate('LABEL.POLICY_2_TITLE')}</div>
                    <div className="description" dangerouslySetInnerHTML={{__html: onTranslate('LABEL.POLICY_2_DESCRIPTION')}}></div>
                </div>
                <div className="group">
                    <div className="title">3. {onTranslate('LABEL.POLICY_3_TITLE')}</div>
                    <div className="description" dangerouslySetInnerHTML={{__html: onTranslate('LABEL.POLICY_3_DESCRIPTION')}}></div>
                </div>
                <div className="group">
                    <div className="title">4. {onTranslate('LABEL.POLICY_4_TITLE')}</div>
                    <div className="description" dangerouslySetInnerHTML={{__html: onTranslate('LABEL.POLICY_4_DESCRIPTION')}}></div>
                </div>
                <div className="group">
                    <div className="title">5. {onTranslate('LABEL.POLICY_5_TITLE')}</div>
                    <div className="description" dangerouslySetInnerHTML={{__html: onTranslate('LABEL.POLICY_5_DESCRIPTION')}}></div>
                </div>
            </Col>
            <Col span={AppConfigurations.blankCol.span} sm={AppConfigurations.blankCol.sm}></Col>
        </Row>
    )
}

export default Policy