import axios from "axios"
import { authApiConfig, checkPremiumApi, deleteUserApi, getAllUsersApi, getUserByKeywordApi, getUserProfileApi, logoutApi, refreshApi, signInWithOTPApi, signUpWithOTPApi, updateProfileApi, validateOTPApi } from "./APIsConfig"
import { APIErrorController } from "./APIsController";
import { IRegisterOtpData, IResponseUserData, IValidateOTP } from "../../Shared/Model/UserInterface";
import { onSigninSuccessfully } from "../Auth/Authentication";
import { message } from "antd";
import { getCookie, setCookie } from "../Utils/StorageUtils";
import { tokenId } from "../../Shared/Enums/StorageEnum";
import { downloadFile } from "../Utils/FIleUtils";

export const onSignOutApi = () => {
    // return axios.post(logoutApi, {})
    //     .then((res) => {
    //         console.log(res);
    //     })
    //     .catch((err) => {
    //         APIErrorController(err);
    //     })
}

export const onSignInWithOTP = (email: string) => {
    return axios.post(signInWithOTPApi, { email })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            APIErrorController(err);
        })

}

export const onRegisterWithOTP = (data: IRegisterOtpData) => {
    return axios.post(signUpWithOTPApi, data)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            APIErrorController(err);
        })

}

export const onValidateOTP = (data: IValidateOTP) => {
    return axios.post(validateOTPApi, data)
        .then((res) => {
            const token = res.data.data.token as any;
            const userData = res.data.data.userData;

            return onSigninSuccessfully({ token, userData } as IResponseUserData) as any;
        })
        .catch((err) => {
            APIErrorController(err);
        })
}

export const onLogout = () => {
    const config = authApiConfig();

    if (!config) {
        message.error('You must be logged in to log out');
        return;
    }

    return axios.post(logoutApi, {}, config)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            // APIErrorController(err);
        })
}

export const onRefreshToken = () => {

    // Get stored token in cookies
    const token = {
        accessToken: getCookie(tokenId.ACCESS_TOKEN),
        refreshToken: `${getCookie(tokenId.REFRESH_TOKEN)}==`,
    }

    // then remove old token in cookies
    setCookie(tokenId.REFRESH_TOKEN, null);
    setCookie(tokenId.ACCESS_TOKEN, null);

    let content = `--------Token--------- \n ${JSON.stringify(token)} \n\n --------Error Response--------- \n`

    return axios.post(refreshApi, token)
        .then((res) => {
            // Save new token to cookies
            const { accessToken, refreshToken } = res.data.data;
            setCookie(tokenId.REFRESH_TOKEN, refreshToken);
            setCookie(tokenId.ACCESS_TOKEN, accessToken);
            return true;
        })
        .catch((err) => {
            content += JSON.stringify(err.response);
            // downloadFile(content, `Refresh error: ${(new Date).toLocaleString()}`)
            console.log(err)
        })
}

export const onGetUserByKeyword = (keyword: string) => {
    const config = authApiConfig();
    return axios.get(getUserByKeywordApi(keyword), config as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onGetAllUsers = () => {
    const config = authApiConfig();
    return axios.get(getAllUsersApi, config as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onUpdateProfile = (email: string, fullName: string, phone: string, avatar: string) => {
    const config = authApiConfig();
    return axios.put(updateProfileApi, { email, fullName, phone, avatar }, config as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}
export const onGetUserProfile = (userId: string) => {
    return axios.get(getUserProfileApi(userId))
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onDeleteUser = (userId: string) => {
    const config = authApiConfig();
    return axios.delete(deleteUserApi(userId), config as any)
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}

export const onCheckPremium = (userId: string) => {
    return axios.get(checkPremiumApi(userId))
        .then((res) => res.data.data)
        .catch((err) => APIErrorController(err))
}