// Event
export enum EventStatus {
    ON_GOING = 'OnGoing',
    ENDED = 'Ended',
    NOT_YET = 'NotYet',
    ABORTED = 'Aborted',
    CANCEL = 'Cancel',
    DELETED = 'Deleted',
}

export enum EventRole {
    GUEST = 1,
    SPONSOR = 2,
    EVENT_OPERATOR = 3,
    CHECKING_STAFF = 4,
    VISITOR = 5
}

// Participants
export enum ParticipantEventStatusEnum {
    PENDING = "Pending",
    CONFIRMED = "Confirmed",
    BLOCKED = "Blocked",
    CANCEL = "Cancel"
}

export enum ParticipantEventSortBy {
    NAME,
    CHECK_IN_TIME,
    CREATED_TIME
}

export enum SponsorRequestStatusEnum {
    PROCESSING = 'Processing',
    CONFIRMED = 'Confirmed',
    REJECTED = 'Reject',
}
