import React, { useEffect, useState } from 'react'
import Certificate from '../../Components/DefaultCertification/Certificate'
import { IRecCerti } from '../../Shared/Model/CertificateInterface';
import { useNavigate, useParams } from 'react-router-dom';
import { onFilterCertificate } from '../../Services/APIs/CertificateAPIs';
import LoadingDots from '../../Components/LoadingDots/LoadingDots';
import { Col, Row, message } from 'antd';
import "./CertificateViewPage.scss"
import { AppConfigurations } from '../../Shared/Constants/AppConfigurations';
import { formatDateTime, formatTime } from '../../Services/Utils/TimeUtils';

type Props = {}

const CertificateViewPage = (props: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [certificate, setCertificate] = useState<IRecCerti | null>(null);
  const [imgSrc, setImgSrc] = useState<string | null>(null);

  const { certificateId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    handleGetCertificate();
  }, []);

  const handleGetCertificate = async () => {
    if (!certificateId) {
      navigate('/');
      return;
    }

    setIsLoading(true);
    const result = await onFilterCertificate({ certificateId });
    setIsLoading(false);

    if (!result || !result[0].certificate) return;
    setCertificate(result[0].certificate);
    const loadTimeout = setTimeout(() => {
      onConvertSvgToImage(result[0].certificate.certicateId);
      clearTimeout(loadTimeout);
    }, 20)
  }

  const onConvertSvgToImage = (certId: string) => {
    const svg = document.querySelector(`.view-c-${certId}`);
    if (!svg) return;

    const svgData = new XMLSerializer().serializeToString(svg);
    // Create a Data URL from the SVG string
    const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
    const url = URL.createObjectURL(svgBlob);

    setImgSrc(url);
  }

  return (
    <Row className='certificateCtn'>
      <Col span={AppConfigurations.blankCol.span} sm={AppConfigurations.blankCol.sm}></Col>
      <Col span={AppConfigurations.contentCol.span} sm={AppConfigurations.contentCol.sm}>
        {
          isLoading
            ? <LoadingDots />
            :
            <div style={{ position: 'absolute', opacity: 0 }}>
              {
                certificate
                && <Certificate className={`view-c-${certificate.certicateId}`} certificateId={certificate.certicateId} eventName={certificate.eventName.toUpperCase()} userName={certificate.fullName.toUpperCase()} issuedDate={new Date(certificate.issueDate)} />
              }
            </div>
        }
        {
          (!isLoading && certificate && imgSrc)
            ?
            <>
              <img className="certiImage" src={imgSrc} alt='certificate' />
              <div className='description'>This certificate created at <b>{formatTime(certificate.issueDate)} {formatDateTime(certificate.issueDate)}</b> certified that <b>{certificate.fullName}</b> has participated in <b>{certificate.eventName}</b></div>
            </>
            : <></>
        }
        {
          !isLoading && !certificate
            && <div style={{textAlign: 'center'}}>Certificate not found</div>
        }
      </Col>
      <Col span={AppConfigurations.blankCol.span} sm={AppConfigurations.blankCol.sm}></Col>
    </Row>
  )
}

export default CertificateViewPage