import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { DarkTheme } from '../../Shared/Constants/Theme/DarkTheme';
import { avgColor } from '../../Services/Utils/ThemeUtils';
import { Colors } from '../../Shared/Constants/Theme/Colors';

type Props = {
    transData: any[],
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const TransDataChart = ({ transData }: Props) => {
    const data = {
        labels: transData.map((data: any) => data.date),
        datasets: [
            {
                label: 'Ticket',
                data: transData.map((data: any) => data.totalsByType.Ticket),
                fill: false,
                borderColor: Colors['--cranberry-20'],
                backgroundColor: Colors['--cranberry-20'],
                tension: 0.1
            },
            {
                label: 'Sponsor',
                data: transData.map((data: any) => data.totalsByType.Sponsor),
                fill: false,
                borderColor: Colors['--barney-20'],
                backgroundColor: Colors['--barney-20'],
                tension: 0.1
            },
            {
                label: 'Advertise',
                data: transData.map((data: any) => data.totalsByType.Advertise),
                fill: false,
                borderColor: Colors['--yellow-20'],
                backgroundColor: Colors['--yellow-20'],
                tension: 0.1
            },
            {
                label: 'Subscription',
                data: transData.map((data: any) => data.totalsByType.Subscription),
                fill: false,
                borderColor: Colors['--blue-20'],
                backgroundColor: Colors['--blue-20'],
                tension: 0.1
            }
        ]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: ''
            }
        },
        scales: {
            x: {
                type: 'category',
                labels: transData.map((data: any) => data.date),
                ticks: {
                    color: DarkTheme['--secondary-text-color'] // X axis text color
                },
                grid: {
                    color: DarkTheme['--quaternary-text-color'] // X axis grid line color
                }
            },
            y: {
                beginAtZero: true,
                ticks: {
                    color: DarkTheme['--secondary-text-color'] // X axis text color
                },
                grid: {
                    color: DarkTheme['--quaternary-text-color'] // X axis grid line color
                }
            }
        }
    };
    return (
        <Line data={data} options={options as any} />
    )
}

export default TransDataChart