import React, { useEffect, useState } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';
import "./ViewEventDetails.scss"
import { onParseCheckinQR } from '../../Services/Utils/StringUtils';
import Button from '../../Components/Button/Button';
import { message } from 'antd';
import { FaXmark } from 'react-icons/fa6';
import { onCheckinUser } from '../../Services/APIs/EventsApis';
import LoadingDots from '../../Components/LoadingDots/LoadingDots';
import { useLanguage } from '../../Services/Hooks/useLanguage';

type Props = {
    setIsCheckingIn: (val: boolean) => void;
}

const QRScanner = ({ setIsCheckingIn }: Props) => {
    const { onTranslate } = useLanguage();
    const [checkedInUser, setCheckedInUser] = useState<any>(null);
    const [isInvalidData, setIsInvalidData] = useState<string>('');
    const [currScanner, setCurrScanner] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        onInitScannerCam();
    }, [])

    const onInitScannerCam = () => {
        const scanner = new Html5QrcodeScanner(
            'reader',
            {
                qrbox: {
                    width: 250,
                    height: 250
                },
                fps: 3
            },
            false
        );
        setCurrScanner(scanner);

        const onSuccess = (decodedText: any, decodedResult: any) => {
            const data = onParseCheckinQR(decodedText);
            setIsInvalidData(onTranslate('MESSAGE.INVALID_CHECKIN_QR'));

            if (!data) return;

            const invalidData = Object.values(data).filter((d) => d === undefined);

            if (invalidData.length > 0) return;

            setIsInvalidData('');
            setCheckedInUser(data)
            scanner.clear();
        }

        const onFail = (e: any) => {
            // console.log(e);
            // setIsInvalidData('NO QR CODE FOUND');
        }

        scanner.render(onSuccess, onFail);
    }

    const onCancelCheckin = () => {
        onInitScannerCam();
        setCheckedInUser(null);
    }

    const onCloseModal = () => {
        const playState = currScanner.getState();

        if (playState === 1) {
            setIsCheckingIn(false);
            return;
        }

        message.error(onTranslate('MESSAGE.STOP_SCAN_TO_CLOSE'));
    }

    const handleCheckin = async () => {
        setIsLoading(true);
        const result = await onCheckinUser(checkedInUser.eventId, checkedInUser.userId);
        setIsLoading(false);

        if (!result) return message.error(onTranslate('MESSAGE.CHECKEDIN_FAIL'));

        setCheckedInUser(null);
        message.success(onTranslate('MESSAGE.CHECKEDIN_SUCCESS'));
        onInitScannerCam();
    }

    return (
        <div className='qrScannerCtn'>
            {!checkedInUser && <FaXmark className='closeCheckinIcon' onClick={onCloseModal} />}

            <div id="reader"></div>

            {isInvalidData && <div className='invalidData'>{isInvalidData}</div>}

            {
                checkedInUser
                &&
                <div className='informationConfirm'>
                    <div className='title'>{onTranslate('LABEL.CHECKEDIN_CONFIRMATION')}</div>
                    <div className='info'> {onTranslate('LABEL.CHECKING_IN_FOR', checkedInUser?.fullName, checkedInUser?.email)}</div>

                    {
                        isLoading
                            ?
                            <LoadingDots />
                            :
                            <>
                                <Button className='cancelCheckin' onClick={onCancelCheckin}>{onTranslate('BUTTON.CANCEL')}</Button>
                                <Button className='checkin' type="primary" onClick={handleCheckin}>{onTranslate('BUTTON.CHECKIN')}</Button>
                            </>
                    }
                </div>
            }

        </div>
    )
}

export default QRScanner