import { useEffect, useRef, useState } from "react";
import "./Input.scss";

type Props = {
    placeholder?: string;
    size?: 'large' | 'medium' | 'small';
    onChange?: (value: any) => void;
    className?: string;
    id?: string;
    value?: string;
    isTextArea?: boolean;
}

const TextInput = ({ isTextArea = false, value = '', id = '', className = '', placeholder, size = 'medium', onChange = () => { } }: Props) => {
    const [textInput, setTextInput] = useState<string>(value);

    useEffect(() => {
        setTextInput(value)
    }, [value])

    const inputRef = useRef(null);

    const handleInputSizeChange = (newInput: any) => {
        const inputEle = inputRef.current;

        if (!inputEle) return;

        const inputHeight = (inputEle as HTMLTextAreaElement).scrollHeight;
        (inputEle as HTMLTextAreaElement).style.height = newInput.length > textInput.length ? `${inputHeight}px` : 'auto';
        setTextInput(newInput);
    }

    const handleChange = (e: any) => {
        onChange(e.target.value);
        handleInputSizeChange(e.target.value);
    }

    return (
        <>
            {isTextArea ?
                <textarea ref={inputRef} rows={1} value={textInput} onChange={handleChange} id={`textInputCurrent ${id}`} className={`textInput ${size} ${className}`} placeholder={placeholder} />
                :
                <input ref={inputRef} value={textInput} onChange={handleChange} id={`textInputCurrent ${id}`} className={`textInput ${size} ${className}`} placeholder={placeholder} />
            }
        </>
    )
}

export default TextInput