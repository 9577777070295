import React, { useState } from 'react'
import NumberInput from '../../../Components/Input/NumberInput'
import Button from '../../../Components/Button/Button';
import "./Ticket.scss"
import { message } from 'antd';
import { useLanguage } from '../../../Services/Hooks/useLanguage';

type Props = {
    setPrice: (value: number) => void;
    setModal: (val: boolean) => void;
    price: number;
}

const Ticket = ({ setPrice, price, setModal }: Props) => {
    const { onTranslate } = useLanguage();
    const [_price, _setPrice] = useState<number>(price);

    const onSave = () => {
        if (_price < 10000 && _price !== 0) {
            message.error(onTranslate('MESSAGE.TICKET_PRICE_ERR', '10.000'));
            return;
        }
        setPrice(_price);
        setModal(false);
    }

    const onRemove = () => {
        setPrice(0);
        setModal(false);
    }
    return (
        <div className='Ticket'>
            <div className="limitTitle">{onTranslate('LABEL.TICKET_PRICE')}</div>
            <NumberInput value={_price} setValue={_setPrice} />

            <div className="eventSizeBtnCtn">
                <Button type="primary" onClick={onSave}>{onTranslate('BUTTON.SET_PRICE')}</Button>
                <Button type="secondary" onClick={onRemove}>{onTranslate('BUTTON.REMOVE_PRICE')}</Button>
            </div>
        </div>
    )
}

export default Ticket