import { Link } from "react-router-dom";
import "./Button.scss"

type Props = {
    type?: 'primary' | 'secondary' | 'success' | 'error' | 'link';
    size?: 'big' | 'medium' | 'small';
    children?: any;
    borderType?: 'smooth' | 'sharp';
    className?: string;
    style?: object;
    disabled?: boolean;
    target?: "blank" | "_self"

    onClick?: (value: any) => void;
    onLinkTo?: string;
    badge?: string;
}

const Button = ({target = "_self", disabled = false, style = {}, className = '', children, onClick, type = 'secondary', borderType = 'smooth', size = 'small', onLinkTo, badge }: Props) => {
    return (
        <>
            {
                onLinkTo
                    ?
                    <Link target={target} style={style} className={`CustomButton ${type} ${borderType} ${size} ${className}`} to={`${onLinkTo}`}>{children}</Link>
                    :
                    <button disabled={disabled} style={style} className={`CustomButton ${type} ${borderType} ${size} ${className} ${disabled ? 'disabled' : ''}`} onClick={onClick}>{children}</button>
            }
            {badge && <div className="btnBadge">{badge}</div>}
        </>
    )
}

export default Button;