import { useEffect, useState } from "react";
import "./LoadingDots.scss"
import { useLocation } from "react-router-dom";

type Props = {
    color?: string;
}

const LoadingDots = ({ color }: Props) => {
    const location = useLocation();
    const [bgColor, setBgColor] = useState<string>(color ?? 'var(--brand-color)');

    useEffect(() => {
        if(location.pathname.includes('/events')) setBgColor('var(--event-theme-color)');
    }, [])
    return (
        <ul className='waitingAnim bigAnim'>
            <li style={{ background: bgColor }}></li>
            <li style={{ background: bgColor }}></li>
            <li style={{ background: bgColor }}></li>
        </ul>
    )
}

export default LoadingDots