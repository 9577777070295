import React, { createContext, useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Navbar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'
import { IUserData } from '../Shared/Model/UserInterface'
import { IContext } from '../Shared/Model/Others'
import { useUser } from '../Services/Hooks/useUser'
import { Spin } from 'antd'
import { setCookie } from '../Services/Utils/StorageUtils'
import { tokenId } from '../Shared/Enums/StorageEnum'

type Props = {}

const MainLayout = (props: Props) => {
  const location = useLocation();
  const { user, userRouteAccessValidation, onLoadPremium } = useUser();

  useEffect(() => {
    userRouteAccessValidation(location);
  }, [location, user]);

  useEffect(() => {
    onLoadPremium();
  }, []);

  useEffect(() => {
    if (!user) {
      // Remove cookies
      setCookie(tokenId.REFRESH_TOKEN, null);
      setCookie(tokenId.ACCESS_TOKEN, null);
    }
  }, [user])

  return (

    <div className='MainLayout' id='mainLayout'>
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  )
}

export default MainLayout