import { useEffect, useState } from "react"
import Button from "../../Components/Button/Button"
import { avgColor, getTextColorBaseOnBackground } from "../../Services/Utils/ThemeUtils"
import { IResponseGetAll, ISubmitFeedback } from "../../Shared/Model/EventsInterface"
import "./ViewEventDetails.scss"
import { FaRegStar, FaStar } from "react-icons/fa6"
import { useUser } from "../../Services/Hooks/useUser"
import { APIsAuthorizeUsers } from "../../Services/APIs/APIsController"
import { ApiId } from "../../Shared/Enums/APIsEnum"
import LoadingDots from "../../Components/LoadingDots/LoadingDots"
import { message } from "antd"
import { DarkTheme } from "../../Shared/Constants/Theme/DarkTheme"
import { useLanguage } from "../../Services/Hooks/useLanguage"

type Props = {
    currEvent: IResponseGetAll
}

const Feedback = ({ currEvent }: Props) => {
    const { onTranslate } = useLanguage();
    const { user } = useUser();
    const [originFback, setOriginFback] = useState(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [fbackStars, setFbackStars] = useState<number>(0);
    const [inputFback, setInputFback] = useState<string>('');
    const [isEditing, setIsEditing] = useState<boolean>(true);

    useEffect(() => {
        onCheckUserFeedback();
    }, [])

    const onCheckUserFeedback = async () => {
        setIsLoading(true);
        const result = await APIsAuthorizeUsers(ApiId.GET_MY_FEEDBACK, currEvent.eventId);
        setIsLoading(false);

        if (!result) return;

        setOriginFback(result)
        setIsEditing(false);
        setFbackStars(result.rating);
        setInputFback(result.content);
    }

    const onUpdatingStar = (value: number) => {
        if (!isEditing) return;
        setFbackStars(value)
    }

    const onSubmitFeedback = async () => {
        if (!user) return;

        const submitData = {
            userId: user.userId,
            eventId: currEvent.eventId,
            content: inputFback,
            rating: fbackStars
        }

        setIsLoading(true);
        let result;
        if (originFback) {
            result = await APIsAuthorizeUsers(ApiId.UPDATE_FEEDBACK, submitData);
        } else {
            result = await APIsAuthorizeUsers(ApiId.SEND_FEEDBACK, submitData);
        }
        setIsLoading(false);

        if (!result) {
            message.error(onTranslate('MESSAGE.FEEDBACK_FAILED'));
            return;
        }

        onCheckUserFeedback();
    }

    return (
        <div className="fbackCtn">
            {
                isLoading
                    ?
                    <LoadingDots color={currEvent.theme} />
                    :
                    <>
                        <div className="fbackStarsCtn">
                            {
                                Array(5).fill('').map((val, i) => (
                                    <FaStar style={i < fbackStars ? {} : { color: avgColor(currEvent.theme, DarkTheme["--secondary-bg-color"]) }} onClick={() => onUpdatingStar(i + 1)} className="star" key={i} />
                                ))
                            }
                        </div>
                        {
                            isEditing
                                ?
                                <>
                                    <textarea value={inputFback} onChange={(e) => setInputFback(e.target.value)} rows={3} style={{ background: avgColor(currEvent.theme, '#535557') }} placeholder="what do you think?" />
                                    <Button
                                        type="primary"
                                        className="regBtn"
                                        style={{ color: currEvent ? getTextColorBaseOnBackground(currEvent.theme) : 'black' }}
                                        onClick={onSubmitFeedback}
                                        disabled={fbackStars === 0 || inputFback?.trim() === ''}
                                    >
                                        {onTranslate('BUTTON.FEEDBACK')}
                                    </Button>
                                </>
                                :
                                <>
                                    <div className="feedbackContent">{inputFback}</div>
                                    <Button
                                        type="primary"
                                        className="regBtn"
                                        style={{ color: currEvent ? getTextColorBaseOnBackground(currEvent.theme) : 'black' }}
                                        onClick={() => setIsEditing(true)}
                                    >
                                        {onTranslate('BUTTON.UPDATE_FEEDBACK')}
                                    </Button>
                                </>
                        }
                    </>
            }
        </div>
    )
}

export default Feedback