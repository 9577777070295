import React from 'react'
import { EventStatus } from '../../Shared/Enums/EventsEnum'
import { useLanguage } from '../../Services/Hooks/useLanguage'

const TimelineStatus = (status: string) => {
    const { onTranslate } = useLanguage();
    switch (status) {
        case EventStatus.ABORTED:
            return <li style={{ background: 'var(--cranberry-60)' }}>{onTranslate('LABEL.ABORTED')}</li>
        case EventStatus.NOT_YET:
            return <li style={{ background: 'var(--yellow-60)' }}>{onTranslate('LABEL.COMING_SOON')}</li>
        case EventStatus.ON_GOING:
            return <li style={{ background: 'var(--error-btn-bg-color)' }}>{onTranslate('LABEL.ON_GOING')}</li>
        case EventStatus.DELETED:
            return <li style={{ background: 'var(--cranberry-60)' }}>{onTranslate('LABEL.DELETED')}</li>
        case EventStatus.ENDED:
            return <li style={{ background: 'var(--half-gray)' }}>{onTranslate('LABEL.ENDED')}</li>
        case EventStatus.CANCEL:
            return <li style={{ background: 'var(--cranberry-60)' }}>{onTranslate('LABEL.CANCEL')}</li>
        default:
            return <li></li>
    }
    return <></>
}

export default TimelineStatus