import "./Timeline.scss"
import { Col, Row } from 'antd'
import TimelineEvents from './TimelineEvents'
import { IEvents, IEventsDate } from "../../Shared/Model/EventsInterface"
import { addZeroBefore } from "../../Services/Utils/NumberUtils"
import { useEffect, useState } from "react"
import { formatDateTime, formatDay } from "../../Services/Utils/TimeUtils"
import { useLanguage } from "../../Services/Hooks/useLanguage"

type Props = {
    dateEvents: any
}

const TimelineDate = ({ dateEvents }: Props) => {
    const { onGetCurrLang } = useLanguage();
    return (
        <Row>
            <Col span={6}>
                <div className="date">{formatDateTime(dateEvents.startDate)}</div>
                <div className="day">{formatDay(dateEvents.startDate, onGetCurrLang())}</div>
                <div className="eventMark"></div>
            </Col>
            <Col span={18} className="timeline_EventsCtn">
                <TimelineEvents event={dateEvents} />
            </Col>
        </Row>
    )
}

export default TimelineDate