import { signInWithPopup } from "firebase/auth"
import { auth, provider } from "../Firebase/FirebaseCfg"
import { IRequestGoogleUserDataApi, IResponseUserData } from "../../Shared/Model/UserInterface"
import { APIErrorController } from "../APIs/APIsController"
import axios from "axios"
import { setCookie, setDataLocalStorage } from "../Utils/StorageUtils"
import { localStorageId, tokenId } from "../../Shared/Enums/StorageEnum"
import { onLogout, onSignOutApi } from "../APIs/UserApis"
import { signInWithGoogleApi } from "../APIs/APIsConfig"
import { message } from "antd"
import { AccountStatus } from "../../Shared/Enums/UsersEnum"
import { onSaveUserToLocal } from "../Utils/UserUtils"

const onEncryptRole = (role: number) => {
    return btoa(role.toString());
}

export const onDecryptRole = (role: string) => {
    return Number(atob(role));
}

export const onSigninSuccessfully = (userCredential: IResponseUserData) => {

    if (userCredential.userData.status === AccountStatus.BLOCKED) return AccountStatus.BLOCKED;

    const user = {
        ...userCredential.userData,
        roleId: onEncryptRole(userCredential.userData.roleId)
    }


    // Set user & save user info to localstorage
    onSaveUserToLocal(user as any);
    // setDataLocalStorage(localStorageId.USER_DATA, user);

    // Redirect to events page & refresh page
    // window.location.href = '/events';
    // window.location.reload();

    // Remove old cookies (if have)
    setCookie(tokenId.REFRESH_TOKEN, null);
    setCookie(tokenId.ACCESS_TOKEN, null);

    // Set cookies
    setCookie(tokenId.REFRESH_TOKEN, userCredential.token.refreshToken);
    setCookie(tokenId.ACCESS_TOKEN, userCredential.token.accessToken);

    return user;
}

export const onSigninFailed = () => {

}

export const handleSigninWithGoogle = async (test: boolean = false) => {

    // Get credentials from Google
    const userGoogleCredentials = await signInWithPopup(auth, provider)
        .then((res: any) => {
            const userResponse = res.user;
            if (test) console.log(res);
            return {
                // gguid: userResponse.uid,
                fullName: userResponse.displayName,
                email: userResponse.email,
                photoUrl: userResponse.photoURL,
                token: res._tokenResponse.oauthAccessToken
            } as IRequestGoogleUserDataApi
        })
        .catch((err: any) => {
            APIErrorController(err);
        })
        .finally(() => { });

    // Send Google credentials to BE & get user data + token
    // Only get token

    if (test) return;

    const userFemCredentials = await axios.post(signInWithGoogleApi, userGoogleCredentials)
        .then((res) => {
            return res.data.data as IResponseUserData;
        })
        .catch((err) => {
            APIErrorController(err);
            return null;
        })

    // Handle FEM credentials
    return userFemCredentials
        ?
        onSigninSuccessfully(userFemCredentials)
        :
        onSigninFailed()
}

export const onSignOut = async (sessionTimeout: boolean) => {

    // Remove cookies
    setCookie(tokenId.REFRESH_TOKEN, null);
    setCookie(tokenId.ACCESS_TOKEN, null);

    // Remove user from local storage
    setDataLocalStorage(localStorageId.USER_DATA, null);
    setDataLocalStorage(localStorageId.LAST_ACTION, null);

    // Call API signout to remove token
    if (!sessionTimeout) onLogout();

    // Redirect to home page & refresh page
    // window.location.href = '/';
    window.location.reload();
    alert('Signed out successfully');

}