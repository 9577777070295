export const userRouteAllowedAccess = {
    auth: [
        '/',
        '/my-events',
        '/new-event',
        '/events',
        '/my-transaction',
        '/profile',
        '/search-event',
        '/about',
        '/policy',
        '/pricing',
        '/help',
        '/c',
        '/quiz'
    ],
    unauth: [
        '/',
        '/new-event',
        '/events',
        '/profile',
        '/search-event',
        '/about',
        '/policy',
        '/pricing',
        '/help',
        '/c',
    ],
    admin: [
        '/dashboard'
    ]
}