import { Slider, message } from "antd"
import { useLanguage } from "../../../Services/Hooks/useLanguage"
import "./Advertise.scss"
import { useEffect, useState } from "react"
import Button from "../../../Components/Button/Button"
import { IZaloPaymentCreateOrder } from "../../../Shared/Model/ZaloPayment"
import { IResponseGetAll } from "../../../Shared/Model/EventsInterface"
import { PaymentSubscriptionType } from "../../../Shared/Enums/PaymentEnum"
import { useUser } from "../../../Services/Hooks/useUser"
import { onZaloCreateTransaction } from "../../../Services/APIs/PaymentAPIs"
import LoadingDots from "../../../Components/LoadingDots/LoadingDots"
import { onGetAdvertisedOfEvent } from "../../../Services/APIs/Advertise"
import { formatDateTime, formatTime } from "../../../Services/Utils/TimeUtils"
import Table from "../../../Components/Table/Table"

type Props = {
  currEvent: IResponseGetAll | undefined;
}

const Advertise = ({ currEvent }: Props) => {
  const { onTranslate } = useLanguage();
  const { user } = useUser();

  const [numOfDate, setNumOfDate] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [advData, setAdvData] = useState<any[]>([]);

  useEffect(() => {
    onInitAdvertise()
  }, [])

  const onInitAdvertise = async () => {
    if (!currEvent) return;
    const result = await onGetAdvertisedOfEvent(currEvent.eventId);

    console.log(result)
    if(!result) return;
    setAdvData(result);
  }

  const onSubmitPayment = async () => {
    if (!currEvent || !user) return;
    const paymentData: IZaloPaymentCreateOrder = {
      eventId: currEvent.eventId,
      userId: user.userId,
      description: `Advertise Event ${currEvent.eventName}: ${numOfDate} days_`,
      numOfDate,
      amount: (33000 * numOfDate).toString(),
      subscriptionType: PaymentSubscriptionType.ADVERTISE,
      redirectUrl: window.location.href
    }

    setIsLoading(true);
    const result = await onZaloCreateTransaction(paymentData);
    setIsLoading(false);

    if (!result) return message.error(onTranslate('MESSAGE.PAYMENT_FAILED'));
    // Create new payment success
    message.success(onTranslate('MESSAGE.PAYMENT_SUCCESS'));
    window.open(result.order_url);
    return;
  }

  const tableColumn = [
    {
      title: onTranslate('LABEL.CREATED_DATE'),
      key: 'createdDate',
      render: (row: any) => {
        return (
          <>
            {formatTime(row.createdDate)} {formatDateTime(row.createdDate)}
          </>
        )
      }
    },
    {
      title: onTranslate('LABEL.START_DATE'),
      key: 'startDate',
      render: (row: any) => {
        return (
          <>
            {formatTime(row.startDate)} {formatDateTime(row.startDate)}
          </>
        )
      }
    },
    {
      title: onTranslate('LABEL.END_DATE'),
      key: 'endDate',
      render: (row: any) => {
        return (
          <>
            {formatTime(row.endDate)} {formatDateTime(row.endDate)}
          </>
        )
      }
    },
    {
      title: onTranslate('LABEL.PRICE'),
      key: 'price',
      render: (row: any) => {
        return (
          <>
            {row.purchasedPrice.toLocaleString()} VND
          </>
        )
      }
    },
    {
      title: onTranslate('LABEL.STATUS'),
      key: 'status',
      render: (row: any) => {
        return (
          <>
            {onTranslate(`LABEL.${row.status.toUpperCase()}`)}
          </>
        )
      }
    },
  ]


  return (
    <div className="AdvertiseCtn">
      <div className='title'>{onTranslate('LABEL.ADVERTISE')}</div>
      <div className="subtitle">{onTranslate('LABEL.ADVERTISE_DESCRIPTION')}</div>
      <div className='money'>{numOfDate} {onTranslate('LABEL.DAYS')} ~ <b>{(33000 * numOfDate).toLocaleString() ?? 0} VND</b></div>
      <Slider
        min={1}
        max={100}
        value={numOfDate}
        onChange={(value) => setNumOfDate(value)}
      />
      {
        isLoading
          ? <LoadingDots />
          : <Button onClick={onSubmitPayment} className="paymentBtn" type="primary">{onTranslate('BUTTON.PAYMENT')}</Button>
      }

      <div className='title' style={{marginTop: '30px'}}>{onTranslate('LABEL.ADVERTISE_HISTORY')}</div>
      <div className="subtitle">{onTranslate('LABEL.ADVERTISE_HISTORY_DESCRIPTION')}</div>
      <Table columns={tableColumn} data={advData} color={currEvent?.theme} />
    </div>
  )
}

export default Advertise