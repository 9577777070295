import { FaAngleRight, FaChevronRight, FaUserXmark } from "react-icons/fa6";
import Button from "../../Components/Button/Button";
import { Popconfirm, Popover } from "antd";
import { formatDateTime, formatTime } from "../../Services/Utils/TimeUtils";
import { PaymentStatus, PaymentSubscriptionType } from "../../Shared/Enums/PaymentEnum";

export const UsersTableColumn = (UsersTableColumn: (val: string) => void) => {
    return [
        {
            title: 'Avatar',
            key: 'avatar',
            render: (row: any) => (
                <img src={row.avatar} style={{ width: '25px', height: '25px', borderRadius: '50%' }} />
            )
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'fullName'
        },
        {
            title: 'Email',
            key: 'email',
            dataIndex: 'email'
        },
        {
            title: 'Phone',
            key: 'phone',
            dataIndex: 'phone'
        },
        {
            title: 'Role',
            key: 'roleName',
            render: (row: any) => {
                return (
                    <Popover content={`Purchased Premium ${row.numOfPurchasing ?? 0} times ~ ${row.totalMoney?.toLocaleString() ?? 0} VND`}>
                        {row.isPremiumUser ? 'Premium User' : row.roleName === 'User' ? "Free User" : row.roleName}
                    </Popover>
                )
            }
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status'
        },
        {
            title: 'Action',
            key: 'action',
            render: (row: any) => {
                return (
                    row.roleName === "Admin"
                        ? <></>
                        :
                        <Popconfirm title="Do you sure want to delete this user?" onConfirm={() => UsersTableColumn(row.userId)}>
                            <Button type="link"><FaUserXmark style={{ color: 'red' }} /></Button>
                        </Popconfirm>
                )
            }
        }
    ]
}

export const EventsTableColumn = [
    {
        title: 'image',
        key: 'image',
        render: (row: any) => (
            <img src={row.image} style={{ width: '30px', height: '30px', borderRadius: '5px' }} />
        )
    },
    {
        title: 'eventName',
        key: 'eventName',
        dataIndex: 'eventName'
    },
    {
        title: 'Host',
        key: 'host',
        render: (row: any) => (
            <>{row.host.name}</>
        )
    },
    {
        title: 'Location',
        key: 'location',
        render: (row: any) => (
            <>{row.location.name}</>
        )
    },
    {
        title: 'Start Time',
        key: 'starttime',
        render: (row: any) => (
            <>{formatTime(row.startDate)} {formatDateTime(row.startDate)}</>
        )
    },
    {
        title: 'Status',
        key: 'status',
        dataIndex: 'status'
    },
    // {
    //     title: 'Action',
    //     key: 'action',
    //     render: (row: any) => (
    //         <Popconfirm title="Do you sure want to delete this user?">
    //             <Button type="link"><FaUserXmark style={{ color: 'red' }} /></Button>
    //         </Popconfirm>
    //     )
    // }
]

export const EventsAdvertiseTableColumn = (onViewEventAdvertiseDetails: any) => [
    {
        title: 'image',
        key: 'image',
        render: (row: any) => (
            <img src={row.image} style={{ width: '30px', height: '30px', borderRadius: '5px' }} />
        )
    },
    {
        title: 'eventName',
        key: 'eventName',
        dataIndex: 'eventName'
    },
    {
        title: 'Host',
        key: 'host',
        render: (row: any) => (

            <>{row.host.name}</>
        )
    },
    {
        title: 'Number of Advertised',
        key: 'noadv',
        render: (row: any) => (
            <>{row.totalAdvertise}</>
        )
    },
    {
        title: 'Total Money',
        key: 'starttime',
        render: (row: any) => (
            <>{row.totalMoney?.toLocaleString()} VND</>
        )
    },
    {
        title: 'Status',
        key: 'status',
        dataIndex: 'status'
    },
    {
        title: '',
        key: 'action',
        render: (row: any) => (
            <div onClick={() => onViewEventAdvertiseDetails(row.eventId)} style={{ fontWeight: '400', cursor: 'pointer' }}>View Advertise<FaAngleRight style={{ fontSize: '11px', transform: 'translateY(1px)', marginLeft: '5px' }} /></div>
        )
    }
]

export const TransactionsTableColumn = [
    {
        title: "ID",
        key: 'appTransId',
        render: (row: any) => (
            <Popover content={row.id}>
                <div style={{ maxWidth: '10vw', overflow: 'hidden', textWrap: 'nowrap', textOverflow: 'ellipsis' }}>{row.appTransId}</div>
            </Popover>
        )
    },
    {
        title: "Time",
        key: 'timestamp',
        render: (row: any) => (
            <>{formatTime(row.timestamp)} {formatDateTime(row.timestamp)}</>
        )
    },
    {
        title: 'Type',
        key: 'refund',
        render: (row: any) => {
            switch (row.subscriptionType) {
                case PaymentSubscriptionType.ADVERTISE:
                    return "Advertise";
                case PaymentSubscriptionType.SPONSOR:
                    return "Sponsor";
                case PaymentSubscriptionType.SUBSCRIPTION:
                    return "Subscription";
                case PaymentSubscriptionType.TICKET:
                    return "Ticket";
            }
        }
    },
    {
        title: "Amount",
        key: 'amount',
        render: (row: any) => (
            <>{Number(row.amount)?.toLocaleString()} VND</>
        )
    },
    {
        title: "Description",
        key: 'description',
        render: (row: any) => (
            <Popover content={row.tranMessage}>
                <div style={{ maxWidth: '15vw', overflow: 'hidden', textWrap: 'nowrap', textOverflow: 'ellipsis' }}>{row.description}</div>
            </Popover>
        )
    },
    {
        title: "Status",
        key: 'status',
        render: (row: any) => {
            switch (row.status) {
                case PaymentStatus.FAIL:
                    return "Failed";
                case PaymentStatus.SUCCESS:
                    return "Success";
                case PaymentStatus.PROCESSING:
                    return "Processing";
            }
        }
    },
]

export const AdvertiseDetailsTableColumn = [
    {
        title: 'ID',
        key: 'id',
        render: (row: any) => (
            <Popover content={row.id}>
                <div style={{ maxWidth: '80px', overflow: 'hidden', textWrap: 'nowrap', textOverflow: 'ellipsis' }}>{row.id}</div>
            </Popover>
        )
    },
    {
        title: 'Advertise Time',
        key: 'advTIme',
        render: (row: any) => (
            <Popover content={`${formatTime(row.startDate)} ${formatDateTime(row.startDate)} - ${formatTime(row.endDate)} ${formatDateTime(row.endDate)}`}>
                <div style={{ maxWidth: '120px', overflow: 'hidden', textWrap: 'nowrap', textOverflow: 'ellipsis' }}>{(row.endDate - row.startDate) / (1000 * 60 * 60 * 24)} days</div>
            </Popover>
        )
    },
    {
        title: 'Price',
        key: 'price',
        render: (row: any) => (
            <>{row.purchasedPrice.toLocaleString()} VND</>
        )
    },
    {
        title: 'Status',
        key: 'status',
        render: (row: any) => (
            <>{row.status}</>
        )
    }
]