import React, { useEffect, useState } from 'react'
import { onFilterPaymentTransactions, onGetAllTransactions, onGetAllTypeTransactionsChart } from '../../Services/APIs/PaymentAPIs';
import Table from '../../Components/Table/Table';
import { TransactionsTableColumn } from './TableColumns';
import LoadingDots from '../../Components/LoadingDots/LoadingDots';
import { IChartParticipants } from '../../Shared/Model/Others';
import dayjs, { Dayjs } from 'dayjs';
import { convertDayjsToDate } from '../../Services/Utils/TimeUtils';
import TransDataChart from './TransactionsChart';
import { DatePicker } from 'antd';
import { FaArrowRight, FaMagnifyingGlass } from 'react-icons/fa6';
import Button from '../../Components/Button/Button';

type Props = {}

const Transactions = (props: Props) => {
    const [trans, setTrans] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [searchStartDate, setSearchStartDate] = useState<Dayjs>(dayjs().subtract(7, 'day'));
    const [searchEndDate, setSearchEndDate] = useState<Dayjs>(dayjs());
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [transactiosnData, setTransactiosnData] = useState<any[]>([]);

    useEffect(() => {
        handleGetTransactions();
        handleInitTransactionsChart();
    }, []);

    const handleGetTransactions = async () => {
        setIsLoading(true);
        const result = await onFilterPaymentTransactions();
        setIsLoading(false);

        if (!result) return;
        setTrans(result)
    }

    const handleInitTransactionsChart = async () => {
        const data: IChartParticipants = {
            startDate: convertDayjsToDate(searchStartDate).toISOString(),
            endDate: convertDayjsToDate(searchEndDate).toISOString(),
            isDay: true
        }

        setIsSearching(true)
        const result = await onGetAllTypeTransactionsChart(data);
        setIsSearching(false);
        if (result) setTransactiosnData(result.dailyTransactions);
    }
    return (
        <div>
            {
                isLoading
                    ?
                    <LoadingDots />
                    :
                    <Table data={trans} columns={TransactionsTableColumn} />
            }


            <div style={{ margin: '40px 0 0 0' }}>Transactions Chart</div>
            <div className="searchCtn">
                <DatePicker size="small" style={{ marginRight: '10px' }} value={searchStartDate} format="DD/MM/YYYY" onChange={(e) => setSearchStartDate(e)} />
                <FaArrowRight style={{ marginRight: '10px' }} />
                <DatePicker size="small" style={{ marginRight: '10px' }} value={searchEndDate} format="DD/MM/YYYY" onChange={(e) => setSearchEndDate(e)} />
                {
                    isSearching
                        ? <LoadingDots />
                        :
                        <Button className="searchBtn" type="link" onClick={handleInitTransactionsChart}>
                            <FaMagnifyingGlass />
                        </Button>
                }
            </div>
            <TransDataChart transData={transactiosnData} />

        </div>
    )
}

export default Transactions