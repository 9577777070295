/** @format */

import { AppConfigurations } from "../../Shared/Constants/AppConfigurations";
import { en } from "../../Shared/Constants/Language/en";
import { vi } from "../../Shared/Constants/Language/vi";
import { localStorageId } from "../../Shared/Enums/StorageEnum";
import { getDataLocalStorage } from "./StorageUtils";

export const onLoadLanguage = () => {
    return getDataLocalStorage(localStorageId.LANGUAGE) ?? AppConfigurations.defaultLanguage;
};

const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, key) => acc && acc[key], obj);
}

export const onTranslateOutsideHook = (inputLang: string | null, translateKey: string, replacementKey?: any, secondReplacementKey?: any) => {
    if(!translateKey) return;
    const language = inputLang ?? getDataLocalStorage(localStorageId.LANGUAGE) ?? AppConfigurations.defaultLanguage;

    let langObj = null;
        switch (language) {
            case 'en':
                langObj = en;
                break;
            case 'vi':
                langObj = vi;
                break;
            default:
                return;
        }

        let result = getNestedValue(langObj, translateKey);
        if (!result) return translateKey;

        // placeholder phase
        if (replacementKey !== undefined) result = result.replace("%s", replacementKey);
        if (secondReplacementKey !== undefined) result = result.replace("%z", secondReplacementKey);
        // linebreak phase
        result = result.replace(/\n/g, '<br/>');

        return result;
}
