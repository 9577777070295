import { IEvents } from "../../Shared/Model/EventsInterface"
import "./Timeline.scss"
import { FaLocationDot } from 'react-icons/fa6'
import TimelineStatus from "./TimelineStatus"
import { formatTime } from "../../Services/Utils/TimeUtils"
import { useNavigate } from "react-router-dom"
import { EventStatus } from "../../Shared/Enums/EventsEnum"

type Props = {
    event: any
}

const TimelineEvents = ({ event }: Props) => {
    const navigate = useNavigate();

    const onViewEvent = () => {
        if (event.status === EventStatus.DELETED) return;
        navigate(`/events/${event.eventId}`);
    }

    return (
        <div className="timeline_event" onClick={onViewEvent}>
            <ul className="eventInfo">
                <li>{formatTime(event?.startDate)}</li>
                <li>{event?.eventName}</li>
                <li>
                    <img className="avatar" src={event?.host?.avatar} /> {event?.host?.name}
                </li>
                <li><FaLocationDot className="icon" />{event?.location?.name ? event?.location?.name : event?.location}</li>
                {TimelineStatus(event?.status)}
            </ul>
            <div className="imgCtn">
                <img className="eventImg" src={event?.image} />
            </div>
        </div>
    )
}

export default TimelineEvents