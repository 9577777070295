import { Col, Row } from "antd";
import "./Explore.scss";
import { AppConfigurations } from "../../Shared/Constants/AppConfigurations";
import EventsPreviewList from "../../Components/EventPreview/EventsPreviewList";
import { useEffect, useState } from "react";
import { APIsAuthorizeUsers } from "../../Services/APIs/APIsController";
import { ApiId } from "../../Shared/Enums/APIsEnum";
import { IGetAllEventsFormat, IResponseGetAll } from "../../Shared/Model/EventsInterface";
import { useLoading } from "../../Services/Hooks/useLoading";
import { EventStatus } from "../../Shared/Enums/EventsEnum";
import Organizer from "./Organizer";
import Locations from "./Locations";
import LoadingDots from "../../Components/LoadingDots/LoadingDots";
import { FaMagnifyingGlass } from "react-icons/fa6";
import Button from "../../Components/Button/Button";
import { useNavigate } from "react-router-dom";

type Props = {}

const Explore = (props: Props) => {
    const navigate = useNavigate();
    
    const [eventsList, setEventsList] = useState<IResponseGetAll[] | null>(null);
    const [eventsLoading, setEventsLoading] = useState<boolean>(false);

    useEffect(() => {
        onGetAllEvents();
    }, []);

    const onGetAllEvents = async () => {
        setEventsLoading(true);

        const events = await APIsAuthorizeUsers(ApiId.GET_ALL_EVENTS, { pageNo: 1, elementEachPage: 6, status: EventStatus.NOT_YET } as IGetAllEventsFormat);
        setEventsLoading(false);

        if (!events) return;
        setEventsList(events);
    }

    return (
        <Row className="explore linearBG">
            <Col span={AppConfigurations.blankCol.span} sm={AppConfigurations.blankCol.sm}></Col>

            <Col span={AppConfigurations.contentCol.span} sm={AppConfigurations.contentCol.sm}>
                <div className="title">Explore</div>
                <div className="popularGroup">
                    <div className="groupTitle">Popular Events <FaMagnifyingGlass className="icon" onClick={() => navigate('/search-event')}/></div>
                    {/* <Button><FaMagnifyingGlass className="icon" /></Button> */}
                    {
                        eventsLoading
                            ?
                            <div style={{ display: 'flex', justifyContent: 'left' }}>
                                <LoadingDots color="var(--brand-color)" />
                            </div>
                            :
                            <EventsPreviewList eventsList={eventsList} />
                    }
                </div>
                <div className="popularGroup">
                    <div className="groupTitle">Popular Locations</div>
                    <Locations />
                </div>
                {/* <div className="popularGroup">
                    <div className="groupTitle">Popular Organizers</div>
                    <Organizer />
                </div> */}
            </Col>

            <Col span={AppConfigurations.blankCol.span} sm={AppConfigurations.blankCol.sm}></Col>
        </Row>
    )
}

export default Explore