export enum tokenId {
    ACCESS_TOKEN = 'accessToken',
    REFRESH_TOKEN = 'refreshToken'
}

export enum localStorageId {
    USER_DATA = '_userData',
    LAST_ACTION = 'lastAction',
    PAYPAL_SAVED_DATA = 'payPalSavedData',
    PAYPAL_SPONSOR_DATA = 'paypalSponsorData',
    TIMED_OUT = 'timedOut',
    LANGUAGE = 'language'
}