export const Colors: any = {
    '--white': '#fff',
    '--gray-10': '#f4f5f6',
    '--gray-20': '#ebeced',
    '--gray-30': '#dee0e2',
    '--gray-40': '#d2d4d7',
    '--gray-50': '#b3b5b7',
    '--gray-60': '#939597',
    '--gray-70': '#737577',
    '--gray-80': '#535557',
    '--gray-90': '#333537',
    '--gray-100': '#212325',
    '--gray-100-base-rgb': '33, 35, 37',
    '--black-base-rgb': '19, 21, 23',
    '--black': 'rgb(var(--black-base-rgb))',
    '--pure-black': '#000',
    '--white-transparent': '#ffffff00',
    '--gray-10-transparent': '#f4f5f600',
    '--gray-20-transparent': '#ebeced00',
    '--gray-30-transparent': '#dee0e200',
    '--gray-40-transparent': '#d2d4d700',
    '--gray-50-transparent': '#b3b5b700',
    '--gray-80-transparent': '#53555700',
    '--gray-90-transparent': '#33353700',
    '--gray-100-transparent': '#21232500',
    '--black-transparent': 'rgba(var(--black-base-rgb),0)',
    '--gray-10-thick-translucent': '#f4f5f6cc',
    '--gray-20-translucent': '#eaecee40',
    '--gray-100-thick-translucent': '#212325cc',
    '--white-translucent': '#ffffff40',
    '--white-thick-translucent': '#fffd',
    '--black-translucent': 'rgba(var(--black-base-rgb),0.25)',
    '--black-thick-translucent': 'rgba(var(--black-base-rgb),0.8)',
    '--darker-pale-gray': '#93959744',
    '--pale-gray': '#93959722',
    '--faint-gray': '#93959711',
    '--pale-white': '#fff2',
    '--half-white': '#fff9',
    '--half-gray': '#b3b5b799',
    '--half-black': '#0009',
    '--cranberry-10': '#fbe5eb',
    '--cranberry-20': '#f7cbd8',
    '--cranberry-30': '#ee98b1',
    '--cranberry-40': '#e6658a',
    '--cranberry-50': '#de3163',
    '--cranberry-60': '#c22b57',
    '--cranberry-70': '#8b1f3e',
    '--cranberry-80': '#531225',
    '--pale-cranberry': '#de316322',
    '--faint-cranberry': '#de316311',
    '--barney-10': '#f6e5f8',
    '--barney-20': '#eecaf1',
    '--barney-30': '#dd96e3',
    '--barney-40': '#cc62d5',
    '--barney-50': '#bb2dc7',
    '--barney-60': '#a427ae',
    '--barney-70': '#751c7c',
    '--barney-80': '#46114b',
    '--pale-barney': '#bb2dc722',
    '--faint-barney': '#bb2dc711',
    '--purple-10': '#e3defd',
    '--purple-20': '#d4c4ff',
    '--purple-30': '#b596ff',
    '--purple-40': '#7b49ff',
    '--purple-50': '#682fff',
    '--purple-60': '#5b29df',
    '--purple-70': '#4b23bf',
    '--purple-80': '#2f1880',
    '--pale-purple': '#682fff22',
    '--faint-purple': '#682fff11',
    '--blue-10': '#d4e5ff',
    '--blue-20': '#b6d3ff',
    '--blue-30': '#76adff',
    '--blue-40': '#3787ff',
    '--blue-50': '#325ffa',
    '--blue-60': '#2c53db',
    '--blue-70': '#1f3b9c',
    '--blue-80': '#13245e',
    '--pale-blue': '#325ffa22',
    '--faint-blue': '#325ffa11',
    '--transparent-blue': '#325ffa00',
    '--chat-blue': '#287eff',
    '--green-10': '#d4f2e1',
    '--green-20': '#a3e4bf',
    '--green-30': '#75d79e',
    '--green-40': '#47c97e',
    '--green-50': '#07a460',
    '--green-60': '#078c53',
    '--green-70': '#067847',
    '--green-80': '#045030',
    '--darker-pale-green': '#07a46044',
    '--pale-green': '#07a46022',
    '--faint-green': '#07a46011',
    '--yellow-10': '#fff2cc',
    '--yellow-20': '#ffecb2',
    '--yellow-30': '#fde38f',
    '--yellow-40': '#fbd85b',
    '--yellow-50': '#facc28',
    '--yellow-60': '#d19d20',
    '--yellow-70': '#a56915',
    '--yellow-80': '#7f3e11',
    '--pale-yellow': '#d19d2022',
    '--faint-yellow': '#d19d2011',
    '--orange-10': '#ffecdb',
    '--orange-20': '#ffcba0',
    '--orange-30': '#ffb071',
    '--orange-40': '#ff9641',
    '--orange-50': '#ec660d',
    '--orange-60': '#cf590b',
    '--orange-70': '#b14d0a',
    '--orange-80': '#763307',
    '--pale-orange': '#ec660d22',
    '--faint-orange': '#ec660d11',
    '--red-10': '#ffe5e7',
    '--red-20': '#ffc1c5',
    '--red-30': '#ff838c',
    '--red-40': '#ff5965',
    '--red-50': '#e83b47',
    '--red-60': '#cb343e',
    '--red-70': '#98272f',
    '--red-80': '#5b171b',
    '--pale-red': '#e83b4722',
    '--faint-red': '#e83b4711',
    '--half-red': '#e83b4777',
    '--calendar-red': '#d86156',
    '--black-opacity-0': 'rgba(var(--black-base-rgb),0)',
    '--black-opacity-2': 'rgba(var(--black-base-rgb),0.02)',
    '--black-opacity-4': 'rgba(var(--black-base-rgb),0.04)',
    '--black-opacity-8': 'rgba(var(--black-base-rgb),0.08)',
    '--black-opacity-16': 'rgba(var(--black-base-rgb),0.16)',
    '--black-opacity-24': 'rgba(var(--black-base-rgb),0.24)',
    '--black-opacity-32': 'rgba(var(--black-base-rgb),0.32)',
    '--black-opacity-48': 'rgba(var(--black-base-rgb),0.48)',
    '--black-opacity-64': 'rgba(var(--black-base-rgb),0.64)',
    '--black-opacity-80': 'rgba(var(--black-base-rgb),0.8)',
    '--white-base-rgb': '255, 255, 255',
    '--white-opacity-0': 'rgba(var(--white-base-rgb),0)',
    '--white-opacity-2': 'rgba(var(--white-base-rgb),0.02)',
    '--white-opacity-4': 'rgba(var(--white-base-rgb),0.04)',
    '--white-opacity-8': 'rgba(var(--white-base-rgb),0.08)',
    '--white-opacity-16': 'rgba(var(--white-base-rgb),0.16)',
    '--white-opacity-24': 'rgba(var(--white-base-rgb),0.24)',
    '--white-opacity-32': 'rgba(var(--white-base-rgb),0.32)',
    '--white-opacity-48': 'rgba(var(--white-base-rgb),0.48)',
    '--white-opacity-64': 'rgba(var(--white-base-rgb),0.64)',
    '--white-opacity-80': 'rgba(var(--white-base-rgb),0.8)',
}