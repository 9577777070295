export const SizeConfigurations = {
    // Breakpoints
    '--sm-breakpoint': '576px',
    '--md-breakpoint': '768px',
    '--lg-breakpoint': '992px',
    '--xl-breakpoint': '1200px',
    '--xxl-breakpoint': '1600px',

    // Font
    '--pc-font-huge': '30px',
    '--pc-font-large': '22px',
    '--pc-font-medium': '15px',
    '--pc-font-small': '12px',
}