import "./EventThemePicker.scss";
import { EventThemeColors } from '../../Shared/Data/EventThemeData'
import { useState } from "react";
import { getEventTheme, setEventTheme } from "../../Services/Utils/ThemeUtils";
import { ColorPicker } from "antd";
import Button from '../Button/Button';
import { useLanguage } from "../../Services/Hooks/useLanguage";

type Props = {
    setDisplayTheme?: (theme: string) => void;
}

const EventThemePicker = ({ setDisplayTheme }: Props) => {
    const { onTranslate } = useLanguage();

    const [currentTheme, setCurrentTheme] = useState<string | null>(getEventTheme());
    const [customColor, setCustomColor] = useState<string | null>(null);

    // Handle when theme color changing
    const onChangeTheme = (newColor: string) => {
        setEventTheme(newColor);
        setCurrentTheme(newColor);

        if (setDisplayTheme) {
            setDisplayTheme(newColor)
        }
    }

    // Handle when user select provided Theme
    const onSelectProvidedTheme = (newColor: string) => {
        setCustomColor(null);
        onChangeTheme(newColor)
    }

    // Handle when user select custom theme
    const onCustomColor = (e: any) => {
        const customColor = e.toHexString().toUpperCase()
        onChangeTheme(customColor);
        setCustomColor(customColor);
    }

    return (
        <div className='EventThemePicker'>
            <div className='header'>{onTranslate("LABEL.COLOR_THEME_PICKER")}</div>
            <ul className='colorPresentation'>
                {
                    EventThemeColors.map((color, i) => (
                        <li key={i} onClick={() => onSelectProvidedTheme(color)}>
                            <div className={`preview ${currentTheme?.toUpperCase() === color.toUpperCase() && 'selected'}`} style={{ backgroundColor: color }}></div>
                            <label>{color.toUpperCase()}</label>
                        </li>
                    ))
                }
                <ColorPicker size="small" placement="bottomLeft" defaultValue="#ffffff" onChange={onCustomColor}>
                    <li>
                        <div className='preview customColor'></div>
                        <label>{onTranslate("LABEL.CUSTOM_COLOR")}</label>
                        {customColor &&
                            <div className="customColorCode">({customColor})</div>
                        }
                    </li>
                </ColorPicker>
            </ul>
        </div>
    )
}

export default EventThemePicker