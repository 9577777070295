import React, { useEffect, useState } from 'react'
import { ParseIntFromString } from '../../Services/Utils/NumberUtils';
import Button from '../../Components/Button/Button';
import { APIsAuthorizeUsers } from '../../Services/APIs/APIsController';
import { ApiId } from '../../Shared/Enums/APIsEnum';
import { IResponseGetAll } from '../../Shared/Model/EventsInterface';
import { useUser } from '../../Services/Hooks/useUser';
import LoadingDots from '../../Components/LoadingDots/LoadingDots';
import { onFilterPaymentTransactions, onProcessSponsorPayment, onZaloCreateTransaction } from '../../Services/APIs/PaymentAPIs';
import { useLanguage } from '../../Services/Hooks/useLanguage';
import { IZaloPaymentCreateOrder } from '../../Shared/Model/ZaloPayment';
import { PaymentStatus, PaymentSubscriptionType } from '../../Shared/Enums/PaymentEnum';
import { message } from 'antd';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';

type Props = {
    currEvent: IResponseGetAll | undefined
}

const SponsorAdd = ({ currEvent }: Props) => {
    const { onTranslate } = useLanguage();
    const { user } = useUser();
    const [amount, setAmount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [notCompleteOrder, setNotCompleteOrder] = useState<any>(null);
    const [isViewHistory, setIsViewHistory] = useState<boolean>(false);
    const [paymentList, setPaymentList] = useState<any[]>([])

    useEffect(() => {
        onInitTransList();
    }, []);

    const handleNumberChange = (e: any) => {
        let parsedValue = e.target.value.trim() === '' ? '' : ParseIntFromString(e.target.value);

        if (parsedValue === null) return;
        setAmount(parsedValue === '' ? 0 : parsedValue as number);
    }

    const onCreateSponsorPayment = async () => {
        if (!currEvent || !user) return;
        setIsLoading(true);

        const zaloPaymentData: IZaloPaymentCreateOrder = {
            userId: user.userId,
            description: `Sponsor: ${user.fullName} sponsor ${currEvent.eventName} - `,
            amount: `${amount}`,
            eventId: currEvent.eventId,
            subscriptionType: PaymentSubscriptionType.SPONSOR,
            redirectUrl: window.location.href
        }
        const result = await onZaloCreateTransaction(zaloPaymentData);

        // Create new payment success
        onInitTransList();
        message.success(onTranslate('MESSAGE.PAYMENT_SUCCESS'));
        window.open(result.order_url);
        setIsLoading(false);
        return;
    }

    const onPayment = () => {
        window.open(notCompleteOrder.orderUrl);
    }

    const onChangeViewMode = () => {
        setIsViewHistory(!isViewHistory);
    }

    const onInitTransList = async () => {
        setIsLoading(true);
        const result = await onFilterPaymentTransactions(currEvent?.eventId, user?.userId, undefined, PaymentSubscriptionType.SPONSOR);
        console.log(result);
        setIsLoading(false);
        if (!result) return;
        setPaymentList(result);
        // Set not complete order
        const notPay = result.find((item: any) => item.status === PaymentStatus.PROCESSING);
        if (notPay) setNotCompleteOrder(notPay);
    }

    return (
        <div className='sponsorAddCtn'>
            {
                isLoading
                    ?
                    <LoadingDots />
                    :
                    <>
                        <div className="title">{onTranslate('LABEL.DEPOSIT_MONEY')}</div>
                        <div className='subtitle'>{onTranslate('LABEL.UNIT', "VND")}</div>
                        {
                            isViewHistory
                                ?
                                <>
                                    <div className='descript back' onClick={onChangeViewMode}><FaAngleLeft className='icon' /> {onTranslate('LABEL.BACK_TO_SPONSOR_PAGE')}</div>
                                    <ol className="OrderHistory">
                                        {
                                            paymentList.map((item) => (
                                                <li key={item.appTransId}>[{(new Date(item.timestamp)).toLocaleString()}]: {Number(item.amount).toLocaleString()} VND</li>
                                            ))
                                        }
                                    </ol>
                                </>

                                :
                                notCompleteOrder
                                    ?
                                    <>
                                        <div className='descript' onClick={onChangeViewMode}>{onTranslate('LABEL.CLICK_TO_VIEW_SPONSOR_HISTORY')} <FaAngleRight className='icon' /></div>
                                        <div className='notCompleteOrder'>{onTranslate('LABEL.HAVE_NOT_COMPLETE_SPONSOR', Number(notCompleteOrder.amount).toLocaleString())} </div>
                                        <Button type="primary" className='deposit' onClick={onPayment}>{onTranslate('BUTTON.PAYMENT')}</Button>
                                    </>
                                    :
                                    <>
                                        <div className='descript' onClick={onChangeViewMode}>{onTranslate('LABEL.CLICK_TO_VIEW_SPONSOR_HISTORY')} <FaAngleRight className='icon' /></div>
                                        <input type="number" value={amount} onChange={handleNumberChange} />
                                        <Button type="primary" className='deposit' onClick={onCreateSponsorPayment} disabled={amount <= 0}>{onTranslate('BUTTON.DEPOSIT')}</Button>
                                    </>
                        }
                    </>
            }
        </div >
    )
}

export default SponsorAdd