import React, { useEffect, useState } from 'react'
import { IResponseGetAll } from '../../../Shared/Model/EventsInterface'
import { IFilterCerti } from '../../../Shared/Model/CertificateInterface'
import { onCertifiedUser, onFilterCertificate } from '../../../Services/APIs/CertificateAPIs'
import Table from '../../../Components/Table/Table'
import Button from '../../../Components/Button/Button'
import { FaAngleRight, FaSquare, FaUser, FaUserCheck } from 'react-icons/fa6'
import { useLanguage } from '../../../Services/Hooks/useLanguage'
import LoadingDots from '../../../Components/LoadingDots/LoadingDots'
import { message } from 'antd'
import { useNavigate } from 'react-router-dom'

type Props = {
    currEvent: IResponseGetAll | undefined
}

const CertiTable = ({ currEvent }: Props) => {
    const { onTranslate } = useLanguage();

    const [selectedUsersId, setSelectedUsersId] = useState<string[]>([]);
    const [usersList, setUsersList] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        onInitUserCertiParticipants();
    }, []);

    const onInitUserCertiParticipants = async () => {
        if (!currEvent) return;

        const data: IFilterCerti = { eventId: currEvent.eventId };

        setIsLoading(true);
        const result = await onFilterCertificate(data);
        setIsLoading(false);

        if (!result) return;
        setUsersList(result);
    }

    const onChangeSelect = (userId: string) => {
        const newSelectedUsersId = [...selectedUsersId];
        const isCheck = newSelectedUsersId.findIndex(id => id === userId) === -1;

        if (isCheck) {
            newSelectedUsersId.push(userId);
            setSelectedUsersId(newSelectedUsersId)
            return;
        }

        const newArr = newSelectedUsersId.filter(id => id !== userId);
        setSelectedUsersId(newArr);
    }

    const handleCertifiedUser = async () => {
        if (!currEvent) return;

        setIsLoading(true);
        const result = await onCertifiedUser(currEvent.eventId, selectedUsersId);
        setIsLoading(false);

        // if (!result) return message.error('Failed');
        message.success('Certified successfully');
        setSelectedUsersId([]);
        onInitUserCertiParticipants();
    }


    const certiTab = [
        {
            title: `${onTranslate('LABEL.SELECTED')} (${selectedUsersId.length})`,
            render: (row: any) => {
                return (
                    <>
                        {
                            !row.certificate &&
                            (
                                selectedUsersId.findIndex(id => id === row.userId) === -1
                                    ? <FaUser className={`selectBox`} onClick={() => onChangeSelect(row.userId)} />
                                    : <FaUserCheck className={`selectBox selected`} onClick={() => onChangeSelect(row.userId)} />
                            )
                        }
                    </>

                )
            }
        },
        {
            title: onTranslate('LABEL.NAME'),
            key: 'name',
            dataIndex: 'fullName'
        },
        {
            title: onTranslate('LABEL.EMAIL'),
            key: 'email',
            dataIndex: 'email'
        },
        {
            title: onTranslate('LABEL.CERTIFICATE'),
            key: 'certificate',
            render: (row: any) => {
                return (
                    <>
                        {
                            !row.certificate
                                ? 'None'
                                : <div className='viewBtn' onClick={() => navigate(`/c/${row.certificate.certicateId}`)}>{onTranslate('BUTTON.VIEW')} <FaAngleRight className='icon' /></div>
                        }
                    </>
                )
            }
        },
    ]
    return (
        <div className='tablePartCertiCtn'>
            <Table columns={certiTab} data={usersList} color={currEvent?.theme} />
            {
                isLoading
                    ? <LoadingDots />
                    : <Button onClick={handleCertifiedUser} className='certifiedBtn' type='primary' disabled={selectedUsersId.length === 0}>{onTranslate('BUTTON.CERTIFIED_SELECTED_PARTICIPANTS')}</Button>
            }
        </div>
    )
}

export default CertiTable