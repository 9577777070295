import { Col, Row } from "antd";
import { useLanguage } from "../../Services/Hooks/useLanguage"
import "./Pricing.scss"
import { AppConfigurations } from "../../Shared/Constants/AppConfigurations";
import PackageCtn from "./PackageCtn";
import AdvPricePre from "./AdvPricePre";
import { getAllPrices } from "../../Services/APIs/PriceAPIs";
import { useEffect, useState } from "react";
import { PriceEnum } from "../../Shared/Enums/PriceEnum";
import LoadingDots from "../../Components/LoadingDots/LoadingDots";

type Props = {}

const Pricing = (props: Props) => {
  const { onTranslate } = useLanguage();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allPrices, setAllPrices] = useState<any[]>([]);

  useEffect(() => {
    onInitPrice();
  }, [])

  const onInitPrice = async () => {
    setIsLoading(true);
    const result = await getAllPrices();

    setIsLoading(false);
    if (!result) return;
    setAllPrices(result);
  }
  return (
    <Row className="pricing">
      <Col span={AppConfigurations.blankCol.span} sm={AppConfigurations.blankCol.sm}></Col>

      <Col span={AppConfigurations.contentCol.span} sm={AppConfigurations.contentCol.sm}>
        {
          isLoading
            ? <LoadingDots />
            :
            <>
              <div className="title">{onTranslate('LABEL.PRICING').toUpperCase()}</div>
              <div className="title_desc">{onTranslate('LABEL.TRANSACTION_NO_MORE_FEE')}</div>

              <div className="subTitle">{onTranslate('LABEL.ACCOUNT_PACKAGE')}</div>
              <div className="subTitle_desc">{onTranslate('LABEL.ACCOUNT_PACKAGE_SUB')}</div>
              <Row className="allPkgs">
                <Col span={24} sm={12} style={{marginBottom: '15px'}}>
                  <PackageCtn price={0} packageType="FREE" />
                </Col>
                <Col span={24} sm={12}>
                  <PackageCtn price={allPrices.find(x => x.priceId === PriceEnum.PACKAGE)?.amount} packageType="PREMIUM" />
                </Col>
              </Row>

              <div className="subTitle">{onTranslate('LABEL.EVENT_ADVERTISEMENT')}</div>
              <div className="subTitle_desc">{onTranslate('LABEL.EVENT_ADVERTISEMENT_SUB')}</div>
              <div className="subTitle_desc">{onTranslate('LABEL.EVENT_ADVERTISEMENT_SUB2')}</div>
              <AdvPricePre price={allPrices.find(x => x.priceId === PriceEnum.ADVERTISE)?.amount} />
            </>
        }
      </Col>

      <Col span={AppConfigurations.blankCol.span} sm={AppConfigurations.blankCol.sm}></Col>
    </Row>
  )
}

export default Pricing