import React, { useEffect, useState } from 'react'
import { onGetUserResponseOfQuiz } from '../../Services/APIs/QuizAPIs';
import { useUser } from '../../Services/Hooks/useUser';
import { getLetterFromIndex } from '../../Services/Utils/StringUtils';
import { FaCircle, FaRegCircle } from 'react-icons/fa6';
import LoadingDots from '../../Components/LoadingDots/LoadingDots';
import { useLanguage } from '../../Services/Hooks/useLanguage';

type Props = {
    quizId: string | undefined
}

const ViewAllResponses = ({ quizId }: Props) => {
    const { user } = useUser();
    const { onTranslate } = useLanguage();

    const [allUserResponses, setAllUserResponses] = useState<any>([]);
    const [currViewQNo, setCurrViewQNo] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        onInitUserResponse();
    }, [])

    const onInitUserResponse = async () => {
        if (!quizId || !user) return;

        setIsLoading(true);
        const result = await onGetUserResponseOfQuiz(quizId, user.userId);
        setIsLoading(false);
        console.log(result)

        if (!result) return;
        setAllUserResponses(result)
    }
    return (
        <div className='viewAllResponsesCtn'>
            <ul className='responsesNoCtn'>
                {
                    allUserResponses.map((response: any, index: number) => {
                        return <li onClick={() => setCurrViewQNo(index)} className={`${currViewQNo === index ? 'currView' : ''}`}>{index + 1}</li>
                    })
                }
            </ul>
            <div className='title'>{onTranslate('LABEL.YOUR_RESPONSE')}</div>
            {
                isLoading
                    ? <LoadingDots />
                    :
                    allUserResponses[currViewQNo]?.value?.length > 0
                    &&
                    <>
                        {
                            allUserResponses[currViewQNo]?.value?.map((userAnswered: any, i: number) => {
                                return (
                                    <div className='questionAttempt'>
                                        <div className={`questionName`}>{i + 1}. {userAnswered.question.questionName}</div>
                                        {
                                            !userAnswered.question.isMultipleAnswers
                                                ?
                                                <>
                                                    <textarea value={userAnswered.answerContent} disabled placeholder='answer...' rows={3} className='answerInput' />
                                                </>
                                                :
                                                <ul className='multileAnswersCtn'>
                                                    {
                                                        userAnswered.question.answers.map((a: any, i: number) => {
                                                            return <li
                                                                key={a.answerId}>
                                                                {userAnswered.answerId === a.answerId ? <FaCircle className='icon' /> : <FaRegCircle className='icon' />}
                                                                {getLetterFromIndex(i)}. {a.answerContent}
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                        }
                                    </div>
                                )
                            })
                        }
                    </>

            }
        </div>
    )
}

export default ViewAllResponses