import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { DarkTheme } from '../../Shared/Constants/Theme/DarkTheme';
import { avgColor } from '../../Services/Utils/ThemeUtils';
import MonthlyEventsChart from './MonthlyEventsChart';

type Props = {
    monthlyUsers: any[]
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const MonthlyUsersChart = ({ monthlyUsers }: Props) => {
    const data = {
        labels: monthlyUsers.map((data) => data.month),
        datasets: [{
            label: 'New Users',
            data: monthlyUsers.map((data) => data.total),
            backgroundColor: avgColor(DarkTheme['--brand-color'], DarkTheme['--quaternary-text-color']),
            borderColor: DarkTheme['--quaternary-bg-color'],
            borderWidth: 1
        }]
    };

    const options = {
        scales: {
            x: {
                type: 'category',
                labels: monthlyUsers.map((data) => data.month),
                ticks: {
                    color: DarkTheme['--secondary-text-color'] // X axis text color
                },
                grid: {
                    color: DarkTheme['--quaternary-text-color'] // X axis grid line color
                }
            },
            y: {
                beginAtZero: true,
                ticks: {
                    color: DarkTheme['--secondary-text-color'] // X axis text color
                },
                grid: {
                    color: DarkTheme['--quaternary-text-color'] // X axis grid line color
                }
            }
        },
        borderColor: DarkTheme['--quaternary-bg-color'],
    };

    return (
        <Bar data={data} options={options as any} />
    )
}

export default MonthlyUsersChart