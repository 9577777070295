import { Col, Row, Timeline } from "antd"
import "./About.scss"
import { AppConfigurations } from "../../Shared/Constants/AppConfigurations"
import { DarkTheme } from "../../Shared/Constants/Theme/DarkTheme"
import WebCrystalIcon from "../../Components/WebLogo/WebCrystalIcon"
import WebStarIcon from "../../Components/WebLogo/WebStarIcon"
import { useLanguage } from "../../Services/Hooks/useLanguage"

type Props = {}

const About = (props: Props) => {
    const { onTranslate } = useLanguage();
    return (
        <Row className="about">
            <Col span={AppConfigurations.blankCol.span} sm={AppConfigurations.blankCol.sm}></Col>
            <Col span={AppConfigurations.contentCol.span} sm={AppConfigurations.contentCol.sm}>
                <div className="group">
                    <div className="infoCtn">
                        <div className="title">{onTranslate('LABEL.ABOUT_VISION')}</div>
                        {/* <div className="divider"></div> */}
                        <div className="content">{onTranslate('LABEL.ABOUT_VISION_DESCRIPTION')}</div>
                    </div>
                </div>
                <div className="group">
                    <div className="infoCtn">
                        <div className="title">{onTranslate('LABEL.ABOUT_DESTINY')}</div>
                        {/* <div className="divider"></div> */}
                        <div className="content">{onTranslate('LABEL.ABOUT_DESTINY_DESCRIPTION')}</div>
                    </div>
                </div>

                <div className="itemTitle">{onTranslate('LABEL.ABOUT_VALUE')}</div>
                <Timeline
                    mode="alternate"
                    items={[
                        {
                            color: DarkTheme["--brand-color"],
                            children: (
                                <div className="timelineGroup">
                                    <div className="timelineGroup-title">{onTranslate('LABEL.ABOUT_VALUE_1_TITLE')}</div>
                                    <div className="timelineGroup-content">{onTranslate('LABEL.ABOUT_VALUE_1_DESCRIPTION')}</div>
                                </div>
                            )
                        },
                        {
                            color: DarkTheme["--brand-color"],
                            children: (
                                <div className="timelineGroup">
                                    <div className="timelineGroup-title">{onTranslate('LABEL.ABOUT_VALUE_2_TITLE')}</div>
                                    <div className="timelineGroup-content">{onTranslate('LABEL.ABOUT_VALUE_2_DESCRIPTION')}</div>
                                </div>
                            )
                        },
                        {
                            color: DarkTheme["--brand-color"],
                            children: (
                                <div className="timelineGroup">
                                    <div className="timelineGroup-title">{onTranslate('LABEL.ABOUT_VALUE_3_TITLE')}</div>
                                    <div className="timelineGroup-content">{onTranslate('LABEL.ABOUT_VALUE_3_DESCRIPTION')}</div>
                                </div>
                            )
                        },
                        {
                            color: DarkTheme["--brand-color"],
                            children: (
                                <div className="timelineGroup">
                                    <div className="timelineGroup-title">{onTranslate('LABEL.ABOUT_VALUE_4_TITLE')}</div>
                                    <div className="timelineGroup-content">{onTranslate('LABEL.ABOUT_VALUE_4_DESCRIPTION')}</div>
                                </div>
                            )
                        },
                        {
                            color: DarkTheme["--brand-color"],
                            children: (
                                <div className="timelineGroup">
                                    <div className="timelineGroup-title">{onTranslate('LABEL.ABOUT_VALUE_5_TITLE')}</div>
                                    <div className="timelineGroup-content">{onTranslate('LABEL.ABOUT_VALUE_5_DESCRIPTION')}</div>
                                </div>
                            )
                        }
                    ]}
                />
            </Col>
            <Col span={AppConfigurations.blankCol.span} sm={AppConfigurations.blankCol.sm}></Col>
        </Row>
    )
}

export default About