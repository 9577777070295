import { useEffect, useRef, useState } from "react";
import Table from "../../../Components/Table/Table";
import { IResponseGetAll } from "../../../Shared/Model/EventsInterface";
import { TableColumn, comitteeColumn, onlyInfoColumn, participantsColumn } from "../Overview/TableColumns";
import "./Participants.scss";
import { useUser } from "../../../Services/Hooks/useUser";
import Button from "../../../Components/Button/Button";
import { DatePicker, Modal } from "antd";
import AddCommittee from "./AddCommittee";
import { useLoading } from "../../../Services/Hooks/useLoading";
import UsersSponsorRequestPreview from "../Overview/UsersSponsorRequestPreview";
import { handleImageValidation, readDataAsUrl } from "../../../Services/Utils/ImageUtils";
import { useLanguage } from "../../../Services/Hooks/useLanguage";
import { onGetTotalParticipantsChart } from "../../../Services/APIs/EventsApis";
import { IChartParticipants } from "../../../Shared/Model/Others";
import ParticipantsChart from "./ParticipantsChart";
import { FaArrowRight, FaLock, FaMagnifyingGlass } from "react-icons/fa6";
import { getTextColorBaseOnBackground } from "../../../Services/Utils/ThemeUtils";
import dayjs, { Dayjs } from "dayjs";
import { convertDayjsToDate } from "../../../Services/Utils/TimeUtils";
import LoadingDots from "../../../Components/LoadingDots/LoadingDots";

type Props = {
    checkinList: any;
    currEvent: IResponseGetAll | undefined,
    onInitCheckinList: () => void;
    sponsorRequests: any;
}

const Participants = ({ checkinList, currEvent, onInitCheckinList, sponsorRequests }: Props) => {
    const { onTranslate } = useLanguage();
    const { user } = useUser();
    const { updateLoading } = useLoading();
    const inputRef = useRef(null);

    const [currViewRequest, setCurrViewRequest] = useState<any>(null);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [columns, setColumns] = useState<any[]>(() => TableColumn(currEvent?.eventId as string, onInitCheckinList));
    const [comCols, setComCols] = useState<any[]>(() => comitteeColumn(currEvent as IResponseGetAll, updateLoading, onInitCheckinList));
    const [chartParticipants, setChartParticipants] = useState<any[]>([]);

    const [searchStartDate, setSearchStartDate] = useState<Dayjs>(dayjs().subtract(7, 'day'));
    const [searchEndDate, setSearchEndDate] = useState<Dayjs>(dayjs());
    const [isSearching, setIsSearching] = useState<boolean>(false);

    useEffect(() => {
        onInitParticipantsChart();
    }, []);

    const onInitParticipantsChart = async () => {
        if (!currEvent || !user?.isPremiumUser || isSearching) return;

        setIsSearching(true);
        const data: IChartParticipants = {
            eventId: currEvent.eventId,
            startDate: convertDayjsToDate(searchStartDate).toISOString(),
            endDate: convertDayjsToDate(searchEndDate).toISOString(),
            isDay: true
        }
        const result = await onGetTotalParticipantsChart(data);
        setIsSearching(false);

        if (!result) return
        setChartParticipants(result.dailyParticipation);
    }
    const onViewRequest = (user: any) => {
        const foundedRequest = sponsorRequests.find((req: any) => req.email === user.email);
        setCurrViewRequest(foundedRequest);
    }

    // Image
    const onClickAddLogo = (userId: string) => {
        (inputRef.current as any).click();
    }

    const [sponsorCols, setSponsorCols] = useState<any>(() => onlyInfoColumn(onViewRequest, onClickAddLogo));

    const handleInputChange = async (e: any) => {
        const currFiles = e.target.files;

        if (currFiles.length === 0) return;

        handleUploadImage(currFiles);
    }

    const handleUploadImage = async (currFiles: any) => {


        // loading
        const resultFile = await handleImageValidation(currFiles);

        if (resultFile) {
            const dataUrlFile = await readDataAsUrl(resultFile) as string;

            if (dataUrlFile) {
                // Call Api
            }
        }

        // stop loading
    }

    return (
        <div className="participants">

            <input type="file" onChange={(e) => handleInputChange(e)} ref={inputRef} hidden />

            <Modal open={isShowModal} footer={null} onCancel={() => setIsShowModal(false)}>
                <AddCommittee committeeList={checkinList[0]} currEvent={currEvent} onInitCheckinList={onInitCheckinList} />
            </Modal>
            <Modal open={Boolean(currViewRequest)} footer={null} onCancel={() => setCurrViewRequest(null)}>
                <UsersSponsorRequestPreview setCurrViewRequest={setCurrViewRequest} currViewRequest={currViewRequest} />
            </Modal>

            <div className="title">{onTranslate('LABEL.PENDING_REQUEST')} ({checkinList[2]?.length})</div>
            <div className="subtitle">{onTranslate('LABEL.PENDING_REQUEST_DESCRIPTION')}</div>
            <Table columns={columns} data={checkinList[2]} color={currEvent?.theme} />

            <div className="divider" style={{ margin: '25px 0 25px 0' }}></div>

            <div className="headerCtn">
                <div className="info">
                    <div className="title">{onTranslate('LABEL.ORGANIZING_COMMITTEE')} ({checkinList[0]?.length + 1})</div>
                    <div className="subtitle">{onTranslate('LABEL.ORGANIZING_COMMITTEE_DESCRIPTION')}</div>
                </div>
                <Button className="addComitteeBtn" onClick={() => setIsShowModal(true)}>{onTranslate('BUTTON.ADD')}</Button>
            </div>
            <Table columns={comCols} data={[user, ...checkinList[0]]} color={currEvent?.theme} />

            <div className="divider" style={{ margin: '25px 0 25px 0' }}></div>

            <div className="title">{onTranslate('LABEL.SPONSOR')} ({checkinList[1]?.length})</div>
            <div className="subtitle">{onTranslate('LABEL.SPONSOR_DESCRIPTION')}</div>
            <Table columns={sponsorCols} data={checkinList[1]} color={currEvent?.theme} />

            <div className="divider" style={{ margin: '25px 0 25px 0' }}></div>

            <div className="title">{onTranslate('LABEL.PARTICIPANTS')} ({onTranslate('BUTTON.CHECKEDIN')}: {checkinList[4]?.length}, {onTranslate('LABEL.NOT_CHECKIN')}: {checkinList[5]?.length})</div>
            <div className="subtitle">{onTranslate('LABEL.PARTICIPANTS_DESCRIPTION')}</div>
            <Table columns={participantsColumn} data={[...checkinList[4], ...checkinList[5]]} color={currEvent?.theme} />

            <div className="title" style={{ marginTop: '30px' }}>{onTranslate('LABEL.PARTICIPANTS_CHART')}</div>
            <div className="subtitle">{onTranslate('LABEL.PARTICIPANTS_CHART_DESCRIPTION')}</div>
            <div className="searchCtn">
                <DatePicker size="small" style={{ marginRight: '10px' }} value={searchStartDate} format="DD/MM/YYYY" onChange={(e) => setSearchStartDate(e)} />
                <FaArrowRight style={{ marginRight: '10px' }} />
                <DatePicker size="small" style={{ marginRight: '10px' }} value={searchEndDate} format="DD/MM/YYYY" onChange={(e) => setSearchEndDate(e)} />
                {
                    !user?.isPremiumUser
                        ? <FaLock style={{ color: 'red' }} />
                        :
                        isSearching
                            ? <LoadingDots color={currEvent?.theme} />
                            :
                            <Button className="searchBtn" type="link" onClick={onInitParticipantsChart}>

                                <FaMagnifyingGlass />
                            </Button>


                }
            </div>
            <div className="chartCtn" style={{ position: 'relative', marginBottom: '20px' }}>
                {!user?.isPremiumUser && <FaLock style={{ fontSize: '35px', color: 'red', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}
                <div style={!user?.isPremiumUser ? { filter: 'blur(5px)' } : {}}>
                    <ParticipantsChart monthlyEvents={chartParticipants} eventThemeColor={currEvent?.theme as string} />
                </div>
            </div>
        </div >
    )
}

export default Participants