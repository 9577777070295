import { Modal } from "antd";
import Button from "../../../Components/Button/Button";
import { useLanguage } from "../../../Services/Hooks/useLanguage";
import "./Quiz.scss"
import CreateQuizModal from "./CreateQuizModal";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IQuiz } from "../../../Shared/Model/QuizInterface";
import { onGetQuizByEvent } from "../../../Services/APIs/QuizAPIs";
import QuizView from "./QuizView";
import QuestionsManage from "./QuestionsManage/QuestionsManage";
import LoadingDots from "../../../Components/LoadingDots/LoadingDots";

type Props = {
    eventTheme: string | undefined
}

const Quiz = ({ eventTheme }: Props) => {
    const { onTranslate } = useLanguage();
    const params = useParams();

    const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);
    const [quizList, setQuizList] = useState<IQuiz[]>([]);
    const [updatedQuiz, setUpdatedQuiz] = useState<IQuiz | null>(null);
    const [manageQuestionQuiz, setManageQuestionQuiz] = useState<IQuiz | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        onInitQuizList();
    }, []);

    const onInitQuizList = async () => {
        if (!params.eventId) return;

        setIsLoading(true);
        const result = await onGetQuizByEvent(params.eventId);
        setIsLoading(false);

        if (!result) return;
        setQuizList(result);
    }

    const onClickUpdateQuiz = (quiz: IQuiz) => {
        setUpdatedQuiz(quiz);
        setIsShowCreateModal(true);
    }

    const onCloseModal = () => {
        setUpdatedQuiz(null);
        setIsShowCreateModal(false);
    }

    const onManageQuizQuestion = (selectedQuiz: IQuiz) => {
        setManageQuestionQuiz(selectedQuiz);
        setUpdatedQuiz(null);
        setIsShowCreateModal(false);
    }

    return (
        <>
            <Modal open={isShowCreateModal} footer={null} onCancel={onCloseModal} onClose={onCloseModal}>
                <CreateQuizModal onManageQuizQuestion={onManageQuizQuestion} updateData={updatedQuiz} eventId={params.eventId as string} onCloseModal={onCloseModal} onInitQuizList={onInitQuizList} />
            </Modal>

            {
                isLoading ? <LoadingDots /> :
                    manageQuestionQuiz
                        ? <QuestionsManage manageQuestionQuiz={manageQuestionQuiz} setManageQuestionQuiz={setManageQuestionQuiz} />
                        :
                        <div className="Quiz">
                            <div className="quizHeader">
                                <div className="info">
                                    <div className="title">{onTranslate('LABEL.QUIZ')}</div>
                                    <div className="subtitle">{onTranslate('LABEL.QUIZ_DESCRIPTION')}</div>
                                </div>
                                <Button className="createQuizBtn" onClick={() => setIsShowCreateModal(true)}>{onTranslate('BUTTON.CREATE')}</Button>
                            </div>

                            <div className="quizList">
                                {
                                    quizList.map(quiz => <QuizView onInitQuizList={onInitQuizList} onClickUpdateQuiz={onClickUpdateQuiz} quiz={quiz} eventTheme={eventTheme} />)
                                }
                            </div>
                        </div>
            }
        </>

    )
}

export default Quiz