import { Col, Row } from "antd";
import "./Events.scss";
import { AppConfigurations } from "../../Shared/Constants/AppConfigurations";
import GridSwitch from "../../Components/GridSwitch/GridSwitch";
import { IGridSwitch } from "../../Shared/Model/Others";
import Timeline from "../../Components/Timeline/Timeline";
import { TestEventsData } from "../../Shared/TestData/Events";
import { useEffect, useState } from "react";
import { APIsAuthorizeUsers } from "../../Services/APIs/APIsController";
import { ApiId } from "../../Shared/Enums/APIsEnum";
import { useLoading } from "../../Services/Hooks/useLoading";
import LoadingDots from "../../Components/LoadingDots/LoadingDots";
import { useLanguage } from "../../Services/Hooks/useLanguage";

type Props = {}


const Events = (props: Props) => {
  const { onTranslate } = useLanguage();

  const viewType: IGridSwitch[] = [
    { id: 'future', label:  onTranslate('LABEL.FUTURE')},
    { id: 'past', label: onTranslate('LABEL.PAST') },
  ];

  const [currOpts, setCurrOpts] = useState<IGridSwitch>(viewType[0]);
  const [inCommingEvents, setInCommingEvents] = useState<any>([]);
  const [pastEvents, setPastEvents] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    onInitMyEvents();
  }, [])

  const onOptChange = (newOpt: IGridSwitch) => {
    setCurrOpts(newOpt);
  }

  const onInitMyEvents = async () => {
    setIsLoading(true);
    const myEvents = await APIsAuthorizeUsers(ApiId.MY_EVENT);
    setIsLoading(false);

    if (!myEvents) return;
    setInCommingEvents(myEvents.IncomingEvent);
    setPastEvents(myEvents.PastEvent);
  }

  return (
    <div className='EventsPage linearBGLighter'>
      <Row className="eventsContainer">
        <Col span={AppConfigurations.blankCol.span} sm={AppConfigurations.blankCol.sm}></Col>

        <Col span={AppConfigurations.contentCol.span} sm={AppConfigurations.contentCol.sm}>
          <div className="EventsCtn_Header">
            <div className="title">{onTranslate('LABEL.EVENTS')}</div>
            <GridSwitch options={viewType} onChange={(e) => onOptChange(e)} />
          </div>

          <div className="EventsCtn_Content">
            {
              isLoading
                ?
                <LoadingDots />
                :
                <Timeline mockArr={currOpts.id === 'past' ? pastEvents : inCommingEvents} />
            }
          </div>
        </Col>

        <Col span={AppConfigurations.blankCol.span} sm={AppConfigurations.blankCol.sm}></Col>
      </Row>
    </div>
  )
}

export default Events