import { Slider } from 'antd';
import React, { useState } from 'react'
import { useLanguage } from '../../Services/Hooks/useLanguage';

type Props = {
    price: number;
}

const AdvPricePre = ({ price }: Props) => {
    const { onTranslate } = useLanguage();
    const [value, setValue] = useState<number>(1);

    return (
        <div className='advPricePre'>
            <div className='money'>{value} {onTranslate('LABEL.DAYS')} ~ <b>{(33000 * value).toLocaleString() ?? 0} VND</b></div>
            <Slider
                min={1}
                max={100}
                value={value}
                onChange={(value) => setValue(value)}
            />
        </div>
    )
}

export default AdvPricePre