export enum UserRole {
    ADMIN = 3,
    USER = 2,
    GUEST = 1,
}

export enum UserGender {
    FEMALE = 'Female',
    MALE = 'Male',
    UNKNOWN = 'Unknown'
}

export enum AccountStatus {
    ACTIVE = 'Active',
    BLOCKED = 'Blocked'
}